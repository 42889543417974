import moment from 'moment';
import {
    getOwnEvents,
    getProperRoomId,
    returnAuthenticated
} from '../../context/getData';
import { fetchEvents } from './CalendarFunctions';
const shouldEventBeVisible = (event, isBookingCalendar) => {
    // Check if the event is an own event
    if (event.isOwnEvent) {
        return true;
    } else {
        if (isBookingCalendar) {
            return false;
        } else {
            return true;
        }
    }
};

export const createEvents = (eventsData, isBookingCalendar, ownEvents) => {
    const events = [];
    // If isBookingCalendar is true, then it means that we only have to display available timeslots
    // If isBookingCalendar is false, we only have to display the events.
    // Create event objects for each event
    eventsData.forEach((event) => {
        const formattedDate = moment(event.date).format('YYYY-MM-DD');
        const dateWithTime = formattedDate + ' ' + event.start;
        // console.log('show event', event);
        const newEvent = {
            event_id: event.id,
            title: event.title,
            date: event.startdate,
            startTime: event.start.slice(0, 5),
            endTime: event.end.slice(0, 5),
            type: event.type,
            dateTimeValue: moment(dateWithTime, 'YYYY-MM-DD HH:mm'),
            roomId: event.roomId,
            repeatdays: event.repeatdays,
            enddate: event.enddate,
            repeatSeqId: event.repeatSeqId,
            state: event.state,
            shouldBeVisible: shouldEventBeVisible(event, isBookingCalendar),
            isPrivate: event.isPrivate,
            userId: event.userId
        };

        events.push(newEvent);
    });
    if (events && ownEvents && events.length > 0 && ownEvents.length > 0) {
        for (let i = 0; i < events.length; i++) {
            for (let j = 0; j < ownEvents.length; j++) {
                if (events[i].event_id === ownEvents[j].id) {
                    events[i].isOwnEvent = true;
                }
            }
        }
    }

    return events;
};

export const getEventsOfTheDay = (events, date) => {
    const eventsOfTheDay = events.filter(
        (event) => moment(event.date).format('YYYY-MM-DD') === date
    );

    return eventsOfTheDay;
};

export const getEventsOfTheRoom = (events, room) => {
    const eventsOfTheRoom = events.filter(
        (event) => event.roomId === getProperRoomId(room)
    );
    return eventsOfTheRoom;
};

// StartTime = the start time of the this available event
// EndTime = end time of this available event
export const createCustomAvailableEvents = (startTime, endTime, dateOfDay) => {
    let isBookingCalendar;
    if (window.location.href.includes('booking-kalender')) {
        isBookingCalendar = true;
    } else {
        isBookingCalendar = false;
    }

    const startTimeDate =
        moment(dateOfDay).format('YYYY-MM-DD') + ' ' + startTime;
    const startDateTimeValue = moment(startTimeDate, 'YYYY-MM-DD HH:mm');

    const endTimeDate = moment(dateOfDay).format('YYYY-MM-DD') + ' ' + endTime;
    const endDateTimeValue = moment(endTimeDate, 'YYYY-MM-DD HH:mm');

    const duration = moment(endDateTimeValue).diff(
        moment(startDateTimeValue),
        'minutes'
    );

    //If the duration is less than 15 minutes, then no available event is going to be displayed.
    if (duration >= 15) {
        return {
            title: 'Ledig',
            date: dateOfDay,
            dateTimeValue: moment(
                dateOfDay + ' ' + startTime,
                'YYYY-MM-DD HH:mm'
            ),
            startTime,
            endTime,
            isAvailable: true,
            shouldBeVisible: isBookingCalendar ? true : false
        };
    } else {
        return {};
    }
};

// Sorting events according to start time
export const sortEvents = (eventsArray) => {
    // This did not work in Safari!!!
    // return eventsArray.sort(
    //   (a, b) =>
    //     new Date(a.date + " " + a.startTime) -
    //     new Date(b.date + " " + b.startTime)
    // );
    // This works in Safari too!!
    return eventsArray.sort((a, b) =>
        moment(a.date + ' ' + a.startTime).diff(
            moment(b.date + ' ' + b.startTime)
        )
    );
};

// export const sortEventsByStartDate = (eventsArray) => {
//   // return eventsArray.sort(
//   //   (a, b) =>
//   //     new Date(a.startdate + " " + a.starttime) -
//   //     new Date(b.startdate + " " + b.starttime)
//   // );

// };

const generateFirstFreeEvent = (allMeetings) => {
    return createCustomAvailableEvents(
        '08:00',
        allMeetings[0].startTime,
        allMeetings[0].date
    );
};

// Generate last free event - end time depends on if it is Friday/Saturday, or other days
const generateLastFreeEvent = (allMeetings, dayName) => {
    const lastMeetingIndex = allMeetings.length - 1;
    if (isFridayOrSaturday(dayName)) {
        return createCustomAvailableEvents(
            allMeetings[lastMeetingIndex].endTime,
            '24:00',
            allMeetings[lastMeetingIndex].date
        );
    } else {
        return createCustomAvailableEvents(
            allMeetings[lastMeetingIndex].endTime,
            '23:00',
            allMeetings[lastMeetingIndex].date
        );
    }
};

// (Friday and Saturday has longer opening hours)
const isFridayOrSaturday = (dayName) => {
    if (dayName === 'lørdag' || dayName === 'fredag') {
        return true;
    } else {
        return false;
    }
};

// Creates free events for the whole day
const getWholeDayFreeEvents = (eventArray, date, dayName) => {
    let newEvent;
    // Check if day is Thursday or Friday and set end time according to that
    if (isFridayOrSaturday(dayName)) {
        newEvent = createCustomAvailableEvents('08:00', '24:00', date);
    } else {
        newEvent = createCustomAvailableEvents('08:00', '23:00', date);
    }
    eventArray.push(newEvent);
};

// All events contains both available time slots and events
export const getAllEvents = (allMeetings, date, dayName) => {
    const freeEvents = [];
    // Create free events according to the number of meetings on the day
    if (allMeetings.length < 1) {
        getWholeDayFreeEvents(allMeetings, date, dayName);
        //  getWholeRoomFreeEvents(allMeetings, date, dayName);
    } else {
        for (let i = 0; i < allMeetings.length; i++) {
            if (allMeetings.length > 1) {
                if (i === 0) {
                    // Create free event before the first meeting
                    freeEvents.push(generateFirstFreeEvent(allMeetings));
                } else if (i === allMeetings.length - 1) {
                    // Create free event before and after the last meeting
                    freeEvents.push(
                        createCustomAvailableEvents(
                            allMeetings[i - 1].endTime,
                            allMeetings[i].startTime,
                            allMeetings[i].date
                        )
                    );
                    freeEvents.push(
                        generateLastFreeEvent(allMeetings, dayName)
                    );
                } else {
                    freeEvents.push(
                        createCustomAvailableEvents(
                            allMeetings[i - 1].endTime,
                            allMeetings[i].startTime,
                            allMeetings[i].date
                        )
                    );
                }
            } else if (allMeetings.length === 1) {
                freeEvents.push(generateFirstFreeEvent(allMeetings));
                freeEvents.push(generateLastFreeEvent(allMeetings, dayName));
            }
        }
    }

    //Remove empty arrays
    const filteredFreeEvents = freeEvents.filter(
        (event) => Object.keys(event).length !== 0
    );
    // Merge the array of meetings and free events, and sort them
    const meetingsWithFreeEvents = sortEvents([
        ...allMeetings,
        ...filteredFreeEvents
    ]);
    return meetingsWithFreeEvents;
};

// Takes a time string like 'HH:MM' and returns the hours
export const getHour = (timeString) => {
    return timeString.slice(0, 2);
};

export const getMinute = (timeString) => {
    return timeString.slice(3, 5);
};

// eslint-disable-next-line
// const updateTodaysStartdate = (self) => {
//     const now = new Date();
//     const minutes = moment(now).format('mm');
//     const hours = moment(now).format('HH');
//     var m = ((((minutes + 7.5) / 15) | 0) * 15) % 60;
//     var h = (((minutes / 105 + 0.5) | 0) + hours) % 24;
//     if (moment(self.state.startdate).isBefore(moment(now))) {
//         // Get the closest next quarter hour and minute to now.
//         // Should get a moment function, which rounds up time to the closest quarter.
//         // if (moment(self.state.startdate).isBefore(moment)) self.setState({});
//     }
// };

export const setTimesForEditingEvents = (
    allEventsOfTheDay,
    self,
    selectedEventIdToEdit
) => {
    // updateTodaysStartdate();
    // We need to check if there is an available timeslot or an other event before this existing event
    if (allEventsOfTheDay) {
        // Find the index of this event which is to be edited
        const currentEventIndex = allEventsOfTheDay.findIndex(
            (event) => event.event_id === selectedEventIdToEdit
        );

        const updateEnddate = (repeatdays, enddate) => {
            if (repeatdays && repeatdays !== 0) {
                return new Date(enddate);
            } else {
                return self.state.enddate;
            }
        };

        if (currentEventIndex === 0) {
            // ---------------> If it is the first event in the array, then we need to check if the next event is available or not <-----------
            if (
                allEventsOfTheDay.length > 1 &&
                allEventsOfTheDay[currentEventIndex + 1].event_id
            ) {
                // ---------->  If there is an event afterwards, we need to set the endTime to the beginning of that event <------------
                self.setState({
                    availableEndTime: allEventsOfTheDay[1].startTime,
                    endHour: getHour(allEventsOfTheDay[0].endTime),
                    endMinute: getMinute(allEventsOfTheDay[0].endTime),
                    originalEndTime: allEventsOfTheDay[0].endTime,
                    // repeatdays: allEventsOfTheDay[0].repeatdays,
                    enddate: updateEnddate(
                        allEventsOfTheDay[currentEventIndex].repeatdays,
                        allEventsOfTheDay[currentEventIndex].enddate
                    ),
                    repeatSeqId:
                        allEventsOfTheDay[currentEventIndex].repeatSeqId,
                    repeatdays: allEventsOfTheDay[currentEventIndex].repeatdays
                });
            } else {
                // --------> It is either a free time slot or just one all day event <-----------
                if (allEventsOfTheDay.length === 1) {
                    // -----> There is only one event that takes the whole day. <---------
                    self.setState({
                        originalEndTime: allEventsOfTheDay[0].endTime,
                        repeatSeqId:
                            allEventsOfTheDay[currentEventIndex].repeatSeqId,
                        repeatdays:
                            allEventsOfTheDay[currentEventIndex].repeatdays,
                        enddate: updateEnddate(
                            allEventsOfTheDay[currentEventIndex].repeatdays,
                            allEventsOfTheDay[currentEventIndex].enddate
                        )
                    });
                } else {
                    // ------> There are more events and the next is an available time slot <--------
                    // Get the endtime of the available time slot
                    const newEndTime =
                        allEventsOfTheDay[currentEventIndex + 1].endTime;
                    self.setState({
                        originalEndTime: allEventsOfTheDay[0].endTime,
                        newAvailableEndTime: newEndTime,
                        // repeatdays: allEventsOfTheDay[0].repeatdays,
                        enddate: updateEnddate(
                            allEventsOfTheDay[0].repeatdays,
                            allEventsOfTheDay[0].enddate
                        ),
                        repeatSeqId:
                            allEventsOfTheDay[currentEventIndex].repeatSeqId,
                        repeatdays:
                            allEventsOfTheDay[currentEventIndex].repeatdays
                    });
                }
            }
        } else if (currentEventIndex === allEventsOfTheDay.length - 1) {
            // --------> If it is the last event in the array <------------
            // Find out if the previous event is an available time slot or an actual event
            if (
                allEventsOfTheDay[currentEventIndex - 1] &&
                allEventsOfTheDay[currentEventIndex - 1].event_id
            ) {
                // It is an event, we don't need to set anything with starttimes
                self.setState({
                    originalEndTime:
                        allEventsOfTheDay[currentEventIndex].endTime,
                    repeatSeqId:
                        allEventsOfTheDay[currentEventIndex].repeatSeqId,
                    repeatdays: allEventsOfTheDay[currentEventIndex].repeatdays,
                    enddate: updateEnddate(
                        allEventsOfTheDay[currentEventIndex].repeatdays,
                        allEventsOfTheDay[currentEventIndex].enddate
                    )
                });
            } else {
                // it is an available time slot, so find the start time of that, and set as newAvailableStartTime
                const newStartTime =
                    allEventsOfTheDay[currentEventIndex - 1].startTime;
                self.setState({
                    originalEndTime:
                        allEventsOfTheDay[currentEventIndex].endTime,
                    originalStartTime:
                        allEventsOfTheDay[currentEventIndex].startTime,
                    newAvailableStartTime: newStartTime,
                    repeatSeqId:
                        allEventsOfTheDay[currentEventIndex].repeatSeqId,
                    repeatdays: allEventsOfTheDay[currentEventIndex].repeatdays,
                    enddate: updateEnddate(
                        allEventsOfTheDay[currentEventIndex].repeatdays,
                        allEventsOfTheDay[currentEventIndex].enddate
                    )
                });
            }
        } else {
            // It is the middle event in the array
            // Check if the event before the selected one is an event or an available time slot
            if (
                allEventsOfTheDay[currentEventIndex - 1] &&
                allEventsOfTheDay[currentEventIndex - 1].event_id
            ) {
                // It is an event, we don't need to set anything with starttimes
                self.setState({
                    originalEndTime:
                        allEventsOfTheDay[currentEventIndex].endTime,
                    repeatSeqId:
                        allEventsOfTheDay[currentEventIndex].repeatSeqId,
                    repeatdays: allEventsOfTheDay[currentEventIndex].repeatdays,
                    enddate: updateEnddate(
                        allEventsOfTheDay[currentEventIndex].repeatdays,
                        allEventsOfTheDay[currentEventIndex].enddate
                    )
                });
            } else {
                // it is an available time slot, so find the start time of that, and set as newAvailableStartTime
                const newStartTime =
                    allEventsOfTheDay[currentEventIndex - 1].startTime;
                self.setState({
                    originalEndTime:
                        allEventsOfTheDay[currentEventIndex].endTime,
                    originalStartTime:
                        allEventsOfTheDay[currentEventIndex].startTime,
                    newAvailableStartTime: newStartTime
                });
            }

            // Check if the event after the selected one is an available time slot or an event
            if (
                allEventsOfTheDay[currentEventIndex + 1] &&
                allEventsOfTheDay[currentEventIndex + 1].event_id
            ) {
                // If there is an event afterwards, we need to set the endTime to the beginning of that event
                self.setState({
                    availableEndTime:
                        allEventsOfTheDay[currentEventIndex + 1].startTime,
                    endHour: getHour(
                        allEventsOfTheDay[currentEventIndex].endTime
                    ),
                    endMinute: getMinute(
                        allEventsOfTheDay[currentEventIndex].endTime
                    ),
                    originalEndTime:
                        allEventsOfTheDay[currentEventIndex].endTime,
                    repeatSeqId:
                        allEventsOfTheDay[currentEventIndex].repeatSeqId,
                    repeatdays: allEventsOfTheDay[currentEventIndex].repeatdays
                });
            } else {
                const newEndTime =
                    allEventsOfTheDay[currentEventIndex + 1].endTime;
                self.setState({
                    originalEndTime:
                        allEventsOfTheDay[currentEventIndex].endTime,
                    newAvailableEndTime: newEndTime,
                    repeatSeqId:
                        allEventsOfTheDay[currentEventIndex].repeatSeqId,
                    repeatdays: allEventsOfTheDay[currentEventIndex].repeatdays,
                    enddate: updateEnddate(
                        allEventsOfTheDay[currentEventIndex].repeatdays,
                        allEventsOfTheDay[currentEventIndex].enddate
                    )
                });
            }
        }
    } else {
        // this.setState({
        //   startHour: getHour(this.props.startTime),
        //   startMinute: getMinute(this.props.startTime),
        //   endHour: getHour(this.props.endTime),
        //   endMinute: getMinute(this.props.endTime),
        // });
    }
};

export const filterData = (
    dataFromApi,
    isBookingCalendar,
    ownEvents,
    chosenRoom,
    self
) => {
    //Should filter incoming data
    const filteredData = dataFromApi.filter(
        (event) => event.roomId === getProperRoomId(chosenRoom)
    );

    const events = createEvents(filteredData, isBookingCalendar, ownEvents);
    self.setState({ events });
};

export const fetchAndFilterEvents = async (
    isBookingCalendar,
    chosenRoom,
    startDate,
    endDate,
    handleError,
    self
) => {
    const properRoomId = getProperRoomId(chosenRoom);
    const data = await fetchEvents(
        properRoomId,
        startDate,
        endDate,
        handleError
    );

    let ownEvents;
    if (returnAuthenticated()) {
        ownEvents = await getOwnEvents(() => {
            console.log('error when getting own events');
        });
    }

    filterData(data, isBookingCalendar, ownEvents, chosenRoom, self);
};
