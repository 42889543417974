import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

const LoaderComponent = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.primary.contrastText};
`;

const LoaderContainer = styled.div`
  width: fit-content;
  text-align: center;
`;

function Loader() {
  return (
    <LoaderContainer>
      <LoaderComponent />
    </LoaderContainer>
  );
}

export default Loader;
