import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ArrowIcon from '../../assets/icons/return-arrow.png';

const LinkComponent = styled(Link)`
    color: ${(props) => props.theme.palette.primary.contrastText};
    text-decoration: none;
    width: fit-content;
`;

const LinkText = styled(Typography)`
    margin-bottom: ${(props) =>
        props.marginbottom ? props.marginbottom : '1.5rem'};
`;

const Arrow = styled.span`
    margin-right: 0.5rem;

    img {
        width: 31px;
        height: 16px;
    }
`;

function ReturnLinkReusable({ destination, url, marginBottom }) {
    if (url) {
        return (
            <LinkComponent to={`${url}`}>
                <LinkText variant="subtitle2" marginbottom={marginBottom}>
                    <Arrow>
                        <img src={ArrowIcon} alt="return-arrow" />
                    </Arrow>{' '}
                    Tilbage {destination && ` til ${destination}`}
                </LinkText>
            </LinkComponent>
        );
    } else {
        // This is used from RegistrationInformation component. There we redirect the user in an other way.
        return (
            <LinkText variant="subtitle2" marginbottom={marginBottom}>
                <Arrow>
                    <img src={ArrowIcon} alt="return-arrow" />
                </Arrow>{' '}
                Tilbage {destination && ` til ${destination}`}
            </LinkText>
        );
    }
}

export default ReturnLinkReusable;
