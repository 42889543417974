import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const DialogComponent = styled(Dialog)`
  & .MuiPaper-root {
    padding: 1rem !important;
  }
`;

function ConflictingEventsDialog({
  close,
  conflictingEvents,
  bookNonConflictingEvents,
}) {
  const [eventDates, setEventDates] = useState([]);

  // ComponentDidMount
  useEffect(() => {
    if (conflictingEvents && conflictingEvents.length > 0) {
      const conflictingStartDates = conflictingEvents.map(
        (event) => event.startdate
      );
      const filteredConflictingStartDates = [...new Set(conflictingStartDates)];
      setEventDates(filteredConflictingStartDates);
    }
  }, [conflictingEvents]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.7 }}
    >
      <DialogComponent
        onClose={close}
        open={true}
        aria-labelledby="success"
        aria-describedby="success-description"
        disableRestoreFocus
      >
        {/* English text */}
        <DialogTitle id="success">Ups</DialogTitle>

        <DialogContent>
          <DialogContentText id="success-description">
            Der er desværre optaget i dette lokale på følgende datoer:
            <ul>
              {eventDates.length > 0 &&
                eventDates.map((event) => <li key={event}>{event}</li>)}
            </ul>
            Ønsker du at fortsætte reservationen uden{" "}
            {eventDates.length > 1
              ? `disse ${eventDates.length} datoer`
              : `denne dato`}
            ?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => bookNonConflictingEvents()}>Ja</Button>
          <Button onClick={() => close()}>Nej</Button>
        </DialogActions>
      </DialogComponent>
    </motion.div>
  );
}

export default ConflictingEventsDialog;
