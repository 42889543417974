import moment from "moment";
import { getHour, getMinute } from "./EventFunctions";

const createDefaultEndHour = (startHourNumber) => {
  if (startHourNumber === 8) {
    return "09";
  } else {
    return (startHourNumber + 1).toString();
  }
};

export const setSelectableTimes = (
  startTime,
  endTime,
  timeType,
  hoursArray,
  minutesArray,
  self,
  parentComponent,
  existingEvent,
  originalEndTime,
  originalStartTime
) => {
  //Separate the startTime into hour and minute
  let startHourNumber = Number(startTime.slice(0, 2));
  let startHourString = startTime.slice(0, 2);
  const endHourNumber = Number(endTime.slice(0, 2));
  const endHourString = endTime.slice(0, 2);
  let startMinute = startTime.slice(3, 5);

  // Check if the starthour is not 00, 15, 30 or 45
  if (Number(startMinute) > 0 && Number(startMinute) < 15) {
    startMinute = "15";
  } else if (Number(startMinute) > 15 && Number(startMinute) < 30) {
    startMinute = "30";
  } else if (Number(startMinute) > 30 && Number(startMinute) < 45) {
    startMinute = "45";
  } else if (Number(startMinute) > 45 && Number(startMinute) < 60) {
    startMinute = "00";
    startHourNumber = startHourNumber + 1;
    startHourString = Number(startHourString + 1).toString();
  }

  if (!existingEvent) {
    //Set the start hour and minute to the beginning of the available time slot
    if (timeType === "startTime") {
      self.setState({ hour: startHourString, minute: startMinute });
      // Save start time in parent component state
      parentComponent.setState({
        newEventStartTime: startHourString + ":" + startMinute,
      });
      // When we set the hour and minute, we also want to change it in available timeslot component
    } else {
      const defaultEndHour = createDefaultEndHour(startHourNumber);

      const defaultEndTime = defaultEndHour + ":" + startMinute;

      // Set end hour and minute 1 hour later than the start time, unless the available slot is less than 1 hour.
      // In that case we just leave the start and end time same, and the user must select.
      self.setState({
        hour: isEndTimeInvalid(defaultEndTime, self.props.date, endTime)
          ? startHourString
          : defaultEndHour,
        minute: startMinute,
      });
      // Save end time in parent component state
      parentComponent.setState({
        newEventEndTime: defaultEndHour + ":" + startMinute,
      });
    }
  } else {
    // If it is an existing event, set the newEventEnd and startTimes to the value which is coming from the event
    parentComponent.setState({
      newEventStartTime: originalStartTime ? originalStartTime : startTime,
      newEventEndTime: originalEndTime ? originalEndTime : endTime,
    });
    // Check if end time is same as start time
    if (timeType === "startTime") {
      if (originalStartTime) {
        self.setState({
          hour: getHour(originalStartTime),
          minute: getMinute(originalStartTime),
        });
      } else {
        self.setState({ hour: startHourString, minute: startMinute });
      }
    } else {
      if (originalEndTime) {
        self.setState({
          hour: getHour(originalEndTime),
          minute: getMinute(originalEndTime),
        });
      } else {
        self.setState({
          hour: endHourString,
          minute: getMinute(endTime),
        });
      }
    }
  }

  // Display only those hours in the select element that are within the time of the available time slot
  const displayedHours = hoursArray.filter(
    (hour) =>
      hour.numberFormat >= startHourNumber && hour.numberFormat <= endHourNumber
  );

  self.setState({ displayedHours, displayedMinutes: minutesArray });
};

export const getInitialTimes = () => {};

const isStartTimeInvalid = (selectedTime, date, startTime, endTime) => {
  const selectedDateTime = date + " " + selectedTime;
  const startDateTime = date + " " + startTime;
  const endDateTime = date + " " + endTime;
  // Checks if the selected time is before the actual beginning of the available time slot
  if (moment(selectedDateTime).isBefore(moment(startDateTime))) {
    return true;
    //Check if selected time is not already in the next event
  } else if (
    moment(endDateTime).isBefore(moment(selectedDateTime)) ||
    endDateTime === selectedDateTime
  ) {
    return true;
  } else {
    return false;
  }
};

const isMaxEndTimeBeforeSelected = (maxEndDateTime, selectedDateTime) => {
  // Checks if selected date is after endTime of the available time slot
  if (!moment(maxEndDateTime).isBefore(moment(selectedDateTime))) {
    // Check if they are the same date and time
    if (maxEndDateTime === selectedDateTime) {
      return false;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const isEndTimeInvalid = (selectedTime, date, maxEndTime) => {
  const selectedDateTime = date + " " + selectedTime;
  const maxEndDateTime = date + " " + maxEndTime;
  // If maxEndTime is 23 or 24, the minutes must be 00!
  if (maxEndTime === "23:00") {
    if (
      selectedTime === "23:15" ||
      selectedTime === "23:30" ||
      selectedTime === "23:45"
    ) {
      // Invalid endtime!
      return true;
    } else {
      if (isMaxEndTimeBeforeSelected(maxEndDateTime, selectedDateTime)) {
        return true;
      } else {
        return false;
      }
    }
  } else if (maxEndTime === "24:00") {
    if (
      selectedTime === "24:15" ||
      selectedTime === "24:30" ||
      selectedTime === "24:45"
    ) {
      // Invalid endtime!
      return true;
    } else {
      if (isMaxEndTimeBeforeSelected(maxEndDateTime, selectedDateTime)) {
        return true;
      } else {
        return false;
      }
    }
  } else {
    if (isMaxEndTimeBeforeSelected(maxEndDateTime, selectedDateTime)) {
      return true;
    } else {
      return false;
    }
  }
};

// Check if end time has been selected. If not, create one automatically. If yes, save that.
const saveEndTime = (
  isEndTimeChosen,
  isStartTimeInvalid,
  chosenStartTime,
  chosenEndTime,
  self,
  existingEvent
) => {
  // If it is updating an existing event, we don't want to change the end time
  if (existingEvent) {
    self.setState({ chosenEndTime, chosenStartTime });
  } else {
    // Save chosen start and end times as chosen times
    // If user haven't selected end time, create one automatically, if there is no error in start time.
    if (
      !isEndTimeChosen &&
      !isStartTimeInvalid(
        chosenStartTime,
        self.props.date,
        self.props.startTime,
        self.props.endTime
      )
    ) {
      // Automatically created end time should be +15 minutes later from the start time
      const chosenStartTimeMoment = moment(
        self.props.date + " " + chosenStartTime
      );
      const automaticEndTime = chosenStartTimeMoment.add(15, "minute");

      self.setState({
        chosenEndTime: automaticEndTime.format("HH:mm"),
        endHour: automaticEndTime.format("H"), //Is this needed?
        endMinute: automaticEndTime.format("mm"), //Is this needed?
        automaticEndTime: automaticEndTime,
        newEventEndTime: automaticEndTime.format("HH:mm"),
      });

      // If end time is chosen but start time is invalid
    } else if (
      !isEndTimeChosen &&
      isStartTimeInvalid(
        (chosenStartTime,
        self.props.date,
        self.props.startTime,
        self.props.endTime)
      ) &&
      !existingEvent
    ) {
      self.setState({ chosenEndTime: null });
    } else {
      self.setState({ chosenEndTime });
    }
  }
};

export const validateAndSaveChosenTime = (
  timeType,
  hour,
  minute,
  self,
  existingEvent,
  newAvailableEndTime,
  newAvailableStartTime
) => {
  let {
    startHour,
    startMinute,
    endHour,
    endMinute,
    isEndTimeChosen,
  } = self.state;

  //Get chosen startTime if it has been modified
  if (timeType === "startTime") {
    // Check if there is newAvailableStartTime, and use that
    const maxStartTime = newAvailableStartTime
      ? newAvailableStartTime
      : self.props.startTime;

    // Check validity
    if (
      isStartTimeInvalid(
        hour + ":" + minute,
        self.props.date,
        maxStartTime,
        self.props.endTime
      )
    ) {
      self.setState({ startTimeError: true });
    } else {
      self.setState({
        startTimeError: false,
        newEventStartTime: hour + ":" + minute,
      });
      startHour = hour;
      startMinute = minute;
    }

    //Get endTime if it has been modified
  } else {
    // If there is new available endtime, we want to use that
    const maxEndTime = newAvailableEndTime
      ? newAvailableEndTime
      : self.props.endTime;
    if (isEndTimeInvalid(hour + ":" + minute, self.props.date, maxEndTime)) {
      self.setState({
        isEndTimeChosen: true,
        endTimeError: true,
      });
    } else {
      self.setState({
        isEndTimeChosen: true,
        endTimeError: false,
        newEventEndTime: hour + ":" + minute,
      });
      endHour = hour;
      endMinute = minute;
      isEndTimeChosen = true;
    }
  }

  const chosenStartTime = startHour + ":" + startMinute;
  const chosenEndTime = endHour + ":" + endMinute;

  //   // Save chosen start and end times as chosen times
  //   // If user haven't selected end time, create one automatically, if there is no error in start time.
  saveEndTime(
    isEndTimeChosen,
    isStartTimeInvalid,
    chosenStartTime,
    chosenEndTime,
    self,
    existingEvent
  );
};

// If user selects midnigh (24 o clock), only "00" minute can be selected. Otherwise all minute options should be available
export const checkForLastHour = (
  elementName,
  selectedHour,
  minutesArray,
  endTime,
  self
) => {
  const endTimeHour = endTime.slice(0, 2);
  if (elementName === "hour") {
    // Check if the endHour is 23 or 24

    if (endTimeHour === "23" || endTimeHour === "24") {
      if (selectedHour === endTimeHour) {
        const filteredMinutesArray = minutesArray.filter(
          (minute) => minute.title === "00"
        );
        self.setState({ displayedMinutes: filteredMinutesArray, minute: "00" });
      } else {
        self.setState({ displayedMinutes: minutesArray });
      }
    }
  }
};

export const isStartTimeLaterThanEnd = (startTime, endTime) => {
  // Date does not matter as long as they are the same, since we are only checking the hour
  const date = "2021-01-01";
  const startDateTime = moment(date + " " + startTime);
  const endDateTime = moment(date + " " + endTime);
  if (moment(startDateTime).isAfter(endDateTime)) {
    return true;
  } else {
    return false;
  }
};
