import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import FatGreenButton from '../../components/buttons/fat-green-button/FatGreenButton';
import FacebookIcon from '../../components/icons/facebook-icon/FacebookIcon';
import InstagramIcon from '../../components/icons/instagram-icon/InstagramIcon';
import LinkedinIcon from '../../components/icons/linkedin-icon/LinkedinIcon';
import YoutubeIcon from '../../components/icons/youtube-icon/YoutubeIcon';
import TextFromRichTextEditor from '../../components/rich-text-editor/TextFromRichTextEditor';
import WebIcon from '../../assets/icons/web.png';
import Mailbox from '../../assets/icons/mailbox.png';
import PhoneIcon from '../../assets/icons/phone.png';
import { overridings } from '../../themes/DarkTheme';
import Loader from '../../components/loader/Loader';
import { profileImagesFunc } from './ActivityFunctions';
import { getAndReturnData } from '../../context/helperFunctions';
import { Box, Grid, Typography, withStyles } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import { getUserEmail } from '../../context/getData';

const StyledGrid = withStyles({
    root: {
        height: '100%'
    }
})(Grid);

const CustomGrid = styled(Grid)`
    margin-top: 65px;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin-top: 28px;
        padding-top: 20px;
    }
`;

const LeftColumn = styled(StyledGrid)`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        padding-bottom: 0 !important;
    }
`;

const RightColumn = styled(StyledGrid)``;

const ActivityTitleDesktop = styled(Typography)`
    color: ${overridings.palette.colors.green};
    font-size: 2.3rem;
    font-weight: bold;
    margin-bottom: 30px;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: none;
    }
`;

const ActivityTitleMobile = styled(Typography)`
    color: ${overridings.palette.colors.green};
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 16px;
    display: none;
    text-align: center;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

const UserNameElDesktop = styled(Typography)`
    font-size: 0.937rem;
    margin-bottom: 35px;
    text-transform: uppercase;
    color: ${overridings.palette.colors.green};
    display: block;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: none;
    }
`;

const UserNameElMobile = styled(Typography)`
    font-size: 0.937rem;
    margin-bottom: 35px;
    text-transform: uppercase;
    color: ${overridings.palette.colors.green};
    display: none;
    text-align: center;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

const ProfileCarousel = styled(Carousel)`
    width: 100%;
    height: 100%;

    .makeStyles-indicators-4 {
        height: 30px;
        bottom: 0px;
        margin-top: 15px;
        @media (max-width: 600px) {
            position: relative;
        }
    }

    .makeStyles-indicators-4 {
        height: 30px;
    }

    && .MuiSvgIcon-root {
        width: 0.8rem;
        height: 0.8rem;
        color: white;
        /* display: none; */
    }
    .MuiSvgIcon-root:hover {
        border: 1px solid grey;
        border-radius: 50%;
    }

    && .MuiSvgIcon-root {
        width: 0.8rem;
        height: 0.8rem;
    }
    .css-1i8snpa-MuiButtonBase-root-MuiIconButton-root {
        color: pink;
    }

    && > div:nth-child(2) {
        right: 20px;
        @media (min-width: 1050px) {
            right: 37px;
        }

        @media (min-width: 1120px) {
            right: 70px;
        }
    }
`;

const ProfileImageContainer = styled.div`
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
`;

const ProfileImage = styled.img`
    height: 298px;

    @media (max-width: 1100px) {
        width: 300px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 220px;
        height: 220px;
    }

    @media (max-width: 500px) {
        width: 196px;
        height: 196px;
    }
`;

const MediaImage = styled.img`
    width: 24px;
    height: 24px;
    margin-right: 14px;
`;
const MediaText = styled(Typography)`
    font-size: 1rem;
    word-break: break-all;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        font-size: 1rem;
    }
`;

const CarouselContainer = styled.div``;

const ContactContainer = styled(Box)`
    /* width: 298px; */
    width: fit-content;
    margin: 30px auto 0;
    display: flex;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin: 22px auto 0px;
    }
`;

const EmailPhoneContainer = styled(Box)`
    width: fit-content;
    margin: 30px auto 0;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin: 22px auto 0px;
    }
`;

const Contact = styled(Box)`
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
    width: fit-content;
`;

const MediaSvgContainer = styled.span`
    svg {
        color: ${overridings.palette.colors.green};
        margin-right: 14px;
        width: 24px;
        height: 24px;
    }
`;

const Underlined = styled.span`
    text-decoration: underline;
`;

function ActivityCardFull(props) {
    const [userInfo, setUserInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [userProfileImages, setUserProfileImages] = useState([
        '/images/avatar.png'
    ]);
    const [showPhone, setShowPhone] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [isTheMusicSchool, setIsTheMusicSchool] = useState(false);

    const handleError = (err) => {
        return console.log('Error:', err);
    };

    const getUserInfo = async () => {
        let userIdToUse;
        if (props.userIdFromProps) {
            userIdToUse = props.userIdFromProps;
        }
        if (props.match.params.id) {
            userIdToUse = props.match.params.id;
        }

        if (userIdToUse) {
            const userData = await getAndReturnData(
                `${window.location.origin}/api/v1/public-activity/${userIdToUse}`,
                handleError
            );

            if (
                !userIdToUse ||
                !userData ||
                Object.keys(userData).length === 0
            ) {
                return props.history.push('/opslagstavle');
            }

            setUserInfo(userData);
            profileImagesFunc(
                userData.imageInfo,
                userIdToUse,
                setUserProfileImages,
                setIsLoading
            );

            setIsLoading(false);
        } else {
            console.log('no userid');
        }
    };

    const handleContactBtnClick = () => {
        window.location.href = `mailto:${userInfo.email}`;
    };

    const onContactClick = (type) => {
        let resource;
        switch (type) {
            case 'webpage':
                resource = userInfo.webpageLink;
                break;
            case 'facebook':
                resource = userInfo.facebook;
                break;
            case 'instagram':
                resource = userInfo.instagram;
                break;
            case 'linkedin':
                resource = userInfo.linkedin;
                break;
            case 'youtube':
                resource = userInfo.youtube;
                break;
            default:
                break;
        }

        if (resource.includes('https://') || resource.includes('http://')) {
            window.open(`${resource}`, '_blank');
        } else {
            window.open(`https://${resource}`, '_blank');
        }
    };

    const showInfo = (type) => {
        if (type === 'email') {
            setShowEmail(true);
        } else if (type === 'phone') {
            setShowPhone(true);
        }
    };

    // ComponentDidMount
    useEffect(() => {
        setIsLoading(true);
        if (!props.userInfoFromProps) {
            getUserInfo();
        } else {
            setUserInfo(props.userInfoFromProps);
        }

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (
            userInfo &&
            userInfo.email &&
            userInfo.email === 'admin@denlillemusikskole.dk'
        ) {
            console.log('it is the music school');
            setIsTheMusicSchool(true);
        }
    }, [userInfo]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <CustomGrid container spacing={3}>
                    <LeftColumn item xs={12} md={6}>
                        <ActivityTitleMobile>
                            {userInfo.activityName}
                        </ActivityTitleMobile>
                        <UserNameElMobile>
                            {userInfo.firstname} {userInfo.surname}
                        </UserNameElMobile>

                        <ProfileCarousel
                            className="profile-carousel"
                            autoPlay={false}
                            activeIndicatorIconButtonProps={{
                                style: {
                                    border: '1px solid white',
                                    borderRadius: '50%',
                                    padding: '0px',
                                    margin: '0px'
                                }
                            }}
                        >
                            {userProfileImages.map((item, key) => (
                                <span key={key}>
                                    <CarouselContainer>
                                        <ProfileImageContainer className="profile-image-container">
                                            <ProfileImage
                                                src={item}
                                                alt="Profile image"
                                            ></ProfileImage>
                                        </ProfileImageContainer>
                                    </CarouselContainer>
                                </span>
                            ))}
                        </ProfileCarousel>
                        <EmailPhoneContainer>
                            {isTheMusicSchool ? (
                                <>
                                    {' '}
                                    <Contact>
                                        <MediaImage src={Mailbox} />

                                        <MediaText>
                                            <a
                                                href={`mailto:kontakt@denlillemusikskole.dk`}
                                            >
                                                {userInfo.email}
                                            </a>
                                        </MediaText>
                                    </Contact>
                                    <Contact>
                                        <MediaImage src={PhoneIcon} />

                                        <MediaText>
                                            <a href={`tel:30140123`}>
                                                {' '}
                                                30 14 01 23
                                            </a>
                                        </MediaText>
                                    </Contact>
                                </>
                            ) : (
                                <>
                                    {userInfo.email && (
                                        <Contact>
                                            <MediaImage src={Mailbox} />
                                            {showEmail ? (
                                                <MediaText>
                                                    <a
                                                        href={`mailto:${userInfo.email}`}
                                                    >
                                                        {userInfo.email}
                                                    </a>
                                                </MediaText>
                                            ) : (
                                                <MediaText>
                                                    <Underlined
                                                        onClick={() =>
                                                            showInfo('email')
                                                        }
                                                    >
                                                        Vis email
                                                    </Underlined>
                                                </MediaText>
                                            )}
                                        </Contact>
                                    )}
                                    {userInfo.activityPhone && (
                                        <Contact>
                                            <MediaImage src={PhoneIcon} />
                                            {showPhone ? (
                                                <MediaText>
                                                    <a
                                                        href={`tel:${userInfo.activityPhone}`}
                                                    >
                                                        {' '}
                                                        {userInfo.activityPhone}
                                                    </a>
                                                </MediaText>
                                            ) : (
                                                <MediaText>
                                                    <Underlined
                                                        onClick={() =>
                                                            showInfo('phone')
                                                        }
                                                    >
                                                        Vis telefon
                                                    </Underlined>
                                                </MediaText>
                                            )}
                                        </Contact>
                                    )}
                                </>
                            )}
                        </EmailPhoneContainer>

                        <ContactContainer className="media-container">
                            {userInfo.webpageLink && (
                                <Contact
                                    onClick={() => onContactClick('webpage')}
                                >
                                    <MediaImage src={WebIcon} />
                                    {/* <MediaText>
                                        {userInfo.webpageLink}
                                    </MediaText> */}
                                </Contact>
                            )}
                            {userInfo.facebook && (
                                <Contact
                                    onClick={() => onContactClick('facebook')}
                                >
                                    <MediaSvgContainer>
                                        {' '}
                                        <FacebookIcon
                                            color={
                                                overridings.palette.colors.green
                                            }
                                        />
                                    </MediaSvgContainer>

                                    {/* <MediaText>{userInfo.facebook}</MediaText> */}
                                </Contact>
                            )}
                            {userInfo.instagram && (
                                <Contact
                                    onClick={() => onContactClick('instagram')}
                                >
                                    <MediaSvgContainer>
                                        {' '}
                                        <InstagramIcon
                                            color={
                                                overridings.palette.colors.green
                                            }
                                        />
                                    </MediaSvgContainer>

                                    {/* <MediaText>{userInfo.instagram}</MediaText> */}
                                </Contact>
                            )}
                            {userInfo.linkedin && (
                                <Contact
                                    onClick={() => onContactClick('linkedin')}
                                >
                                    <MediaSvgContainer>
                                        {' '}
                                        <LinkedinIcon
                                            color={
                                                overridings.palette.colors.green
                                            }
                                        />
                                    </MediaSvgContainer>

                                    {/* <MediaText>{userInfo.linkedin}</MediaText> */}
                                </Contact>
                            )}
                            {userInfo.youtube && (
                                <Contact
                                    onClick={() => onContactClick('youtube')}
                                >
                                    <MediaSvgContainer>
                                        <YoutubeIcon
                                            color={
                                                overridings.palette.colors.green
                                            }
                                        />
                                    </MediaSvgContainer>

                                    {/* <MediaText>{userInfo.youtube}</MediaText> */}
                                </Contact>
                            )}
                        </ContactContainer>
                    </LeftColumn>
                    <RightColumn item xs={12} md={6}>
                        <ActivityTitleDesktop>
                            {userInfo.activityName}
                        </ActivityTitleDesktop>
                        <UserNameElDesktop>
                            {userInfo.firstname} {userInfo.surname}
                        </UserNameElDesktop>

                        <TextFromRichTextEditor userId={userInfo.id} />

                        <FatGreenButton
                            onButtonClick={handleContactBtnClick}
                            title="Kontakt"
                            minWidth="200px"
                            mobileWidth={'255px'}
                            mobileMargin="1rem auto 0"
                        />
                    </RightColumn>
                </CustomGrid>
            )}
        </>
    );
}

export default withRouter(ActivityCardFull);
