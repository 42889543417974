import React from "react";
import { Context } from "../../context/Context";

export const withContext = (Element) => {
  return React.forwardRef((props, ref) => {
    return (
      <Context.Consumer>
        {(context) => <Element contextValue={context} {...props} ref={ref} />}
      </Context.Consumer>
    );
  });
};
