import React from 'react';
import styled from 'styled-components';
import { overridings } from '../../../themes/DarkTheme';
import RegistrationLinkButton from '../link-button/RegistrationLinkButton';

const RegistrationButtonContainer = styled.div`
    width: ${(props) => (props.width ? props.width : '18%')};
    margin: ${(props) => (props.margin ? props.margin : '2rem 0rem 2rem 0rem')};
    min-width: ${(props) => props.minwidthdesktop && props.minwidthdesktop};

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: ${(props) => (props.mobilewidth ? props.mobilewidth : '50%')};
        max-width: ${(props) => !props.minwidth && '10rem'};
        min-width: ${(props) => props.minwidth && props.minwidth};

        margin: ${(props) =>
            props.mobilemargin ? props.mobilemargin : 'initial'};
    }
    @media (max-width: 300px) {
        width: 100%;
        max-width: ${(props) => !props.minwidth && '100%'};
    }
`;

function RegistrationButton({
    onButtonClick,
    title,
    width,
    mobileWidth,
    minWidth,
    minWidthDesktop,
    margin,
    mobileMargin,
    id,
    color,
    border
}) {
    return (
        <RegistrationButtonContainer
            minwidth={minWidth}
            minwidthdesktop={minWidthDesktop}
            width={width}
            margin={margin}
            onClick={() => onButtonClick()}
            mobilemargin={mobileMargin}
            mobilewidth={mobileWidth}
        >
            <RegistrationLinkButton
                title={title ? title : 'Videre'}
                size="medium"
                color={color ? color : overridings.palette.colors.green}
                border={border ? border : overridings.palette.colors.green}
                id={id}
            />
        </RegistrationButtonContainer>
    );
}

export default RegistrationButton;
