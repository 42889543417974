import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Context } from '../../../context/Context';

import { Box, ClickAwayListener, Popper, Typography } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import HeaderLogo from '../../../assets/logos/new-logo.png';
import ProfileDialog from '../../dialogs/profile-dialog/ProfileDialog';
import HeaderLoginContainer from '../../containers/HeaderLoginContainer';
import {
    getOrder,
    getOwnEvents,
    getUserFirstnameHooks,
    returnAuthenticated
} from '../../../context/getData';
import NavContainerDesktop from './NavContainerDesktop';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import UserIcon from '../../icons/user-icon/UserIcon';
import BasketIcon from '../../../assets/icons/basket.png';
import { isLoginFromCalendar } from '../../../context/helperFunctions';

const HeaderContainer = styled(Box)`
    width: 90%;
    max-width: 1200px;
    padding-top: 1.5rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: none;
    }
`;

const Logo = styled.img`
    width: 15rem;

    @media (max-width: 1000px) {
        width: 13rem;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 6rem;
        height: 1rem;
        padding-top: 2rem;
    }
`;

const LoginContainer = styled(Box)`
    position: relative;
    top: 6px;
    & a {
        text-decoration: none;
        display: block;
    }
`;

const HeaderUserFirstname = styled(Typography)`
    display: flex;
    cursor: pointer;
    margin-left: 1.5rem;
    font-size: 1.125rem;
    position: relative;
    bottom: 8px;
`;

const DesktopContainer = styled.div`
    display: block;
    position: relative;
    z-index: 2;
    background-color: ${(props) =>
        props.shouldstick === 'true' ? '#000000' : 'transparent'};

    position: fixed;
    top: ${(props) => {
        if (props.offlinetextshown && props.shouldstick === 'false') {
            return '32px';
        } else if (props.shouldstick === 'true' && props.offlinetextshown) {
            return '0';
        } else {
            return '0';
        }
    }};
    /* top: 0; */
    width: 100vw;
    padding-bottom: ${(props) =>
        props.shouldstick === 'true' ? '1rem' : '3rem'};

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: none;
    }
`;

const BasketIconElm = styled.img`
    width: 24px;
    height: 24px;
    margin-left: 20px;
    cursor: pointer;
    position: relative;
    bottom: 2px;
`;

const PopperText = styled.div`
    background-color: ${(props) => props.theme.palette.primary.light};
    color: ${(props) => props.theme.palette.primary.contrastText};
    padding: 0.5rem;
`;

function NavHeaderDesktop(props) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userFirstname, setUserFirstname] = useState('');
    const [profileDialogOpen, setProfileDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [shouldStick, setShouldStick] = useState(false);

    const basketRef = useRef();
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const context = useContext(Context);

    const toggleProfileDialog = () => {
        if (profileDialogOpen) {
            setProfileDialogOpen(false);
        } else {
            setProfileDialogOpen(true);
        }
    };

    const closeProfileDialog = () => {
        setProfileDialogOpen(false);
    };

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const onBasketIconClick = async () => {
        // Check if user has anything in cart
        // if yes, take them to the opsummering page
        const ownEventsFromDb = await getOwnEvents();
        const subscriptionOrder = getOrder();

        if (
            ownEventsFromDb.length > 0 ||
            (subscriptionOrder && subscriptionOrder.subscription === true)
        ) {
            // Has something in cart, take them to the checkout page
            const currentPath = window.location.pathname;
            props.history.push({
                pathname: '/opsummering',
                state: {
                    from: {
                        path: `${currentPath}`
                    }
                }
            });
        } else {
            setAnchorEl(anchorEl ? null : basketRef.current);
        }
    };

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY;
            if (windowHeight > 10) {
                setShouldStick(true);
            } else {
                setShouldStick(false);
            }
        }
    };

    // Stick the navbar if user scrolls
    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);

        return () => {
            window.removeEventListener('scroll', stickNavbar);
        };
    }, []);

    useEffect(() => {
        getUserFirstnameHooks(setIsAuthenticated, setUserFirstname);
    }, [context.state.currentUser]);

    return (
        <DesktopContainer
            shouldstick={shouldStick.toString()}
            className="nav-header-desktop"
            offlinetextshown={props.offlineTextShown}
        >
            <HeaderContainer>
                <Box>
                    <Link to="/">
                        <Logo
                            shouldstick={shouldStick.toString()}
                            src={HeaderLogo}
                            alt="Logo of Bulowsvej10"
                        />
                    </Link>
                </Box>

                <Box
                    mt="3px"
                    display="flex"
                    justifyContent="right"
                    position="relative"
                >
                    <NavContainerDesktop />
                    <LoginContainer textAlign="right">
                        {isAuthenticated ? (
                            <HeaderLoginContainer>
                                <HeaderUserFirstname
                                    variant="subtitle1"
                                    onClick={() => toggleProfileDialog()}
                                >
                                    <UserIcon
                                        height="25px"
                                        width="25px"
                                        marginRight="5px"
                                    />{' '}
                                    {userFirstname}
                                    <ArrowDropDownIcon />
                                </HeaderUserFirstname>
                                {profileDialogOpen && (
                                    <ClickAwayListener
                                        onClickAway={closeProfileDialog}
                                    >
                                        <div>
                                            <ProfileDialog
                                                userFirstname={userFirstname}
                                                closeProfileDialog={
                                                    closeProfileDialog
                                                }
                                                prompt={props.prompt}
                                                promptToInstall={
                                                    props.promptToInstall
                                                }
                                                isVisible={props.isVisible}
                                                setVisibleState={
                                                    props.setVisibleState
                                                }
                                                setIsFirefox={
                                                    props.setIsFirefox
                                                }
                                                isFirefox={props.isFirefox}
                                            />
                                        </div>
                                    </ClickAwayListener>
                                )}
                            </HeaderLoginContainer>
                        ) : (
                            <Box ml="2rem" position="relative" bottom="5px">
                                <Link
                                    className="frontpage-job"
                                    to={{
                                        pathname: '/login',
                                        state: {
                                            modal: true,
                                            fromCalendar: isLoginFromCalendar()
                                        }
                                    }}
                                >
                                    <UserIcon height="25px" width="25px" />
                                </Link>
                            </Box>
                        )}
                    </LoginContainer>
                    {returnAuthenticated() && (
                        <div>
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <BasketIconElm
                                    onClick={() => onBasketIconClick()}
                                    src={BasketIcon}
                                    ref={basketRef}
                                />
                            </ClickAwayListener>
                            <Popper id={id} open={open} anchorEl={anchorEl}>
                                <PopperText>Din kurv er tom</PopperText>
                            </Popper>
                        </div>
                    )}
                </Box>
            </HeaderContainer>
        </DesktopContainer>
    );
}

export default withRouter(NavHeaderDesktop);
