import React, { useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import { Context } from '../../../context/Context';

const DialogComponent = styled(Dialog)`
    & .MuiPaper-root {
        padding: 1rem !important;
    }
`;

function RenovationInfoDialog({ close }) {
    const context = useContext(Context);
    // ComponentDidMount
    // useEffect(() => {
    //     if (setScrollView) {
    //         setScrollView();
    //     }
    //     // Come back: check if its okay not to include setScrollView in dependencies
    //     // eslint-disable-next-line
    // }, []);

    const closeDialog = (isButtonClicked) => {
        if (isButtonClicked) {
            context.saveHaveSeenRenovationWarning(true);
            close();
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <DialogComponent
                onClose={() => closeDialog(false)}
                open={true}
                aria-labelledby="success"
                aria-describedby="success-description"
                disableRestoreFocus
            >
                <DialogTitle id="success"></DialogTitle>

                <DialogContent>
                    <DialogContentText id="success-description">
                        Vi renoverer løbende lokalerne i stueplan på Bülowsvej
                        10, for at give vores brugere en bedre oplevelse. Det
                        vil altid fremgå af kalenderen, hvilket lokale der
                        renoveres.
                        <br /> Du kan altid kontakte kontoret på telefon 3014
                        0123 mandag og onsdag mellem 10 og 12 eller på
                        mailadressen{' '}
                        <a href="mailto:kontakt@bulowsvej10.dk">
                            kontakt@bulowsvej10.dk
                        </a>{' '}
                        for nærmere information om renoveringen.
                    </DialogContentText>
                    <DialogContentText>
                        Vi glæder os til at se dig og dine deltagere på
                        Bülowsvej 10.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        id="obs-renovation-ok"
                        onClick={() => closeDialog(true)}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </DialogComponent>
        </motion.div>
    );
}

export default RenovationInfoDialog;
