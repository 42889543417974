const getStatus = (stages, currentStage) => {
  // Find the stageNumber of the current stage
  const match = stages.filter((stage) => stage.stageTitle === currentStage);

  stages.forEach((stage) => {
    // Give active status to current stage
    if (stage.stageNumber === match[0].stageNumber) {
      stage.status = "active";
      // Give completed status to the stages that have lower number that current stage
    } else if (stage.stageNumber < match[0].stageNumber) {
      stage.status = "completed";
    } else {
      // Give incomplete status to the stages that have higher number that current stage
      stage.status = "incomplete";
    }
  });
  return stages;
};

export { getStatus };
