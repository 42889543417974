import { Box } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAddToHomescreenPrompt } from '../../../useAddToHomescreenPrompt';
import NavHeaderDesktop from '../nav-header/NavHeaderDesktop';
import NavHeaderMobile from '../nav-header/NavHeaderMobile';
import NavHeaderMobileContainer from '../nav-header/NavHeaderMobileContainer';
import { Context } from '../../../context/Context';

const HeadersContainerEl = styled(Box)`
    padding-bottom: 3rem;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        padding-bottom: 0;
    }
`;

function HeadersContainer(props) {
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const [isVisible, setVisibleState] = useState(false);
    const [isFirefox, setIsFirefox] = useState(false);
    const [offlineTextShown, setOfflineTextShown] = useState(false);

    const context = useContext(Context);

    useEffect(() => {
        if (context.state.isOffline) {
            setOfflineTextShown(true);
        }
    }, [context.state.isOffline]);

    return (
        <HeadersContainerEl>
            <NavHeaderDesktop
                offlineTextShown={offlineTextShown}
                prompt={prompt}
                promptToInstall={promptToInstall}
                isVisible={isVisible}
                setVisibleState={setVisibleState}
                setIsFirefox={setIsFirefox}
                isFirefox={isFirefox}
            />
            <NavHeaderMobileContainer>
                <NavHeaderMobile
                    offlineTextShown={offlineTextShown}
                    prompt={prompt}
                    promptToInstall={promptToInstall}
                    isVisible={isVisible}
                    setVisibleState={setVisibleState}
                    setIsFirefox={setIsFirefox}
                    isFirefox={isFirefox}
                />
            </NavHeaderMobileContainer>
        </HeadersContainerEl>
    );
}

export default HeadersContainer;
