import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

const ContentContainerComponent = styled(Box)`
    width: 85%;
    max-width: ${(props) => (props.maxwidth ? props.maxwidth : '1200px')};
    margin: ${(props) => props.margintop || '4rem'} auto
        ${(props) => props.marginbottom || '4rem'} auto;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
        width: 90%;
        margin-top: 0;
        padding-top: ${(props) =>
            props.mobilepaddingtop && props.mobilepaddingtop};
    }
`;

function RegistrationContentContainer({
    children,
    marginBottom,
    marginTop,
    mobilePaddingTop,
    maxWidth
}) {
    return (
        <ContentContainerComponent
            mobilepaddingtop={mobilePaddingTop}
            marginbottom={marginBottom}
            margintop={marginTop}
            maxwidth={maxWidth}
        >
            {children}
        </ContentContainerComponent>
    );
}

export default RegistrationContentContainer;
