import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { overridings } from '../../themes/DarkTheme';

const FormControlComponent = styled(FormControl)`
    width: 100%;
`;

const SelectComponent = styled(Select)`
    & label {
        color: pink;
    }
    & label.Mui-focused {
        color: ${(props) => props.focuscolor && props.focuscolor};
    }

    & .MuiSelect-selectMenu {
        font-size: 0.9rem;
    }
`;

const InputLabelComponent = styled(InputLabel)`
    color: ${(props) => props.theme.palette.primary.contrastText};
    /* font-size: 1rem; */

    & :focus {
        color: pink;
    }
`;

function SelectRepeatdays({ setRepeatdays, repeatdays }) {
    const handleChange = (event) => {
        setRepeatdays(event.target.value);
    };

    return (
        <FormControlComponent>
            <InputLabelComponent
                shrink
                id="demo-simple-select-placeholder-label-label"
            >
                Gentagelse
            </InputLabelComponent>
            <SelectComponent
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={repeatdays}
                onChange={handleChange}
                displayEmpty
                focuscolor={overridings.palette.primary.contrastText}
            >
                <MenuItem value={0}>Ingen</MenuItem>
                <MenuItem value={1}>Hver dag</MenuItem>
                <MenuItem value={7}>Hver uge</MenuItem>
                <MenuItem value={14}>Hver 14 dage</MenuItem>
            </SelectComponent>
        </FormControlComponent>
    );
}

export default SelectRepeatdays;
