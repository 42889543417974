import React from 'react';
import styled from 'styled-components';

const StaircaseImageFlippedComponent = styled.img`
    width: ${(props) => props.width}rem;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: ${(props) => (props.mobileWidth ? props.mobileWidth : 1)}rem;
    }
`;

function StaircaseImageFlipped({ src, alt, width, mobileWidth }) {
    return (
        <StaircaseImageFlippedComponent
            className="flipped-image"
            src={src}
            alt={alt}
            width={width}
            mobileWidth={mobileWidth}
        />
    );
}

export default StaircaseImageFlipped;
