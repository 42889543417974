import React from 'react';
import styled from 'styled-components';
import { overridings } from '../../../themes/DarkTheme';
import RegistrationLinkButton from '../link-button/RegistrationLinkButton';

const FatGreenButtonContainer = styled.div`
    width: ${(props) => (props.width ? props.width : '18%')};
    min-width: ${(props) => props.minwidth && props.minwidth};
    margin: ${(props) => (props.margin ? props.margin : '2rem 0rem 2rem 0rem')};
    max-width: 250px;

    button {
        padding: ${(props) => props.custompadding && props.custompadding};

        span {
            white-space: ${(props) => props.wrap && props.wrap};
        }
    }

    .MuiButton-root:hover {
        opacity: ${(props) => props.opacitychangeonhover && 0.8};
        /* opacity: 0.5!important; */
        background-color: ${(props) =>
            props.opacitychangeonhover && overridings.palette.colors.green};
        transition: all 0.5s;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: ${(props) => (props.mobilewidth ? props.mobilewidth : '50%')};
        max-width: ${(props) => !props.minwidth && '10rem'};
        min-width: ${(props) => props.minwidth && props.minwidth};
        margin: ${(props) => props.mobilemargin && props.mobilemargin};
    }
    @media (max-width: 300px) {
        width: 100%;
        max-width: ${(props) => !props.minwidth && '100%'};
    }
`;

function FatGreenButton({
    onButtonClick,
    title,
    width,
    minWidth,
    margin,
    opacityChangeOnHover,
    mobileMargin,
    mobileWidth,
    customPadding,
    wrap
}) {
    return (
        <FatGreenButtonContainer
            minwidth={minWidth}
            width={width}
            margin={margin}
            onClick={() => onButtonClick()}
            opacitychangeonhover={opacityChangeOnHover}
            mobilemargin={mobileMargin}
            mobilewidth={mobileWidth}
            custompadding={customPadding}
            wrap={wrap}
        >
            <RegistrationLinkButton
                title={title ? title : 'Videre'}
                size="medium"
                color={overridings.palette.colors.green}
                border={overridings.palette.colors.green}
            />
        </FatGreenButtonContainer>
    );
}

export default FatGreenButton;
