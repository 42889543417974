import React from 'react';

function YoutubeIcon(props) {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-brand-youtube"
                width={props.width ? props.width : '23'}
                height={props.height ? props.height : '23px'}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke={props.color ? props.color : '#ffffff'}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="3" y="5" width="18" height="14" rx="4" />
                <path d="M10 9l5 3l-5 3z" />
            </svg>
        </>
    );
}

export default YoutubeIcon;
