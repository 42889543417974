import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import NavHeadline from '../../headlines/NavHeadline';
import logo from '../../../assets/logos/new-logo.png';
import AskForLoginDialog from '../../dialogs/ask-for-login-dialog/AskForLoginDialog';
import { returnAuthenticated } from '../../../context/getData';

const NavContainerComponent = styled.div`
    display: block;
    position: static;
    & a {
        display: block;
    }
    @media (min-width: 960px) {
        display: none;
    }
`;

const LogoContainer = styled.img`
    width: 13rem;
    margin: 0rem 0rem 1rem 1rem;
    position: relative;
    top: -2rem;
`;

const BookNav = styled.div`
    margin-top: 0;
    margin-bottom: 0;
`;

function NavContainerMobile({ children }) {
    const [askForLoginDialog, setAskForLoginDialog] = useState(false);
    const [activeMenu, setActiveMenu] = useState('');

    const onBookClick = () => {
        if (returnAuthenticated()) {
            window.location.replace('/booking-kalender');
        } else {
            setAskForLoginDialog(true);
        }
    };

    useEffect(() => {
        // Check if the person is on a page which is present int he navbar

        if (window.location.pathname.includes('aktivitets-kalender')) {
            setActiveMenu('aktivitets-kalender');
        } else if (window.location.pathname.includes('booking-kalender')) {
            setActiveMenu('booking-kalender');
        } else if (window.location.pathname.includes('lokaler')) {
            setActiveMenu('lokaler');
        } else if (window.location.pathname.includes('opslagstavle')) {
            setActiveMenu('opslagstavle');
        }
        //eslint-disable-next-line
    }, [window.location.pathname]);

    return (
        <React.Fragment>
            <NavContainerComponent>
                <a href="/">
                    <LogoContainer className="logo" src={logo} alt="logo" />
                </a>

                <a href="/lokaler">
                    <NavHeadline isActive={activeMenu} title="Se lokaler" />
                </a>

                <BookNav onClick={() => onBookClick()}>
                    <NavHeadline
                        isActive={activeMenu}
                        title="Reserver lokale"
                    />
                </BookNav>

                <a href="/aktivitets-kalender">
                    <NavHeadline isActive={activeMenu} title="Kalender" />
                </a>

                <a href="/faq">
                    <NavHeadline title="Alt det vigtigste" />
                </a>
                <a href="/opslagstavle">
                    <NavHeadline isActive={activeMenu} title="Opslagstavle" />
                </a>
                {askForLoginDialog && (
                    <AskForLoginDialog
                        close={() => setAskForLoginDialog(false)}
                    />
                )}
            </NavContainerComponent>
            {children}
        </React.Fragment>
    );
}

export default NavContainerMobile;
