import React, { useContext, useEffect } from 'react';
import { Context } from '../../context/Context';

import { Box, Typography } from '@material-ui/core';
import ContentContainer from '../../components/containers/ContentContainer';

import Loader from '../../components/loader/Loader';
import { motion } from 'framer-motion';
import HeadersContainer from '../../components/headers/headers-container/HeadersContainer';

function SessionExpired(props) {
    const context = useContext(Context);

    // ComponentDidMount
    useEffect(() => {
        // Redirect user to /login in 3 seconds
        setTimeout(() => {
            context.onLogout();
            window.location.href = '/login';
        }, 2000);
        // eslint-disable-next-line
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <HeadersContainer />

            <ContentContainer>
                <Box
                    width="fit-content"
                    ml="auto"
                    mr="auto"
                    textAlign="center"
                    boxShadow="inset 0 0 5px grey"
                    padding="3rem"
                >
                    <Typography variant="h5">Din session er udløbet</Typography>
                    <Typography fontSize="0.7rem">
                        Du bliver sendt til loginsiden om lidt.
                    </Typography>
                    <Box width="fit-content" ml="auto" mr="auto" mt="2rem">
                        <Loader />
                    </Box>
                </Box>
            </ContentContainer>
        </motion.div>
    );
}

export default SessionExpired;
