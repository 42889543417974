import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import OpenDoorIcon from '../../icons/open-door-icon/OpenDoorIcon';

const ButtonComponent = styled(Button)`
  border-radius: 30px;
  border: 2px solid
    ${(props) =>
        props.background
            ? props.background
            : props.theme.palette.primary.contastText};
  text-transform: none;
  min-width: ${(props) => (props.minwidth ? props.minwidth : '100%')};
  margin: ${(props) => props.margin && props.margin};

  padding: ${(props) =>
      props.paddingvertical ? props.paddingvertical : '6px'};
  /* ${(props) =>
      props.paddinghorizontal
          ? props.paddinghorizontal
          : '18px'} duplicates??? */

  background-color: ${(props) =>
      props.background ? props.background : 'transparent'};
  color: ${(props) => props.buttoncolor && props.buttoncolor};

    &.MuiButton-root:hover {
        color: ${(props) => props.hovercolor && props.hovercolor};
        /* color: white; */
    }

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) { 
    width: ${(props) => props.mobilewidth && props.mobilewidth};
  }
`;

const RegularButton = ({
    title,
    onButtonClick,
    background,
    color,
    name,
    paddingHorizontal,
    paddingVertical,
    size,
    mobileWidth,
    minWidth,
    margin,
    disabled,
    isOpenDoorButton,
    hoverColor
}) => {
    return (
        <ButtonComponent
            variant="outlined"
            disabled={disabled}
            size={size}
            background={background}
            onClick={(event) => {
                onButtonClick(event);
            }}
            paddinghorizontal={paddingHorizontal}
            paddingvertical={paddingVertical}
            buttoncolor={color}
            name={name}
            mobilewidth={mobileWidth}
            minwidth={minWidth}
            margin={margin}
            hovercolor={hoverColor}
        >
            {title}
            {isOpenDoorButton && <OpenDoorIcon />}
        </ButtonComponent>
    );
};

export default RegularButton;
