import React from 'react';
import { nextWeek, nextDay } from '../CalendarFunctions';
import styled from 'styled-components';

const ArrowContainer = styled.div`
    font-size: 1.8rem;
    position: absolute;
    top: ${(props) => (props.top ? props.top : '15rem')};
    right: 1rem;
    cursor: pointer;
`;

function RightArrow({ setDateObject, dateObject, arrowType, top }) {
    return (
        <ArrowContainer
            top={top}
            onClick={() =>
                arrowType === 'rooms'
                    ? setDateObject(nextDay(dateObject))
                    : setDateObject(nextWeek(dateObject))
            }
        >
            &#62;
        </ArrowContainer>
    );
}

export default RightArrow;
