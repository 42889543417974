import { motion } from 'framer-motion';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import {
    getEventsOfTheRoom,
    createEvents,
    sortEvents,
    getAllEvents
} from './EventFunctions';
import AvailableTimeSlot from './available-time-slot/AvailableTimeSlot';
import Event from './event/Event';
import moment from 'moment';

import { fetchDayEventsForRoom } from './CalendarFunctions';

import { getLocationNameFromRoomId } from '../../context/getData';

const RoomContainer = styled.div`
    display: flex;
    margin-top: 1rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    @media (min-width: 960px) {
        flex-basis: 100%;
        display: block;
    }
`;
const HeadlineContainer = styled.div`
    width: auto; // here we should use flex grow instead of setting the width??
    margin-bottom: 0.5rem;
    margin-right: 1rem;
    @media (min-width: 960px) {
        width: auto;
        margin-right: 0;
    }
`;

const RoomHeadline = styled.div`
    border-radius: 15px;
    margin: 0rem auto 0.2rem auto;
    padding: 0rem 0.5rem 0rem 0.5rem;
    @media (min-width: 960px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 0.3rem;
    }
`;

const RoomHeadlineMobile = styled(Box)`
    display: inline-block;
    @media (min-width: 960px) {
        display: none;
    }
`;

const RoomDisplay = styled(Typography)`
    margin: 0;
    padding: 0;
    font-size: 1rem;
    @media (min-width: 960px) {
        font-weight: normal;
    }
`;

const RoomHeadlineDesktop = styled(Box)`
    display: none;
    @media (min-width: 960px) {
        display: contents;
    }
`;

const RoomContentContainer = styled.div`
  /* width:100%; */
  // background-color: ${(props) => props.theme.palette.secondary.light};
  min-height: 100%;
  overflow: ${(props) => (props.isexpanded ? 'inherit' : 'scroll')};
  -ms-overflow-style: none; /* Overflow hiding in IE and Edge */
  scrollbar-width: none; /*Overflow hiding in Firefox */
  &::-webkit-scrollbar {
    display: none; /* Overflow hiding in Chrome, Safari, Opera */
  }

  @media (min-width: 960px) {
    background-color: ${(props) => props.theme.palette.secondary.main};
    min-height: 100%;
    overflow: ${(props) => (props.isexpanded ? 'inherit' : 'scroll')};
    -ms-overflow-style: none; /* Overflow hiding in IE and Edge */
    scrollbar-width: none; /*Overflow hiding in Firefox */ 
    &::-webkit-scrollbar {
        display: none; /* Overflow hiding in Chrome, Safari, Opera */
    }
    }
    
    @media (min-width: 960px) {
        background-color: ${(props) => props.theme.palette.secondary.main};
        min-height: 100%;
        border: 1px solid
            ${(props) => props.theme.palette.secondary.contrastText};
        width: auto;
    }
`;

class Room extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allEventsOfTheRoom: [],
            isExpanded: false,
            displayBackdrop: false,
            events: [],
            isLoading: false
        };
    }

    setIsExpanded = (value) => {
        // this.setState({ isExpanded: value });
        if (value) {
            this.setState({ displayBackdrop: true });
        } else {
            this.setState({ displayBackdrop: false });
        }
    };

    setRoomColor = (roomName) => {
        switch (roomName) {
            case 'Lokale 1':
                return `purple`;
            case 'Lokale 2':
                return `green`;
            case 'Lokale 3':
                return `yellow`;
            case 'Lokale 4':
                return `blue`;
            case 'Lokale 5':
                return `orange`;
            case 'Lokale 6':
                return `red`;
            case 'Lokale 7':
                return `aqua`;
            case 'Lokale 8':
                return `#a71f48`;
            default:
                return `red`;
        }
    };

    isFromToday = (event) => {
        // If it is a day in the past, then we should not display available time slot.
        const today = new Date();
        const todayFormatted = moment(today).format('YYYY-MM-DD');
        const todayMorning = todayFormatted + ' 00:01';
        if (
            moment(todayMorning).isBefore(
                moment(event.date + ' ' + event.startTime)
            )
        ) {
            return true;
        } else {
            return false;
        }
    };

    fetchAndFilterEvents = async () => {
        // Return the right roomId for the selected room - in database the roomId is not the same as the number in the name of the room

        // const properRoomId = getProperRoomId(this.state.chosenRoom);

        const data = await fetchDayEventsForRoom(
            this.props.roomId,
            this.props.date,
            this.props.startDate,
            this.props.endDate,
            this.handleError
        );

        const events = createEvents(
            data,
            this.props.isBookingCalendar,
            this.props.ownEvents
        );
        this.setState({ events });

        this.getAllEventsOfTheRoom();
    };

    getAllEventsOfTheRoom = () => {
        // Get events of the Room, and merge and sort them.
        const allEventsOfTheRoom = sortEvents([
            ...getEventsOfTheRoom(this.state.events, this.props.roomNumber)
        ]);
        this.setState({
            allEventsOfTheRoom: getAllEvents(
                allEventsOfTheRoom,
                this.props.date,
                this.props.dayName
            )
        });
    };

    componentDidUpdate(nextProps) {
        const { date, rooms, ownEvents } = this.props;
        if (nextProps.date !== date || nextProps.ownEvents !== ownEvents) {
            // this.getAllEventsOfTheRoom();
            this.fetchAndFilterEvents();
        }
        if (nextProps.rooms !== rooms) {
            //  this.fetchAndFilterEvents();
            this.fetchAndFilterEvents();
        }
        // this.fetchAndFilterEvents();
    }

    componentDidMount() {
        this.fetchAndFilterEvents();
    }
    render() {
        const { roomId } = this.props;
        return (
            // <RoomCalendar roomsCount={this.props.roomsCount}  key={this.props.roomId}>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.7 }}
                key={this.props.roomId}
                style={{ width: `${100 / this.props.roomsCount}%` }}
                //  width:${(props) =>(100/props.roomsCount)}%;

                // roomsCount={this.props.roomsCount}  key={this.props.roomId}
            >
                {/* <div> */}
                <RoomContainer roomsCount={this.props.roomsCount}>
                    <HeadlineContainer>
                        <RoomHeadline>
                            <RoomHeadlineMobile>
                                <RoomDisplay variant="subtitle1">
                                    Lokale{' '}
                                    {getLocationNameFromRoomId(
                                        this.props.roomId
                                    )}
                                </RoomDisplay>
                            </RoomHeadlineMobile>

                            <RoomHeadlineDesktop>
                                <RoomDisplay variant="subtitle1">
                                    Lokale{' '}
                                    {getLocationNameFromRoomId(
                                        this.props.roomId
                                    )}
                                </RoomDisplay>
                            </RoomHeadlineDesktop>
                        </RoomHeadline>
                    </HeadlineContainer>
                    <RoomContentContainer isexpanded={this.state.isExpanded}>
                        {this.state.allEventsOfTheRoom.map((event, key) => {
                            if (
                                this.props.selectedEventIdToEdit &&
                                event.event_id ===
                                    this.props.selectedEventIdToEdit &&
                                this.isFromToday(event)
                            ) {
                                return (
                                    // Check if selectedEventIdToEdit exists in the list
                                    // Event needs to be modified

                                    <AvailableTimeSlot
                                        key={key}
                                        date={event.date}
                                        startTime={event.startTime}
                                        endTime={event.endTime}
                                        roomId={event.roomId}
                                        fetchAndFilterEventsInCalendar={
                                            this.fetchAndFilterEvents
                                        }
                                        saveEditedEvent={
                                            this.props.saveEditedEvent
                                        }
                                        edit={true}
                                        title={event.title}
                                        isPrivate={event.isPrivate}
                                        existingEvent={true}
                                        selectedEventIdToEdit={
                                            this.props.selectedEventIdToEdit
                                        }
                                        removeEdit={this.props.removeEdit}
                                        // updateOwnEventsList={this.props.updateOwnEventsList}
                                        handleError={this.props.handleError}
                                        // Check if the previous and next event is an actual event or an available time slot
                                        allEventsOfTheRoom={
                                            this.state.allEventsOfTheRoom
                                        }
                                        setIsExpanded={this.setIsExpanded}
                                        roomsCalendar={true}
                                        setJustBookedEvents={
                                            this.props.setJustBookedEvents
                                        }
                                        setConflictingEvents={
                                            this.props.setConflictingEvents
                                        }
                                        setConflictPostRequestBody={
                                            this.props
                                                .setConflictPostRequestBody
                                        }
                                        // addNewOwnEvent={addNewOwnEvent}
                                        repeatSeqId={event.repeatSeqId}
                                        ownEvents={this.props.ownEvents}
                                        updateOwnEvents={
                                            this.props.updateOwnEvents
                                        }
                                        isExpanded={this.state.isExpanded}
                                        shouldBeVisible={event.shouldBeVisible}
                                    />
                                );
                            } else if (
                                event.isAvailable &&
                                this.isFromToday(event)
                            ) {
                                return (
                                    <AvailableTimeSlot
                                        key={key}
                                        date={event.date}
                                        startTime={event.startTime}
                                        endTime={event.endTime}
                                        roomId={roomId}
                                        fetchAndFilterEventsInCalendar={
                                            this.fetchAndFilterEvents
                                        }
                                        handleError={this.props.handleError}
                                        setIsExpanded={this.setIsExpanded}
                                        setJustBookedEvents={
                                            this.props.setJustBookedEvents
                                        }
                                        setConflictingEvents={
                                            this.props.setConflictingEvents
                                        }
                                        setConflictPostRequestBody={
                                            this.props
                                                .setConflictPostRequestBody
                                        }
                                        ownEvents={this.props.ownEvents}
                                        updateOwnEvents={
                                            this.props.updateOwnEvents
                                        }
                                        isExpanded={this.state.isExpanded}
                                        removeEdit={this.props.removeEdit}
                                        shouldBeVisible={event.shouldBeVisible}
                                    />
                                );
                            } else {
                                if (event.title !== 'Ledig') {
                                    console.log('should return an event');
                                    return (
                                        <Event
                                            key={key}
                                            // key={event.event_id}
                                            eventId={event.event_id}
                                            title={event.title}
                                            isPrivate={event.isPrivate}
                                            startTime={event.startTime}
                                            endTime={event.endTime}
                                            type={event.type}
                                            holdId={event.hold_id}
                                            state={event.state}
                                            date={event.date}
                                            repeatSeqId={event.repeatSeqId}
                                            ownEvents={this.props.ownEvents}
                                            editBookedEvent={
                                                this.props.editBookedEvent
                                            }
                                            confirmRemovingEvent={
                                                this.props.confirmRemovingEvent
                                            }
                                            setIsExpanded={this.setIsExpanded}
                                            dayColor={this.setRoomColor(
                                                this.props.roomName
                                            )}
                                            shouldBeVisible={
                                                event.shouldBeVisible
                                            }
                                            isOwnEvent={event.isOwnEvent}
                                            removeEdit={this.props.removeEdit}
                                            userId={event.userId}
                                            isBookingCalendar={
                                                this.props.isBookingCalendar
                                            }
                                        />
                                    );
                                } else {
                                    return (
                                        <AvailableTimeSlot
                                            key={event.endTime}
                                            date={event.date}
                                            startTime={event.startTime}
                                            endTime={event.endTime}
                                            roomId={roomId}
                                            fetchAndFilterEventsInCalendar={
                                                this.fetchAndFilterEvents
                                            }
                                            handleError={this.props.handleError}
                                            setIsExpanded={this.setIsExpanded}
                                            setJustBookedEvents={
                                                this.props.setJustBookedEvents
                                            }
                                            setConflictingEvents={
                                                this.props.setConflictingEvents
                                            }
                                            setConflictPostRequestBody={
                                                this.props
                                                    .setConflictPostRequestBody
                                            }
                                            ownEvents={this.props.ownEvents}
                                            updateOwnEvents={
                                                this.props.updateOwnEvents
                                            }
                                            isExpanded={this.state.isExpanded}
                                            removeEdit={this.props.removeEdit}
                                            shouldBeVisible={
                                                event.shouldBeVisible
                                            }
                                        />
                                    );
                                }
                            }
                        })}
                        {/* {this.state.displayBackdrop && (
                <Backdrop
                  onClick={() => {
                    this.setState({ displayBackdrop: false });
                    this.setIsExpanded(false);
                  }}
                />
              )} */}
                    </RoomContentContainer>
                </RoomContainer>
                {/* </div> */}
            </motion.div>
            // </RoomCalendar>
            // </motion.div>
        );
    }
}

export default Room;
