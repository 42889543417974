import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import da from 'date-fns/locale/da';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
// import "../../index.css";

registerLocale('da', da);

const Container = styled.div`
    & .react-datepicker__month-container {
        width: 360px;
        height: 100%;
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        font-size: 1.2rem;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        font-size: 1.2rem;
        line-height: 2.8rem;
        width: 2.8rem;
        height: 2.8rem;
    }

    .react-datepicker__input-container input {
        padding: 0.5rem;
    }
`;
function DatePickerComponent({ startdate, setStartdate }) {
    return (
        <Container>
            <DatePicker
                portalId="root-portal-startdate"
                dateFormat="dd/MM/yyyy"
                locale="da"
                selected={startdate}
                onChange={(date) => setStartdate(date)}
            />
        </Container>
    );
}

export default DatePickerComponent;
