import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const NavHeadlineComponent = styled(Typography)`
    color: ${(props) =>
        props.isnavactive === 'true'
            ? props.theme.palette.colors.green
            : props.theme.palette.primary.contrastText};
    font-size: 1.125rem;
    display: inline-block;
    font-family: 'Benton Sans Medium';
    text-decoration: ${(props) => props.isnavactive === 'true' && 'underline'};
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        font-size: 1.4rem;
    }
`;

const NavHeadline = ({ title, isActive }) => {
    const [isNavActive, setIsNavActive] = useState(false);

    useEffect(() => {
        if (title === 'Se lokaler' && isActive === 'lokaler') {
            setIsNavActive(true);
        } else if (
            title === 'Reserver lokale' &&
            isActive === 'booking-kalender'
        ) {
            setIsNavActive(true);
        } else if (title === 'Kalender' && isActive === 'aktivitets-kalender') {
            setIsNavActive(true);
        } else if (title === 'Opslagstavle' && isActive === 'opslagstavle') {
            setIsNavActive(true);
        }
        // eslint-disable-next-line
    }, [isActive]);

    return (
        <NavHeadlineComponent
            variant="body1"
            isnavactive={isNavActive ? 'true' : 'false'}
        >
            {title}
        </NavHeadlineComponent>
    );
};

export default NavHeadline;
