import React, { useCallback, useEffect, useState } from 'react';
import ContentContainer from '../../components/containers/ContentContainer';

import Loader from '../../components/loader/Loader';
import RoomGrid from '../../components/room-grid/RoomGrid';
import { getAndReturnData } from '../../context/helperFunctions';
import { motion } from 'framer-motion';
import HeadersContainer from '../../components/headers/headers-container/HeadersContainer';

function Rooms(props) {
    const [roomData, setRoomData] = useState([]);

    const handleError = (err) => {
        console.log(err);
    };

    const getRooms = useCallback(async () => {
        const roomData = await getAndReturnData(
            'api/v1/room?locationId=1',
            handleError
        );
        if (roomData) {
            // Get only rooms that we are able to rent out
            const filteredRoomData = roomData.rooms.filter(
                (room) =>
                    room.id === 1 ||
                    room.id === 2 ||
                    room.id === 5 ||
                    room.id === 9 ||
                    room.id === 10 ||
                    room.id === 12 ||
                    room.id === 13 ||
                    room.id === 14
            );

            const houseData = roomData.rooms.filter((room) => room.id === 26);

            const sortedRooms = filteredRoomData.sort(
                (a, b) => b.area - a.area
            );
            const roomsWithHouse = [...houseData, ...sortedRooms];

            setRoomData(roomsWithHouse);
        }
    }, []);

    useEffect(() => {
        getRooms();
    }, [getRooms]);
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <HeadersContainer />

            <ContentContainer>
                {roomData.length > 0 ? (
                    <RoomGrid
                        returnPath="/lokaler"
                        returnTitle="Lokaleoversigt"
                        url={'/lokaler'}
                        data={roomData}
                    />
                ) : (
                    <Loader />
                )}
            </ContentContainer>
        </motion.div>
    );
}

export default Rooms;
