import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row-reverse;
    width: 85%;
    margin: auto;
    position: relative;
    z-index: 1;
    margin-top: ${(props) => props.mt && props.mt};
`;

const SwitchButtonContainer = styled.div`
    display: block;
    display: flex;
    position: relative;

    @media (max-width: 960px) {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
`;

const CalendarBox = styled.div`
    width: 170px;
    border: 1px solid white;
    padding: 10px 25px 10px 20px;
    border-right: none;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 2;
    height: 42px;

    /* @media (max-width: 400px) {
        width: 138px;
    } */

    span {
        @media (min-width: 960px) {
            position: relative;
            bottom: 3px;
        }
    }
`;
const LocationBox = styled.div`
    width: 170px;
    border: 1px solid white;
    border-left: none;
    padding: 10px 20px 10px 25px;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 2;
    height: 42px;

    /* @media (max-width: 400px) {
        width: 138px;
    } */

    span {
        @media (min-width: 960px) {
            position: relative;
            bottom: 3px;
        }
    }
`;

const BackgroundDrop = styled.span`
    width: 180px;
    height: 40px;
    margin: 1px 0px;
    background: ${(props) => props.theme.palette.colors.green};
    border-radius: 30px;
    position: absolute;
    left: ${(props) =>
        props.currentlyActiveCalendar === 'false' ? 0 : '158px'};
    transition: all 0.3s;
    border: 3px solid black;

    @media (max-width: 400px) {
        width: 152px;
    }
`;

function SwitchButtonCalendar({ handleChange, checked, marginTop }) {
    return (
        <Container mt={marginTop}>
            <SwitchButtonContainer className="switch-button">
                <CalendarBox
                    className="calendar-box"
                    onClick={() => {
                        handleChange();
                    }}
                >
                    <span>Ugevisning</span>
                </CalendarBox>
                <LocationBox
                    className="location-box"
                    onClick={() => {
                        handleChange();
                    }}
                >
                    <span>Lokalevisning</span>
                </LocationBox>

                <BackgroundDrop currentlyActiveCalendar={`${checked}`} />
            </SwitchButtonContainer>
        </Container>
    );
}

export default SwitchButtonCalendar;
