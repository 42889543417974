import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import DownloadIcon from '../../assets/icons/download.png';
import { overridings } from '../../themes/DarkTheme';

const ButtonLink = styled(Typography)`
    text-align: center;
    cursor: pointer;
    color: ${overridings.palette.colors.green};
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        text-decoration: none;
        text-align: left;
    }
`;

const DownloadIconImg = styled.img`
    margin-right: 10px;
    position: relative;
    top: 5px;
`;

function SaveToHomeScreen({
    prompt,
    promptToInstall,
    isVisible,
    setVisibleState,
    setIsFirefox,
    isFirefox,
    variant
}) {
    const onPwaClick = () => {
        console.log('clicked button');
        if (prompt) {
            console.log('prompt is this', prompt);
            // prompt.prompt();
            promptToInstall();
        } else {
            console.log('theres no prompt', prompt);
        }
    };

    const hideButtonInFirefox = () => {
        const firefoxBrowser = /Firefox/i.test(navigator.userAgent);

        if (firefoxBrowser) {
            setIsFirefox(true);
        }
    };

    useEffect(() => {
        if (prompt) {
            console.log('Save to home screen prompt');
            // console.log(prompt);
            setVisibleState(true);
        }
        //eslint-disable-next-line
    }, [prompt]);

    useEffect(() => {
        hideButtonInFirefox();
        //eslint-disable-next-line
    }, []);

    return (
        <>
            {isVisible && (
                <ButtonLink
                    variant={variant ? variant : 'subtitle1'}
                    id="butInstall2"
                    onClick={() => onPwaClick()}
                >
                    <DownloadIconImg src={DownloadIcon} />
                    Hent ned til hjemmeskærm
                </ButtonLink>
            )}
        </>
    );
}

export default SaveToHomeScreen;
