import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';

const DialogComponent = styled(Dialog)`
    & .MuiPaper-root {
        padding: 1rem !important;
    }
`;

function UpdateAllEventsDialog({
    close,
    updateDraftEvents,
    firstEventIdInState,
    firstEventStartDateInState
}) {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <DialogComponent
                onClose={close}
                open={true}
                aria-labelledby="success"
                aria-describedby="success-description"
                disableRestoreFocus
            >
                <DialogTitle id="success"></DialogTitle>

                <DialogContent>
                    <DialogContentText id="success-description">
                        Denne begivenhed er relateret til andre begivenheder.
                        Vil du opdatere dette enkelt begivenhed, eller alle
                        relaterede tilbagevendende begivenheder?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() =>
                            updateDraftEvents(
                                false,
                                firstEventStartDateInState,
                                firstEventIdInState
                            )
                        }
                    >
                        Kun denne
                    </Button>
                    <Button
                        onClick={() =>
                            updateDraftEvents(
                                true,
                                firstEventStartDateInState,
                                firstEventIdInState
                            )
                        }
                    >
                        Alle
                    </Button>
                </DialogActions>
            </DialogComponent>
        </motion.div>
    );
}

export default UpdateAllEventsDialog;
