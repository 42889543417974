import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const DialogComponent = styled(Dialog)`
    & .MuiPaper-root {
        padding: 1rem !important;
        width: 100%;
    }
`;

// const CloseIcon = styled(Typography)`
//     font-size: 2rem;
//     position: absolute;
//     left: 10px;
//     top: -6px;
//     cursor: pointer;
//     color: ${(props) => props.theme.palette.primary.light};

//     @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
//         right: 10px;
//         top: -6px;
//     }
// `;

const DialogContentTextCustom = styled(DialogContentText)`
    display: inline;
`;

const UnderlinedText = styled(Typography)`
    text-decoration: underline;
    display: inline;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.1428571428571428rem;
`;

function AskForLoginDialog(props) {
    const onRegisterClick = () => {
        props.history.push({
            pathname: '/oplysninger',
            state: {
                from: { path: '/', title: 'hjemmesiden' },
                isOnlySignup: true
            }
        });
    };

    const onLoginClick = () => {
        props.close();
        props.history.push({
            pathname: '/login',
            state: {
                fromCalendar: 'booking-kalender',
                modal: true
            }
        });
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <DialogComponent
                onClose={props.close}
                open={true}
                aria-labelledby="success"
                aria-describedby="success-description"
                disableRestoreFocus
                className="login-or-signup-dialog"
            >
                {/* <CloseIcon onClick={() => props.close()}>&#10799;</CloseIcon> */}

                <DialogContent>
                    <Box mb="1rem" mt="1.5rem">
                        <DialogContentTextCustom variant="subtitle1">
                            For at reservere et lokale, skal du have en login.
                        </DialogContentTextCustom>
                    </Box>
                    <Box>
                        <DialogContentTextCustom variant="subtitle1">
                            Log ind{' '}
                        </DialogContentTextCustom>

                        <UnderlinedText
                            variant="body2"
                            onClick={() => onLoginClick()}
                        >
                            her
                        </UnderlinedText>
                        <DialogContentTextCustom variant="subtitle1">
                            , eller opret en profil{' '}
                        </DialogContentTextCustom>
                        <UnderlinedText
                            variant="body2"
                            onClick={() => onRegisterClick()}
                        >
                            her.
                        </UnderlinedText>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.close()}>Ok</Button>
                </DialogActions>
            </DialogComponent>
        </motion.div>
    );
}

export default withRouter(AskForLoginDialog);
