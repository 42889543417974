import React from 'react';
import styled from 'styled-components';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Typography
} from '@material-ui/core';
import RegularButton from '../../buttons/regular-button/RegularButton';
import Loader from '../../loader/Loader';
import { useState } from 'react';
//import { deleteAndReturnRespWithAuth } from "../../../context/helperFunctions";
//import { getToken, getUserId } from "../../../context/getData";

const DialogComponent = styled(Dialog)`
    & .MuiPaper-root {
        padding: 1rem !important;
    }
`;

const ButtonContainer = styled.div`
    width: 12rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
`;

const CloseIcon = styled(Typography)`
    font-size: 2.8rem;
    position: absolute;
    right: 1rem;
    top: 0rem;
    cursor: pointer;
    color: ${(props) => props.theme.palette.primary.contrastText};
`;

function DeleteOrderDialog({ close, confirmDeleteOrder }) {
    // eslint-disable-next-line
    const [error, setError] = useState(false);
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
    const [deleted, setDeleted] = useState(false);

    return (
        <React.Fragment>
            <DialogComponent
                onClose={close}
                open={true}
                aria-labelledby="slet-profil"
                aria-describedby="Slet din profil."
                disableRestoreFocus
            >
                <CloseIcon onClick={() => close()}>&#10799;</CloseIcon>
                {/* <DialogTitle>Vigtigt</DialogTitle> */}
                <DialogContent>
                    <DialogContentText>
                        Er du sikker, at du vil slette din bestilling?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonContainer>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <RegularButton
                                title="Bekræft"
                                onButtonClick={() => {
                                    confirmDeleteOrder();
                                }}
                            />
                        )}
                    </ButtonContainer>
                </DialogActions>
            </DialogComponent>
        </React.Fragment>
    );
}

export default DeleteOrderDialog;
