import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';

import ChevronDownIcon from '../icons/chevron-down-icon/ChevronDownIcon';
import styled from 'styled-components';
import QuestionmarkIcon from '../../assets/icons/questionmark.png';

const RulesTitle = styled(Box)`
    cursor: pointer;
    width: fit-content;
`;

const ChevronUpContainer = styled.span`
    & .icon-tabler {
        transform: rotate(180deg);
    }

    position: relative;
    top: 5px;
    margin-left: 6px;
`;

const ChevronDownContainer = styled.span`
    position: relative;
    top: 5px;
    margin-left: 6px;
`;

const QuestionmarkComponent = styled.img.attrs((props) => ({
    className: props.className
}))`
    width: ${(props) => props.width};
    margin-right: 5px;
    margin-left: 2px;
    cursor: pointer;
`;

function NewSubscriptionOnlyText({ setShowQuestionMarkDialog }) {
    const [
        isMembershipRulesSectionOpen,
        setIsMembershipRulesSectionOpen
    ] = useState(false);
    return (
        <div>
            <RulesTitle
                display="flex"
                onClick={() =>
                    setIsMembershipRulesSectionOpen(
                        !isMembershipRulesSectionOpen
                    )
                }
            >
                <Typography variant="subtitle1">Regler og vilkår</Typography>{' '}
                {isMembershipRulesSectionOpen ? (
                    <ChevronUpContainer>
                        <ChevronDownIcon />
                    </ChevronUpContainer>
                ) : (
                    <ChevronDownContainer>
                        <ChevronDownIcon />
                    </ChevronDownContainer>
                )}
            </RulesTitle>
            {isMembershipRulesSectionOpen && (
                <Box>
                    <Box mt="1rem" mb="1rem">
                        <Typography variant="body2">
                            Medlemskabet koster 400 kr. om måneden og fortsætter
                            indtil du opsiger det.
                            <br />
                            Opsigelse af medlemskabet skal foretages senest 48
                            timer før den 1. i måneden.
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2">
                            Medlemskabet giver følgende fordele:{' '}
                        </Typography>
                    </Box>

                    <Box>
                        <ul>
                            <li>
                                Fri adgang til alle ledige
                                <QuestionmarkComponent
                                    src={QuestionmarkIcon}
                                    alt="questionmark"
                                    width={'15px'}
                                    onClick={() =>
                                        setShowQuestionMarkDialog(true)
                                    }
                                    className="questionmark-component"
                                    aria-describedby={'questionmark'}
                                />
                                Lokaler på Bülowsvej 10{' '}
                            </li>
                            <li>50% rabat på lokaleleje</li>
                            <li>Rabat på annullering af reservationer</li>
                            <li>
                                Automatisk fornyelse af dit opslag på
                                opslagstavlen
                            </li>
                        </ul>
                    </Box>
                </Box>
            )}
        </div>
    );
}

export default NewSubscriptionOnlyText;
