import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const ButtonComponent = styled(Button)`
    border-radius: 30px;
    border: 2px solid
        ${(props) => props.border || props.theme.palette.primary.contastText};
    text-transform: none;
    color: ${(props) =>
        props.textcolor
            ? props.textcolor
            : props.theme.palette.primary.contastText};

    width: 100%;
    padding: 8px;
    background-color: ${(props) => props.backgroundcolor || 'transparent'};
    position: relative;
`;

const RegistrationLinkButton = ({
    title,
    size,
    color,
    border,
    id,
    textColor
}) => {
    return (
        <ButtonComponent
            variant="outlined"
            size={size}
            backgroundcolor={color}
            border={border}
            disableRipple
            id={id}
            textcolor={textColor}
        >
            {title}
        </ButtonComponent>
    );
};

export default RegistrationLinkButton;
