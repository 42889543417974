import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import { Box, Typography } from '@material-ui/core';
import StaircaseImage from '../staircase-image/StaircaseImage';
import StaircaseImageFlipped from '../staircase-image/StaircaseImageFlipped';
import StaircaseWhiteImage from '../../assets/staircase/staircase-white.png';

const QABoxComponent = styled(Box)`
    width: ${(props) => props.width};
    border-bottom: 1px solid
        ${(props) => props.theme.palette.primary.contrastText};
    margin-bottom: 1.7rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const StaircaseContainer = styled(Box)`
    position: relative;
    & img {
        margin-right: 1.5rem;
        position: relative;
        top: 0.5rem;
    }
`;

const Question = styled(Typography)`
    margin-bottom: 1.7rem;
    margin-top: 8px;
    width: ${(props) => props.width};
    color: ${(props) => props.theme.palette.colors.orange};
    font-size: 1.25rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
        font-size: 1.125rem;
        margin-top: 3px;
        margin-bottom: 1rem;
    }
`;

const QuestionContainer = styled(Box)`
    cursor: pointer;
`;

function QABox({
    content,
    width,
    openedSection,
    activeSectionId,
    setActiveSectionId
}) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleQABox = (id) => {
        console.log('show id', content);
        if (!isOpen && id > 0) {
            // User opened the section, update id in parent element
            setActiveSectionId(id);
        } else {
            setActiveSectionId(0);
        }

        setIsOpen(!isOpen);
    };

    useEffect(() => {
        // if activeSectionId is changed in paret, we need to change, if it is the current question
        // if it is, then we should open the section
        if (activeSectionId === content.id) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [activeSectionId]);

    return (
        <QABoxComponent width={width}>
            <QuestionContainer display="flex">
                <StaircaseContainer onClick={() => toggleQABox(content.id)}>
                    {isOpen ? (
                        <StaircaseImage
                            src={StaircaseWhiteImage}
                            alt="staircase-logo"
                            width="1.5"
                            height="auto"
                        />
                    ) : (
                        <StaircaseImageFlipped
                            src={StaircaseWhiteImage}
                            alt="staircase-logo"
                            width="1.5"
                        />
                    )}
                </StaircaseContainer>
                <Box>
                    <Question
                        onClick={() => toggleQABox(content.id)}
                        variant="body2"
                        width={width}
                    >
                        {content.question}
                    </Question>
                </Box>
            </QuestionContainer>
            {isOpen && <div className="answer">{content.answers}</div>}
        </QABoxComponent>
    );
}

export default QABox;
