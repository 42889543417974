import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';

const DialogComponent = styled(Dialog)`
    & .MuiPaper-root {
        padding: 1rem !important;
    }

    position: relative;
`;

function ErrorDialog({
    dialogTitle,
    errorMessage,
    close,
    setScrollView,
    secondPartErrorMessage
}) {
    // ComponentDidMount
    useEffect(() => {
        if (setScrollView) {
            setScrollView();
        }
        // Come back: check if its okay not to include setScrollView in dependencies
        // eslint-disable-next-line
    }, []);

    return (
        <DialogComponent
            onClose={close}
            open={true}
            aria-labelledby="error"
            aria-describedby="error-description"
            disableRestoreFocus
        >
            <DialogTitle id="error">{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="error-description">
                    {errorMessage}
                    {secondPartErrorMessage !== undefined ? (
                        <>
                            <br />
                            <br />
                            <span style={{ fontWeight: 'bold' }}> </span>
                            {secondPartErrorMessage}
                        </>
                    ) : (
                        <></>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}>Ok</Button>
            </DialogActions>
        </DialogComponent>
    );
}

export default ErrorDialog;
