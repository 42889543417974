import React from 'react';
import styled from 'styled-components';

const SvgDoorIcon = styled.svg`
    position: relative;
    top: 2px;
    margin-left: 5px;
`;

function OpenDoorIcon(props) {
    return (
        <span>
            <SvgDoorIcon
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-door-enter"
                width={props.width ? props.width : '23'}
                height={props.height ? props.height : '23px'}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#ffffff"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M13 12v.01" />
                <path d="M3 21h18" />
                <path d="M5 21v-16a2 2 0 0 1 2 -2h6m4 10.5v7.5" />
                <path d="M21 7h-7m3 -3l-3 3l3 3" />
            </SvgDoorIcon>
        </span>
    );
}

export default OpenDoorIcon;
