import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';

const DialogComponent = styled(Dialog)`
    & .MuiPaper-root {
        padding: 1rem !important;
    }
`;

function RuleExplanationDialog({ close }) {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <DialogComponent
                onClose={close}
                open={true}
                aria-labelledby="success"
                aria-describedby="success-description"
                disableRestoreFocus
            >
                <DialogTitle id="success"></DialogTitle>

                <DialogContent>
                    <DialogContentText id="success-description">
                        Medlemmer kan frit benytte ledige lokaler, der ikke er
                        udlejet. <br />
                        <br />
                        Kalenderen viser ledige og reserverede tidspunkter, men
                        det står enhver frit at leje et lokale - også med kort
                        varsel. Det betyder, at lokalet skal forlades med det
                        samme, hvis en lejer anmoder om det.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={() => close()}>Ok</Button>
                </DialogActions>
            </DialogComponent>
        </motion.div>
    );
}

export default RuleExplanationDialog;
