import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import ActivityPopup from '../../../containers/activities/ActivityPopup';

const DialogComponent = styled(Dialog)`
    & .MuiPaper-root {
        padding: 1rem !important;
        width: 100%;
    }
`;

const CloseIcon = styled(Typography)`
    font-size: 2rem;
    position: absolute;
    left: 10px;
    top: -6px;
    cursor: pointer;
    color: ${(props) => props.theme.palette.primary.light};

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        right: 10px;
        top: -6px;
    }
`;

function ActivityDialog({ close, eventId, activityInfo }) {
    // const [activityInfo, setActivityInfo] = useState({});

    // const getActivityInfo = async () => {
    //     const data = await getAndReturnData(
    //         `/api/v1/events/activity/${eventId}`
    //     );
    //     if (data) {
    //         console.log('show data', data);
    //         setActivityInfo(data);
    //         setTimeout(() => {
    //             setIsLoading(false);
    //         }, 500);
    //     }
    // };

    // componentDidMount
    // useEffect(() => {
    //     setIsLoading(true);
    //     getActivityInfo();
    //     // eslint-disable-next-line
    // }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <DialogComponent
                onClose={close}
                open={true}
                aria-labelledby="success"
                aria-describedby="success-description"
                disableRestoreFocus
                className="activity-dialog"
            >
                <CloseIcon onClick={() => close()}>&#10799;</CloseIcon>

                <DialogContent>
                    <>
                        {/* <div className="activity-card-container">
                                        <ActivityCard
                                            userId={activityInfo.id}
                                            title={activityInfo.activityName}
                                            imagesUrl={
                                                activityInfo.profileImages
                                                    .imageInfo
                                            }
                                        />
                                    </div> */}

                        <ActivityPopup
                            activityInfo={activityInfo}
                            isInDialog={true}
                        />
                    </>
                </DialogContent>
            </DialogComponent>
        </motion.div>
    );
}

export default ActivityDialog;
