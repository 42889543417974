import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ChevronDownIcon from '../icons/chevron-down-icon/ChevronDownIcon';
import { isSubscriber } from '../../context/getData';
import styled from 'styled-components';
import QuestionmarkIcon from '../../assets/icons/questionmark.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import RegularButton from '../buttons/Button';

const RulesTitle = styled(Box)`
    cursor: pointer;
    width: fit-content;
`;

const ChevronUpContainer = styled.span`
    & .icon-tabler {
        transform: rotate(180deg);
    }

    position: relative;
    top: 5px;
    margin-left: 6px;
`;

const ChevronDownContainer = styled.span`
    position: relative;
    top: 5px;
    margin-left: 6px;
`;

const QuestionmarkComponent = styled.img.attrs((props) => ({
    className: props.className
}))`
    width: ${(props) => props.width};
    margin-right: 5px;
    margin-left: 2px;
    cursor: pointer;
`;

const UnderlinedText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;

const ButtonContainer = styled(Box)`
    width: 12rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

function NewEventsOnlyText({
    addSubscription,
    newSubscriber,
    setShowQuestionMarkDialog
}) {
    const [isEventRulesSectionOpen, setIsEventRulesSectionOpen] = useState(
        false
    );
    const [isPaymentRulesSectionOpen, setIsPaymentRulesSectionOpen] = useState(
        false
    );

    return (
        <div>
            <>
                {isSubscriber() ? (
                    <>
                        <RulesTitle
                            display="flex"
                            onClick={() => {
                                setIsEventRulesSectionOpen(
                                    !isEventRulesSectionOpen
                                );
                                if (isPaymentRulesSectionOpen) {
                                    setIsPaymentRulesSectionOpen(false);
                                }
                            }}
                        >
                            <Typography variant="subtitle1">
                                Regler og vilkår
                            </Typography>{' '}
                            {isEventRulesSectionOpen ? (
                                <ChevronUpContainer>
                                    <ChevronDownIcon />
                                </ChevronUpContainer>
                            ) : (
                                <ChevronDownContainer>
                                    <ChevronDownIcon />
                                </ChevronDownContainer>
                            )}
                        </RulesTitle>
                        {isEventRulesSectionOpen && (
                            <Box mt="1rem">
                                <Typography variant="body2">
                                    Medlemskabet koster 400 kr. om måneden og
                                    fortsætter indtil du opsiger det. <br />
                                    Opsigelse af medlemskabet skal foretages
                                    senest 48 timer før den 1. i måneden.{' '}
                                </Typography>
                                <Typography variant="body2">
                                    Medlemskabet giver følgende fordele:
                                </Typography>
                                <Box>
                                    <ul>
                                        <li>
                                            Fri adgang til alle ledige
                                            <QuestionmarkComponent
                                                src={QuestionmarkIcon}
                                                alt="questionmark"
                                                width={'15px'}
                                                onClick={() =>
                                                    setShowQuestionMarkDialog(
                                                        true
                                                    )
                                                }
                                                className="questionmark-component"
                                                aria-describedby={
                                                    'questionmark'
                                                }
                                            />
                                            Lokaler på Bülowsvej 10{' '}
                                        </li>
                                        <li>50% rabat på lokaleleje</li>
                                        <li>
                                            Rabat på annullering af
                                            reservationer
                                        </li>
                                        <li>
                                            Automatisk fornyelse af dit opslag
                                            på opslagstavlen
                                        </li>
                                    </ul>
                                </Box>
                                <Typography variant="body2">
                                    Kalenderen viser ledige og reserverede
                                    tidspunkter, men det står enhver frit at
                                    leje et lokale - også med kort varsel. Det
                                    betyder, at lokalet skal forlades med det
                                    samme, hvis en lejer anmoder om det.
                                </Typography>
                            </Box>
                        )}
                        <Box mt="1rem" mb="1rem">
                            <RulesTitle
                                display="flex"
                                onClick={() => {
                                    setIsPaymentRulesSectionOpen(
                                        !isPaymentRulesSectionOpen
                                    );

                                    if (isEventRulesSectionOpen) {
                                        setIsEventRulesSectionOpen(false);
                                    }
                                }}
                            >
                                <Typography variant="subtitle1">
                                    Betalings- og annulleringsregler:
                                </Typography>{' '}
                                {isPaymentRulesSectionOpen ? (
                                    <ChevronUpContainer>
                                        <ChevronDownIcon />
                                    </ChevronUpContainer>
                                ) : (
                                    <ChevronDownContainer>
                                        <ChevronDownIcon />
                                    </ChevronDownContainer>
                                )}
                            </RulesTitle>
                        </Box>
                        {isPaymentRulesSectionOpen && (
                            <>
                                <Box>
                                    <Typography variant="body2">
                                        Forbrug faktureres månedsvis bagud og
                                        forfalder til betaling den 1. i måneden.
                                        <br />
                                    </Typography>
                                    <Typography variant="body2">
                                        Det er til enhver tid muligt for brugere
                                        og medlemmer at annullere en reservation
                                        efter følgende regler:
                                        <br />
                                        Ved annullering betales fra 0 til 50% af
                                        reservationsprisen:
                                    </Typography>
                                    <ul>
                                        <li>
                                            Hvis den annullerede reservation
                                            ligger inden for den nærmeste uge
                                            fra annulleringstidspunktet,
                                            opkræves brugere uden medlemskab 50%
                                            af lejeprisen, og medlemmer opkræves
                                            25% af lejeprisen.
                                        </li>
                                        <li>
                                            Hvis den annullerede reservation
                                            ligger senere, opkræves brugere uden
                                            medlemskab 25% af lejeprisen,
                                            hvorimod det er gratis for
                                            medlemmer.
                                        </li>
                                    </ul>
                                </Box>
                                <Box>
                                    <Typography variant="body2">
                                        Se eller rediger dine kommende
                                        reservationer på{' '}
                                        <UnderlinedText>
                                            <Link
                                                to="/min-side/reservationer"
                                                target="_blank"
                                            >
                                                Min side.
                                            </Link>
                                        </UnderlinedText>
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <RulesTitle
                            display="flex"
                            onClick={() => {
                                setIsEventRulesSectionOpen(
                                    !isEventRulesSectionOpen
                                );
                                if (isPaymentRulesSectionOpen) {
                                    setIsPaymentRulesSectionOpen(false);
                                }
                            }}
                        >
                            <Typography variant="subtitle1">
                                Bliv medlem og få følgende fordele:
                            </Typography>{' '}
                            {isEventRulesSectionOpen ? (
                                <ChevronUpContainer>
                                    <ChevronDownIcon />
                                </ChevronUpContainer>
                            ) : (
                                <ChevronDownContainer>
                                    <ChevronDownIcon />
                                </ChevronDownContainer>
                            )}
                        </RulesTitle>
                        {isEventRulesSectionOpen && (
                            <>
                                <Box>
                                    <ul>
                                        <li>
                                            Fri adgang til alle ledige
                                            <QuestionmarkComponent
                                                src={QuestionmarkIcon}
                                                alt="questionmark"
                                                width={'15px'}
                                                onClick={() =>
                                                    setShowQuestionMarkDialog(
                                                        true
                                                    )
                                                }
                                                className="questionmark-component"
                                                aria-describedby={
                                                    'questionmark'
                                                }
                                            />
                                            Lokaler på Bülowsvej 10{' '}
                                        </li>
                                        <li>50% rabat på lokaleleje</li>
                                        <li>
                                            Rabat på annullering af
                                            reservationer
                                        </li>
                                        <li>
                                            Automatisk fornyelse af dit opslag
                                            på opslagstavlen
                                        </li>
                                    </ul>
                                </Box>
                                <Typography variant="body2">
                                    Kalenderen viser ledige og reserverede
                                    tidspunkter, men det står enhver frit at
                                    leje et lokale - også med kort varsel. 
                                    <br /> Det betyder, at lokalet skal forlades
                                    med det samme, hvis en lejer anmoder om det.
                                </Typography>
                                <Box mb="1rem"></Box>
                                <Typography variant="body2">
                                    NB! Rabatfordelene gælder kun, hvis
                                    medlemskabet oprettes sammen med oprettelsen
                                    af reservationerne og bevares. Så snart
                                    medlemskabet ophører, bortfalder rabatten{' '}
                                </Typography>
                                {!newSubscriber && !isSubscriber() && (
                                    <ButtonContainer mt="1.5rem">
                                        <RegularButton
                                            title="Tilføj medlemskab"
                                            onButtonClick={() =>
                                                addSubscription()
                                            }
                                            mobileMaxWidth="12rem"
                                        />
                                    </ButtonContainer>
                                )}
                            </>
                        )}
                        <Box mt="1rem" mb="1rem">
                            <RulesTitle
                                display="flex"
                                onClick={() => {
                                    setIsPaymentRulesSectionOpen(
                                        !isPaymentRulesSectionOpen
                                    );

                                    if (isEventRulesSectionOpen) {
                                        setIsEventRulesSectionOpen(false);
                                    }
                                }}
                            >
                                <Typography variant="subtitle1">
                                    Betalings- og annulleringsregler:
                                </Typography>{' '}
                                {isPaymentRulesSectionOpen ? (
                                    <ChevronUpContainer>
                                        <ChevronDownIcon />
                                    </ChevronUpContainer>
                                ) : (
                                    <ChevronDownContainer>
                                        <ChevronDownIcon />
                                    </ChevronDownContainer>
                                )}
                            </RulesTitle>
                        </Box>
                        {isPaymentRulesSectionOpen && (
                            <>
                                <Box>
                                    <Typography variant="body2">
                                        Forbrug faktureres månedsvis bagud og
                                        forfalder til betaling den 1. i måneden.
                                    </Typography>
                                    <Box mb="1rem"></Box>
                                    <Typography variant="body2">
                                        Det er til enhver tid muligt for brugere
                                        og medlemmer at annullere en reservation
                                        efter følgende regler:
                                    </Typography>
                                    <ul>
                                        <li>
                                            Hvis den annullerede reservation
                                            ligger inden for den nærmeste uge
                                            fra annulleringstidspunktet,
                                            opkræves brugere uden medlemskab 50%
                                            af lejeprisen, og medlemmer opkræves
                                            25% af lejeprisen.
                                        </li>
                                        <li>
                                            Hvis den annullerede reservation
                                            ligger senere, opkræves brugere uden
                                            medlemskab 25% af lejeprisen,
                                            hvorimod det er gratis for
                                            medlemmer.
                                        </li>
                                    </ul>
                                </Box>
                                <Box>
                                    <Typography variant="body2">
                                        Se eller rediger dine kommende
                                        reservationer på{' '}
                                        <UnderlinedText>
                                            <Link
                                                to="/min-side/reservationer"
                                                target="_blank"
                                            >
                                                Min side.
                                            </Link>
                                        </UnderlinedText>
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </>
                )}
            </>
        </div>
    );
}

export default NewEventsOnlyText;
