import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import PersonalDataDialog from '../dialogs/personal-data-dialog/PersonalDataDialog';
const List = styled.ul``;

const ListItem = styled.li`
    width: 70%;
    font-size: 0.85rem;
    padding: 0.5rem 0rem;
    margin-bottom: 0rem !important;
`;

const Bold = styled.span`
    font-family: 'Benton Sans Med';
`;
const UnderlinedText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;
const OuterContainer = styled.div`
    position: relative;
    margin-bottom: 2rem;
`;

const TermsOfPurchaseContainer = styled.div`
    height: 25rem;

    overflow: scroll;
    overflow-x: hidden;

    & :last-child {
        margin-bottom: 5rem;
    }
`;

const LinkText = styled.a`
    text-decoration: underline;
`;

const GradientOverlay = styled.div`
    height: 6rem;
    width: 100%;
    background-image: linear-gradient(rgba(21, 19, 19, 0), rgba(21, 19, 19, 1));
    position: absolute;
    bottom: 0;
`;

const TextTitle = styled(Typography)`
    font-size: 1.2rem;
    padding: 0.8rem 0rem 0.1rem 0rem;
    color: ${(props) => props.theme.palette.colors.orange};
    width: 80%;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 90%;
    }
    @media (max-width: 350px) {
        font-size: 1rem;
    }
`;
const Text = styled(Typography)`
    padding: 0.3rem 0rem;
    width: 75%;
    font-size: 1rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 90%;
    }
    @media (max-width: 350px) {
        font-size: 0.9rem;
    }
`;

function CookieRules({ isDialog }) {
    const [isPersonalDataDialogOpen, setIsPersonalDataDialogOpen] = useState(
        false
    );
    const showPersonalDataRules = () => {
        setIsPersonalDataDialogOpen(true);
    };

    return (
        <OuterContainer>
            {isDialog ? (
                <>
                    <TermsOfPurchaseContainer>
                        <TextTitle variant="subtitle1">Generelt</TextTitle>
                        <Text variant="body2" gutterBottom>
                            Denne cookiepolitik er baseret på kravene i{' '}
                            <LinkText
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.retsinformation.dk/eli/lta/2011/1148"
                            >
                                Cookiebekendtgørelsen
                            </LinkText>
                            : ”Bekendtgørelse om krav til information og
                            samtykke ved lagring af eller adgang til oplysninger
                            i slutbrugeres terminaludstyr”.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Bekendtgørelsen indeholder en række regler angående
                            information om og samtykke til, hvilke cookies der
                            lagres på din computer. Når du besøger vores
                            hjemmeside, indsamles en række oplysninger om dig,
                            din computer og din adfærd på hjemmesiden. I denne
                            cookiepolitik kan du læse mere om de informationer,
                            vi indsamler, hvordan vi behandler oplysningerne,
                            hvad vi bruger dem til, hvem der har adgang til
                            oplysningerne, og hvem du kan kontakte om de
                            indsamlede oplysninger.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Ved at besøge vores hjemmeside og acceptere cookies,
                            giver du samtidig dit samtykke til denne
                            cookiepolitik og indsamlingen af oplysningerne om
                            dig.
                        </Text>

                        <TextTitle variant="subtitle1">
                            Hvad er en cookie?
                        </TextTitle>

                        <Text variant="body2" gutterBottom>
                            En cookie er en lille fil, som gemmes i din browser,
                            og som kan indeholde tekst, tal eller fx en dato,
                            men der er ingen personlige oplysninger gemt i en
                            cookie.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Der findes to typer cookies:
                        </Text>

                        <List>
                            <ListItem>
                                Sessionscookies, der slettes, så snart du
                                forlader en hjemmeside.
                            </ListItem>
                            <ListItem>
                                Permanente cookies, der lagres på din computer,
                                smartphone eller tablet med en forudbestemt
                                udløbsdato.
                            </ListItem>
                        </List>

                        <Text variant="body2" gutterBottom>
                            Cookies udgør ikke en trussel mod din computer,
                            smartphone eller tablet (som fx en virus), og
                            cookies kan ikke få adgang til programmerne på din
                            computer.
                        </Text>

                        <TextTitle variant="subtitle1">
                            Brug af cookies
                        </TextTitle>

                        <Text variant="body2" gutterBottom>
                            Når du besøger vores hjemmeside, modtager du
                            automatisk en eller flere cookies. Du bliver bedt om
                            aktivt at acceptere cookies, og det er kun Den Ny
                            Musikskole ApS, som dataansvarlig, som bruger de
                            oplysninger, der indsamles. Tredjeparter har adgang
                            til oplysningerne i det omfang, det er nødvendigt
                            for at drive hjemmesiden.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Vi behandler dine private data fortroligt, og du kan
                            altid logge ind og se dine oplysninger eller lukke
                            din konto, så alle data anonymiseres i det omfang
                            lovgivningen tillader det. Vi er jævnfør
                            regnskabsloven forpligtet til at gemme
                            regnskabsinformationer om betalinger i 5 år.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Du kan vælge mellem 4 cookieindstillinger:
                        </Text>

                        <Text variant="body2" gutterBottom>
                            <Bold>Nødvendige: </Bold>Cookies, som skal bruges
                            for at hjemmesiden kan fungere.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            <Bold>Præference: </Bold>Cookies, som husker nogle
                            af dine valg, fx dit foretrukne sprog eller i
                            hvilken del af landet, du befinder dig.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            <Bold>Statistik: </Bold>Cookies, som kan bruges i
                            vores statistik. Det er fx registrering af klik på
                            links eller hvor lang tid, der bruges på en side.
                            Statistiske cookies hjælper os med at måle trafik og
                            gøre hjemmesiden mere brugervenlig.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            <Bold>Marketing: </Bold>Cookies, som kan bruges til
                            at give dig relevante oplysninger, tilbud og
                            annoncer, som er tilpasset den enkelte bruger.
                        </Text>

                        <TextTitle variant="subtitle1">
                            I hvor lang tid opbevarer vi cookies?
                        </TextTitle>

                        <Text variant="body2" gutterBottom>
                            De cookies, der er sendt til dig fra denne
                            hjemmeside, vil blive opbevaret i højest 24 måneder
                            fra sidste gang, du var inde på hjemmesiden. Hver
                            gang du besøger vores hjemmeside, forlænges
                            perioden. De pågældende cookies opbevares derfor i
                            højest 24 måneder fra dit seneste besøg på vores
                            hjemmeside.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Brugen af cookies kan medføre behandling af
                            personoplysninger, og vi anbefaler derfor, at du
                            læser vores{' '}
                            <UnderlinedText onClick={showPersonalDataRules}>
                                persondatapolitik her
                            </UnderlinedText>
                            .
                        </Text>

                        <TextTitle variant="subtitle1">
                            Hvordan undgår du cookies?
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            <Bold>Afvisning af cookies:</Bold> Du kan altid
                            afvise cookies på din computer ved at ændre
                            indstillingerne i din Internet-browser. Hvor du
                            finder indstillingerne, afhænger af hvilken browser,
                            du anvender. Du skal dog være opmærksom på, at hvis
                            du gør det, kan der være funktioner, du ikke kan
                            bruge, fordi disse funktioner og services
                            forudsætter, at hjemmesiden kan huske de valg, du
                            foretager.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            <Bold>Sletning af cookies: </Bold>Cookies, som du
                            tidligere har accepteret, kan efterfølgende nemt
                            slettes. Benytter du en computer med en nyere
                            Internet-browser, kan du slette dine cookies ved at
                            bruge genvejstasterne: CTRL + SHIFT + Delete. Virker
                            genvejstasterne ikke, og/eller benytter du en Mac,
                            skal du starte med at finde ud af, hvilken browser
                            du bruger og herefter klikke på de relevante link.{' '}
                            <Bold>Husk:</Bold> Bruger du flere
                            Internet-browsere, skal du slette cookies i dem
                            alle.
                        </Text>
                        <Box mb="3rem">
                            {' '}
                            <Text variant="h5" gutterBottom>
                                Cookiepolitik er senest opdateret den 25.08.2021
                            </Text>
                        </Box>
                    </TermsOfPurchaseContainer>{' '}
                    <GradientOverlay />
                </>
            ) : (
                <>
                    <TextTitle variant="subtitle1">Generelt</TextTitle>
                    <Text variant="body2" gutterBottom>
                        Denne cookiepolitik er baseret på kravene i{' '}
                        <LinkText
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.retsinformation.dk/eli/lta/2011/1148"
                        >
                            Cookiebekendtgørelsen
                        </LinkText>
                        : ”Bekendtgørelse om krav til information og samtykke
                        ved lagring af eller adgang til oplysninger i
                        slutbrugeres terminaludstyr”.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Bekendtgørelsen indeholder en række regler angående
                        information om og samtykke til, hvilke cookies der
                        lagres på din computer. Når du besøger vores hjemmeside,
                        indsamles en række oplysninger om dig, din computer og
                        din adfærd på hjemmesiden. I denne cookiepolitik kan du
                        læse mere om de informationer, vi indsamler, hvordan vi
                        behandler oplysningerne, hvad vi bruger dem til, hvem
                        der har adgang til oplysningerne, og hvem du kan
                        kontakte om de indsamlede oplysninger.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Ved at besøge vores hjemmeside og acceptere cookies,
                        giver du samtidig dit samtykke til denne cookiepolitik
                        og indsamlingen af oplysningerne om dig.
                    </Text>

                    <TextTitle variant="subtitle1">
                        Hvad er en cookie?
                    </TextTitle>

                    <Text variant="body2" gutterBottom>
                        En cookie er en lille fil, som gemmes i din browser, og
                        som kan indeholde tekst, tal eller fx en dato, men der
                        er ingen personlige oplysninger gemt i en cookie.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Der findes to typer cookies:
                    </Text>

                    <List>
                        <ListItem>
                            Sessionscookies, der slettes, så snart du forlader
                            en hjemmeside.
                        </ListItem>
                        <ListItem>
                            Permanente cookies, der lagres på din computer,
                            smartphone eller tablet med en forudbestemt
                            udløbsdato.
                        </ListItem>
                    </List>

                    <Text variant="body2" gutterBottom>
                        Cookies udgør ikke en trussel mod din computer,
                        smartphone eller tablet (som fx en virus), og cookies
                        kan ikke få adgang til programmerne på din computer.
                    </Text>

                    <TextTitle variant="subtitle1">Brug af cookies</TextTitle>

                    <Text variant="body2" gutterBottom>
                        Når du besøger vores hjemmeside, modtager du automatisk
                        en eller flere cookies. Du bliver bedt om aktivt at
                        acceptere cookies, og det er kun Den Ny Musikskole ApS,
                        som dataansvarlig, som bruger de oplysninger, der
                        indsamles. Tredjeparter har adgang til oplysningerne i
                        det omfang, det er nødvendigt for at drive hjemmesiden.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Vi behandler dine private data fortroligt, og du kan
                        altid logge ind og se dine oplysninger eller lukke din
                        konto, så alle data anonymiseres i det omfang
                        lovgivningen tillader det. Vi er jævnfør regnskabsloven
                        forpligtet til at gemme regnskabsinformationer om
                        betalinger i 5 år.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Du kan vælge mellem 4 cookieindstillinger:
                    </Text>

                    <Text variant="body2" gutterBottom>
                        <Bold>Nødvendige: </Bold>Cookies, som skal bruges for at
                        hjemmesiden kan fungere.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        <Bold>Præference: </Bold>Cookies, som husker nogle af
                        dine valg, fx dit foretrukne sprog eller i hvilken del
                        af landet, du befinder dig.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        <Bold>Statistik: </Bold>Cookies, som kan bruges i vores
                        statistik. Det er fx registrering af klik på links eller
                        hvor lang tid, der bruges på en side. Statistiske
                        cookies hjælper os med at måle trafik og gøre
                        hjemmesiden mere brugervenlig.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        <Bold>Marketing: </Bold>Cookies, som kan bruges til at
                        give dig relevante oplysninger, tilbud og annoncer, som
                        er tilpasset den enkelte bruger.
                    </Text>

                    <TextTitle variant="subtitle1">
                        I hvor lang tid opbevarer vi cookies?
                    </TextTitle>

                    <Text variant="body2" gutterBottom>
                        De cookies, der er sendt til dig fra denne hjemmeside,
                        vil blive opbevaret i højest 24 måneder fra sidste gang,
                        du var inde på hjemmesiden. Hver gang du besøger vores
                        hjemmeside, forlænges perioden. De pågældende cookies
                        opbevares derfor i højest 24 måneder fra dit seneste
                        besøg på vores hjemmeside.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Brugen af cookies kan medføre behandling af
                        personoplysninger, og vi anbefaler derfor, at du læser
                        vores{' '}
                        <UnderlinedText onClick={showPersonalDataRules}>
                            persondatapolitik her
                        </UnderlinedText>
                        .
                    </Text>

                    <TextTitle variant="subtitle1">
                        Hvordan undgår du cookies?
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        <Bold>Afvisning af cookies:</Bold> Du kan altid afvise
                        cookies på din computer ved at ændre indstillingerne i
                        din Internet-browser. Hvor du finder indstillingerne,
                        afhænger af hvilken browser, du anvender. Du skal dog
                        være opmærksom på, at hvis du gør det, kan der være
                        funktioner, du ikke kan bruge, fordi disse funktioner og
                        services forudsætter, at hjemmesiden kan huske de valg,
                        du foretager.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        <Bold>Sletning af cookies: </Bold>Cookies, som du
                        tidligere har accepteret, kan efterfølgende nemt
                        slettes. Benytter du en computer med en nyere
                        Internet-browser, kan du slette dine cookies ved at
                        bruge genvejstasterne: CTRL + SHIFT + Delete. Virker
                        genvejstasterne ikke, og/eller benytter du en Mac, skal
                        du starte med at finde ud af, hvilken browser du bruger
                        og herefter klikke på de relevante link.{' '}
                        <Bold>Husk:</Bold> Bruger du flere Internet-browsere,
                        skal du slette cookies i dem alle.
                    </Text>
                    <Text variant="h5" gutterBottom>
                        Cookiepolitik er senest opdateret den 25.08.2021
                    </Text>
                </>
            )}
            {isPersonalDataDialogOpen && (
                <PersonalDataDialog
                    close={() => setIsPersonalDataDialogOpen(false)}
                />
            )}
        </OuterContainer>
    );
}

export default CookieRules;
