import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText
} from '@material-ui/core';

const DialogComponent = styled(Dialog)`
    & .MuiPaper-root {
        padding: 1rem !important;
    }

    position: relative;
`;
// IsWarning property is passed in Checkout page, if the user is trying to pay without having the phone or email confirmed.

function ResendCodeDialog({ close, setScrollView, isWarning }) {
    // const [success, setSuccess] = useState(false);
    // const [successMessage, setSuccessMessage] = useState("");
    // const [error, setError] = useState(false);
    // const [isLoadingEmail, setIsLoadingEmail] = useState(false);
    // const [isLoadingPhone, setIsLoadingPhone] = useState(false);
    // const jwtToken = getToken();

    // Removed the links, since it can confuse the user
    // const verify = async (verifyType) => {
    //   if (verifyType === "email") {
    //     setIsLoadingEmail(true);
    //   }
    //   if (verifyType === "sms") {
    //     setIsLoadingPhone(true);
    //   }

    //   const userId = getUserId();
    //   const response = await postTokenAuthorization(
    //     `/api/v1/users/${userId}/send-verification-${verifyType}`,
    //     {},
    //     jwtToken,
    //     handleError
    //   );

    //   if (response && response.status === 200) {
    //     setSuccess(true);
    //     setSuccessMessage(`Sendt.`);
    //     setSuccess(true);
    //   } else {
    //     setError(true);
    //   }
    //   setIsLoadingEmail(false);
    //   setIsLoadingPhone(false);
    // };

    // const handleError = () => {
    //   setError(true);
    //   console.error(error);
    // };
    // ComponentDidMount
    useEffect(() => {
        if (setScrollView) {
            setScrollView();
        }
        // Come back: check if its okay not to include setScrollView in dependencies
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <DialogComponent
                onClose={close}
                open={true}
                aria-labelledby="confirmation"
                aria-describedby="confirmation-description"
                disableRestoreFocus
            >
                <DialogContent>
                    {isWarning ? (
                        <Box id="confirmation-description">
                            <DialogContentText>
                                Du skal trykke på linket både i din mail og i
                                din sms for at bekræfte din profil. Herefter er
                                du klar til at oprette dit medlemskab eller
                                reservere lokaler.
                            </DialogContentText>
                            {/* <Box>
                <Box mb=".5rem" width="fit-content">
                  {isLoadingEmail ? (
                    <Loader />
                  ) : (
                    <RegularLink
                      title="Gensend email"
                      onClick={() => verify("email")}
                    ></RegularLink>
                  )}
                </Box>
                <Box width="fit-content">
                  {isLoadingPhone ? (
                    <Loader />
                  ) : (
                    <RegularLink
                      title="Gensend sms"
                      onClick={() => verify("sms")}
                    ></RegularLink>
                  )}
                </Box>
              </Box> */}
                        </Box>
                    ) : (
                        <Box id="confirmation-description">
                            <DialogContentText>
                                Du har nu oprettet din profil.
                            </DialogContentText>
                            <DialogContentText>
                                Vi har sendt en email og en sms til dig. Du skal
                                trykke på linket både i din mail og i din sms
                                for at bekræfte din profil. Herefter er du klar
                                til at oprette dit medlemskab.
                            </DialogContentText>
                            <DialogContentText>
                                Har du ikke modtaget en email eller en sms fra
                                os indenfor et par minutter, kan du tjekke dit
                                spamfilter eller få gensendt linket ved at gå
                                ind på din profil, under Min side.
                            </DialogContentText>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => close()}>Ok</Button>
                </DialogActions>
            </DialogComponent>
        </div>
    );
}

export default ResendCodeDialog;
