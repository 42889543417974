import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const Headline = styled(Typography)`
font-size: ${(props) => (props.fontsize ? props.fontsize : 'auto')}
  color: ${(props) =>
      props.textcolor && props.textcolor !== ''
          ? `rgba(${props.textcolor} 1)`
          : 'inherit'};

  margin-bottom: ${(props) =>
      props.marginbottom ? props.marginbottom : '2rem'};
    text-align: left;
  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    margin-bottom: 1.5rem;
  }

/* font-size: ${(props) => (props.fontsize ? props.fontsize : 'auto')} */
`;

function RegularHeadline({
    title,
    component,
    marginbottom,
    color,
    variant,
    fontsize
}) {
    return (
        <Headline
            variant={variant ? variant : 'h5'}
            component={component}
            marginbottom={marginbottom}
            textcolor={color}
            fontSize={fontsize}
        >
            {title}
        </Headline>
    );
}

export default RegularHeadline;
