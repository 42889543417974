import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    TextField
} from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { overridings } from '../../themes/DarkTheme';
import VisibilityIconComponent from '../visibility-icon/VisibilityIconComponent';

const CustomFormControl = styled(FormControl)`
    &.MuiFormControl-root {
        width: 100%;
    }
`;

const InputLabelComponent = styled(InputLabel)`
    color: ${(props) => props.theme.palette.primary.contrastText};
    margin-bottom: 0.5rem;
    font-size: 0.875rem;

    &.MuiInputLabel-formControl {
        position: static;
        transform: none;
    }
`;

const TextFieldComponent = styled(TextField)`
    /* width: 20rem; */
    & label.Mui-focused {
        color: ${(props) => props.focuscolor && props.focuscolor};
    }

    & .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.palette.primary.contrastText};
        border-radius: 0;
    }

    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.palette.primary.light};
    }

    & .MuiOutlinedInput-input {
        /* padding-top: 0.6rem;
    padding-bottom: 0.7rem; */
    }

    & .MuiInputBase-input {
        font-size: 0.8rem;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const TextInputContainer = styled.div`
    /* margin-right: 2rem; */
    margin-bottom: ${(props) => (props.mb ? props.mb : '1rem')};
    padding-top: 1rem;
    position: relative;
    width: fit-content;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const VisibilityContainer = styled.div`
    position: absolute;
    top: 3rem;
    right: 0.5rem;
`;

function TextInput({
    label,
    onInputChange,
    smallErrorText,
    inputError,
    id,
    type,
    visible,
    handleVisibility,
    customClass,
    valueFromParent,
    marginBottom,
    removeErrorOnChange,
    variant,
    maxLength
}) {
    const [value, setValue] = useState('');
    // Next is needed for onBlur
    const [unsavedValue, setUnsavedValue] = useState(value);

    const handleInputChange = (e) => {
        if (removeErrorOnChange) {
            removeErrorOnChange();
        }
        // Remove error message
        setUnsavedValue(e.target.value);
    };

    // Send value to parent on onBlur
    const handleInputChangeOnBlur = () => {
        setValue(unsavedValue);
        const valueObject = {
            label,
            value: unsavedValue
        };

        onInputChange(valueObject);
    };

    useEffect(() => {
        setValue(valueFromParent);
        setUnsavedValue(valueFromParent);
    }, [valueFromParent]);

    return (
        <CustomFormControl error={inputError} className={customClass}>
            <TextInputContainer mb={marginBottom}>
                {label === 'Aktivitetsnavn' ? null : (
                    <InputLabelComponent label={label} htmlFor={id}>
                        {label}
                    </InputLabelComponent>
                )}

                <TextFieldComponent
                    id={id}
                    variant={variant ? variant : 'outlined'}
                    onChange={(event) => handleInputChange(event)}
                    value={unsavedValue}
                    aria-describedby={label}
                    type={type}
                    onBlur={handleInputChangeOnBlur}
                    label={label}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    focuscolor={overridings.palette.primary.contrastText}
                />
                {(label === 'Password' || label === 'Gentag password') && (
                    <VisibilityContainer>
                        <VisibilityIconComponent
                            visible={visible}
                            handleVisibility={handleVisibility}
                        />
                    </VisibilityContainer>
                )}
                {inputError ? (
                    <Box maxWidth="fit-content">
                        <FormHelperText>{smallErrorText}</FormHelperText>
                    </Box>
                ) : null}
            </TextInputContainer>
        </CustomFormControl>
    );
}

export default TextInput;
