import React from 'react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';

const ButtonComponent = styled(Button)`
    border-radius: 30px;
    border: 2px solid ${(props) => props.theme.palette.primary.contastText};
    text-transform: none;
    width: 100%;
    padding: 3px 15px;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        max-width: ${(props) => props.mobilemaxwidth && props.mobilemaxwidth};
    }
`;

const RegularButton = ({ title, onButtonClick, size, mobileMaxWidth }) => {
    return (
        <ButtonComponent
            variant="outlined"
            size={size}
            onClick={(event) => {
                onButtonClick(event);
            }}
            mobilemaxwidth={mobileMaxWidth}
        >
            {title}
        </ButtonComponent>
    );
};

export default RegularButton;
