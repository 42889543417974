import React from 'react';
import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import SquareIcon from '../icons/square-icon/SquareIcon';
import SquareCheckedIcon from '../icons/square-checked-icon/CheckedSquareIcon';

const CheckboxComp = styled(Checkbox)`
    position: ${(props) =>
        props.position === 'static' ? 'static' : 'absolute'};
    top: ${(props) => (props.position === 'static' ? '0' : '40%')};
    right: ${(props) => (props.position === 'static' ? '0' : '6rem')};
    padding: 2px;

    & .MuiIconButton-label input {
        width: ${(props) => {
            if (props.linksintext && props.linksintext === 'true') {
                return '3rem';
            } else {
                return '100%';
            }
        }};
    }

    & .MuiIconButton-label {
        height: 28px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        right: ${(props) => (props.position === 'static' ? '0' : '1rem')};
    }
`;

// const CustomRadioButtonChecked = styled(RadioButtonChecked)`
// color: ${(props) =>
//     props.color ? props.color : props.theme.palette.primary.contrastText} ;
//      /* padding: 0 2px 0 0; */
//      margin-right: 1px;
//      /* background: yellow; */
//   /* color: ${(props) => props.theme.palette.primary.contrastText}; */
// `;

// const CustomRadioButtonUnchecked = styled(RadioButtonUnchecked)`
//   color: ${(props) => props.theme.palette.primary.contrastText};
//   &.MuiSvgIcon-root {
//     color: ${(props) =>
//       props.error && props.error === "true"
//         ? props.theme.palette.error.main
//         : props.theme.palette.primary.contrastText};
//   }
// `;

function CheckboxComponent({
    onCheckboxChange,
    checked,
    position,
    error,
    linksInText,
    color,
    value
}) {
    return (
        <CheckboxComp
            onChange={(event) => {
                onCheckboxChange(event);
            }}
            checkedIcon={<SquareCheckedIcon width="22px" height="22px" />}
            name="checked"
            checked={checked}
            disableRipple
            position={position}
            linksintext={linksInText}
            value={value}
            icon={<SquareIcon width="22px" height="22px" />}
        />
    );
}

export default CheckboxComponent;
