import React, { useContext, useRef, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
    removeWhiteSpace,
    removeWhiteSpacePhone
} from '../../../context/helperFunctions';
import {
    findUnfilledInputOrRegister,
    hasErrors,
    validateEmail,
    validateName,
    validatePassword,
    validatePasswordMatch,
    validatePhone,
    validatePostcode,
    validateStringAddress,
    returnValue,
    validateActivityDescription,
    validateActivityOrWebpage
} from './RegisterInformationFunctions';
import { Context } from '../../../context/Context';
import {
    getLatestRegData,
    getAuthentication,
    returnAuthenticated,
    hasPrevPageDestination,
    getPrevPageDestination,
    getPrevPageUrl
} from '../../../context/getData';
import { motion } from 'framer-motion';

import { Box, Typography } from '@material-ui/core';
import RegistrationButton from '../../../components/buttons/registration-button/RegistrationButton';
import RegistrationContentContainer from '../../../components/containers/registration/RegistrationContentContainer';

import RegularHeadline from '../../../components/headlines/RegularHeadline';
import ReturnLinkReusable from '../../../components/links/ReturnLinkReusable';
import ErrorDialog from '../../../components/dialogs/error-dialog/ErrorDialog';
import InformationTextInput from '../../../components/text-input/InformationTextInput';

import ProgressBar from '../../../components/progress-bar/ProgressBar';
import Loader from '../../../components/loader/Loader';
import CheckboxComponent from '../../../components/checkbox/CheckboxComponent';
import TermsDialog from '../../../components/dialogs/terms-dialog/TermsDialog';
import HouseRulesDialog from '../../../components/dialogs/house-rules-dialog/HouseRulesDialog';
import PersonalDataDialog from '../../../components/dialogs/personal-data-dialog/PersonalDataDialog';
import CookieDialog from '../../../components/dialogs/cookie-dialog/CookieDialog';
import HeadersContainer from '../../../components/headers/headers-container/HeadersContainer';
// import ActivityRichTextEditor from '../../../components/rich-text-editor/ActivityRichTextEditor';

const DescriptionText = styled(Typography)`
    margin-bottom: 1rem;
    width: 60%;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

// const CategoryBox = styled(Box)``;

const DescriptionTextLink = styled(Typography)`
    margin-bottom: 1rem;
    width: 60%;
    text-decoration: underline;
    cursor: pointer;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const UnderlinedText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;
const OrderList = styled.ul`
    font-size: 0.9rem;
`;

// const PublicProfileContainer = styled.div`
//     margin-top: 30px;
// `;

const NameContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 50rem;

    @media (max-width: 1010px) {
        width: 85%;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
        width: 100%;
    }
`;

const ButtonContainer = styled(Box)`
    cursor: pointer;
`;

const CheckboxText = styled(Typography)`
    margin-top: 7px;

    color: ${(props) =>
        props.error === 'true'
            ? props.theme.palette.error.main
            : props.theme.palette.primary.contrastText};
`;

// const Li = styled.li`
//     font-size: 1rem;
// `;

// const CategoriesContainer = styled.div``;

// const CategoryContainer = styled.div`
//     display: flex;
//     flex-direction: row;
//     align-content: flex-start;
// `;

// const LabelText = styled(Typography)`
//     font-size: 1.375rem;
// `;

// const RichTextTitle = styled(Typography)`
//     font-size: 0.875rem;
//     margin-bottom: 0.5rem;
// `;

function RegisterInformation(props) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordInputType, setPasswordInputType] = useState('password');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [checkboxErrorRules, setCheckboxErrorRules] = useState('false');
    const [acceptedRules, setAcceptedRules] = useState(false);
    // If it is only signup (user is coming from Login dialog - Sign up button), then the process bar is not visible, and user should be redirected to My page.
    const [isOnlySignup, setIsOnlySignup] = useState(false);
    const [termsAndConditionsDialog, setTermsAndConditionsDialog] = useState(
        false
    );
    const [houseRulesDialog, setHouseRulesDialog] = useState(false);
    const [personalDataDialog, setPersonalDataDialog] = useState(false);
    const [cookieRulesDialog, setCookieRulesDialog] = useState(false);

    // eslint-disable-next-line
    const [acceptedPublicProfile, setAcceptedPublicProfile] = useState(false);

    const [firstnameObj, setFirstnameObj] = useState({
        label: 'firstname',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Fornavn',
        placeholder: 'Fornavn'
    });
    const [surnameObj, setSurnameObj] = useState({
        label: 'surname',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Efternavn',
        placeholder: 'Efternavn'
    });
    const [streetObj, setStreetObj] = useState({
        label: 'street',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Gade',
        placeholder: 'Gade'
    });
    const [housenumberObj, setHousenumberObj] = useState({
        label: 'housenumber',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Husnummer/etage/side',
        placeholder: 'Husnummer/etage/side'
    });
    const [postcodeObj, setPostcodeObj] = useState({
        label: 'postcode',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Postnummer',
        placeholder: 'Postnummer'
    });
    const [cityObj, setCityObj] = useState({
        label: 'city',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'By',
        placeholder: 'By'
    });
    const [emailObj, setEmailObj] = useState({
        label: 'email',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Mailadresse',
        placeholder: 'E-Mail'
    });
    const [phoneObj, setPhoneObj] = useState({
        label: 'phone',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Mobil',
        placeholder: 'Mobilnummer'
    });
    const [passwordObj, setPasswordObj] = useState({
        label: 'password',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Adgangskode',
        placeholder: 'Adgangskode'
    });
    const [repeatPasswordObj, setRepeatPasswordObj] = useState({
        label: 'repeatPassword',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Gentag adgangskode',
        placeholder: 'Gentag adgangskode'
    });

    const [activityNameObj, setActivityNameObj] = useState({
        label: 'activityName',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Navn på din aktivitet',
        placeholder: 'Fx. klaverundervisning'
    });

    const [activityDescriptionObj, setActivityDescriptionObj] = useState({
        label: 'activityDescription',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Aktivitets beskrivelse',
        placeholder: 'Aktivitets beskrivelse'
    });

    const [webpageLinkObj, setWebpageLinkObj] = useState({
        label: 'webpageLink',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Hjemmeside',
        placeholder: 'Fx. https://bulowsvej10.dk/'
    });

    const [activityPhoneObj, setActivityPhoneObj] = useState({
        label: 'activityPhone',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Kontaktnummer',
        placeholder: 'Kontaktnummer'
    });

    const [facebookObj, setFacebookObj] = useState({
        label: 'facebook',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Facebook',
        placeholder: 'Fx. https://www.facebook.com/Bulowsvej10'
    });

    const [instagramObj, setInstagramObj] = useState({
        label: 'instagram',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Instagram',
        placeholder: 'Fx. https://www.instagram.com/denlillemusikskole/'
    });

    const [linkedinObj, setLinkedinObj] = useState({
        label: 'linkedIn',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'LinkedIn',
        placeholder: 'Fx. https://www.linkedin.com/company/bulowsvej10/'
    });

    const [youtubeObj, setYoutubeObj] = useState({
        label: 'youtube',
        value: '',
        error: false,
        errorMessage: '',
        danishLabel: 'Youtube',
        placeholder: 'Fx. https://www.youtube.com/@JustInstrumentalMusicYT'
    });

    // const [organizationObj, setOrganizationObj] = useState({
    //     label: 'organization',
    //     value: '',
    //     error: false,
    //     errorMessage: '',
    //     danishLabel: 'Organisation',
    //     placeholder: 'Organisation'
    // });

    // const [imageObj, setImageObj] = useState({
    //   label: "image",
    //   value: "",
    //   error: false,
    //   errorMessage: "",
    //   danishLabel: "Billede",
    //   placeholder: "Billede",
    // });

    // const [sendNewsLetters, setSendNewsLetters] = useState(true);

    // const [acceptedCategory1, setAcceptedCategory1] = useState(false);
    // const [acceptedCategory2, setAcceptedCategory2] = useState(false);

    // Refs
    const contentRef = useRef();
    const parentNameRef = useRef();
    const addressRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const passwordRef = useRef();
    const termsRef = useRef();

    const context = useContext(Context);

    const handleVisibility = () => {
        if (!passwordVisible) {
            setPasswordVisible(true);
            setPasswordInputType('text');
        } else {
            setPasswordVisible(false);
            setPasswordInputType('password');
        }
    };

    const saveInputValue = (valueObject) => {
        const valueNoWhiteSpace = removeWhiteSpace(valueObject.value);
        if (
            valueObject.label === 'Fornavn' ||
            valueObject.label === 'Efternavn'
        ) {
            validateName(
                valueNoWhiteSpace,
                valueObject.label,
                setFirstnameObj,
                setSurnameObj,
                firstnameObj,
                surnameObj
            );
        } else if (
            valueObject.label === 'Gade' ||
            valueObject.label === 'By' ||
            valueObject.label === 'Husnummer/etage/side'
        ) {
            validateStringAddress(
                valueNoWhiteSpace,
                valueObject.label,
                setStreetObj,
                setHousenumberObj,
                setCityObj,
                streetObj,
                housenumberObj,
                cityObj
            );
        } else if (valueObject.label === 'Postnummer') {
            validatePostcode(valueNoWhiteSpace, setPostcodeObj, postcodeObj);
        } else if (
            valueObject.label === 'Mobil' ||
            valueObject.label === 'Kontaktnummer'
        ) {
            const valueNoWhiteSpacePhone = removeWhiteSpacePhone(
                valueObject.value
            );
            validatePhone(
                valueNoWhiteSpacePhone,
                valueObject.label,
                setPhoneObj,
                setActivityPhoneObj,
                phoneObj,
                activityPhoneObj
            );
        } else if (valueObject.label === 'Adgangskode') {
            validatePassword(valueObject.value, setPasswordObj, passwordObj);
        } else if (valueObject.label === 'Gentag adgangskode') {
            validatePasswordMatch(
                passwordObj.value,
                valueObject.value,
                setRepeatPasswordObj,
                repeatPasswordObj
            );
        } else if (valueObject.label === 'Mailadresse') {
            validateEmail(valueNoWhiteSpace, setEmailObj, emailObj);
        } else if (
            valueObject.label === 'Navn på din aktivitet' ||
            valueObject.label === 'Hjemmeside' ||
            valueObject.label === 'Facebook' ||
            valueObject.label === 'Instagram' ||
            valueObject.label === 'LinkedIn' ||
            valueObject.label === 'Youtube'
            // valueObject.label === 'Organisation'
        ) {
            // Check if the user verified email and SMS
            validateActivityOrWebpage(
                valueNoWhiteSpace,
                valueObject.label,
                setActivityNameObj,
                setWebpageLinkObj,
                setFacebookObj,
                setInstagramObj,
                setLinkedinObj,
                setYoutubeObj,
                // setOrganizationObj,
                activityNameObj,
                webpageLinkObj,
                facebookObj,
                instagramObj,
                linkedinObj,
                youtubeObj
                // organizationObj
            );
        } else if (valueObject.label === 'Aktivitets beskrivelse') {
            validateActivityDescription(
                valueNoWhiteSpace,
                valueObject.label,
                setActivityDescriptionObj,
                activityDescriptionObj
            );
        }
    };

    // const handleCheckboxChangeCategories = (category) => {
    //     switch (category) {
    //         case 'category1':
    //             return setAcceptedCategory1(!acceptedCategory1);
    //         case 'category2':
    //             return setAcceptedCategory2(!acceptedCategory2);
    //         default:
    //             setError(true);
    //             setErrorMessage('Category error');
    //     }
    // };

    // const handleCheckboxChangePublicProfile = () => {
    //     setAcceptedPublicProfile(!acceptedPublicProfile);
    // };

    // const getSelectedCategories = () => {
    //     let selectedCategories;
    //     if (acceptedCategory1 && acceptedCategory2) {
    //         selectedCategories = ['category1', 'category2'];
    //     } else if (acceptedCategory1 && !acceptedCategory2) {
    //         selectedCategories = ['category1'];
    //     } else if (!acceptedCategory1 && acceptedCategory2) {
    //         selectedCategories = ['category2'];
    //     } else if (!acceptedCategory1 && !acceptedCategory2) {
    //         selectedCategories = ['category2'];
    //     }

    //     return selectedCategories;
    // };

    const onSubmit = () => {
        if (!acceptedRules) {
            setCheckboxErrorRules('true');
            setError(true);
            setErrorMessage(
                'Du mangler at acceptere persondata- og cookiepolitikken.'
            );
            termsRef.current.scrollIntoView();
            return setError(true);
        }
        setIsLoading(true);
        let states;
        if (acceptedPublicProfile) {
            states = [
                firstnameObj,
                surnameObj,
                streetObj,
                cityObj,
                housenumberObj,
                postcodeObj,
                phoneObj,
                emailObj,
                passwordObj,
                repeatPasswordObj,
                activityNameObj,
                webpageLinkObj,
                activityPhoneObj,
                facebookObj,
                instagramObj,
                linkedinObj,
                youtubeObj,
                // organizationObj,
                activityDescriptionObj
            ];
        } else {
            states = [
                firstnameObj,
                surnameObj,
                streetObj,
                cityObj,
                housenumberObj,
                postcodeObj,
                phoneObj,
                emailObj,
                passwordObj,
                repeatPasswordObj
            ];
        }

        const setStates = [
            { label: 'firstname', function: setFirstnameObj },
            { label: 'surname', function: setSurnameObj },
            { label: 'street', function: setStreetObj },
            { label: 'city', function: setCityObj },
            { label: 'housenumber', function: setHousenumberObj },
            { label: 'postcode', function: setPostcodeObj },
            { label: 'phone', function: setPhoneObj },
            { label: 'email', function: setEmailObj },
            { label: 'password', function: setPasswordObj },
            { label: 'repeatPassword', function: setRepeatPasswordObj },
            { label: 'activityName', function: setActivityNameObj },
            {
                label: 'activityDescription',
                function: setActivityDescriptionObj
            },
            { label: 'webpageLink', function: setWebpageLinkObj },
            { label: 'activityPhone', function: setActivityPhoneObj },
            // { label: 'organization', function: setOrganizationObj },
            {
                label: 'activityDescription',
                function: setActivityDescriptionObj
            },
            { label: 'facebook', function: setFacebookObj },
            { label: 'instagram', function: setInstagramObj },
            { label: 'linkedIn', function: setLinkedinObj },
            { label: 'youtube', function: setYoutubeObj }
        ];

        // const selectedCategories = getSelectedCategories();

        // Check for errors
        if (!hasErrors(states)) {
            findUnfilledInputOrRegister(
                states,
                setStates,
                context,
                props,
                isAuthenticated,
                parentNameRef,
                emailRef,
                phoneRef,
                passwordRef,
                addressRef,
                setError,
                setErrorMessage,
                setIsLoading,
                isOnlySignup,
                acceptedPublicProfile,
                null
            );
        } else {
            // Trow error in dialog
            setError(true);
            setErrorMessage('Du skal udfylde alle felter.');
            setIsLoading(false);
        }
    };

    const removeErrorOnChange = (label) => {
        const states = [
            firstnameObj,
            surnameObj,
            streetObj,
            cityObj,
            housenumberObj,
            postcodeObj,
            phoneObj,
            emailObj,
            passwordObj,
            repeatPasswordObj,
            activityDescriptionObj,
            activityNameObj,
            activityPhoneObj,
            webpageLinkObj,
            facebookObj,
            instagramObj,
            linkedinObj,
            youtubeObj
            // organizationObj
        ];
        const changedField = states.find(
            (state) => state.danishLabel === label
        );

        changedField.error = false;
        changedField.errorMessage = '';
    };

    // If there is anything in storage, fill the inputs
    const recoverInformation = (regInformation) => {
        if (regInformation) {
            if (regInformation.firstname) {
                setFirstnameObj({
                    ...firstnameObj,
                    value: regInformation.firstname
                });
            }
            if (regInformation.surname) {
                setSurnameObj({ ...surnameObj, value: regInformation.surname });
            }
            if (regInformation.city) {
                setCityObj({ ...cityObj, value: regInformation.city });
            }
            if (regInformation.street) {
                setStreetObj({ ...streetObj, value: regInformation.street });
            }
            if (regInformation.postcode) {
                setPostcodeObj({
                    ...postcodeObj,
                    value: regInformation.postcode
                });
            }
            if (regInformation.housenumber) {
                setHousenumberObj({
                    ...housenumberObj,
                    value: regInformation.housenumber
                });
            }
            if (regInformation.email) {
                setEmailObj({ ...emailObj, value: regInformation.email });
            }
            if (regInformation.phone) {
                setPhoneObj({ ...phoneObj, value: regInformation.phone });
            }
            // if (regInformation.activityName) {
            //   setActivityNameObj({
            //     ...activityNameObj,
            //     value: regInformation.activityName,
            //   });
            // }
            // if (regInformation.activityDescription) {
            //   setActivityDescriptionObj({
            //     ...activityDescriptionObj,
            //     value: regInformation.activityDescription,
            //   });
            // }
            // if (regInformation.webpageLink) {
            //   setWebpageLinkObj({
            //     ...webpageLinkObj,
            //     value: regInformation.webpageLink,
            //   });
            // }
            // if (regInformation.isProfilePublic) {
            //   setAcceptedPublicProfile(regInformation.isProfilePublic);
            // }
            // if (regInformation.organization) {
            //   setOrganizationObj({
            //     ...organizationObj,
            //     value: regInformation.organization,
            //   });
            // }
            // if (regInformation.activityPhone) {
            //   setActivityPhoneObj({
            //     ...activityPhoneObj,
            //     value: regInformation.activityPhone,
            //   });
            // }
        }
    };

    const onBackToCalendarClick = () => {
        const states = [
            firstnameObj,
            surnameObj,
            streetObj,
            cityObj,
            housenumberObj,
            postcodeObj,
            phoneObj,
            emailObj,
            passwordObj,
            repeatPasswordObj
            // activityNameObj,
            // activityDescriptionObj,
            // webpageLinkObj,
        ];
        // Save info to local storage, except for password. So if user does not complete the signup and returns, we can restore the given values.
        const regDataWithoutPassword = {
            firstname: returnValue(states, 'firstname'),
            surname: returnValue(states, 'surname'),
            email: returnValue(states, 'email'),
            phone: returnValue(states, 'phone'),
            street: returnValue(states, 'street'),
            housenumber: returnValue(states, 'housenumber'),
            city: returnValue(states, 'city'),
            postcode: returnValue(states, 'postcode')
            // activityName: returnValue(states, "activityName"),
            // activityDescription: returnValue(states, "activityDescription"),
            // webpageLink: returnValue(states, "webpageLink"),
            // isProfilePublic: acceptedPublicProfile,
        };
        // Save information to local storage
        context.saveRegistrationData(regDataWithoutPassword);

        // // In case of adding subscription, we should redirect the user to /abonnement page.
        // // This will get a bit more complex, when we implement the calendar, because we will need to check, if the user is coming from calendar (rates??) or subscription page
        // props.history.push("/abonnement");
    };

    const handleCheckBoxChangeRules = () => {
        if (checkboxErrorRules) {
            setCheckboxErrorRules('false');
        }
        setAcceptedRules(!acceptedRules);
    };

    // const handleCheckboxChangePublicProfile = () => {
    //   if (checkboxErrorPublicProfile) {
    //     setCheckboxErrorPublicProfile("false");
    //   }
    //    setAcceptedPublicProfile(!acceptedPublicProfile);
    // };

    // const handleCheckBoxChangeNewsLetter = () => {
    //   setSendNewsLetters(!sendNewsLetters);
    // };

    // Get authentication
    useEffect(() => {
        getAuthentication(setIsAuthenticated);
    }, [context.state.currentUser]);

    // If user logs in, redirect them to rates page
    useEffect(() => {
        if (isAuthenticated) {
            props.history.push('/opsummering');
        }
        // eslint-disable-next-line
    }, [isAuthenticated]);

    //ComponentDidMount
    // Load data if user has already filled meetings
    useEffect(() => {
        // Check if the user is coming from login dialog (so they clicked on Signup button) - they won't be directed to the kasse page.
        if (props.location.state && props.location.state.isOnlySignup) {
            setIsOnlySignup(true);
        }

        //If user is authenticated, they should not see this page, we take them to the kasse
        if (returnAuthenticated()) {
            props.history.push('/opsummering');
        } else {
            const regData = getLatestRegData(context);
            recoverInformation(regData);
            // Scroll view to top of page where content starts
            contentRef.current.scrollIntoView();
        }

        // eslint-disable-next-line
    }, [props.history]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <div>
                <HeadersContainer />

                <span ref={contentRef}></span>
                <RegistrationContentContainer
                    marginTop="2.5rem"
                    mobilePaddingTop="1rem"
                >
                    {hasPrevPageDestination(props) ? (
                        <>
                            <ReturnLinkReusable
                                destination={getPrevPageDestination(props)}
                                url={getPrevPageUrl(props)}
                            />
                        </>
                    ) : (
                        <>
                            <Box width="fit-content">
                                <ReturnLinkReusable
                                    url="/"
                                    destination="Hjemmesiden"
                                />
                            </Box>
                        </>
                    )}

                    {!isOnlySignup && (
                        <ProgressBar currentStage={'Oplysninger'} />
                    )}

                    <RegularHeadline
                        component="h1"
                        title="Opret brugerprofil"
                        marginbottom="1.5rem"
                    />
                    <DescriptionText variant="body2">
                        For at oprette en offentlig profil, skal du først
                        oprette en brugerprofil ved at udfylde nedenstående
                        formular.
                        <br />
                        Du kan altid rette dine oplysninger inde på Min Side
                    </DescriptionText>
                    <DescriptionTextLink
                        onClick={() =>
                            props.history.push({
                                pathname: '/login',
                                state: {
                                    modal: true,
                                    fromOnlyRegistration: true
                                }
                            })
                        }
                        variant="body2"
                    >
                        Har du allerede en profil? Log ind her.
                    </DescriptionTextLink>
                    {/* <RegularButtonContainer>
                        <RegularButton
                            onButtonClick={() => {
                                props.history.push({
                                    pathname: '/login',
                                    state: {
                                        modal: true,
                                        fromOnlyRegistration: true
                                    }
                                });
                            }}
                            title="Log ind her"
                            background={overridings.palette.colors.purple}
                        />
                    </RegularButtonContainer> */}

                    <React.Fragment>
                        <NameContainer ref={parentNameRef}>
                            <InformationTextInput
                                stateObj={firstnameObj}
                                onInputChange={saveInputValue}
                                removeErrorOnChange={removeErrorOnChange}
                            />
                            <InformationTextInput
                                stateObj={surnameObj}
                                onInputChange={saveInputValue}
                                removeErrorOnChange={removeErrorOnChange}
                            />
                        </NameContainer>

                        {/* Address */}
                        <NameContainer ref={addressRef}>
                            <InformationTextInput
                                stateObj={streetObj}
                                onInputChange={saveInputValue}
                                removeErrorOnChange={removeErrorOnChange}
                            />

                            <InformationTextInput
                                stateObj={housenumberObj}
                                onInputChange={saveInputValue}
                                removeErrorOnChange={removeErrorOnChange}
                            />
                        </NameContainer>

                        <NameContainer>
                            <InformationTextInput
                                stateObj={postcodeObj}
                                onInputChange={saveInputValue}
                                type="number"
                                removeErrorOnChange={removeErrorOnChange}
                            />

                            <InformationTextInput
                                stateObj={cityObj}
                                onInputChange={saveInputValue}
                                removeErrorOnChange={removeErrorOnChange}
                            />
                        </NameContainer>
                        <NameContainer ref={emailRef}>
                            <InformationTextInput
                                stateObj={emailObj}
                                onInputChange={saveInputValue}
                                removeErrorOnChange={removeErrorOnChange}
                                type="email"
                            />

                            <span ref={phoneRef}></span>

                            <InformationTextInput
                                stateObj={phoneObj}
                                onInputChange={saveInputValue}
                                removeErrorOnChange={removeErrorOnChange}
                                type="number"
                            />
                        </NameContainer>

                        <NameContainer ref={passwordRef}>
                            <InformationTextInput
                                stateObj={passwordObj}
                                onInputChange={saveInputValue}
                                type={passwordInputType}
                                handleVisibility={handleVisibility}
                                visible={passwordVisible}
                                removeErrorOnChange={removeErrorOnChange}
                            />

                            <InformationTextInput
                                stateObj={repeatPasswordObj}
                                onInputChange={saveInputValue}
                                type={passwordInputType}
                                handleVisibility={handleVisibility}
                                visible={passwordVisible}
                                customClass="last-text-input"
                                // handleEnterPress={() => onSubmit()}
                                removeErrorOnChange={removeErrorOnChange}
                            />
                        </NameContainer>
                    </React.Fragment>

                    {/* <PublicProfileContainer> */}
                    {/* <LabelText>Offentlig profil</LabelText> */}

                    {/* <Box
                            display="flex"
                            position="relative"
                            mb="1.5rem"
                            width="fit-content"
                        >
                            <CheckboxComponent
                                checked={acceptedPublicProfile}
                                onCheckboxChange={
                                    handleCheckboxChangePublicProfile
                                }
                                position="static"
                            />

                            <CheckboxText
                                variant="body2"
                                onClick={handleCheckboxChangePublicProfile}
                            >
                                Udvidet profil
                            </CheckboxText>
                        </Box> */}
                    {/* </PublicProfileContainer> */}
                    {/* {acceptedPublicProfile && (
                        <>
                            <DescriptionText variant="body2">
                                Herunder kan du indtaste de oplysninger, du vil
                                dele med brugerne af hjemmesiden under{' '}
                                <Link to="/opslagstavle" target="_blank">
                                    <UnderlinedText>
                                        Opslagstavle.
                                    </UnderlinedText>
                                </Link>
                            </DescriptionText>
                            <OrderList>
                                <Li>Du kan uploade op til 5 billeder.</Li>
                                <Li>
                                    Skrive en god tekst om dig selv og dine
                                    aktiviteter (gør dig gerne umage med den
                                    del).
                                </Li>
                                <Li>
                                    Vælge en eller flere kategorier for din
                                    aktiviteter.
                                </Li>
                            </OrderList>
                            <>
                                <NameContainer>
                                    <InformationTextInput
                                        stateObj={activityNameObj}
                                        onInputChange={saveInputValue}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                    />

                                    <InformationTextInput
                                        stateObj={webpageLinkObj}
                                        onInputChange={saveInputValue}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                    />
                                </NameContainer>
                                <NameContainer>
                                    <InformationTextInput
                                        stateObj={facebookObj}
                                        onInputChange={saveInputValue}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                    />

                                    <InformationTextInput
                                        stateObj={instagramObj}
                                        onInputChange={saveInputValue}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                    />
                                </NameContainer>

                                <NameContainer>
                                    <InformationTextInput
                                        stateObj={youtubeObj}
                                        onInputChange={saveInputValue}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                    />

                                    <InformationTextInput
                                        stateObj={linkedinObj}
                                        onInputChange={saveInputValue}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                    />
                                </NameContainer>

                                <NameContainer>
                                    <Box>
                                        <RichTextTitle>
                                            Beskrivelse
                                        </RichTextTitle>
                                        <ActivityRichTextEditor
                                            setActivityDescriptionObj={
                                                setActivityDescriptionObj
                                            }
                                            activityDescriptionObj={
                                                activityDescriptionObj
                                            }
                                            type="activity"
                                        />
                                    </Box>

                                    <InformationTextInput
                                        stateObj={activityPhoneObj}
                                        onInputChange={saveInputValue}
                                        removeErrorOnChange={
                                            removeErrorOnChange
                                        }
                                    />
                                </NameContainer>

                                <Box mt="1.5rem">
                                    <LabelText>Aktivitetskategorier</LabelText>

                                    <CategoriesContainer>
                                        <CategoryBox
                                            position="relative"
                                            width="fit-content"
                                        >
                                            <CategoryContainer>
                                                <CheckboxComponent
                                                    checked={acceptedCategory1}
                                                    onCheckboxChange={() =>
                                                        handleCheckboxChangeCategories(
                                                            'category1'
                                                        )
                                                    }
                                                    position="static"
                                                    // error={checkboxErrorPublicProfile}
                                                    category="category1"
                                                />{' '}
                                                <CheckboxText
                                                    variant="body2"
                                                    onClick={() =>
                                                        handleCheckboxChangeCategories(
                                                            'category1'
                                                        )
                                                    }
                                                    // error={checkboxErrorPublicProfile}
                                                >
                                                    Musik
                                                </CheckboxText>
                                            </CategoryContainer>
                                        </CategoryBox>
                                        <CategoryBox
                                            position="relative"
                                            width="fit-content"
                                        >
                                            <CategoryContainer>
                                                <CheckboxComponent
                                                    checked={acceptedCategory2}
                                                    onCheckboxChange={() =>
                                                        handleCheckboxChangeCategories(
                                                            'category2'
                                                        )
                                                    }
                                                    position="static"
                                                />{' '}
                                                <CheckboxText
                                                    variant="body2"
                                                    onClick={() =>
                                                        handleCheckboxChangeCategories(
                                                            'category2'
                                                        )
                                                    }
                                                >
                                                    Andet
                                                </CheckboxText>
                                            </CategoryContainer>
                                        </CategoryBox>
                                    </CategoriesContainer>
                                </Box>
                            </>
                        </>
                    )} */}

                    <span ref={termsRef}></span>
                    <Box mt="2.5rem">
                        <DescriptionText variant="body2">
                            Når du har oprettet en brugerprofil, kan du:
                            {/* <UnderlinedText onClick={() => setPersonalDataDialog(true)}>
                persondatapolitikken
              </UnderlinedText>{" "}
              og{" "}
              <UnderlinedText onClick={() => setCookieRulesDialog(true)}>
                cookiepolitikken,
              </UnderlinedText>{" "} */}
                            {/* <UnderlinedText onClick={() => setTermsAndConditionsDialog(true)}>
                Læs handelsbetingelserne her.
              </UnderlinedText>{" "} */}
                            {/* <UnderlinedText onClick={() => setHouseRulesDialog(true)}>
                Læs husordenen her.
              </UnderlinedText> */}
                        </DescriptionText>
                        <OrderList>
                            <li>oprette et medlemskab</li>
                            <li>reservere lokaler</li>
                            <li>redigere din profil</li>
                            <li>oprette opslag til opslagstavlen</li>
                        </OrderList>
                    </Box>

                    {!isAuthenticated && (
                        <>
                            {/* <Box display="flex" position="relative">
                <CheckboxComponent
                  checked={sendNewsLetters}
                  onCheckboxChange={() => handleCheckBoxChangeNewsLetter()}
                  position="static"
                />

                <CheckboxText
                  variant="body2"
                  onClick={() => handleCheckBoxChangeNewsLetter()}
                >
                  Yes, I would like to receive newsletters.
                </CheckboxText>
              </Box> */}

                            {/* <Box display="flex" position="relative">
                <CheckboxComponent
                  onCheckboxChange={() => handleCheckboxChangeShoes()}
                  position="static"
                  error={checkboxErrorShoes}
                />

                <CheckboxText
                  variant="body2"
                  onClick={() => handleCheckboxChangeShoes()}
                  error={checkboxErrorShoes}
                >
                  Alle der færdes i huset skal tage deres sko af i garderoben
                  eller bruge overtræk. Den der har booket, har det fulde ansvar
                  for også deres gæster/kunder overholder dette.
                </CheckboxText>
              </Box> */}

                            <Box display="flex" position="relative">
                                <CheckboxComponent
                                    onCheckboxChange={handleCheckBoxChangeRules}
                                    position="static"
                                    error={checkboxErrorRules}
                                    linksInText="true"
                                />

                                <CheckboxText
                                    variant="body2"
                                    error={checkboxErrorRules}
                                >
                                    Jeg erklærer at have læst og forstået{' '}
                                    <UnderlinedText
                                        onClick={() =>
                                            setPersonalDataDialog(true)
                                        }
                                    >
                                        persondatapolitikken
                                    </UnderlinedText>{' '}
                                    og{' '}
                                    <UnderlinedText
                                        onClick={() =>
                                            setCookieRulesDialog(true)
                                        }
                                    >
                                        cookiepolitikken
                                    </UnderlinedText>{' '}
                                    for oprettelse af profil på Bülowsvej 10.
                                </CheckboxText>
                            </Box>
                        </>
                    )}

                    {isLoading ? (
                        <Loader />
                    ) : (
                        <RegistrationButton
                            title={
                                isAuthenticated
                                    ? 'Videre'
                                    : 'Opret brugerprofil'
                            }
                            onButtonClick={() => onSubmit()}
                            minWidth="12rem"
                            width="12rem"
                            mobileMargin="1.5rem 0"
                        />
                    )}

                    <ButtonContainer
                        width="fit-content"
                        onClick={() => onBackToCalendarClick()}
                    >
                        <ReturnLinkReusable
                            destination={getPrevPageDestination()}
                        />
                    </ButtonContainer>
                </RegistrationContentContainer>
            </div>

            {/* Come back: error text to danish */}
            {error && (
                <ErrorDialog
                    dialogTitle=""
                    errorMessage={errorMessage}
                    close={() => setError(false)}
                />
            )}
            {termsAndConditionsDialog && (
                <TermsDialog close={() => setTermsAndConditionsDialog(false)} />
            )}
            {houseRulesDialog && (
                <HouseRulesDialog close={() => setHouseRulesDialog(false)} />
            )}
            {personalDataDialog && (
                <PersonalDataDialog
                    close={() => setPersonalDataDialog(false)}
                />
            )}

            {cookieRulesDialog && (
                <CookieDialog close={() => setCookieRulesDialog(false)} />
            )}
        </motion.div>
        // </div>
    );
}

export default withRouter(RegisterInformation);
