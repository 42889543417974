import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from "./serviceWorker";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from './context/Context';
import { BrowserRouter } from 'react-router-dom';

import ThemeProvider from './themes/ThemeProvider';
import DarkTheme from './themes/DarkTheme';

import PWAPrompt from 'react-ios-pwa-prompt';
const { DateTime } = require("luxon");

// Only if online clear window.caches to load latest app version
if (navigator.onLine) {
    caches.keys().then(function (keys) {
        keys.forEach(function (key) {
            caches.delete(key);
        });
    });
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider>
                <ThemeProvider theme={DarkTheme}>
                    <App />
                    <PWAPrompt
                        promptOnVisit={1}
                        timesToShow={3}
                        copyClosePrompt="Close"
                        permanentlyHideOnDismiss={false}
                    />
                </ThemeProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorkerRegistration.register();

// We can detect that a PWA is not installable by checking if the features required for that function are available. If not, we can be certain there’s no PWA functionality on that browser and hide our button altogether.
if (!('serviceWorker' in navigator)) {
    console.log('Pwa is installable.');
}

// If the app is open in its own window, we know the app is already installed 
window.addEventListener('DOMContentLoaded', function () {
    console.log("Check page loaded matches a PWA display mode");
    if (
        navigator.standalone ||
        window.matchMedia('(display-mode: standalone)').matches ||
        window.matchMedia('(display-mode: fullscreen)').matches ||
        window.matchMedia('(display-mode: minimal-ui)').matches
    ) {
        console.log("PWA already installed, hide install button.");
        const butInstall = document.getElementById('butInstall');

        if (butInstall) {
            butInstall.style.display = 'none';
        }
    }
});

// Add listener after app installation on desktop, that will make app open in its own window right away.
window.addEventListener('DOMContentLoaded', function () {
    window.matchMedia('(display-mode: standalone)').addListener(function (e) {
        if (e.matches) {
            console.log('PWA installed in display-mode: standalone');

            const butInstall = document.getElementById('butInstall');
            if (butInstall) {
                butInstall.style.display = 'none';
            }
        }
    });
    window.matchMedia('(display-mode: fullscreen)').addListener(function (e) {
        if (e.matches) {
            console.log('PWA installed in display-mode: fullscreen');
            const butInstall = document.getElementById('butInstall');
            if (butInstall) {
                butInstall.style.display = 'none';
            }
        }
    });
    window.matchMedia('(display-mode: minimal-ui)').addListener(function (e) {
        if (e.matches) {
            console.log('PWA installed in display-mode: minimal ui');
            const butInstall = document.getElementById('butInstall');
            if (butInstall) {
                butInstall.style.display = 'none';
            }
        }
    });
});

const butInstall = document.getElementById('butInstall');

let deferredPrompt;
const addBtn = document.querySelector('.add-buttonFirefox');

window.addEventListener('beforeinstallprompt', (e) => {
    console.log('👍','Add event listener beforeinstallprompt');

    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI to notify the user they can add to home screen
    if (addBtn) {
        addBtn.style.display = 'none';

        addBtn.addEventListener('click', () => {
            // hide our user interface that shows our A2HS button
            addBtn.style.display = 'none';
            // Show the prompt
            deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                deferredPrompt = null;
            });
        });
    }
});

// Save to homescreen button for Iphone
window.addEventListener('appinstalled', (event) => {
    console.log('👍', 'appinstalled');
    // console.log(event);
    
    // 'appinstalled=true;expires=Tue, 30 Mar 2023 23:59:59 GMT;path=/';
    // Make expire timestamp in UTC format, 3 months ahead in time
    const utcStr = DateTime.now().plus({months: 3}).toJSDate().toUTCString();
    document.cookie = 'appinstalled=true;expires='+utcStr+';path=/';

    window.deferredPrompt = null;
    const butInstall = document.getElementById('butInstall');
    if (butInstall) {
        butInstall.style.display = 'none';
    }
});

const cancelIos = document.getElementById('cancelIos');
if (cancelIos) {
    cancelIos.addEventListener('click', function () {
        document.getElementById('iosPWA').style.display = 'none';
    });
    if (butInstall) {
        butInstall.addEventListener('click', function () {
            if (
                [
                    'iPad Simulator',
                    'iPhone Simulator',
                    'iPod Simulator',
                    'iPad',
                    'iPhone',
                    'iPod'
                ].includes(navigator.platform)
            ) {
                document.getElementById('iosPWA').style.display = 'block';

                return true;
            }
            // iPad on iOS 13
            else if (
                navigator.userAgent.includes('Mac') &&
                'ontouchend' in document
            ) {
                document.getElementById('iosPWA').style.display = 'block';
                return true;
            } else {
                if (deferredPrompt !== undefined) {
                    // The user has had a postive interaction with our app and Chrome
                    // has tried to prompt previously, so let's show the prompt.
                    deferredPrompt.prompt();

                    // Follow what the user has done with the prompt.
                    deferredPrompt.userChoice.then(function (choiceResult) {
                        console.log(choiceResult.outcome);

                        if (choiceResult.outcome === 'dismissed') {
                            console.log(
                                'User cancelled home screen install dismissed'
                            );
                        } else {
                            console.log('User added to home screen');
                        }

                        // Clear prompt
                        deferredPrompt = null;
                    });
                }
            }
        });
    }
}

// Delete cache and reload
window.addEventListener('activate', (event) => {
    event.waitUntil(
        caches
            .keys()
            .then((cacheNames) => {
                return Promise.all(
                    cacheNames.map((cacheName) => {
                        return caches.delete(cacheName);
                    })
                );
            })
            .then(() => {
                console.log('App will reload');
                window.location.reload();
            })
    );
});
