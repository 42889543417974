import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getLocationNameFromRoomId } from '../../../context/getData';
import moment from 'moment';
import Loader from '../../loader/Loader';

const DialogComponent = styled(Dialog)`
    & .MuiPaper-root {
        padding: 1rem !important;
    }

    position: relative;
`;

const DialogActionsCustom = styled(DialogActions)`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }

    & span {
        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            font-size: 0.875rem;
            margin-left: 0;
        }
    }

    & button {
        @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
            margin-left: 0 !important;
        }
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

const UnderlinedText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;

function DeleteDialog({
    close,
    removeBookedEvent,
    eventId,
    multipleEvents,
    repeatSeqId,
    isDraftEvent,
    calculateCancellationPrice,
    ownEvents,
    isLoading
}) {
    const [event, setEvent] = useState({});
    const [deleteAllEventsDialog, setDeleteAllEventsDialog] = useState(false);
    const handleYesClick = (eventId, repeatSeqId) => {
        // If user wants to delete all related events, we need to send multipleEvents
        if (repeatSeqId) {
            removeBookedEvent(eventId, repeatSeqId);
        } else {
            removeBookedEvent(eventId);
        }
    };

    useEffect(() => {
        // Get event details
        if (ownEvents && ownEvents.length > 0) {
            const chosenEvent = ownEvents.find((event) => event.id === eventId);

            if (chosenEvent) {
                setEvent(chosenEvent);
            }
        }
        // eslint-disable-next-line
    }, [ownEvents]);

    return (
        <>
            <DialogComponent
                onClose={close}
                open={true}
                aria-labelledby="confirm-event-deletion"
                aria-describedby="confirm-event-deletion-description"
                disableRestoreFocus
            >
                {/* <DialogTitle id="confirm-event-deletion">Vigtigt</DialogTitle> */}
                <DialogContent>
                    {/* Here we also need to list the rules for cancellation. Text request is sent to Liuwina */}
                    {/* If there is a repeatSeqId, it means that there are more events connected
        We need to ask, if we should delete them all, or just the single event. */}
                    {isDraftEvent && multipleEvents ? (
                        <>
                            <DialogContentText id="confirm-event-deletion-description">
                                Ønsker du at slette nuværende reservation eller
                                inklusive alle gentagelser?
                                {!isDraftEvent && (
                                    <>
                                        <span>
                                            {' '}
                                            Vær opmærksom på, at du ikke får
                                            refunderet allerede betalte
                                            reservationer{' '}
                                            <UnderlinedText>
                                                <Link
                                                    to="/handelsbetingelser-side"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="MuiTypography-colorTextSecondary"
                                                >
                                                    (jf. handelsbetingelserne).
                                                </Link>
                                            </UnderlinedText>
                                        </span>
                                    </>
                                )}
                            </DialogContentText>
                        </>
                    ) : (
                        <>
                            <DialogContentText id="onfirm-event-deletion-description">
                                Du er nu ved at annullere følgende reservation:
                                <br />
                                Lokale {getLocationNameFromRoomId(
                                    event.roomId
                                )}{' '}
                                {moment(event.startdate).format('dddd')} den{' '}
                                {moment(event.startdate).format('DD.MM.YYYY')}{' '}
                                kl.{' '}
                                {moment(
                                    event.startdate + ' ' + event.starttime
                                ).format('HH:mm')}{' '}
                                til{' '}
                                {moment(
                                    event.startdate + ' ' + event.endtime
                                ).format('HH:mm')}
                                .
                            </DialogContentText>
                            {calculateCancellationPrice &&
                            calculateCancellationPrice(eventId) &&
                            !isDraftEvent ? (
                                <DialogContentText>
                                    Det koster{' '}
                                    {
                                        calculateCancellationPrice(eventId)
                                            .percentage
                                    }
                                    % af lejeprisen, svarende til{' '}
                                    {calculateCancellationPrice(eventId).price}{' '}
                                    kr.
                                    <br />
                                    Beløbet forfalder til betaling med det
                                    samme.
                                    <br />
                                    Se handelsbetingelserne{' '}
                                    <UnderlinedText>
                                        <Link
                                            to="/handelsbetingelser-side"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="MuiTypography-colorTextSecondary"
                                        >
                                            her
                                        </Link>
                                    </UnderlinedText>
                                    .
                                </DialogContentText>
                            ) : (
                                <></>
                            )}
                            <DialogContentText>
                                Er du sikker på, at du vil annullere din
                                reservation?
                            </DialogContentText>
                        </>
                    )}
                </DialogContent>

                {isLoading ? (
                    <Box width="fit-content" ml="auto" mr="auto">
                        <Loader />
                    </Box>
                ) : (
                    <DialogActionsCustom>
                        <Button onClick={() => close()}>
                            {multipleEvents ? 'Annuller' : 'Nej'}
                        </Button>
                        {multipleEvents ? (
                            <>
                                <Button
                                    onClick={() =>
                                        // handleYesClick(eventId, repeatSeqId)
                                        setDeleteAllEventsDialog(true)
                                    }
                                >
                                    Slet alle gentagelser
                                </Button>
                                <Button onClick={() => handleYesClick(eventId)}>
                                    Slet kun denne
                                </Button>{' '}
                            </>
                        ) : (
                            <Button onClick={() => handleYesClick(eventId)}>
                                Ja
                            </Button>
                        )}
                    </DialogActionsCustom>
                )}
            </DialogComponent>
            {deleteAllEventsDialog && (
                <DialogComponent
                    onClose={close}
                    open={true}
                    aria-labelledby="confirm-multiple-event-deletion"
                    aria-describedby="confirm-multiple-event-deletion-description"
                    disableRestoreFocus
                >
                    <DialogContent>
                        <DialogContentText id="confirm-multiple-event-deletion-description">
                            Er du sikker på, at du vil slette alle gentagelse?
                        </DialogContentText>
                        <DialogActionsCustom>
                            <Button onClick={() => close()}>Annuller</Button>
                            <Button
                                onClick={() =>
                                    handleYesClick(eventId, repeatSeqId)
                                }
                            >
                                Slet alle gentagelser
                            </Button>
                        </DialogActionsCustom>
                    </DialogContent>
                </DialogComponent>
            )}
        </>
    );
}

export default DeleteDialog;
