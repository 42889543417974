import React from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import styled from "styled-components";

const VisibilityIconComp = styled(VisibilityIcon)`
  cursor: pointer;
`;

const VisibilitiOffIconComp = styled(VisibilityOffIcon)`
  cursor: pointer;
`;

function VisibilityIconComponent({ visible, handleVisibility }) {
  return (
    <div>
      {!visible ? (
        <VisibilityIconComp fontSize="small" onClick={handleVisibility} />
      ) : (
        <VisibilitiOffIconComp
          fontSize="small"
          onClick={handleVisibility}
        />
      )}
    </div>
  );
}

export default VisibilityIconComponent;
