/**
 * This app structure was copied from Sweetspot Add Designer and given to Den Lille Musikskole (DLM)
 * with full use and modification rights according to LGPL3 (https://www.gnu.org/licenses/lgpl-3.0.en.html)
 */
// test line - delete this
import React, { Suspense } from 'react';
import {
    Redirect,
    //  BrowserRouter as Router,
    Route,
    Switch,
    withRouter
} from 'react-router-dom';
import {
    //  Cookies,
    CookiesProvider,
    withCookies
} from 'react-cookie';
import { IntlProvider } from 'react-intl';
import { Context } from './context/Context';

import ErrorBoundary from './components/error-boundary';
import AuthenticatedRoute from './components/authenticated-route/';
import Home from './Home';

// In test set domain to test.denlillemusikskole.dk for navigation to work.
import manifestTemplate from './manifestTemplate.json';
import PrivacyDialog from './components/dialogs/privacy-dialog/PrivacyDialog';

import Footer from './components/footer/Footer';
import RegisterInformation from './containers/register/register-information/RegisterInformation';

import Checkout from './containers/register/checkout/Checkout';
// import PaymentRates from "./containers/register/payment-rates/PaymentRates";

import { withContext } from './components/containers/ContextContainer';
import { getComingFromRoomNumber, getUserData } from './context/getData';
import LoginPage from './containers/login-pages/LoginPage';
import ForgotPasswordPage from './containers/login-pages/ForgotPasswordPage';
import ResetPasswordPage from './containers/login-pages/ResetPasswordPage';

import Rooms from './containers/rooms/Rooms';
// QuickPay debug component
// import { default as PaymentCheckout } from "./components/checkout";
// import PaymentCancel from "./containers/QuickPayCancel";
// import PaymentContinue from "./containers/QuickPayContinue";

import SignupEmailVerification from './containers/verification/SignupEmailVerification';
import SignupPhoneVerification from './containers/verification/SignupPhoneVerification';
// import QuickPayCancel from "./containers/register/quick-pay/QuickPayCancel";

import SessionExpired from './containers/session-expired/SessionExpired';
import styled from 'styled-components';
import PersonalDataPage from './containers/personal-data-page/PersonalDataPage';
import CookiePage from './containers/cookie-page/CookiePage';
import TermsPage from './containers/terms-page/TermsPage';
import RoomPage from './containers/rooms/RoomPage';
import AcceptTermsPage from './containers/register/accept-terms-page/AcceptTermsPage';
import {
    //  fetchWithTimeout,
    getAndReturnResponse,
    removeComingFromRoomNumberFromStorage
} from './context/helperFunctions';

// Language translations
import locales from './locales';
import { Typography } from '@material-ui/core';
import BookingCalendarPage from './containers/calendar/BookingCalendarPage';
import ActivityCalendarPage from './containers/calendar/ActivityCalendarPage';
import FaqPage from './containers/faq/FaqPage';
import TransactionsPage from './containers/transactions/TransactionsPage';

import packageJson from '../package.json';
import ActivityPage from './containers/activities/ActivityPage';
import ReactivateActivity from './containers/reactivation/ReactivateActivity';
import MyEventsAfterMembershipExpiry from './containers/my-events/MyEventsAfterMembershipExpiry';

// Admin Dashboard routes
// Lazy load admin pages, because normal users dont need them at all.
// See https://reactjs.org/docs/code-splitting.html
const Dashboard = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/Dashboard')
);
const SequelizeManager = React.lazy(() =>
    import(
        /* webpackChunkName: "admin" */ './containers/admin/SequelizeManager'
    )
);

const OrderDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/OrderDataGrid')
);
const UserDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/UserDataGrid')
);
const MessageDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/MessageDataGrid')
);
const PaymentTransactionDataGrid = React.lazy(() =>
    import(
        /* webpackChunkName: "admin" */ './containers/admin/PaymentTransactionDataGrid'
    )
);
const LogDataGrid = React.lazy(() =>
    import(/* webpackChunkName: "admin" */ './containers/admin/LogDataGrid')
);

// Lazy load private pages
// import MyPage from "./containers/my-page/MyPage.jsx";
const MyPage = React.lazy(() =>
    import(/* webpackChunkName: "private" */ './containers/my-page/MyPage.jsx')
);
// import UploadImage from "./containers/register/register-information/UploadImage";
const UploadImage = React.lazy(() =>
    import(
        /* webpackChunkName: "private" */ './containers/register/register-information/UploadImage'
    )
);
// import Receipt from "./containers/register/receipt/Receipt";
const Receipt = React.lazy(() =>
    import(
        /* webpackChunkName: "private" */ './containers/register/receipt/Receipt'
    )
);
// import MyEvents from "./containers/my-events/MyEvents";
const MyEvents = React.lazy(() =>
    import(/* webpackChunkName: "private" */ './containers/my-events/MyEvents')
);
// import EditProfile from "./containers/edit-profile/EditUserProfile";
// const EditProfile = React.lazy(() =>
//     import(
//         /* webpackChunkName: "private" */ './containers/edit-profile/EditUserProfile'
//     )
// );
// import EditPublicProfile from "./containers/edit-profile/EditPublicProfile";
// const EditPublicProfile = React.lazy(() =>
//     import(
//         /* webpackChunkName: "private" */ './containers/edit-profile/EditPublicProfile'
//     )
// );

// Lazy load public pages
// import Activities from "./containers/";
const Activities = React.lazy(() =>
    import(/* webpackChunkName: "" */ './containers/activities/Activities')
);

const Message = React.lazy(() =>
    import(/* webpackChunkName: "private" */ './containers/Message')
);

// QuickPay test form
const QuickPay = React.lazy(() =>
    import(/* webpackChunkName: "private" */ './components/quickpay')
);
const QuickPayCancel = React.lazy(() =>
    import(/* webpackChunkName: "private" */ './containers/QuickPayCancel')
);
const QuickPayContinue = React.lazy(() =>
    import(/* webpackChunkName: "private" */ './containers/QuickPayContinue')
);

const locale = 'da-DK';
const messages = locales[locale];

const PageContainer = styled.div`
    position: relative;
    min-height: 100vh;
`;
const ContentWrap = styled.div`
    /* padding-bottom: 5.5rem; */
`;

const InternetSign = styled.div`
    width: 100%;
    background: ${(props) => props.theme.palette.colors.orange};
    color: ${(props) => props.theme.palette.primary.main};
    h6 {
        text-align: center;
    }
`;
var port = process.env.REACT_APP_PORT;
if (port) console.log('REACT_APP_PORT', port);

const createManifest = (domain) => {
    try {
        // make a cloned copy of javascript manifest object is done fastest with parse and stringify, then update properties on cloned object
        const manifest = JSON.parse(JSON.stringify(manifestTemplate));
        manifest.start_url = 'https://' + domain + '/';

        // make blob with manifest json and write dynamic URL to link with manifest-placeholder
        const manifestData = JSON.stringify(manifest, null, 2);
        const blob = new Blob([manifestData], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        const manifestLink = document.querySelector('#manifest-placeholder');
        if (manifestLink) manifestLink.setAttribute('href', manifestURL);
        console.log('Created manifest.json for domain ' + domain);
        console.log('Link: ', manifestURL);
        console.log('Manifest.json', manifestData);
    } catch (err) {
        console.log(err);
    }
};

createManifest(document.location.hostname);

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOffline: false,
            systemUpdateText: null
        };
        // this.scrollTop = 0;
        // this.scrollLeft = 0;

        this.previousLocation = this.props.location;
        this.fetchData = this.fetchData.bind(this);
    }

    static contextType = Context;
    fetchData = async (getSystemUpdateText) => {
        let text = await getSystemUpdateText();
        // setSystemUpdateText(text);
        this.setState({ systemUpdateText: text });
    };
    // READ ONLY DURING UPDATE SYSTEM
    getSystemUpdateText = async () => {
        let res = await getAndReturnResponse('/api/v1/system-update');
        let body = await res.text();
        if (body) console.log('System update text:', body);
        let key = document.location.hostname + ':system-update';
        if (res.status === 200 && body) {
            if (typeof Storage !== 'undefined')
                localStorage.setItem(key, 'true');
            return body;
        }
        if (typeof Storage !== 'undefined') localStorage.setItem(key, 'false');

        return null;
        // eslint-disable-next-line
    };
    fetchSystemUpdate = this.getSystemUpdateText.bind(this);

    componentDidUpdate(prevProps, prevState) {
        // Needed for displaying dialogs
        let { location } = this.props;

        if (!(location.state && location.state.modal)) {
            this.previousLocation = location;
        }

        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }

        if (!this.props.location.pathname.includes('kalender')) {
            // Check if the comingFromRoomPage has a value, if it has, then remove it
            const comingFromRoomNumber = getComingFromRoomNumber();
            if (comingFromRoomNumber) {
                // Need to remove comingFromRoomNumber from storage, if we are not on a calendar page
                this.context.saveComingFromRoomPage(null);
            }
        }

        if (this.state.systemUpdateText !== prevState.systemUpdateText) {
            this.fetchData(this.getSystemUpdateText);
        }
        // window.location.reload(false);
        // if (this.scrollTop > 0 || this.scrollLeft > 0) {
        //   console.log("Scroll to", this.scrollLeft, this.scrollTop);
        //   window.scrollTo(this.scrollLeft, this.scrollTop);
        // }
    }

    // https://chromium.googlesource.com/chromium/src.git/+/65d0e0f4d84abab407f942e81b3937821075ec0d/chrome/browser/banners/app_banner_manager.cc
    // https://whatwebcando.today/installation.html
    // https://pwa.rocks/

    // This is only called in PRODUCTION build, not in development
    handleBeforeInstallPrompt = (event) => {
        // Stash the event for later use
        window.lastbeforeinstallpromptevent = event;
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        if (event.preventDefault) event.preventDefault();
        return false;
    };

    // WebKit on iOS is used for both Safari and Chrome on iPhone.
    // WebKit will unload app, when any action causes the app to lose
    // focus, even if the screen saver kicks in. We need to store state
    // to be able to restore state, when app is reloaded again.

    handleBeforeUnload = (event) => {};

    // handleScroll = (event) => {
    //   // let self = this;
    //   // if (this.scrollTimer) clearTimeout(this.scrollTimer);
    //   // // Store last scroll event only to avoid many updates while user drags scrollbar
    //   // this.scrollTimer = setTimeout(function () {
    //   //   var html = document.documentElement;
    //   //   self.scrollTop = html.scrollTop;
    //   //   self.scrollLeft = html.scrollLeft;
    //   // }, 2000);
    // };

    // Delay event execution 1 seconds, because event can fire many times
    // while user is dragging window to be resized. Only last event will
    // be executed when timeout expires, before being cleared
    // If manifest.display === 'standalone' resize is called every time
    // the browser bottom navigation bar automatically hides itself in Safari
    // If manifest.display === 'fullscreen' this is not a problem
    // just dont call self.forceUpdate() or Safari on iOS will refresh on scroll

    handleResize = (event) => {
        // let self = this;
        if (this.resizeTimer) clearTimeout(this.resizeTimer);
        // Store last resize event only to avoid many updates while user drags window
        this.resizeTimer = setTimeout(function () {
            // var html = document.documentElement;
            // self.scrollTop = html.scrollTop;
            // self.scrollLeft = html.scrollLeft;
        }, 1000);
    };

    updateOnlineIndicator = (event) => {
        if (this.state.isOffline) {
            this.setState({ isOffline: false });
            const burgerMenu = document.querySelector('.bm-burger-button');
            burgerMenu.style.top = '19px';
            // Save in context if user is offline
            this.context.saveIsOffline(false);
        }
    };

    updateOfflineIndicator = (event) => {
        console.log('updating offline');
        this.setState({ isOffline: true });
        // Change the styling of the burger
        const burgerMenu = document.querySelector('.bm-burger-button');
        if (burgerMenu) {
            burgerMenu.style.top = 'unset';
        }

        // Save in context if user is offline
        this.context.saveIsOffline(true);
    };

    displayFooter = () => {
        // If we are on the calendar page, check if it is a mobile version. If it is, we should not display the footer.
        if (this.props.location.pathname.includes('/kalender')) {
            if (document.body.offsetWidth >= 960) {
                return <Footer />;
            }
        } else {
            return <Footer />;
        }
    };

    caching = () => {
        // Delete caching
        let version = localStorage.getItem('version');
        if (version !== packageJson.version) {
            if ('caches' in window) {
                caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach((name) => {
                        caches.delete(name);
                    });
                });

                // Makes sure the page reloads. Changes are only visible after you refresh.
                window.location.reload(true);
            }

            localStorage.clear();
            localStorage.setItem('version', packageJson.version);
        }
    };

    componentWillUnmount() {
        window.removeEventListener(
            'beforeinstallprompt',
            this.handleBeforeInstallPrompt
        );
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
        window.removeEventListener('online', this.updateOnlineIndicator);
        window.removeEventListener('offline', this.updateOfflineIndicator);
        window.removeEventListener('resize', this.handleResize);
        // window.removeEventListener("scroll", this.handleScroll);
        window.lastbeforeinstallpromptevent = null;
    }

    componentDidMount() {
        window.addEventListener(
            'beforeinstallprompt',
            this.handleBeforeInstallPrompt
        );
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        window.addEventListener('online', this.updateOnlineIndicator);
        window.addEventListener('offline', this.updateOfflineIndicator);
        window.addEventListener('resize', this.handleResize);
        this.setState({ isOffline: !navigator.onLine }, () => {
            if (!navigator.onLine) {
                this.updateOfflineIndicator();
                this.context.saveIsOffline(true);
            } else {
                this.updateOnlineIndicator();
                this.context.saveIsOffline(false);
            }
            // this.updateOfflineIndicator();
            // this.updateOnlineIndicator();
        });

        //Check if user is authenticated
        getUserData(this.context);

        this.fetchData(this.getSystemUpdateText);

        this.caching();
    }

    // History location is saved in Cookies if onUnload event is fired - if page is reloaded.
    // When application is mounted, check if there is any location history saved in Cookies, and load that path.
    // Otherwise just load the page with the current url.
    // This part is to be checked and more work is needed, when we have more routes.
    // checkForLocationHistory = () => {
    //   console.log('location history check');
    //   if ( this.props.cookies.cookies.locationHistory) {
    //     console.log('location history is', this.props.cookies.cookies.locationHistory);
    //     this.props.history.push(this.props.cookies.cookies.locationHistory);
    //     // window.location.reload();
    //     document.cookie = "locationHistory="
    //   }
    // }

    render() {
        // const path = "/";
        // Settings for modal display on own route
        const { location } = this.props;
        const isModal =
            location.state &&
            location.state.modal &&
            this.previousLocation !== location;
        return (
            <IntlProvider
                locale={locale}
                messages={messages}
                defaultLocale="da-DK"
            >
                <ErrorBoundary>
                    <CookiesProvider>
                        <Suspense fallback={<div>Loading...</div>}>
                            <PageContainer className="page-container">
                                <ContentWrap className="content-wrap">
                                    <div
                                        className="System-update"
                                        style={{
                                            display:
                                                this.state.systemUpdateText !=
                                                null
                                                    ? 'block'
                                                    : 'none'
                                        }}
                                    >
                                        {this.state.systemUpdateText != null
                                            ? this.state.systemUpdateText
                                            : ''}
                                    </div>

                                    {this.state.isOffline && (
                                        <InternetSign id="internet_warning">
                                            <Typography variant="subtitle1">
                                                Du er offline. Tjek din
                                                internetforbindelse.
                                            </Typography>
                                        </InternetSign>
                                    )}

                                    <Switch
                                        location={
                                            isModal
                                                ? this.previousLocation
                                                : location
                                        }
                                    >
                                        <Route exact path="/home">
                                            <Home />
                                        </Route>

                                        <Route path="/bekraeft-email/:uniqueIdEmail">
                                            <SignupEmailVerification />
                                        </Route>

                                        <Route path="/bekraeft-telefon/:uniqueIdPhone">
                                            <SignupPhoneVerification />
                                        </Route>

                                        <Route path="/genaktiver-opslagstavlen/:uniqueIdActivity">
                                            <ReactivateActivity />
                                        </Route>

                                        <Route path="/persondatapolitik">
                                            <PersonalDataPage />
                                        </Route>

                                        <Route path="/cookiepolitik">
                                            <CookiePage />
                                        </Route>

                                        <Route path="/handelsbetingelser-side">
                                            <TermsPage />
                                        </Route>

                                        {/* <Route path="/unilock">
                    <UniLock />
                  </Route> */}

                                        <Route exact path="/login">
                                            <LoginPage
                                                isModal={isModal}
                                                prevLocation={
                                                    this.previousLocation
                                                }
                                            />
                                        </Route>

                                        <Route path="/glemt-dit-kodeord">
                                            <ForgotPasswordPage
                                                isModal={isModal}
                                                prevLocation={
                                                    this.previousLocation
                                                }
                                            />
                                        </Route>

                                        <Route
                                            exact
                                            path="/nulstil-kodeord/:id"
                                        >
                                            <ResetPasswordPage
                                                isModal={isModal}
                                                prevLocation={
                                                    this.previousLocation
                                                }
                                            />
                                        </Route>

                                        <Route exact path="/oplysninger">
                                            <RegisterInformation />
                                        </Route>

                                        <Route path="/booking-kalender/:id">
                                            <BookingCalendarPage />
                                        </Route>

                                        <Route path="/aktivitets-kalender">
                                            <ActivityCalendarPage />
                                        </Route>

                                        <Route path="/opsummering">
                                            <Checkout />
                                        </Route>

                                        <Route path="/handelsbetingelser">
                                            <AcceptTermsPage />
                                        </Route>

                                        {/* --- Start QuickPay debug pages --- */}
                                        {/* <Route path="/payment/cancel-in-flow/:paymentTransactionId?">
                    <QuickPayCancel />
                  </Route> */}

                                        {/* This route is for Quickpay testing without the flow */}
                                        {/* <Route path="/payment/cancel/:paymentTransactionId?">
                    <PaymentCancel />
                  </Route> */}
                                        {/* This route is for Quickpay testing without the flow */}
                                        {/* <Route path="/payment/continue/:paymentTransactionId?">
                    <PaymentContinue />
                  </Route> */}

                                        {/* <Route path="/payment/checkout">
                    <PaymentCheckout />
                  </Route> */}
                                        {/* --- End QuickPay debug pages --- */}

                                        {/* <Route exact path="/betalingsrater">
                    <PaymentRates />
                  </Route> */}

                                        <Route path="/faq">
                                            <FaqPage />
                                        </Route>

                                        <Route exact path="/opslagstavle">
                                            <Activities />
                                        </Route>

                                        <Route path="/opslagstavle/:id">
                                            <ActivityPage />
                                        </Route>

                                        <Route path="/lokale/:id">
                                            <RoomPage />
                                        </Route>

                                        <Route path="/lokaler">
                                            <Rooms />
                                        </Route>

                                        <Route path="/sessionen-udloebet">
                                            <SessionExpired />
                                        </Route>

                                        {/* Admin routes */}
                                        <AuthenticatedRoute
                                            path="/admin/*"
                                            role="admin"
                                        >
                                            <Route path="/admin/messages">
                                                <MessageDataGrid />
                                            </Route>

                                            <Route path="/admin/users">
                                                <UserDataGrid />
                                            </Route>

                                            <Route path="/admin/payments">
                                                <PaymentTransactionDataGrid />
                                            </Route>

                                            <Route path="/admin/orders">
                                                <OrderDataGrid />
                                            </Route>

                                            <Route path="/admin/logs">
                                                <LogDataGrid />
                                            </Route>

                                            <Route path="/admin/dashboard">
                                                <Dashboard />
                                            </Route>

                                            <Route path="/admin/seqman">
                                                <SequelizeManager />
                                            </Route>
                                        </AuthenticatedRoute>

                                        {/* Private routes */}
                                        <AuthenticatedRoute path="/message/:messageId/edit">
                                            <Message />
                                        </AuthenticatedRoute>

                                        {/* QuickPay test page routes. Add .env.development.local with test page settings. */}
                                        <AuthenticatedRoute path="/quickpay/test">
                                            <QuickPay />
                                        </AuthenticatedRoute>
                                        <AuthenticatedRoute path="/quickpay/opsummering">
                                            <QuickPayCancel />
                                        </AuthenticatedRoute>
                                        <AuthenticatedRoute path="/quickpay/kvittering">
                                            <QuickPayContinue />
                                        </AuthenticatedRoute>

                                        {/* QuickPay test page routes. Add .env.development.local with test page settings. */}
                                        <AuthenticatedRoute path="/quickpay/test">
                                            <QuickPay />
                                        </AuthenticatedRoute>
                                        <AuthenticatedRoute path="/quickpay/opsummering">
                                            <QuickPayCancel />
                                        </AuthenticatedRoute>
                                        <AuthenticatedRoute path="/quickpay/kvittering">
                                            <QuickPayContinue />
                                        </AuthenticatedRoute>

                                        <Route exact path="/min-side">
                                            <Redirect to="/min-side/mit-overblik" />
                                        </Route>

                                        <AuthenticatedRoute path="/min-side/:subPage">
                                            <MyPage />
                                        </AuthenticatedRoute>

                                        <AuthenticatedRoute
                                            exact
                                            path="/upload"
                                        >
                                            <UploadImage />
                                        </AuthenticatedRoute>
                                        <AuthenticatedRoute
                                            exact
                                            path="/dine-betalinger"
                                        >
                                            <TransactionsPage />
                                        </AuthenticatedRoute>

                                        <AuthenticatedRoute
                                            exact
                                            path="/reserveringer"
                                        >
                                            <MyEvents />
                                        </AuthenticatedRoute>

                                        <AuthenticatedRoute
                                            exact
                                            path="/reserveringer-efter-medlemskab"
                                        >
                                            <MyEventsAfterMembershipExpiry />
                                        </AuthenticatedRoute>

                                        <AuthenticatedRoute path="/kvittering/:paymentTransactionId">
                                            <Receipt />
                                        </AuthenticatedRoute>

                                        {/* <AuthenticatedRoute
                                            exact
                                            path="/rediger-profil"
                                        >
                                            <EditFullProfile />
                                        </AuthenticatedRoute> */}

                                        {/* If user navigates to /kalender, we should redirect them to room 1 */}
                                        <Redirect
                                            from="/booking-kalender"
                                            to="/booking-kalender/1"
                                        />

                                        <Redirect
                                            from="/aktivitets-kalender"
                                            to="/aktivitets-kalender/1"
                                        />

                                        <Redirect exact from="/" to="/home" />

                                        {!this.props.location.pathname.includes(
                                            '/api/v1'
                                        ) && <Redirect to="/" />}
                                    </Switch>

                                    {/* </motion.div> */}
                                    {isModal ? (
                                        // If modal is opened then it has its own route,
                                        //while we are still able to see the page where modal was opened from.
                                        <>
                                            <Route exact path="/login">
                                                <LoginPage
                                                    isModal={isModal}
                                                    prevLocation={
                                                        this.previousLocation
                                                    }
                                                />
                                            </Route>

                                            <Route path="/glemt-dit-kodeord">
                                                <ForgotPasswordPage
                                                    isModal={isModal}
                                                    prevLocation={
                                                        this.previousLocation
                                                    }
                                                />
                                            </Route>
                                            <Route path="/nulstil-kodeord/:id">
                                                <ResetPasswordPage
                                                    isModal={isModal}
                                                    prevLocation={
                                                        this.previousLocation
                                                    }
                                                />
                                            </Route>
                                        </>
                                    ) : null}

                                    {!(
                                        this.props.location.pathname.startsWith(
                                            '/admin'
                                        ) ||
                                        this.props.location.pathname.endsWith(
                                            '/edit'
                                        )
                                    ) && this.displayFooter()}
                                </ContentWrap>
                            </PageContainer>
                        </Suspense>
                        <PrivacyDialog />
                    </CookiesProvider>
                </ErrorBoundary>
            </IntlProvider>
        );
    }
}

export default withRouter(withContext(withCookies(App)));
