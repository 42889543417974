import React from 'react';
import RegularHeadline from '../headlines/RegularHeadline';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import Carousel from 'react-material-ui-carousel';
import RegistrationButton from '../buttons/registration-button/RegistrationButton';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const FlexContainer = styled(Box)`
    display: flex;
    margin-bottom: 2rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

const InfoContainer = styled(Box)`
    width: 60%;
    padding-right: 2rem;
    margin-bottom: 2rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

// const RoomImage = styled.img`
//     width: 100%;
//     @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
//         /* position: absolute;
//         bottom: -20%; */
//     }
// `;

const CarouselImageContainer = styled.div`
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
`;

const ProfileCarousel = styled(Carousel)`
    width: 100%;
    height: 100%;

    .makeStyles-indicators-4 {
        height: 30px;
        bottom: 0px;
        margin-top: 15px;
        @media (max-width: 600px) {
            position: relative;
        }
    }

    .makeStyles-indicators-4 {
        height: 30px;
    }

    && .MuiSvgIcon-root {
        width: 0.8rem;
        height: 0.8rem;
        color: white;
        /* display: none; */
    }
    .MuiSvgIcon-root:hover {
        border: 1px solid grey;
        border-radius: 50%;
    }

    && .MuiSvgIcon-root {
        width: 0.8rem;
        height: 0.8rem;
    }
    .css-1i8snpa-MuiButtonBase-root-MuiIconButton-root {
        color: pink;
    }

    && > div:nth-child(2) {
        right: 20px;
        @media (min-width: 1050px) {
            right: 37px;
        }

        @media (min-width: 1120px) {
            right: 70px;
        }
    }
`;

const ImageContainer = styled.div`
    width: 40%;
    @media (min-width: ${(props) => props.theme.breakpoints.values.md}px) {
        max-height: ${(props) => props.portrait && '350px'};
        overflow: ${(props) => props.portrait && 'hidden'};
        position: ${(props) => props.portrait && 'relative'};
    }

    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const CarouselImage = styled.img`
    height: 298px;

    @media (max-width: 1100px) {
        width: auto;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: auto;
        height: 220px;
    }

    @media (max-width: 500px) {
        width: auto;
        height: 196px;
    }
`;

function RoomDescriptionBulowsvej(props) {
    const handleButtonClick = () => {
        props.history.push('/lokaler');
    };

    return (
        <div>
            <RegularHeadline
                title="Velkommen til Bülowsvej 10"
                component="h2"
            />
            <FlexContainer>
                <InfoContainer>
                    <Typography variant="body2">
                        På Bülowsvej 10 tilbyder vi lokaleudlejning til
                        fordelagtige priser.
                        <br />
                        <br />
                        Vi har 8 rum fra 8 til 42 kvadratmeter. <br />
                        <br />
                        Lokalerne kan blandt andet bruges til akustisk øvning og
                        undervisning, instrumentalundervisning, kor, møder,
                        bevægelsesundervisning, massage, yoga, terapi og
                        lignende.
                        <br />
                        <br />
                        Find lokaler, der passer til lige dit behov. <br />
                        Lokalerne kan reserveres i intervaller á 15 minutter.{' '}
                        <br />
                        <br />
                        Bliv medlem og få adgang til medlemsfordele, såsom fri
                        afbenyttelse af ledige lokaler.
                        <br />
                        <br />
                        Vi har rum til dig og dine aktiviteter.
                    </Typography>

                    <Box>
                        <RegistrationButton
                            onButtonClick={() => {
                                handleButtonClick();
                            }}
                            title="Se lokalerne"
                            mobileMargin="1.5rem 0"
                            width="12rem"
                        />
                    </Box>
                </InfoContainer>

                {/* This should be implemented if we want to display multiple pictures. For now we just do one. */}

                <ImageContainer>
                    <ProfileCarousel
                        className="profile-carousel"
                        autoPlay={false}
                        activeIndicatorIconButtonProps={{
                            style: {
                                border: '1px solid white',
                                borderRadius: '50%',
                                padding: '0px',
                                margin: '0px'
                            }
                        }}
                    >
                        {props.imageUrls &&
                            props.imageUrls.length > 0 &&
                            props.imageUrls.map((item, key) => (
                                <span key={key}>
                                    <div
                                        onClick={() => props.onImageClick(item)}
                                    >
                                        <CarouselImageContainer className="profile-image-container">
                                            <CarouselImage
                                                src={item}
                                                alt="Profile image"
                                            ></CarouselImage>
                                        </CarouselImageContainer>
                                    </div>
                                </span>
                            ))}
                    </ProfileCarousel>
                </ImageContainer>

                {/* This is in case we only have one image */}
                {/* <ImageContainer onClick={() => props.onImageClick()}>
                    <RoomImage src={roomImage} alt="room" />
                </ImageContainer> */}
            </FlexContainer>
        </div>
    );
}

export default withRouter(RoomDescriptionBulowsvej);
