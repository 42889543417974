import * as React from 'react';

// interface IBeforeInstallPromptEvent extends Event {
//   readonly platforms: string[];
//   readonly userChoice: Promise<{
//     outcome: "accepted" | "dismissed";
//     platform: string;
//   }>;
//   prompt(): Promise<void>;
// }

export function useAddToHomescreenPrompt() {
    const [prompt, setState] = React.useState();

    const promptToInstall = () => {
        if (prompt) {
            return prompt.prompt();
        }

        return Promise.reject(
            new Error(
                'Tried installing before browser sent "beforeinstallprompt" event'
            )
        );
    };

    React.useEffect(() => {
        const ready = (e) => {
            e.preventDefault();
            setState(e);
        };

        window.addEventListener('beforeinstallprompt', ready);

        return () => {
            window.removeEventListener('beforeinstallprompt', ready);
        };
    }, []);

    return [prompt, promptToInstall];
}
