import React from 'react';
import styled from 'styled-components';
import ContentContainer from '../../components/containers/ContentContainer';
import HeadersContainer from '../headers/headers-container/HeadersContainer';

const BackgroundBody = styled.div`
    min-height: 45vh;
`;

function DialogsBackground() {
    return (
        <>
            <HeadersContainer />
            <ContentContainer>
                <BackgroundBody />{' '}
            </ContentContainer>
        </>
    );
}

export default DialogsBackground;
