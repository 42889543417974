import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { postAndReturnResp } from '../../context/helperFunctions';
import Loader from '../../components/loader/Loader';
import ErrorDialog from '../../components/dialogs/error-dialog/ErrorDialog';
import SuccessDialog from '../../components/dialogs/success-dialog/SuccessDialog';
import { Box } from '@material-ui/core';
import DialogsBackground from './../../components/dialogs/DialogsBackground';

function ReactivateActivity(props) {
    const errorMessage =
        'Could not reactivate your profile. Try to log in in order to prolonge your profile on the opslagstavlen.';

    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const { uniqueIdActivity } = props.match.params;
    const [isLoading, setIsLoading] = useState(false);

    const handleError = (message) => {
        return message;
    };

    const reactivateActivity = async (requestBody) => {
        const response = await postAndReturnResp(
            '/api/v1/reactivate-activity',
            requestBody,
            handleError
        );

        if (response && response.status && response.status === 200) {
            setSuccess(true);
        } else {
            setError(true);
        }
        setIsLoading(false);
    };

    // ComponentDidMount
    useEffect(() => {
        setIsLoading(true);

        const requestBody = {
            uniqueIdActivity
        };

        reactivateActivity(requestBody);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isLoading ? (
                <>
                    <DialogsBackground />
                    <Box ml="auto" mr="auto" width="fit-content">
                        <Loader />
                    </Box>
                </>
            ) : (
                <>
                    {error && (
                        <>
                            <DialogsBackground />
                            <ErrorDialog
                                dialogTitle=""
                                close={() => {
                                    setError(false);
                                    props.history.push('/min-side');
                                }}
                                errorMessage={errorMessage}
                            />
                        </>
                    )}
                    {success && (
                        <>
                            <DialogsBackground />
                            <SuccessDialog
                                close={() => {
                                    setSuccess(false);
                                    props.history.push('/min-side');
                                }}
                                // Comeback: danish text
                                successMessage={'Your actvity is active again.'}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
}
export default withRouter(withCookies(ReactivateActivity));
