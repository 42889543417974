import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/da';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteForever';

import ConfirmedIcon from '@material-ui/icons/CheckCircle';
import PendingIcon from '@material-ui/icons/PauseCircleFilled';
import DraftTimerIcon from '@material-ui/icons/HourglassFull';
import {
    getDateString,
    getDayName,
    getTimeString
} from '../../context/helperFunctions';

const EventContainer = styled(Box)`
    width: fit-content;
    margin-bottom: 0.2rem;
    display: flex;
    align-items: flex-start;
    @media (max-width: 1260px) {
        background-color: ${(props) => props.ismypage && '#2c2b2b'};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        background-color: #2c2b2b;
    }
`;
const EventTime = styled(Typography)`
    width: auto;
`;
const IconContainer = styled.div`
    cursor: pointer;
    display: inline;
    color: ${(props) => props.actiontype === 'delete' && 'red'};
    & .MuiSvgIcon-root {
        width: 0.9rem;
        height: 0.9rem;
    }
`;
const StateIconContainer = styled.div`
    display: inline;
    color: ${(props) => {
        if (props.state === 'draft') {
            return props.theme.palette.colors.orange;
        } else if (props.state === 'final') {
            return props.theme.palette.colors.green;
        } else if (props.state === 'pending') {
            return props.theme.palette.colors.blue;
        }
    }};
    & .MuiSvgIcon-root {
        width: ${(props) => (props.state === 'draft' ? '1rem' : '0.9rem')};
        height: ${(props) => (props.state === 'draft' ? '1rem' : '0.9rem')};
        margin-right: ${(props) => props.state !== 'draft' && '0.1rem'};
    }
`;

const MobileTextInfo = styled.div`
    display: flex;
    margin-bottom: 1rem;
    // If the element is displayed on mypage, it should turn to the mobile version earlier
    @media (max-width: 1260px) {
        flex-direction: ${(props) => props.ismypage && 'column'};
        padding: ${(props) => props.ismypage && '1rem 0.5rem'};
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        flex-direction: column;
        padding: 1rem 0.5rem;
    }
`;
const MobileInfoWithIcons = styled.div`
    display: flex;
    @media (max-width: 1260px) {
        flex-direction: ${(props) => props.ismypage && 'column'};
        padding: ${(props) => props.ismypage && '1rem 0.5rem'};
    }

    // If the element is displayed on mypage, it should turn to the mobile version earlier
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        flex-direction: column;
        padding: 1rem 0.5rem;
    }
`;
const IconText = styled(Typography)`
    text-decoration: ${(props) =>
        props.eventstate === 'true' ? 'none' : 'underline'};
    cursor: pointer;
    position: relative;
    color: ${(props) => props.actiontype === 'delete' && 'red'};
`;

const PrivateContainer = styled(Box)`
    width: 6rem;
    margin-left: 10px;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin-left: 0;
    }
`;

function EventItem({
    event,
    handleDeleteClick,
    handleEditClick,
    handlePendingClick,
    handleConfirmedClick,
    canBeDeleted,
    isMypage
}) {
    // Removed when it is used in desktop calendar, because modal is being handled there
    // const [deleteDialog, setDeleteDialog] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    // const [errorMessage, setErrorMessage] = useState("");
    // const [error, setError] = useState("");

    useEffect(() => {
        // Event should only be able to be edited max 2 hours before the starttime ??
        if (
            moment(event.startdate + ' ' + event.starttime).isAfter(
                moment(new Date())
            )
        ) {
            // There is an own editable event
            setIsEditable(true);
        }
    }, [event.startdate, event.starttime]);

    const stateOfEvent = () => {
        if (event.state === 'draft') {
            return (
                <Box width="fit-content" mr="1rem" display="flex">
                    <StateIconContainer state="draft">
                        <DraftTimerIcon />
                    </StateIconContainer>
                    <IconText
                        eventstate={'true'}
                        variant="body2"
                        // onClick={() => handleConfirmedClick(event.id)}
                    >
                        Endnu ikke betalt
                    </IconText>
                </Box>
            );
        } else if (event.state === 'final') {
            return (
                <Box width="8rem" display="flex">
                    <StateIconContainer state="final">
                        <ConfirmedIcon />
                    </StateIconContainer>
                    <IconText
                        eventstate={'true'}
                        variant="body2"
                        // onClick={() => handleConfirmedClick(event.id)}
                    >
                        Bekræftet
                    </IconText>
                </Box>
            );
        } else if (event.state === 'pending') {
            return (
                <Box width="8rem" display="flex">
                    <StateIconContainer state="pending">
                        <PendingIcon />
                    </StateIconContainer>
                    <IconText
                        eventstate={'true'}
                        variant="body2"
                        onClick={() => handlePendingClick(event.id)}
                    >
                        Pending
                    </IconText>
                </Box>
            );
        }
    };

    return (
        <EventContainer key={event.id} ismypage={isMypage}>
            <MobileTextInfo ismypage={isMypage}>
                <Box width="6rem">
                    <Typography variant="body2">{event.title}</Typography>
                </Box>
                <Box width="11rem">
                    <Typography variant="body2">
                        {getDayName(event.startdate)}{' '}
                        {getDateString(event.startdate)}
                    </Typography>
                </Box>

                <Box width="8rem">
                    <EventTime variant="body2">
                        {getTimeString(event.starttime, event.endtime)}
                    </EventTime>
                </Box>

                <PrivateContainer>
                    <EventTime variant="body2">
                        {event.isPrivate ? 'Privat' : 'Offentlig'}
                    </EventTime>
                </PrivateContainer>
            </MobileTextInfo>
            <MobileInfoWithIcons ismypage={isMypage}>
                {stateOfEvent()}
                {isEditable && handleEditClick && (
                    <Box width="8rem" display="flex">
                        <IconContainer>
                            <EditIcon />
                        </IconContainer>
                        <IconText
                            variant="body2"
                            onClick={() =>
                                handleEditClick(
                                    event.id,
                                    event.startdate,
                                    event.roomId
                                )
                            }
                        >
                            Rediger
                        </IconText>
                    </Box>
                )}

                {event.state === 'pending' ? (
                    <Box display="flex"></Box>
                ) : (
                    <>
                        {canBeDeleted && (
                            <Box display="flex">
                                <IconContainer actiontype="delete">
                                    <DeleteIcon />
                                </IconContainer>
                                <IconText
                                    variant="body2"
                                    actiontype="delete"
                                    onClick={() =>
                                        handleDeleteClick(
                                            event.id,
                                            event.repeatSeqId
                                        )
                                    }
                                >
                                    Annuller
                                </IconText>
                            </Box>
                        )}
                    </>
                )}
            </MobileInfoWithIcons>
        </EventContainer>
    );
}

export default EventItem;
