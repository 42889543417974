import { Box, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import ContentContainer from '../../components/containers/ContentContainer';

import RegularHeadline from '../../components/headlines/RegularHeadline';
import ReturnLinkReusable from '../../components/links/ReturnLinkReusable';
import Loader from '../../components/loader/Loader';
import { getToken, getUserId } from '../../context/getData';
import { getAndReturnDataWithAuth } from '../../context/helperFunctions';
import moment from 'moment';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { motion } from 'framer-motion';
import HeadersContainer from '../../components/headers/headers-container/HeadersContainer';

const { overridings } = require('../../themes/DarkTheme');
const handleColorType = (props) => {
    switch (props) {
        case 'processed':
            return `${overridings.palette.colors.green}`;
        case 'deleted':
            return `red`;
        case `waiting`:
            return `${overridings.palette.colors.orange}`;
        case 'pending':
            return `${overridings.palette.colors.blue}`;
        case 'new':
            return `${overridings.palette.colors.green}`;
        default:
            return `${overridings.palette.primary.light}`;
    }
};

const TransactionsContainerMobile = styled.div`
    display: none;
    @media (max-width: 960px) {
        display: block;
    }
`;
const TransactionsTable = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
`;

const OuterTableRows = styled.div`
    width: 100%;
    font-size: 1.1rem;
`;

const OuterTableElement = styled.div`
    padding: 7px;
    margin: 25px 0px 7px 0px;
`;
const TableElement = styled.div`
    font-size: 1rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        font-size: 0.93rem;
    }
`;

const InnerTable = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const InnerRows = styled.div``;
const InnerRow = styled.div`
    border-top: 3px solid ${(props) => props.theme.palette.colors.purple};
    padding: 30px 0px 10px 0px;
`;

const PaymentTransactios = styled.p`
    width: 100%;
    font-size: 0.87rem;
    padding: 3px;
    color: ${(props) => props.color};
    background-color: #1f1e1e;
    margin: 0px;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        font-size: 0.93;
    }
`;
function TransactionsMobile(props) {
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [expanded, setExpanded] = React.useState(false);

    const getTransactions = useCallback(async () => {
        const token = getToken();
        const userId = getUserId();
        // Get transactions from transaction endpoint.
        const data = await getAndReturnDataWithAuth(
            `api/v1/users/${userId}/transactions`,
            token,
            () => {
                console.log('Something went wrong, could not get transactions');
            }
        );
        if (
            data &&
            data.paymentTransaction &&
            data.paymentTransaction.length > 0
        ) {
            // Go through the orders, and set the paymentTransactions visibility
            data.paymentTransaction.forEach((transaction) => {
                transaction.Order.PaymentTransactions.forEach((trans) => {
                    if (trans.paidAt) {
                        trans.visible = true;
                    }
                });
                // Find the index of the first transaction, which is not paid, and make it visible
                // Because we only want to show the closest upcoming payment.
                const index = transaction.Order.PaymentTransactions.findIndex(
                    (trans) => !trans.paidAt
                );

                if (index > 0) {
                    transaction.Order.PaymentTransactions[index].visible = true;
                }
            });

            setOrders(data.paymentTransaction);
        }
        setIsLoading(false);
    }, []);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    // componentDidMount
    useEffect(() => {
        setIsLoading(false);

        getTransactions();
        // eslint-disable-next-line
    }, []);

    const translateStatus = (status) => {
        if (status === 'deleted') {
            return 'Annulleret';
        } else if (status === 'cancelled'||status === 'cancel') {
            return 'Annulleret';
        } else if (status === 'processed') {
            return 'Betalt';
        } else if (status === 'pending') {
            return 'Afventer';
        } else if (status === 'closed') {
            return 'Lukket';
        } else if (status === 'active') {
            return 'Aktiv';
        } else if (status === 'new') {
            return 'Betalt';
        } else return 'Ukendt';
    };
    return (
        <TransactionsContainerMobile>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.7 }}
            >
                <HeadersContainer />

                <ContentContainer>
                    <Box mb="2rem">
                        <ReturnLinkReusable
                            destination="Min side"
                            url="/min-side"
                        />
                    </Box>
                    <RegularHeadline
                        title="Dine betalinger"
                        marginbottom="1rem"
                    />
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Box>
                            {orders.length > 0 ? (
                                <>
                                    <TransactionsTable>
                                        <OuterTableRows>
                                            {orders.map((order, key) => (
                                                <Accordion
                                                    key={key}
                                                    expanded={expanded === key}
                                                    onChange={handleChange(key)}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon />
                                                        }
                                                        aria-controls="panel1bh-content"
                                                    >
                                                        <OuterTableElement>
                                                            <TableElement>
                                                                Bestillingsdato:{' '}
                                                                {moment(
                                                                    order.Order
                                                                        .createdAt
                                                                ).format(
                                                                    'DD/MM/YYYY'
                                                                )}
                                                            </TableElement>
                                                            <TableElement>
                                                                Ordrestatus:{' '}
                                                                {translateStatus(
                                                                    order.paymentState
                                                                )}
                                                            </TableElement>
                                                            {order.paymentCardLastDigits ===
                                                            null ? (
                                                                <></>
                                                            ) : (
                                                                <TableElement>
                                                                    De fire
                                                                    sidste cifre
                                                                    i
                                                                    kortnummeret:{' '}
                                                                    {
                                                                        order.paymentCardLastDigits
                                                                    }
                                                                </TableElement>
                                                            )}
                                                        </OuterTableElement>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <InnerTable>
                                                            <InnerRows>
                                                                {order.Order.PaymentTransactions.map(
                                                                    (
                                                                        transaction
                                                                    ) => (
                                                                        <span
                                                                            key={
                                                                                transaction.id
                                                                            }
                                                                        >
                                                                            {transaction.visible && (
                                                                                <InnerRow>
                                                                                    <PaymentTransactios>
                                                                                        Beløb:{' '}
                                                                                        {transaction.amount /
                                                                                            100}{' '}
                                                                                        kr.
                                                                                    </PaymentTransactios>
                                                                                    {transaction.paymentState !==
                                                                                    null ? (
                                                                                        <PaymentTransactios
                                                                                            color={handleColorType(
                                                                                                transaction.paymentState
                                                                                            )}
                                                                                        >
                                                                                            Status:{' '}
                                                                                            {translateStatus(
                                                                                                transaction.paymentState
                                                                                            )}
                                                                                        </PaymentTransactios>
                                                                                    ) : (
                                                                                        <PaymentTransactios
                                                                                            color={handleColorType(
                                                                                                'waiting'
                                                                                            )}
                                                                                        >
                                                                                            Status:
                                                                                            Ikke
                                                                                            behandlet
                                                                                        </PaymentTransactios>
                                                                                    )}
                                                                                    <PaymentTransactios>
                                                                                        Betalingsfrist:{' '}
                                                                                        {moment(
                                                                                            transaction.dueDate
                                                                                        ).format(
                                                                                            'DD MMM YYYY'
                                                                                        )}
                                                                                    </PaymentTransactios>
                                                                                    {transaction.paymentState ===
                                                                                        'processed' ||
                                                                                    transaction.paymentState ===
                                                                                        'new' ? (
                                                                                        <PaymentTransactios>
                                                                                            Betalingsdato:{' '}
                                                                                            {moment(
                                                                                                transaction.paidAt
                                                                                            ).format(
                                                                                                'DD MMM YYYY'
                                                                                            )}
                                                                                        </PaymentTransactios>
                                                                                    ) : (
                                                                                        <PaymentTransactios>
                                                                                            Betalingsdato:
                                                                                            ___
                                                                                        </PaymentTransactios>
                                                                                    )}
                                                                                </InnerRow>
                                                                            )}
                                                                        </span>
                                                                    )
                                                                )}
                                                            </InnerRows>
                                                        </InnerTable>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}
                                        </OuterTableRows>
                                    </TransactionsTable>
                                </>
                            ) : (
                                <Typography variant="body2">
                                    Du har ingen betalinger.
                                </Typography>
                            )}
                        </Box>
                    )}
                </ContentContainer>
            </motion.div>
        </TransactionsContainerMobile>
    );
}

export default TransactionsMobile;
