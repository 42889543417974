import React from 'react';

import { motion } from 'framer-motion';
import styled from 'styled-components';
import ContentContainer from '../../components/containers/ContentContainer';
import RegularHeadline from '../../components/headlines/RegularHeadline';
import RegularButton from '../../components/buttons/regular-button/RegularButton';
import { overridings } from '../../themes/DarkTheme';
import { useContext } from 'react';
import { Context } from '../../context/Context';
import CookieRules from '../../components/cookie-rules/CookieRules';
import HeadersContainer from '../../components/headers/headers-container/HeadersContainer';

const ButtonContainer = styled.div`
    width: 20%;
    min-width: 12rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

function CookiePage(props) {
    const context = useContext(Context);
    const showPrivacy = () => {
        context.saveIsOpenPrivacy(true);
    };

    return (
        <div>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.7 }}
            >
                <HeadersContainer />

                <ContentContainer>
                    <RegularHeadline title="Cookiepolitik" />
                    <ButtonContainer>
                        {' '}
                        <RegularButton
                            size="large"
                            paddingHorizontal="18px"
                            paddingVertical="6px"
                            title="Cookie indstillinger"
                            onButtonClick={() => showPrivacy()}
                            background={overridings.palette.colors.green}
                        />
                    </ButtonContainer>

                    <CookieRules />
                </ContentContainer>
            </motion.div>
        </div>
    );
}

export default CookiePage;
