import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import { getAndReturnData } from '../../context/helperFunctions';

const TextEditorContainer = styled.div`
    width: 100%;
    .ql-container.ql-snow {
        border: 0px solid #ccc;
    }
    .ql-editor {
        padding: 10px 0px;
    }
    .ql-container.ql-snow {
        border: 0px solid #ccc;
    }
`;

const QuillDiv = styled.div`
    font-size: 1rem;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        font-size: 1rem;
    }
`;

function TextFromRichTextEditor({ userId, setTextLength }) {
    const { quill, quillRef } = useQuill({
        modules: { toolbar: false },
        readOnly: true
    });

    // come back: handle error
    const handleError = () => {
        console.log('Error in Quill editor');
    };
    const textFromAdmin = async () => {
        if (!userId) {
            return;
        }
        const data = await getAndReturnData(
            `/api/v1/getRteContent/${userId}`,
            handleError
        );
        if (!data || data.length === 0) {
            console.log('No data was coming from Quill');
            return;
        } else {
            if (quill) {
                quill.clipboard.dangerouslyPasteHTML(data.data);
                // setTextLength(quill.getLength());
            }
        }
    };

    useEffect(() => {
        textFromAdmin();

        // eslint-disable-next-line
    }, [quill, userId]);

    return (
        <TextEditorContainer>
            <QuillDiv ref={quillRef} />
        </TextEditorContainer>
    );
}

export default TextFromRichTextEditor;
