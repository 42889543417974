import React from "react";
import styled from "styled-components";

const StageNumberIncompleteComponent = styled.p`
  padding: 0.2rem 0.6rem;
  display: inline-block;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.palette.primary.light};
  background-color: ${(props) => props.theme.palette.primary.light};
`;

function StageNumberIncomplete({ stageNumber }) {
  return (
    <StageNumberIncompleteComponent>
      {stageNumber}
    </StageNumberIncompleteComponent>
  );
}

export default StageNumberIncomplete;
