import { Box, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import FatGreenButton from '../../components/buttons/fat-green-button/FatGreenButton';
import Loader from '../../components/loader/Loader';
import { getAndReturnData } from '../../context/helperFunctions';
import { overridings } from '../../themes/DarkTheme';
import { profileImagesFunc } from './ActivityFunctions';

const Underlined = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;

const ActivityPopupEl = styled(Box)`
    position: ${(props) => (props.isindialog ? 'static' : 'absolute')};
    /* bottom: 5px; */
    background: ${overridings.palette.primary.light};
    width: 250px;
    padding: 10px;
    border-radius: 4px;
    margin: ${(props) => props.isindialog && '0 auto'};
    left: ${(props) => {
        if (props.showpopuponleft === 'true' && props.elleft) {
            return props.elleft - 250 + 'px'; // need to add the width of the element to the left position
        }
    }};
`;

const ProfileCarousel = styled(Carousel)`
    width: 100%;
    height: 100%;
    margin-bottom: 15px;

    .makeStyles-indicators-4 {
        height: 30px;
        bottom: 0px;
        margin-top: 15px;
        @media (max-width: 600px) {
            position: relative;
        }
    }

    .makeStyles-indicators-4 {
        height: 30px;
    }

    && .MuiSvgIcon-root {
        width: 0.8rem;
        height: 0.8rem;
        color: white;
    }
    .MuiSvgIcon-root:hover {
        border: 1px solid grey;
        border-radius: 50%;
    }

    && .MuiSvgIcon-root {
        width: 0.8rem;
        height: 0.8rem;
    }
    .css-1i8snpa-MuiButtonBase-root-MuiIconButton-root {
        color: pink;
    }

    div:nth-child(4) {
        margin-top: 0;
    }
`;

const ProfileImageContainer = styled.div`
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
`;

const ProfileImage = styled.img`
    height: 230px;
    width: 100%;
`;

function ActivityPopup(props) {
    const [userInfo, setUserInfo] = useState({});
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userProfileImages, setUserProfileImages] = useState([
        '/images/avatar.png'
    ]);

    const handleContactClick = () => {
        window.location.href = `mailto:${userInfo.email}`;
    };

    const handleLookMoreClick = () => {
        console.log('handle more click');
        const url = window.location.href;
        const path = url.split('bulowsvej10.dk')[1];

        props.history.push({
            pathname: `/opslagstavle/${props.activityInfo.userId}`,
            state: {
                from: { path, title: 'Kalenderen' }
            }
        });
    };

    const getUserData = useCallback(async () => {
        const userData = await getAndReturnData(
            `${window.location.origin}/api/v1/public-activity/${props.activityInfo.userId}`,
            (err) => console.log('Error when loading userInfo,', err)
        );
        if (userData) {
            setUserInfo(userData);
        } else {
            setError(true);
        }

        profileImagesFunc(
            userData.imageInfo,
            props.activityInfo.userId,
            setUserProfileImages,
            setIsLoading
        );

        setIsLoading(false);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);
        getUserData()
            .then(() => {
                if (isMounted) {
                    setIsLoading(false);
                }
            })
            .catch(() => {
                if (isMounted) {
                    setError(true);
                    setIsLoading(false);
                }
            });

        return () => {
            isMounted = false;
        };
    }, [getUserData]);
    return (
        <ActivityPopupEl
            isindialog={props.isInDialog}
            showpopuponleft={
                props.showPopupOnLeft && props.showPopupOnLeft.toString()
            }
            elleft={props.elementLeft}
            eltop={props.elementTop}
        >
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {userInfo && (
                        <>
                            <ProfileCarousel
                                className="profile-carousel"
                                autoPlay={false}
                                activeIndicatorIconButtonProps={{
                                    style: {
                                        border: '1px solid white',
                                        borderRadius: '50%',
                                        padding: '0px',
                                        margin: '0px'
                                    }
                                }}
                            >
                                {userProfileImages.map((item, key) => (
                                    <span key={key}>
                                        <div>
                                            <ProfileImageContainer className="profile-image-container">
                                                <ProfileImage
                                                    src={item}
                                                    alt="Profile image"
                                                ></ProfileImage>
                                            </ProfileImageContainer>
                                        </div>
                                    </span>
                                ))}
                            </ProfileCarousel>
                            <Typography variant="subtitle1">
                                {userInfo.activityName}
                            </Typography>
                            <Typography variant="body2">
                                {userInfo.firstname} {userInfo.surname}
                            </Typography>
                            <Underlined onClick={() => handleLookMoreClick()}>
                                Se mere
                            </Underlined>
                            <FatGreenButton
                                width="100%"
                                title="Kontakt"
                                onButtonClick={() => handleContactClick()}
                                margin="25px 0 10px"
                                mobileWidth="100%"
                                mobileMargin="25px auto 10px"
                            ></FatGreenButton>
                        </>
                    )}
                    {/* Come back danish text */}
                    {error && (
                        <Typography variant="body2">
                            Could not load user activity.
                        </Typography>
                    )}
                </>
            )}
        </ActivityPopupEl>
    );
}

export default withRouter(ActivityPopup);
