import React from 'react';
import styled from 'styled-components';

const StageNumberCompletedComponent = styled.p`
    background-color: ${(props) => props.theme.palette.colors.green};
    padding: 0.2rem 0.6rem;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.palette.colors.green};
`;

function StageNumberCompleted({ stageNumber }) {
    return (
        <StageNumberCompletedComponent>
            {stageNumber}
        </StageNumberCompletedComponent>
    );
}

export default StageNumberCompleted;
