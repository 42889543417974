import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { postAndReturnData } from '../../context/helperFunctions';
import { Context } from '../../context/Context';
import { getCurrentUserInfo } from '../../context/getData';
import Loader from '../../components/loader/Loader';
import ErrorDialog from '../../components/dialogs/error-dialog/ErrorDialog';
import SuccessDialog from '../../components/dialogs/success-dialog/SuccessDialog';
// import SubscriptionShortSection from "../../components/subscription-short-section/SubscriptionShortSection";
import DialogsBackground from './../../components/dialogs/DialogsBackground';

import { Box } from '@material-ui/core';

function SignupPhoneVerification(props) {
    const errorMessage =
        'Noget gik galt. Din telefon er ikke verificeret. Prøv igen.';
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const { uniqueIdPhone } = props.match.params;
    const [isLoading, setIsLoading] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const context = useContext(Context);

    const handleError = (message) => {
        return message;
    };

    const verifySms = async (requestBody) => {
        const response = await postAndReturnData(
            '/api/v1/users/verify-phone',
            requestBody,
            handleError
        );

        if (!response) {
            setError(true);
        } else {
            if (response.phoneVerifiedAt) {
                const userData = getCurrentUserInfo(context);
                if (userData && userData.userFirstname) {
                    userData.phoneVerifiedAt = response.phoneVerifiedAt;
                    context.saveUser({
                        userFirstname: userData.userFirstname,
                        userEmail: userData.userEmail,
                        userId: userData.userId,
                        emailVerifiedAt: userData.emailVerifiedAt,
                        phoneVerifiedAt: userData.phoneVerifiedAt,
                        subscriber: userData.subscriber,
                        token: userData.token,
                        tokenExpires: userData.tokenExpires,
                        lastDayOfSubscription: userData.lastDayOfSubscription,
                        subscriptionDueDate: userData.subscriptionDueDate,
                        inLastMonthOfSubscription:
                            userData.inLastMonthOfSubscription,
                        isProfilePublic: userData.isProfilePublic,
                        role: userData.role,
                        lastAcceptedTerms: userData.lastAcceptedTerms
                    });
                }

                if (response.emailVerifiedAt) {
                    setIsEmailVerified(true);
                }
                setSuccess(true);
            } else {
                setError(true);
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        setIsLoading(true);
        const requestBody = {
            uniqueIdPhone: uniqueIdPhone
        };

        verifySms(requestBody);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isLoading ? (
                <Box ml="auto" mr="auto" width="fit-content">
                    <Loader />
                </Box>
            ) : (
                <>
                    <DialogsBackground />
                    {error && (
                        <ErrorDialog
                            dialogTitle=""
                            close={() => {
                                setError(false);
                                props.history.push('/min-side');
                            }}
                            errorMessage={errorMessage}
                        />
                    )}
                    {success && (
                        <SuccessDialog
                            // title="Succes"
                            close={() => {
                                setSuccess(false);
                                props.history.push('/min-side');
                            }}
                            successMessage={
                                isEmailVerified
                                    ? 'Din konto er nu verificeret og klar til brug.'
                                    : 'Dit mobilnummer er verificeret. Husk at verificere din email. Tjek din inbox.'
                            }
                        />
                    )}
                </>
            )}
            {/* </ContentContainer> */}
        </>
    );
}
export default withRouter(withCookies(SignupPhoneVerification));
