import React from 'react';

function SquareCheckedIcon(props) {
    return (
        <span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-square-check"
                width={props.width ? props.width : '44px'}
                height={props.height ? props.height : '44px'}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#ffffff"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="4" y="4" width="16" height="16" rx="2" />
                <path d="M9 12l2 2l4 -4" />
            </svg>
        </span>
    );
}

export default SquareCheckedIcon;
