import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import DesktopCalendar from '../../components/calendar/DesktopCalendar';
import MobileCalendar from '../../components/calendar/MobileCalendar';
import { getLocalStorageJson } from '../../util';

import { motion } from 'framer-motion';
import { Box } from '@material-ui/core';
import RoomsDesktopCalendar from '../../components/calendar/RoomsDesktopCalendar';
import { Context } from '../../context/Context';
import { withContext } from '../../components/containers/ContextContainer';
import ProgressBar from '../../components/progress-bar/ProgressBar';
import {
    returnAuthenticated,
    getCurrentStage,
    getEmailVerifiedAt,
    getPhoneVerifiedAt,
    getUserId
} from '../../context/getData';

import RegistrationContentContainer from '../../components/containers/registration/RegistrationContentContainer';
import HeadersContainer from '../../components/headers/headers-container/HeadersContainer';
import ResendCodeDialog from '../../components/dialogs/resend-code-dialog/ResendCodeDialog';

let localStorageKey = document.location.hostname + ':state-history';

function CalendarPage(props) {
    let json = getLocalStorageJson(localStorageKey);
    const context = useContext(Context);
    const [checked, setChecked] = useState(
        json !== undefined
            ? json.calendarType !== 'rooms'
                ? false
                : true
            : false
    );
    const [showVerificationDialog, setShowVerificationDialog] = useState(false);

    const handleChange = () => {
        checked
            ? context.saveCalendarType('normal')
            : context.saveCalendarType('rooms');

        setChecked(!checked);
    };

    // componentDidMount
    useEffect(() => {
        // If the user is logged in, and hasn't been verified, need to show a dialog
        if (getUserId()) {
            if (!getEmailVerifiedAt() || !getPhoneVerifiedAt()) {
                setShowVerificationDialog(true);
            }
        }
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <HeadersContainer />

            <RegistrationContentContainer
                marginTop="2.5rem"
                mobilePaddingTop="1rem"
                maxWidth="none"
            >
                {returnAuthenticated() ? (
                    <ProgressBar
                        currentStage={'Kalender'}
                        type={getCurrentStage()}
                    />
                ) : (
                    <ProgressBar currentStage={'Kalender'} />
                )}
            </RegistrationContentContainer>
            {/* {document.body.offsetWidth < 960 ? (
                <></>
            ) : (
                <SwitchButtonCalendar
                    handleChange={handleChange}
                    checked={checked}
                />
            )} */}

            {document.body.offsetWidth < 960 ? (
                <Box width="90%" ml="auto" mr="auto">
                    <MobileCalendar />
                </Box>
            ) : (
                <>
                    {checked ? (
                        <RoomsDesktopCalendar
                            handleCalendarChange={handleChange}
                            checked={checked}
                        />
                    ) : (
                        <DesktopCalendar
                            handleCalendarChange={handleChange}
                            checked={checked}
                        />
                    )}
                </>
            )}
            {showVerificationDialog && (
                <ResendCodeDialog
                    close={() => props.history.push('/min-side')}
                    isWarning={true}
                />
            )}
        </motion.div>
    );
}

export default withContext(withRouter(CalendarPage));
