import React from "react";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
      position: "fixed", 
      top: 0, 
      width: "100%",
      zIndex: 1000,
      backgroundColor: '#DD0000'
  },
  header: {
    padding: '0px',
    paddingLeft: '10px',
    color: '#FEFEFE'
  },
  textarea: {
    height: '50px', 
    width: '100%',
    backgroundColor: '#DD0000',
    color: '#FEFEFE',
    padding: '5px',
    border: '0px'
  },
  button: {
    backgroundColor: '#FEFEFE',
    marginLeft: '10px',
    borderRadius: '18px',
    border: '1px solid #002460'
  }
});

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        hasError: false, 
        error: '', 
        errorInfo: {} 
      };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.log(error, errorInfo);
      this.setState({error, errorInfo});
    }
  
    handleConfirm = (event) => {
      this.setState({ 
        hasError: false, 
        error: '', 
        errorInfo: '' }, () => {
          document.location.reload('/');
        });
    }

    openMailMessage = () => {
      let url = 'mailto:admin@lisberg.io?subject=';
      url+= encodeURI(this.state.error);
      url+= '&body=';
      url+= encodeURI(this.state.errorInfo);
      document.location.href = url;
    }

    handleReport = (event) => {
      let self = this;
      this.setState({ 
        hasError: false, 
        error: '', 
        errorInfo: '' }, () => {
          navigator.permissions.query({name: "clipboard-write"}).then(result => {
            if (result.state === "granted" || result.state === "prompt") {
              navigator.clipboard.writeText((this.state.errorInfo.componentStack ? this.state.errorInfo.componentStack : '')).then(function() {
                console.log('Copied error report to clipboard')
                self.openMailMessage()
              }, function() {
                console.log('Failed to copy error report to clipboard')
                self.openMailMessage()
              });
            }
            else {
              self.openMailMessage()
            }
          });
          
        });
    }

    render() {
        const { classes } = this.props;
        
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
              <div>
                <div className={classes.container}>
                    <h3 className={classes.header}>
                        Der opstod en fejl {this.state.error}
                        <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                          <Button className={classes.button} onClick={this.handleReport}>Send</Button>
                          <Button className={classes.button} onClick={this.handleConfirm}>Luk</Button>
                        </div>
                    </h3> 
                    <textarea 
                      className={classes.textarea} 
                      readOnly
                      value={(this.state.errorInfo.componentStack ? this.state.errorInfo.componentStack : '')}></textarea>

                </div>
                {this.props.children}
              </div>
            );
        }

        return this.props.children; 
    }
}

export default withStyles(styles)(ErrorBoundary);