import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import ContentContainer from '../../components/containers/ContentContainer';
import { motion } from 'framer-motion';

import QABoxesContainer from '../../components/containers/about/QABoxesContainer';
import QAData from '../../static-data/QAData';
import QABox from '../../components/qa-box/QABox';
import RegularHeadline from '../../components/headlines/RegularHeadline';
import HeadersContainer from '../../components/headers/headers-container/HeadersContainer';

const DescriptionText = styled(Typography)`
    margin-bottom: 1rem;
    max-width: 80%;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        max-width: 100%;
    }
`;

const HeadLine = styled.div`
    color: ${(props) => props.theme.palette.colors.orange};
`;

function FaqPage(props) {
    const [activeSectionId, setActiveSectionId] = useState(0);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <HeadersContainer />

            <ContentContainer>
                <HeadLine style={{ color: 'white' }}>
                    <RegularHeadline
                        title="Spørgsmål og svar på det vigtigste"
                        component="h1"
                        marginbottom="1.5rem"
                    />
                </HeadLine>
                <Box mb="3rem">
                    <DescriptionText variant="body2">
                        Læs følgende inden du bliver medlem og/eller lejer et
                        lokale.
                    </DescriptionText>
                </Box>
                <Box></Box>
                <Box>
                    <QABoxesContainer>
                        {QAData.map((QA) => (
                            <QABox
                                content={QA}
                                key={QA.id}
                                width="100%"
                                activeSectionId={activeSectionId}
                                setActiveSectionId={setActiveSectionId}
                            />
                        ))}
                    </QABoxesContainer>
                </Box>
            </ContentContainer>
        </motion.div>
    );
}

export default withRouter(FaqPage);
