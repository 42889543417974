import { Box, TableCell, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/da';
import React from 'react';
import styled from 'styled-components';
import DeleteIcon from '@material-ui/icons/DeleteForever';

import { getLocationNameFromRoomId } from '../../context/getData';
import { getRightPriceCellsForEventsOnly } from '../product-table/ProductTableFunctions';
import { overridings } from '../../themes/DarkTheme';

const IconContainer = styled.div`
    cursor: pointer;
    display: inline;
    color: ${(props) => props.actiontype === 'delete' && 'red'};
    & .MuiSvgIcon-root {
        width: 0.9rem;
        height: 0.9rem;
    }
`;

const IconText = styled(Typography)`
    text-decoration: ${(props) =>
        props.eventstate === 'true' ? 'none' : 'underline'};
    cursor: pointer;
    position: relative;
    color: ${(props) => props.actiontype === 'delete' && 'red'};
`;

function EventItemCheckout({
    event,
    handleDeleteClick,
    handleEditClick,
    handlePendingClick,
    handleConfirmedClick,
    canBeDeleted,
    isMypage,
    orderType
}) {
    return (
        <>
            <TableRow>
                {' '}
                <TableCell
                    className="no-border-bottom-cell"
                    sx={{
                        borderBottom: '1px solid transparent'
                    }}
                >
                    Lokale {getLocationNameFromRoomId(event.roomId)}
                </TableCell>
                <TableCell
                    className="no-border-bottom-cell"
                    // sx={{ borderBottom: '1px solid transparent' }}
                >
                    {moment(event.startdate).format('DD.MM.YYYY')} <br />
                    {moment(event.startdate + ' ' + event.starttime).format(
                        'HH:mm'
                    )}
                    -
                    {moment(event.startdate + ' ' + event.endtime).format(
                        'HH.mm'
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    sx={{
                        borderBottom: '1px solid transparent'
                    }}
                    className="no-border-bottom-cell"
                >
                    Pris
                </TableCell>

                {orderType === 'events' &&
                    getRightPriceCellsForEventsOnly(event, overridings, true)}
                {orderType === 'combo' && (
                    <TableCell
                        className="no-border-bottom-cell"
                        sx={{ borderBottom: '1px solid transparent' }}
                    >
                        {event.normalPrice / 2 / 100 + 'kr.'}
                    </TableCell>
                )}
            </TableRow>
            <TableRow>
                <TableCell>
                    {canBeDeleted && (
                        <Box display="flex">
                            <IconContainer actiontype="delete">
                                <DeleteIcon />
                            </IconContainer>
                            <IconText
                                variant="body2"
                                actiontype="delete"
                                onClick={() =>
                                    handleDeleteClick(
                                        event.id,
                                        event.repeatSeqId
                                    )
                                }
                            >
                                Annuller
                            </IconText>
                        </Box>
                    )}
                </TableCell>
                <TableCell></TableCell>
            </TableRow>
        </>
        // <EventContainer key={event.id} ismypage={isMypage}>
        //     <MobileTextInfo ismypage={isMypage}>
        //         <Box width="6rem">
        //             <Typography variant="body2">{event.title}</Typography>
        //         </Box>
        //         <Box width="11rem">
        //             <Typography variant="body2">
        //                 {getDayName(event.startdate)}{' '}
        //                 {getDateString(event.startdate)}
        //             </Typography>
        //         </Box>

        //         <Box width="8rem">
        //             <EventTime variant="body2">
        //                 {getTimeString(event.starttime, event.endtime)}
        //             </EventTime>
        //         </Box>

        //         <PrivateContainer>
        //             <EventTime variant="body2">
        //                 {event.isPrivate ? 'Privat' : 'Offentlig'}
        //             </EventTime>
        //         </PrivateContainer>
        //     </MobileTextInfo>
        //     <MobileInfoWithIcons ismypage={isMypage}>
        //         {stateOfEvent()}
        //         {isEditable && handleEditClick && (
        //             <Box width="8rem" display="flex">
        //                 <IconContainer>
        //                     <EditIcon />
        //                 </IconContainer>
        //                 <IconText
        //                     variant="body2"
        //                     onClick={() =>
        //                         handleEditClick(
        //                             event.id,
        //                             event.startdate,
        //                             event.roomId
        //                         )
        //                     }
        //                 >
        //                     Rediger
        //                 </IconText>
        //             </Box>
        //         )}

        //         {event.state === 'pending' ? (
        //             <Box display="flex"></Box>
        //         ) : (
        //             <>
        //                 {canBeDeleted && (
        //                     <Box display="flex">
        //                         <IconContainer actiontype="delete">
        //                             <DeleteIcon />
        //                         </IconContainer>
        //                         <IconText
        //                             variant="body2"
        //                             actiontype="delete"
        //                             onClick={() =>
        //                                 handleDeleteClick(
        //                                     event.id,
        //                                     event.repeatSeqId
        //                                 )
        //                             }
        //                         >
        //                             Annuller
        //                         </IconText>
        //                     </Box>
        //                 )}
        //             </>
        //         )}
        //     </MobileInfoWithIcons>
        // </EventContainer>
    );
}

export default EventItemCheckout;
