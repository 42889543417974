import { TableCell } from '@mui/material';
import moment from 'moment';
import {
    getLastDayOfSubscription,
    isInLastMonthOfSubscription,
    isSubscriber
} from '../../context/getData';

export const returnPriceCellsWithDiscount = (event, overridings, isMobile) => {
    return (
        <>
            {isMobile ? (
                <TableCell className="no-border-bottom-cell">
                    {calculateSingleEventDiscountPrice(
                        event.normalPrice,
                        event.startdate
                    )}{' '}
                    kr.
                </TableCell>
            ) : (
                <>
                    {/* Normal price crossed */}
                    <TableCell
                        sx={{
                            color: overridings.palette.primary.contrastText,
                            textDecoration: 'line-through',
                            minWidth: '115px',
                            width: '13%',
                            '@media (max-width: 600px)': {
                                fontSize: '10px',
                                display: 'none'
                            }
                        }}
                        align="center"
                    >
                        {event.normalPrice / 100} kr.
                    </TableCell>
                    <TableCell
                        sx={{
                            color: overridings.palette.primary.contrastText,
                            minWidth: '115px',
                            width: '13%',
                            '@media (max-width: 600px)': {
                                fontSize: '10px',
                                display: 'none'
                            }
                        }}
                        align="center"
                    >
                        50%
                    </TableCell>
                    <TableCell
                        sx={{
                            color: overridings.palette.primary.contrastText,
                            minWidth: '115px',
                            width: '13%',
                            '@media (max-width: 600px)': {
                                fontSize: '10px',
                                minWidth: 'unset'
                            }
                        }}
                        align="center"
                    >
                        {calculateSingleEventDiscountPrice(
                            event.normalPrice,
                            event.startdate
                        )}{' '}
                        kr.
                    </TableCell>
                </>
            )}
        </>
    );
};

export const returnPriceCellNormalPrice = (event, overridings, isMobile) => {
    return (
        <>
            {' '}
            {isMobile ? (
                <TableCell
                    className="no-border-bottom-cell"
                    sx={{ borderBottom: '1px solid rgba(81, 81, 81, 1)' }}
                >
                    {(event.normalPrice / 100).toString().replaceAll('.', ',')}{' '}
                    kr.
                </TableCell>
            ) : (
                <>
                    <TableCell
                        sx={{
                            color: overridings.palette.primary.contrastText,
                            minWidth: '115px',
                            width: '13%',
                            '@media (max-width: 600px)': {
                                fontSize: '10px',
                                minWidth: 'unset'
                            }
                        }}
                        align="center"
                    >
                        {(event.normalPrice / 100)
                            .toString()
                            .replaceAll('.', ',')}{' '}
                        kr.
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </>
            )}
        </>
    );
};

export const calculateSingleEventDiscountPrice = (
    normalPrice,
    eventDate,
    orderType
) => {
    if (orderType === 'combo') {
        return (normalPrice / 2 / 100).toString().replaceAll('.', ',');
    }

    if (isSubscriber()) {
        return (normalPrice / 2 / 100).toString().replaceAll('.', ',');
    } else if (isInLastMonthOfSubscription()) {
        const lastDayOfSubscription = getLastDayOfSubscription();
        if (moment(eventDate).isSameOrBefore(moment(lastDayOfSubscription))) {
            return (normalPrice / 2 / 100).toString().replaceAll('.', ',');
        } else {
            return (normalPrice / 100).toString().replaceAll('.', ',');
        }
    } else {
        return (normalPrice / 100).toString().replaceAll('.', ',');
    }
};

export const getRightPriceCellsForEventsOnly = (
    event,
    overridings,
    isMobile
) => {
    // Show both normal and discounted price
    if (isSubscriber()) {
        return returnPriceCellsWithDiscount(event, overridings, isMobile);
        // If user is in last month of subscription, only show the discounted for the ones that are within the expiry
    } else if (isInLastMonthOfSubscription()) {
        if (
            moment(event.startdate + ' ' + event.starttime).isSameOrBefore(
                getLastDayOfSubscription()
            )
        ) {
            return returnPriceCellsWithDiscount(event, overridings, isMobile);
        } else {
            return returnPriceCellNormalPrice(event, overridings, isMobile);
        }
    } else {
        return returnPriceCellNormalPrice(event, overridings, isMobile);
    }
};
