import React from "react";
import styled from "styled-components";

const HeaderLoginContainerComponent = styled.div``;

function HeaderLoginContainer({ children }) {
  return (
    <HeaderLoginContainerComponent>{children}</HeaderLoginContainerComponent>
  );
}

export default HeaderLoginContainer;
