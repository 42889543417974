import React, { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import RoomCard from '../cards/RoomCard';
import RegularHeadline from '../headlines/RegularHeadline';
import ImageDialog from '../dialogs/image-dialog/ImageDialog';
import RoomMapImage from '../../assets/rooms/plantegning.jpg';

function RoomGrid({ data, url, returnPath, returnTitle }) {
    const [showImagePopup, setShowImagePopup] = useState(false);

    // const handleButtonClick = () => {
    //     setShowImagePopup(true);
    // };

    return (
        <React.Fragment>
            <Box>
                <RegularHeadline marginbottom="1.5rem" title="Lokaleoversigt" />
            </Box>
            {/* <Box mb="3rem">
                <RegistrationButton
                    onButtonClick={() => {
                        handleButtonClick();
                    }}
                    title="Se plantegning"
                    mobileMargin="1.5rem 0"
                />
            </Box> */}

            <Grid container spacing={2}>
                {data &&
                    data.map((d) => (
                        <Grid key={d.id} item xs={12} sm={6} md={4}>
                            <RoomCard
                                title={d.name}
                                imageUrl={
                                    document.body.offsetWidth < 960
                                        ? d.imageUrlMobile
                                        : d.imageUrl
                                }
                                color={d.colorRGB}
                                colorname={d.color}
                                to={{
                                    pathname: `lokale/${d.id}`,
                                    state: {
                                        from: {
                                            path: returnPath,
                                            title: returnTitle
                                        }
                                    }
                                }}
                            />
                        </Grid>
                    ))}
            </Grid>
            {showImagePopup && (
                <ImageDialog
                    component={RoomMapImage}
                    altText="lokaler tegning"
                    close={() => {
                        setShowImagePopup(false);
                    }}
                />
            )}
        </React.Fragment>
    );
}

export default RoomGrid;
