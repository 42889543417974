import React from 'react';
import styled from 'styled-components';

const StageNumberActiveComponent = styled.p`
    padding: 0.2rem 0.6rem;
    display: inline-block;
    border-radius: 50%;
    border: 2px solid ${(props) => props.theme.palette.colors.green};
`;

function StageNumberActive({ stageNumber }) {
    return (
        <StageNumberActiveComponent>{stageNumber}</StageNumberActiveComponent>
    );
}

export default StageNumberActive;
