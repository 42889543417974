import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const ContentContainerComponent = styled(Box)`
    width: 85%;
    max-width: 1200px;
    margin: 4rem auto;
    margin-top: ${(props) => props.margintop && props.margintop};

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin-bottom: ${(props) =>
            props.marginbottommobile && props.marginbottommobile};
    }
`;

function ContentContainer({ children, marginTop, marginBottomMobile }) {
    return (
        <ContentContainerComponent
            className="content-container"
            margintop={marginTop}
            marginbottommobile={marginBottomMobile}
        >
            {children}
        </ContentContainerComponent>
    );
}

export default ContentContainer;
