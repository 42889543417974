import React from "react";
import styled from "styled-components";
import moment from "moment";
import {
  checkForLastHour,
  validateAndSaveChosenTime,
  setSelectableTimes,
} from "../BookingFunctions";
import { hours, minutes } from "./HourData";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

// Type= time is not supported on Safari or IE12 or earlier! Investigate this!

const Dropdown = styled(Select)`
  font-size: 1rem;
  ${(props) =>
    props.error
      ? `
  color: red;`
      : undefined};
`;

const DropdownContainer = styled.div`
  background-color: ${(props) => props.theme.palette.secondary.main};
  text-align: center;
  padding-top: 0.4rem;
  padding-bottom: 0.1rem;
  @media (min-width: 960px) {
    background-color: inherit;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

class SimpleTimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hour: "",
      minute: "",
      displayedHours: hours,
      displayedMinutes: [],
    };
  }

  handleChange = (e) => {
    // Remove error
    this.props.parentComponentSelf.setState({
      startTimeError: false,
      endTimeError: false,
    });
    // If last hour of the day is selected as end hour, render only '00' minutes.
    checkForLastHour(
      e.target.name,
      e.target.value,
      minutes,
      this.props.availableEndTime,
      this
    );

    this.setState({ [e.target.name]: e.target.value }, () => {
      validateAndSaveChosenTime(
        this.props.timeType,
        this.state.hour,
        this.state.minute,
        this.props.parentComponentSelf,
        this.props.existingEvent,
        this.props.newAvailableEndTime,
        this.props.newAvailableStartTime
      );
    });
  };

  // If start time has been changed new props are being sent to end time.
  componentDidUpdate(nextProps) {
    const { automaticEndTime } = this.props;
    if (nextProps.automaticEndTime !== automaticEndTime) {
      this.setState({
        hour: moment(automaticEndTime).format("HH"),
        minute: moment(automaticEndTime).format("mm"),
      });
    }
  }

  componentDidMount() {
    this.setState({ error: this.props.error });

    if (this.props.newAvailableEndTime && !this.props.newAvailableStartTime) {
      // If it is an event to be edited, where there is new available end time, but no new available start time
      // So it is the first event in the day
      setSelectableTimes(
        this.props.availableStartTime,
        this.props.newAvailableEndTime,
        this.props.timeType,
        hours,
        minutes,
        this,
        this.props.parentComponentSelf,
        this.props.existingEvent,
        this.props.originalEndTime
      );
    } else if (
      !this.props.newAvailableEndTime &&
      this.props.newAvailableStartTime
    ) {
      // If it is an event to be edited where there is new available start time, but no new available end time
      // So it is the last event of the day
      setSelectableTimes(
        this.props.newAvailableStartTime,
        this.props.availableEndTime,
        this.props.timeType,
        hours,
        minutes,
        this,
        this.props.parentComponentSelf,
        this.props.existingEvent,
        undefined,
        this.props.originalStartTime
      );
    } else if (
      this.props.newAvailableEndTime &&
      this.props.newAvailableStartTime
    ) {
      // It is an event to be edited, there is new available start and end times
      // So it is an event that is neither first, nor last of the day
      setSelectableTimes(
        this.props.newAvailableStartTime,
        this.props.newAvailableEndTime,
        this.props.timeType,
        hours,
        minutes,
        this,
        this.props.parentComponentSelf,
        this.props.existingEvent,
        this.props.originalEndTime,
        this.props.originalStartTime
      );
    } else {
      setSelectableTimes(
        this.props.availableStartTime,
        this.props.availableEndTime,
        this.props.timeType,
        hours,
        minutes,
        this,
        this.props.parentComponentSelf,
        this.props.existingEvent
      );
    }
  }

  render() {
    return (
      <div>
        <DropdownContainer>
          <Dropdown
            onChange={(e) => this.handleChange(e)}
            name="hour"
            value={this.state.hour}
            selected
            error={this.props.error}
            disableUnderline={true}
          >
            {this.state.displayedHours.map((hour) => (
              <MenuItem key={hour.title} value={hour.title}>
                {hour.title}
              </MenuItem>
            ))}
          </Dropdown>

          <Dropdown
            onChange={(e) => this.handleChange(e)}
            name="minute"
            value={this.state.minute}
            error={this.props.error}
            disableUnderline={true}
          >
            {this.state.displayedMinutes.map((minute) => (
              <MenuItem key={minute.value} value={minute.title}>
                {minute.title}
              </MenuItem>
            ))}
          </Dropdown>
        </DropdownContainer>
      </div>
    );
  }
}

export default SimpleTimePicker;
