import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Box, Dialog, DialogContent, Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const DialogComponent = styled(Dialog)`
    & .MuiPaper-root {
        padding: 1rem !important;
        width: 100%;
    }
`;

const CloseIcon = styled(Typography)`
    font-size: 2rem;
    position: absolute;
    left: 10px;
    top: -6px;
    cursor: pointer;
    color: ${(props) => props.theme.palette.primary.light};

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        right: 10px;
        top: -6px;
    }
`;

const TypographyText = styled(Typography)`
    display: inline;
`;

const UnderlinedText = styled(Typography)`
    text-decoration: underline;
    display: inline;
    cursor: pointer;
`;

function LoginOrSignupDialog(props) {
    const onRegisterClick = () => {
        props.history.push({
            pathname: '/oplysninger',
            state: {
                from: { path: '/', title: 'hjemmesiden' },
                isOnlySignup: true
            }
        });
    };

    const onLoginClick = () => {
        props.close();
        props.history.push({
            pathname: '/login',
            state: {
                modal: true
            }
        });
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <DialogComponent
                onClose={props.close}
                open={true}
                aria-labelledby="success"
                aria-describedby="success-description"
                disableRestoreFocus
                className="login-or-signup-dialog"
            >
                <CloseIcon onClick={() => props.close()}>&#10799;</CloseIcon>

                <DialogContent>
                    <Box mb="1rem" mt="1.5rem">
                        <TypographyText variant="subtitle1">
                            For at blive medlem, skal du have et login.
                        </TypographyText>
                    </Box>
                    <Box>
                        <TypographyText variant="subtitle1">
                            Log ind{' '}
                        </TypographyText>

                        <UnderlinedText
                            variant="subtitle1"
                            onClick={() => onLoginClick()}
                        >
                            her
                        </UnderlinedText>
                        <TypographyText variant="subtitle1">
                            , eller opret en profil{' '}
                        </TypographyText>
                        <UnderlinedText
                            variant="subtitle1"
                            onClick={() => onRegisterClick()}
                        >
                            her.
                        </UnderlinedText>
                    </Box>
                </DialogContent>
            </DialogComponent>
        </motion.div>
    );
}

export default withRouter(LoginOrSignupDialog);
