import { Dialog, DialogContent, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const DialogComponent = styled(Dialog)`
    position: relative;
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;

    & .MuiPaper-root {
        padding: 1rem !important;
    }

    & .MuiDialog-paperWidthSm {
        max-width: 900px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        height: 90%;
    }
`;

const Image = styled.img`
    width: 100%;
`;

const CloseIcon = styled(Typography)`
    font-size: 2rem;
    position: absolute;
    left: 10px;
    top: -6px;
    cursor: pointer;
    color: ${(props) => props.theme.palette.primary.light};

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        right: 10px;
        top: -6px;
    }
`;

function ImageDialog({ close, url, component, altText }) {
    const onDialogClick = () => {
        close();
    };
    console.log('should be open dialog');

    return (
        <DialogComponent
            onClose={close}
            open={true}
            aria-labelledby="lokale-tegning"
            aria-describedby="lokale-tegning"
            disableRestoreFocus
            onClick={() => onDialogClick()}
        >
            <CloseIcon onClick={() => close()}>&#10799;</CloseIcon>
            <DialogContent>
                {component ? (
                    <Image src={component} alt={altText} />
                ) : (
                    <Image src={url} alt={altText} />
                )}
            </DialogContent>
        </DialogComponent>
    );
}

export default ImageDialog;
