import React from 'react';
import { motion } from 'framer-motion';
import ContentContainer from '../../components/containers/ContentContainer';

import RegularHeadline from '../../components/headlines/RegularHeadline';
import TermsOfPurchase from '../../components/terms-of-purchase/TermsOfPurchase';
import HeadersContainer from '../../components/headers/headers-container/HeadersContainer';

function TermsPage(props) {
    return (
        <div>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.7 }}
            >
                <HeadersContainer />

                <ContentContainer>
                    <RegularHeadline title="Handelsbetingelser" />
                    <TermsOfPurchase />
                </ContentContainer>
            </motion.div>
        </div>
    );
}

export default TermsPage;
