// import { createMuiTheme } from "@material-ui/core/styles"; // Removed, since it gives the findDomNode warning: https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';

// Colors
const black = '#212020';
const lightBlack = '#1F1E1E';
const lighterBlack = '#2C2C2C';
const darkestBlack = '#000000';
const lightBlue = '#84ADED';
const white = '#FFFFFF';
const whiteSand = '#F2E5E3';
const gray = '#4A4A4A';
const orange = '#FC982B';
const blue = '#0532F2';
const green = '#1EA443';
const yellow = '#FAE830';
const purple = '#6b129a';

export const overridings = {
    name: 'Dark Theme',
    palette: {
        primary: {
            main: black,
            light: gray,
            contrastText: white
        },
        secondary: {
            main: lightBlack,
            light: lighterBlack,
            contrastText: white
        },
        background: {
            default: black,
            paper: lightBlack,
            appbar: lightBlue
        },
        colors: {
            black,
            white,
            whiteSand,
            orange,
            blue,
            green,
            yellow,
            purple,
            darkestBlack
        },

        type: 'dark'
    },
    typography: {
        allVariants: {
            fontFamily: 'Benton Sans Regular'
        },
        fontSize: 16,
        fontFamily: 'Benton Sans Regular',
        spacing: {
            gutterSmall: 2
        },
        body1: {
            '@media (max-width: 960px)': {
                fontSize: '0.9rem'
            }
        },
        body2: {
            '@media (max-width: 960px)': {
                fontSize: '0.9rem'
            }
        },
        h5: {
            '@media (max-width: 960px)': {
                fontSize: '1.4rem'
            }
        },
        overrides: {
            MuiCssBaseline: {
                // Use this only if we are self-hosting fonts
                '@global': {
                    '@font-face': [
                        'Benton Sans Regular',
                        'Benton Sans Italic',
                        'Benton Sans Medium',
                        // "Benton Sans Bold",
                        'calibri',
                        'Garamond',
                        'Comic Sans MS'
                    ].join(',')
                }
            }
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920
        }
    }
};

export default createMuiTheme(overridings);
