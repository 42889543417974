import createDataContext from './createDataContext';
import { getLocalStorageJson, setLocalStorageJson } from '../util';
import { getToken, getUserId } from './getData';
import { deleteAndReturnRespWithAuth } from './helperFunctions';

let key = document.location.hostname + ':state-history';
const initialState = {
    currentUser: {},
    isOpenPrivacy: false
};
const userReducer = (state, action) => {
    switch (action.type) {
        case 'SAVE_USER':
            return { ...state, currentUser: action.payload };
        case 'LOGOUT':
            return { ...state, currentUser: '' };
        case 'SAVE_LOCATION':
            return { ...state, location: action.payload };
        case 'SAVE_CONTROL_POINTS':
            return { ...state, controlPoints: action.payload };
        case 'SAVE_REGISTRATION_DATA':
            return { ...state, registrationData: action.payload };
        case 'SAVE_RATES_DATA':
            return { ...state, ratesData: action.payload };
        case 'SAVE_CUSTOM_FIRST_RATE':
            return { ...state, customFirstRate: action.payload };
        case 'SAVE_EVENTS':
            return { ...state, events: action.payload };
        case 'SAVE_EVENT_ID_TO_EDIT':
            return { ...state, eventIdToEdit: action.payload };
        case 'SAVE_ORDER':
            return { ...state, order: action.payload };
        case 'SAVE_NEW_USER':
            return { ...state, newUser: action.payload };
        case 'SAVE_IS_OPEN_PRIVACY':
            return { ...state, isOpenPrivacy: action.payload };
        case 'SAVE_ONLY_SIGNUP':
            return { ...state, isOnlySignup: action.payload };
        case 'SHOW_FULL_PROGRESS_BAR':
            return { ...state, isFullProgressBar: action.payload };
        case 'SAVE_ORDER_INFO':
            return { ...state, orderInfo: action.payload };
        case 'SAVE_LAST_ROOM_ID':
            return { ...state, lastRoomId: action.payload };
        case 'SAVE_CALENDAR_TYPE':
            return { ...state, calendarType: action.payload };
        case 'SAVE_SELECTED_ROOMS':
            return { ...state, selectedRooms: action.payload };
        case 'SAVE_IS_EXPANDED_DATEPICKER':
            return { ...state, isExpandedDatePicker: action.payload };
        case 'SAVE_PROFILE_IMAGE_URL':
            return { ...state, profileImageUrl: action.payload };
        case 'SAVE_IS_OFFLINE':
            return { ...state, isOffline: action.payload };
        case 'SAVE_HAVE_SEEN_RENOVATION_WARNING':
            return { ...state, haveSeenRenovationWarning: action.payload };

        case 'SAVE_RENEW_CARD':
            return { ...state, isRenewCard: action.payload };
        case 'SAVE_COMING_FROM_ROOM_NUMBER':
            return { ...state, comingFromRoomNumber: action.payload };
        default:
            return state;
    }
};

const saveUser = (dispatch) => {
    return async (userObject) => {
        // Set username to local storage
        const currentUser = {
            userFirstname: userObject.userFirstname,
            userEmail: userObject.userEmail,
            userId: userObject.userId,
            emailVerifiedAt: userObject.emailVerifiedAt,
            phoneVerifiedAt: userObject.phoneVerifiedAt,
            subscriber: userObject.subscriber,
            role: userObject.role,
            token: userObject.token,
            tokenExpires: userObject.tokenExpires,
            lastDayOfSubscription: userObject.lastDayOfSubscription,
            subscriptionDueDate: userObject.subscriptionDueDate,
            inLastMonthOfSubscription: userObject.inLastMonthOfSubscription,
            isProfilePublic: userObject.isProfilePublic
        };

        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            currentUser
        };

        setLocalStorageJson(key, updatedLocalStorageData);

        dispatch({ type: 'SAVE_USER', payload: userObject });
    };
};

const onLogout = (dispatch) => {
    return async () => {
        const userId = getUserId();
        const token = getToken();
        //  Delete the session from db
        if (userId && token) {
            await deleteAndReturnRespWithAuth(
                `/api/v1/users/${userId}/logout`,
                token,
                () => {}
            );
        }

        // Redirect user to home page - done from index.js- authenticated route.
        // If we are on a calendar page, we should refresh the page.
        if (window.location.href.includes('kalender')) {
            window.location.reload();
        }

        // Clear local storage
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            currentUser: null,
            order: null,
            orderInfo: null,
            imageUrl: null
        };
        setLocalStorageJson(key, updatedLocalStorageData);

        // Clear currentUser state
        dispatch({ type: 'LOGOUT' });
    };
};

// Save control points (Unilock)
const saveControlPoints = (dispatch) => {
    return async (controlPointsObject) => {
        // Set emailVerifiedAt/ phoneVerifiedAtto local storage

        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            controlPoints: {
                controlPoints: controlPointsObject
            }
        };
        setLocalStorageJson(key, updatedLocalStorageData);

        dispatch({ type: 'CONTROL_POINTS', payload: controlPointsObject });
    };
};

// should this be in a different context?
// const saveLocation = (dispatch) => {
//   return async (locationHistory) => {
//     console.log("Saving location history");

//     //Set username to cookies
//     document.cookie = 'locationHistory=${locationHistory}`;
//     // Save location to context
//     dispatch({ type: "SAVE_LOCATION", payload: locationHistory });
//   };
// };

const saveRegistrationData = (dispatch) => {
    return async (registrationData) => {
        const now = new Date();
        // 3 days in miliseconds
        const threeDays = 259200000;
        // Set expiry time
        registrationData.expiry = now.getTime() + threeDays;
        // Set registration data to local storage
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            registrationData
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        // Clear previous registrationData cookie
        // Save username to context state - Is this even needed?
        dispatch({ type: 'SAVE_REGISTRATION_DATA', payload: registrationData });
    };
};

const saveRatesData = (dispatch) => {
    return async (ratesData) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            ratesData
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({ type: 'SAVE_RATES_DATA', payload: ratesData });
    };
};

const saveCustomFirstRate = (dispatch) => {
    return async (customFirstRate) => {
        dispatch({ type: 'SAVE_CUSTOM_FIRST_RATE', payload: customFirstRate });
    };
};

// When user makes a booking, but have not paid, we want to save the ids of the events
// they created, because we need to list them under the calender, and they should be able
// to edit or delete them.
const saveEvents = (dispatch) => {
    return async (events) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            events
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({ type: 'SAVE_EVENTS', payload: events });
    };
};

const saveEventIdToEdit = (dispatch) => {
    return async (eventId) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            eventId
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({ type: 'SAVE_EVENT_ID_TO_EDIT', payload: eventId });
    };
};

const saveOrder = (dispatch) => {
    return async (order) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            order
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({ type: 'SAVE_ORDER', payload: order });
    };
};

const saveNewUser = (dispatch) => {
    return async (isNewUser) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            isNewUser
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({ type: 'SAVE_NEW_USER', payload: isNewUser });
    };
};

const saveIsOpenPrivacy = (dispatch) => {
    return async (isOpenPrivacy) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            isOpenPrivacy
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({ type: 'SAVE_IS_OPEN_PRIVACY', payload: isOpenPrivacy });
    };
};

const saveOnlySignup = (dispatch) => {
    return async (onlySignup) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            onlySignup
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({ type: 'SAVE_ONLY_SIGNUP', payload: onlySignup });
    };
};

// If isFullProgressBar in context is true, it means that the user just signed up, so we should
// show the oplysninger step in the progressbar too.
const showFullProgressBar = (dispatch) => {
    return async (isFullProgressBar) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            isFullProgressBar
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({
            type: 'SHOW_FULL_PROGRESS_BAR',
            payload: isFullProgressBar
        });
    };
};

const saveOrderInfo = (dispatch) => {
    return async (orderInfo) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            orderInfo
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({ type: 'SAVE_ORDER_INFO', payload: orderInfo });
    };
};

const saveLastRoomId = (dispatch) => {
    return async (roomId) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            roomId
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({ type: 'SAVE_LAST_ROOM_ID', payload: roomId });
    };
};

const saveCalendarType = (dispatch) => {
    return async (calendarType) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            calendarType
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({ type: 'SAVE_CALENDAR_TYPE', payload: calendarType });
    };
};

// This is used, when the user is going to calendar page from Lej lokale pages.
// This is in order to know where to send the user back, if they click on the return button
const saveComingFromRoomPage = (dispatch) => {
    return async (comingFromRoomNumber) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            comingFromRoomNumber
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({
            type: 'SAVE_COMING_FROM_ROOM_NUMBER',
            payload: comingFromRoomNumber
        });
    };
};

const saveSelectedRooms = (dispatch) => {
    return async (selectedRooms) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            selectedRooms
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({ type: 'SAVE_SELECTED_ROOMS', payload: selectedRooms });
    };
};

const saveIsExpandedDatePicker = (dispatch) => {
    return async (isExpandedDatePicker) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            isExpandedDatePicker
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({
            type: 'SAVE_IS_EXPANDED_DATEPICKER',
            payload: isExpandedDatePicker
        });
    };
};

const saveProfileImgUrl = (dispatch) => {
    return async (imageUrl) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            imageUrl
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({
            type: 'SAVE_PROFILE_IMAGE_URL',
            payload: imageUrl
        });
    };
};

const saveIsOffline = (dispatch) => {
    return async (isOffline) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            isOffline
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({
            type: 'SAVE_IS_OFFLINE',
            payload: isOffline
        });
    };
};

const saveHaveSeenRenovationWarning = (dispatch) => {
    return async (haveSeenRenovationWarning) => {
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            haveSeenRenovationWarning
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({
            type: 'SAVE_HAVE_SEEN_RENOVATION_WARNING',
            payload: haveSeenRenovationWarning
        });
    };
};

const saveIsRenewCard = (dispatch) => {
    return async (isRenewCard) => {
        console.log('renew save called', isRenewCard);
        const updatedLocalStorageData = {
            ...getLocalStorageJson(key, {}),
            isRenewCard
        };
        setLocalStorageJson(key, updatedLocalStorageData);
        dispatch({
            type: 'SAVE_RENEW_CARD',
            payload: isRenewCard
        });
    };
};

export const { Context, Provider } = createDataContext(
    userReducer,
    {
        saveUser,
        onLogout,
        saveControlPoints,
        // saveLocation,
        saveRegistrationData,
        saveRatesData,
        saveCustomFirstRate,
        saveEvents,
        saveEventIdToEdit,
        saveOrder,
        saveNewUser,
        saveIsOpenPrivacy,
        saveOnlySignup,
        showFullProgressBar,
        saveOrderInfo,
        saveLastRoomId,
        saveCalendarType,
        saveSelectedRooms,
        saveIsExpandedDatePicker,
        saveProfileImgUrl,
        saveIsOffline,
        saveHaveSeenRenovationWarning,
        saveIsRenewCard,
        saveComingFromRoomPage
    },
    initialState
);
