import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ForgotPasswordDialog from "../../components/dialogs/login-dialogs/ForgotPasswordDialog";
import DialogsBackground from "../../components/dialogs/DialogsBackground";

class ForgotPasswordPage extends Component {
  constructor() {
    super();
    this.state = {
      isAuthenticated: false,
    };
    this.modalRef = React.createRef();
  }

  render() {
    return (
      <React.Fragment>
        <React.Fragment>
          {this.props.isModal ? (
            <div
              ref={this.modalRef}
              onClick={() => this.props.history.goBack()}
            >
              <div onClick={(e) => e.stopPropagation()}>
                <ForgotPasswordDialog prevLocation={this.props.prevLocation} />
              </div>
            </div>
          ) : (
            <div>
              <DialogsBackground />
              <ForgotPasswordDialog
                fullPageView={true}
                prevLocation={this.props.prevLocation}
              />
            </div>
          )}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default withRouter(ForgotPasswordPage);
