import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { overridings } from '../../../themes/DarkTheme';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const ButtonContainer = styled(Box)`
    display: flex;
    position: relative;
    top: 10px;
`;

const VerticalLine = styled.span`
    width: 1px;
    height: 28px;
    background: white;
    margin-left: 10px;
    margin-right: 10px;
`;

const ButtonText = styled(Typography)`
    cursor: ${(props) => !props.isbuttonactive && 'pointer'};
    color: ${(props) =>
        props.isbuttonactive && overridings.palette.colors.green};
`;

function ActivityOrRoomCalendarButton(props) {
    const [activeButton, setActiveButton] = useState('activity');

    useEffect(() => {
        if (window.location.pathname.includes('booking')) {
            setActiveButton('booking');
        }
    }, []);

    const handleButtonClick = (buttonType) => {
        if (activeButton === 'activity' && buttonType === 'booking') {
            props.history.push('/booking-kalender');
        } else if (activeButton === 'booking' && buttonType === 'activity') {
            props.history.push('/aktivitets-kalender');
        }
    };

    return (
        <ButtonContainer>
            <ButtonText
                isbuttonactive={(activeButton === 'activity' && true?'true':'false')}
                variant="subtitle1"
                onClick={() => handleButtonClick('activity')}
            >
                Aktivitetsoversigt
            </ButtonText>
            <VerticalLine />
            <ButtonText
                isbuttonactive={(activeButton === 'booking' && true?'true':'false')}
                variant="subtitle1"
                onClick={() => handleButtonClick('booking')}
            >
                Reservationskalender
            </ButtonText>
        </ButtonContainer>
    );
}

export default withRouter(ActivityOrRoomCalendarButton);
