import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react';
import { slide as Menu } from 'react-burger-menu';
import styled from 'styled-components';
import {
    getOrder,
    getOwnEvents,
    getProfileImageFromStorage,
    getUserFirstnameHooks,
    getUserId,
    returnAuthenticated,
    getRole
} from '../../../context/getData';

import { Box, ClickAwayListener, Popper, Typography } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import MobileLogo from '../../../assets/logos/new-logo.png';

import { Context } from '../../../context/Context';
import NavHeadline from '../../headlines/NavHeadline';
import Avatar from '../../../assets/icons/avatar.png';
import OpenDoor from '../../open-door/OpenDoor';
import NavContainerMobile from './NavContainerMobile';
import BasketIcon from '../../../assets/icons/basket.png';
import {
    getAndReturnData,
    isLoginFromCalendar
} from '../../../context/helperFunctions';
import SaveToHomeScreen from '../../save-to-home-screen/SaveToHomeScreen';
import { getProfileImages } from '../../../containers/activities/ActivityFunctions';

const MobileHeaderContainer = styled(Box)`
    width: 85%;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 0.7rem;
    top: ${(props) => {
        if (props.offlinetextshown==='true') {
            return '32px';
        } else {
            return '0';
        }
    }};

    @media (min-width: 960px) {
        display: none;
    }

    @media (max-width: 370px) {
        width: 95%;
    }
`;

const MainContainer = styled(Box)`
    width: 100%;
    position: fixed;
    z-index: 10;
    transition: all 0.2s;
`;

const Logo = styled.img`
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 14rem;
        padding-left: 0.5rem;
    }
`;

const LoginContainer = styled(Box)`
    & a {
        text-decoration: none;
    }
`;

const MenuContainer = styled.div`
    & .bm-burger-button {
        top: ${(props) => {
            if (props.offlinetextshown) {
                return '55px';
            } else {
                return '19px';
            }
        }};
    }

    & .bm-overlay {
        display: none;
    }
`;

const LogoutLink = styled(Typography)`
    cursor: pointer;
    font-size: 1.4rem;
`;

const AvatarImg = styled.img`
    width: 2.5rem;
`;

const ProfileImageContainer = styled.img`
    width: 2.5rem;
    border-radius: 50%;
`;

const BasketIconElm = styled.img`
    width: 24px;
    height: 24px;
    margin-left: 20px;
    cursor: pointer;
`;

const Text = styled(Typography)`
    font-size: 1.4rem;
`;

const PopperText = styled.div`
    background-color: ${(props) => props.theme.palette.primary.light};
    color: ${(props) => props.theme.palette.primary.contrastText};
    padding: 0.5rem;
`;

function NavHeaderMobile(props) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userFirstname, setUserFirstname] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [profileImageUrl, setProfileImageUrl] = useState('');

    const basketRef = useRef();
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const context = useContext(Context);

    const handleClickAway = () => {
        setAnchorEl(null);
    };

    const handleLogoutClick = () => {
        context.onLogout();
    };

    const onBasketIconClick = async () => {
        // Check if user has anything in cart
        // if yes, take them to the opsummering page
        const ownEventsFromDb = await getOwnEvents();
        const subscriptionOrder = getOrder();

        if (
            ownEventsFromDb.length > 0 ||
            subscriptionOrder.subscription === true
        ) {
            // Has something in cart, take them to the checkout page
            const currentPath = window.location.pathname;
            props.history.push({
                pathname: '/opsummering',
                state: {
                    from: {
                        path: `${currentPath}`
                    }
                }
            });
        } else {
            setAnchorEl(anchorEl ? null : basketRef.current);
        }
    };

    const handleProfileImages = useCallback(async () => {
        const userId = getUserId();
        if (!userId) return;
        const userData = await getAndReturnData(
            `${window.location.origin}/api/v1/public-activity/${userId}`,
            (err) => console.log('Error when loading userInfo,', err)
        );
        if (userData) {
            const profileImgs = await getProfileImages(
                userData.imageInfo,
                userId
            );

            // Save the first img url to local storage
            if (profileImgs && profileImgs.length > 0) {
                context.saveProfileImgUrl(profileImgs[0]);
                setProfileImageUrl(profileImgs[0]);
            }
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        let windowHeight = window.scrollY;
        if (windowHeight > 10) {
            // setShouldStick(true);
        } else {
            // setShouldStick(false);
        }
        //eslint-disable-next-line
    }, [window.scrollY]);

    useEffect(() => {
        getUserFirstnameHooks(setIsAuthenticated, setUserFirstname);
    }, [context.state.currentUser]);

    // ComponentDidMount
    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            const mainContainerElm = document.querySelector(
                '.nav-header-mobile-main-container'
            );
            if (mainContainerElm) {
                if (position > 5) {
                    mainContainerElm.style.background = 'black';
                } else {
                    mainContainerElm.style.background = 'transparent';
                }
            }
        };

        const profileImgUrlFromStorage = getProfileImageFromStorage();

        if (profileImgUrlFromStorage) {
            setProfileImageUrl(profileImgUrlFromStorage);
        } else {
            handleProfileImages();
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleProfileImages]);

    return (
        <MainContainer className="nav-header-mobile-main-container">
            <MobileHeaderContainer
                display="flex"
                offlinetextshown={(props.offlineTextShown?'true':'false')}
            >
                <Box>
                    <Link to="/">
                        <Logo src={MobileLogo} alt="Logo of Bulowsvej10" />
                    </Link>
                </Box>

                {returnAuthenticated() && (
                    <div>
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <BasketIconElm
                                onClick={() => onBasketIconClick()}
                                src={BasketIcon}
                                ref={basketRef}
                            />
                        </ClickAwayListener>
                        <Popper id={id} open={open} anchorEl={anchorEl}>
                            <PopperText>Din kurv er tom</PopperText>
                        </Popper>
                    </div>
                )}

                <MenuContainer
                    offlinetextshown={props.offlineTextShown}
                    className="menu-container"
                >
                    <Menu
                        pageWrapId={'page-wrap'}
                        outerContainerId={'outer-container'}
                        right
                        width={'100%'}
                    >
                        <NavContainerMobile>
                            <LoginContainer>
                                {isAuthenticated && (
                                    <Box width="fit-content">
                                        <OpenDoor />
                                    </Box>
                                )}

                                <SaveToHomeScreen
                                    prompt={props.prompt}
                                    promptToInstall={props.promptToInstall}
                                    isVisible={props.isVisible}
                                    setVisibleState={props.setVisibleState}
                                    setIsFirefox={props.setIsFirefox}
                                    isFirefox={props.setIsFirefox}
                                />

                                {isAuthenticated ? (
                                    <Box mt="2rem">
                                        <Box display="flex" mb="1rem">
                                            <Box>
                                                {profileImageUrl &&
                                                profileImageUrl !== '' ? (
                                                    <ProfileImageContainer
                                                        src={profileImageUrl}
                                                        alt="Avatar"
                                                    />
                                                ) : (
                                                    <AvatarImg
                                                        src={Avatar}
                                                        alt="Avatar"
                                                    />
                                                )}
                                            </Box>
                                            <Box ml="1rem" mt=".5rem">
                                                <Text variant="subtitle1">
                                                    {userFirstname}
                                                </Text>
                                            </Box>
                                        </Box>

                                        <Box width="fit-content">
                                            <a href="/min-side">
                                                <Text variant="subtitle1">
                                                    Min side
                                                </Text>
                                            </a>
                                        </Box>

                                        {(['admin','superuser'].indexOf(getRole())>=0) &&  
                                            <Box width="fit-content">
                                                <a href="/admin/dashboard">
                                                    <Text variant="subtitle1">
                                                        Admin dashboard
                                                    </Text>
                                                </a>
                                            </Box>
                                        }


                                        <Box width="fit-content">
                                            <a href="/opsummering">
                                                <Text variant="subtitle1">
                                                    Min kurv
                                                </Text>
                                            </a>
                                        </Box>

                                        <Box width="fit-content">
                                            <LogoutLink
                                                variant="subtitle1"
                                                onClick={() =>
                                                    handleLogoutClick()
                                                }
                                            >
                                                Log ud
                                            </LogoutLink>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box mt="1.5rem">
                                        <Link
                                            className="frontpage-job"
                                            to={{
                                                pathname: '/login',
                                                state: {
                                                    modal: true,
                                                    fromCalendar: isLoginFromCalendar()
                                                }
                                            }}
                                        >
                                            <NavHeadline title="Log ind" />
                                        </Link>
                                    </Box>
                                )}
                            </LoginContainer>
                        </NavContainerMobile>
                    </Menu>
                </MenuContainer>
            </MobileHeaderContainer>
        </MainContainer>
    );
}

export default withRouter(NavHeaderMobile);
