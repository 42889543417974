import React, { Component } from 'react';
import { Context } from '../../../context/Context';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';

import Avatar from '../../../assets/icons/avatar.png';
import OpenDoor from '../../open-door/OpenDoor';
import SaveToHomeScreen from '../../save-to-home-screen/SaveToHomeScreen';
import RegularButton from '../../buttons/regular-button/RegularButton';
import { overridings } from '../../../themes/DarkTheme';
import { getProfileImages } from '../../../containers/activities/ActivityFunctions';
import { getAndReturnData } from '../../../context/helperFunctions';
import {
    getProfileImageFromStorage,
    getUserId,
    getRole
} from '../../../context/getData';

const ProfileDialogContainer = styled.div`
    box-shadow: inset 0 0 5px grey;
    width: 350px;
    padding: 28px 30px 33px;
    border-radius: 5px;
    position: absolute;
    right: 0;
    z-index: 10;
    background-color: ${(props) => props.theme.palette.primary.main};
`;

const LinkContainer = styled(Box)`
    width: 100%;
`;

const UserFirstname = styled(Typography)``;

const AvatarImg = styled.img`
    width: 2.5rem;
`;

const ProfileImgContainer = styled.img`
    width: 2.5rem;
    border-radius: 50%;
`;

const LogoutLink = styled(Typography)`
    cursor: pointer;
`;

// The reason why this is in a class component is the clickaway listener
class ProfileDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setIsFirefox: false,
            profileImageUrl: ''
        };
    }
    static contextType = Context;

    handleLogoutClick = () => {
        this.context.onLogout();
    };

    hideButtonInFirefox = () => {
        const firefoxBrowser = /Firefox/i.test(navigator.userAgent);

        if (firefoxBrowser) {
            this.setState({ isFirefox: true });
        }
    };

    onPwaClick = () => {
        if (prompt) {
            console.log('prompt is this', prompt);
            prompt.prompt();
        } else {
            console.log('theres no prompt', prompt);
        }
    };

    handleProfileImages = async () => {
        const userId = getUserId();
        if (!userId) return;
        const userData = await getAndReturnData(
            `${window.location.origin}/api/v1/public-activity/${userId}`,
            (err) => console.log('Error when loading userInfo,', err)
        );
        if (userData) {
            const profileImgs = await getProfileImages(
                userData.imageInfo,
                userId
            );

            // Save the first img url to local storage
            if (profileImgs && profileImgs.length > 0) {
                this.context.saveProfileImgUrl(profileImgs[0]);
                this.setState({ profileImageUrl: profileImgs[0] });
            }
        }
    };

    componentDidMount() {
        this.hideButtonInFirefox();
        const profileImgUrl = getProfileImageFromStorage();

        if (profileImgUrl) {
            this.setState({ profileImageUrl: profileImgUrl });
        } else {
            this.handleProfileImages();
        }
    }

    render() {
        return (
            <ProfileDialogContainer>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb="20px"
                >
                    <Box display="flex">
                        <Box>
                            {this.state.profileImageUrl &&
                            this.state.profileImageUrl !== '' ? (
                                <ProfileImgContainer
                                    src={this.state.profileImageUrl}
                                />
                            ) : (
                                <AvatarImg src={Avatar} />
                            )}
                        </Box>
                        <Box ml="1rem" mt=".5rem">
                            <UserFirstname variant="subtitle1">
                                {this.props.userFirstname}
                            </UserFirstname>
                        </Box>
                    </Box>

                    <Box width="fit-content">
                        <LogoutLink
                            variant="body2"
                            onClick={() => this.handleLogoutClick()}
                        >
                            Log ud
                        </LogoutLink>
                    </Box>
                </Box>
                <RegularButton
                    title="Min side"
                    background={overridings.palette.primary.contrastText}
                    color={overridings.palette.primary.main}
                    onButtonClick={() => {
                        this.props.closeProfileDialog();
                        this.props.history.push('/min-side');
                    }}
                    margin="0 0 15px"
                    hoverColor={overridings.palette.primary.contrastText}
                />

                {(['admin','superuser'].indexOf(getRole())>=0) &&  
                    <RegularButton
                    title="Admin dashboard"
                    background={overridings.palette.primary.contrastText}
                    color={overridings.palette.primary.main}
                    onButtonClick={() => {
                        this.props.closeProfileDialog();
                        this.props.history.push('/admin/dashboard');
                    }}
                    margin="0 0 15px"
                    hoverColor={overridings.palette.primary.contrastText}
                />            
                }


                <Box width="100%" className="open-door-container" mb="8px">
                    <OpenDoor customWidth="100%" />
                </Box>

                <LinkContainer>
                    {!this.state.isFirefox && (
                        <SaveToHomeScreen
                            prompt={this.props.prompt}
                            promptToInstall={this.props.promptToInstall}
                            // isVisible={this.props.isVisible}
                            isVisible={true}
                            setVisibleState={this.props.setVisibleState}
                            setIsFirefox={this.props.setIsFirefox}
                            isFirefox={this.props.setIsFirefox}
                            variant="body2"
                        />
                    )}
                </LinkContainer>
            </ProfileDialogContainer>
        );
    }
}

export default withRouter(ProfileDialog);
