import React from "react";
import { Box } from "@material-ui/core";
import styled from "styled-components";

const QABoxesContainerComponent = styled(Box)`
  margin-top: 2rem;
`;

function QABoxesContainer({ children }) {
  return <QABoxesContainerComponent>{children}</QABoxesContainerComponent>;
}

export default QABoxesContainer;
