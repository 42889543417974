import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';

import ChevronDownIcon from '../icons/chevron-down-icon/ChevronDownIcon';
import styled from 'styled-components';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import NewSubscriptionOnlyText from './NewSubscriptionOnlyText';

const RulesTitle = styled(Box)`
    cursor: pointer;
    width: fit-content;
`;

const ChevronUpContainer = styled.span`
    & .icon-tabler {
        transform: rotate(180deg);
    }

    position: relative;
    top: 5px;
    margin-left: 6px;
`;

const ChevronDownContainer = styled.span`
    position: relative;
    top: 5px;
    margin-left: 6px;
`;

const UnderlinedText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;

function NewEventsAndSubscriptionText({ setShowQuestionMarkDialog }) {
    const [isEventRulesSectionOpen, setIsEventRulesSectionOpen] = useState(
        false
    );
    const [isPaymentRulesSectionOpen, setIsPaymentRulesSectionOpen] = useState(
        false
    );

    return (
        <div>
            <NewSubscriptionOnlyText />
            <Box mt="1rem" mb="1rem">
                <RulesTitle
                    display="flex"
                    onClick={() => {
                        setIsPaymentRulesSectionOpen(
                            !isPaymentRulesSectionOpen
                        );

                        if (isEventRulesSectionOpen) {
                            setIsEventRulesSectionOpen(false);
                        }
                    }}
                >
                    <Typography variant="subtitle1">
                        Betalings- og annulleringsregler:
                    </Typography>{' '}
                    {isPaymentRulesSectionOpen ? (
                        <ChevronUpContainer>
                            <ChevronDownIcon />
                        </ChevronUpContainer>
                    ) : (
                        <ChevronDownContainer>
                            <ChevronDownIcon />
                        </ChevronDownContainer>
                    )}
                </RulesTitle>
            </Box>
            {isPaymentRulesSectionOpen && (
                <>
                    <Box>
                        <Typography variant="body2">
                            Forbrug faktureres månedsvis bagud og forfalder til
                            betaling den 1. i måneden.
                            <br />
                        </Typography>
                        <Typography variant="body2">
                            Alle reservationer kan til hver en tid annulleres.
                            <br />
                            Ved annullering betales fra 0 til 50% af
                            reservationsprisen:
                        </Typography>
                        <ul>
                            <li>
                                Medlemmer der annullerer mere end en uge før
                                reservationstidspunktet betaler 0% når
                                reservationen annulleres
                            </li>
                            <li>
                                Medlemmer der annullerer mindre end en uge før
                                reservationstidspunktet betaler 25% når
                                reservationen annulleres
                            </li>
                            <li>
                                Ikke-medlemmer der annullerer mere end en uge
                                før reservationstidspunktet betaler 25% når
                                reservationen annulleres
                            </li>
                            <li>
                                Ikke-medlemmer der annullerer mindre end en uge
                                før reservationstidspunktet betaler 50% når
                                reservationen annulleres
                            </li>
                        </ul>
                    </Box>
                    <Box>
                        <Typography variant="body2">
                            Se eller rediger dine kommende reservationer på{' '}
                            <UnderlinedText>
                                <Link
                                    to="/min-side/reservationer"
                                    target="_blank"
                                >
                                    Min side.
                                </Link>
                            </UnderlinedText>
                        </Typography>
                    </Box>
                </>
            )}
        </div>
    );
}

export default NewEventsAndSubscriptionText;
