import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ContentContainer from '../../components/containers/ContentContainer';
import ReturnLinkReusable from '../../components/links/ReturnLinkReusable';
import RoomDescription from '../../components/room-desription/RoomDescription';
import { motion } from 'framer-motion';
import HeadersContainer from '../../components/headers/headers-container/HeadersContainer';
import { Box, FormControl, Select, Typography } from '@material-ui/core';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import {
    getLocationNameFromRoomId,
    getProperRoomId
} from '../../context/getData';

const FlexContainer = styled(Box)`
    display: flex;
    justify-content: space-between;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
        margin-bottom: 2rem;
    }
`;

const SelectContainer = styled(Box)`
    display: block;
    @media (min-width: 960px) {
        display: flex;
        align-items: baseline;
    }
`;

const SelectText = styled(Typography)`
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    @media (min-width: 960px) {
        margin-bottom: 0;
    }
`;

const MuiFormControl = styled(FormControl)``;

const MuiSelect = styled(Select)`
    border-radius: 0;
    min-width: 9rem;
    @media (min-width: 960px) {
        margin-top: 1rem;
    }

    & .MuiOutlinedInput-input {
        padding: 0.8rem 1rem 0.6rem 1rem;
        @media (min-width: 960px) {
            min-width: 10rem;
        }
    }
`;

function RoomPage(props) {
    const [selectedRoom, setSelectedRoom] = useState('9');

    const returnLinkDestination = () => {
        if (
            props &&
            props.location &&
            props.location.state &&
            props.location.state.from &&
            props.location.state.from.title
        ) {
            return props.location.state.from.title;
        } else {
            return 'Lokaleoversigt';
        }
    };

    const returnLinkUrl = () => {
        if (
            props &&
            props.location &&
            props.location.state &&
            props.location.state.from &&
            props.location.state.from.path
        ) {
            return props.location.state.from.path;
        } else {
            return '/lokaler';
        }
    };

    const handleRoomChange = (e) => {
        setSelectedRoom(e.target.value);
        props.history.push(`/lokale/${getProperRoomId(e.target.value)}`);
    };

    useEffect(() => {
        // Set the roomSelect to the selected room name
        const path = window.location.pathname;
        const pathParts = path.split('/');
        const roomId = Number(pathParts[2]);
        const roomNumber = getLocationNameFromRoomId(roomId);
        setSelectedRoom(roomNumber);
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <HeadersContainer />

            <ContentContainer>
                <FlexContainer>
                    <ReturnLinkReusable
                        destination={returnLinkDestination()}
                        url={returnLinkUrl()}
                    />

                    <Box>
                        <SelectContainer>
                            <SelectText>Skift lokale:</SelectText>
                            <MuiFormControl variant="outlined">
                                <MuiSelect
                                    onChange={handleRoomChange}
                                    value={selectedRoom}
                                >
                                    <MenuItem value="9">Bülowsvej 10</MenuItem>
                                    <MenuItem value="1">Lokale 1</MenuItem>
                                    <MenuItem value="2">Lokale 2</MenuItem>
                                    <MenuItem value="3">Lokale 3</MenuItem>
                                    <MenuItem value="4">Lokale 4</MenuItem>
                                    <MenuItem value="5">Lokale 5</MenuItem>
                                    <MenuItem value="6">Lokale 6</MenuItem>
                                    <MenuItem value="7">Lokale 7</MenuItem>
                                    <MenuItem value="8">Lokale 8</MenuItem>
                                </MuiSelect>
                            </MuiFormControl>
                        </SelectContainer>
                    </Box>
                </FlexContainer>

                <RoomDescription />
            </ContentContainer>
        </motion.div>
    );
}

export default withRouter(RoomPage);
