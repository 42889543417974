import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import da from 'date-fns/locale/da';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import '../../index.css';

registerLocale('da', da);

const Container = styled.div`
    @media (max-width: 960px) {
        .react-datepicker-popper {
            padding-top: 0px;
        }
    }

    .react-datepicker-popper {
        z-index: 9999 !important;
    }
`;

function DatePickerComponent({ enddate, setEnddate }) {
    return (
        <Container>
            <DatePicker
                portalId="root-portal"
                dateFormat="dd/MM/yyyy"
                locale="da"
                selected={enddate}
                onChange={(date) => setEnddate(date)}
            />
        </Container>
    );
}

export default DatePickerComponent;

// import React from "react";
// import DatePicker, { registerLocale } from "react-datepicker";
// import da from "date-fns/locale/da";
// import styled from "styled-components";
// import "react-datepicker/dist/react-datepicker.css";
// // import "../../index.css";

// registerLocale("da", da);
// const Container= styled.div``
// const DatePickerComp= styled(DatePicker)`
// `
// function DatePickerComponent({ enddate, setEnddate }) {
//   return (
//     //  <Container>
//       <DatePickerComp
//       dateFormat="dd/MM/yyyy"
//         locale="da"
//         selected={enddate}
//         onChange={(date) => setEnddate(date)}
//       />
//     // </Container>
//   );
// }

// export default DatePickerComponent;
