import React from 'react';
import styled from 'styled-components';

const StaircaseImageComponent = styled.img`
    width: ${(props) => (props.width ? props.width : 1)}rem;
    height: ${(props) => (props.height ? props.width : '1rem')};

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: ${(props) => (props.mobileWidth ? props.mobileWidth : 1)}rem;
        height: ${(props) =>
            props.mobileHeight ? props.mobileHeight : '1rem'};
    }
`;

function StaircaseImage({
    src,
    alt,
    width,
    height,
    customClassName,
    mobileWidth,
    mobileHeight
}) {
    return (
        <StaircaseImageComponent
            src={src}
            alt={alt}
            width={width}
            height={height}
            className={customClassName ? customClassName : 'original-stairs'}
            mobileWidth={mobileWidth}
            mobileHeight={mobileHeight}
        />
    );
}

export default StaircaseImage;
