export const minutes = [
  { title: "00", value: 0 },
  { title: "15", value: 15 },
  { title: "30", value: 30 },
  { title: "45", value: 45 },
];

export const hours = [
  { title: "08", numberFormat: 8 },
  { title: "09", numberFormat: 9 },
  { title: "10", numberFormat: 10 },
  { title: "11", numberFormat: 11 },
  { title: "12", numberFormat: 12 },
  { title: "13", numberFormat: 13 },
  { title: "14", numberFormat: 14 },
  { title: "15", numberFormat: 15 },
  { title: "16", numberFormat: 16 },
  { title: "17", numberFormat: 17 },
  { title: "18", numberFormat: 18 },
  { title: "19", numberFormat: 19 },
  { title: "20", numberFormat: 20 },
  { title: "21", numberFormat: 21 },
  { title: "22", numberFormat: 22 },
  { title: "23", numberFormat: 23 },
  { title: "24", numberFormat: 24 },
];
