import React from "react";
import styled from "styled-components";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import CookieRules from "../../cookie-rules/CookieRules";

const CustomDialogContent = styled(DialogContent)`
  overflow: hidden;
  @media (max-width: 350px) {
    padding: 8px 10px;
  }
`;

const DialogComponent = styled(Dialog)`
  position: relative;
  & .MuiPaper-root {
    padding: 1rem !important;
    @media (max-width: 350px) {
      padding: 10px 0px !important;
    }
  }
  & .MuiTypography-h6 {
    @media (max-width: 350px) {
      font-size: 1.1rem;
    }
  }
  .MuiDialogTitle-root {
    @media (max-width: 350px) {
      padding: 8px 10px;
    }
  }
`;

function CookieDialog({ close }) {
  return (
    <DialogComponent
      onClose={close}
      open={true}
      aria-labelledby="cookie-politik"
      aria-describedby="cookie-politik"
      disableRestoreFocus
    >
      <DialogTitle id="cookie-politik">Cookiepolitik</DialogTitle>
      <CustomDialogContent>
        <DialogContentText id="cookie-politik"></DialogContentText>
        <CookieRules isDialog={true} />
      </CustomDialogContent>
      <DialogActions>
        <Button onClick={() => close()}>Ok</Button>
      </DialogActions>
    </DialogComponent>
  );
}

export default CookieDialog;
