import React, { useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import { getLocationNameFromRoomId } from '../../../context/getData';
import { withRouter } from 'react-router-dom';
import { Context } from '../../../context/Context';
import RegistrationButton from '../../buttons/registration-button/RegistrationButton';
import moment from 'moment';
import { capitalize } from '../../../context/helperFunctions';

const DialogComponent = styled(Dialog)`
    & .MuiPaper-root {
        padding: 1rem !important;
    }
`;

const DialogActionsElm = styled(DialogActions)`
    display: flex;
    justify-content: space-between;

    @media (max-width: 450px) {
        display: block;
    }
`;

function SuccessBookingDialog(props) {
    const context = useContext(Context);

    const goToCheckout = () => {
        // Save roomId, so if the user navigates back, this should be loaded
        context.saveLastRoomId(props.roomId);

        props.history.push('/opsummering');
    };

    // ComponentDidMount
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <DialogComponent
                onClose={props.close}
                open={true}
                aria-labelledby="success"
                aria-describedby="success-description"
                disableRestoreFocus
            >
                {/* English text */}
                <DialogTitle id="success">
                    Din reservation er nu lagt i kurven
                </DialogTitle>

                <DialogContent>
                    <DialogContentText id="success-description">
                        Lokale{' '}
                        {getLocationNameFromRoomId(props.events[0].roomId)} :
                    </DialogContentText>
                    <ul>
                        {props.events.map((event) => (
                            <li key={event.id}>
                                {event.title}{' '}
                                {capitalize(
                                    moment(event.startdate).format('dddd')
                                )}{' '}
                                {moment(event.startdate).format('DD/MM/YY')}{' '}
                                {event.starttime.substr(0, 2) +
                                    '.' +
                                    event.starttime.substr(3, 2)}
                                -
                                {event.endtime.substr(0, 2) +
                                    '.' +
                                    event.endtime.substr(3, 2)}
                            </li>
                        ))}
                    </ul>
                </DialogContent>

                <DialogActionsElm>
                    <RegistrationButton
                        onButtonClick={() => props.close()}
                        title="Tilføj mere"
                        minWidthDesktop="150px"
                        mobileMargin="2rem auto!important"
                        mobileWidth="100%"
                    />

                    <RegistrationButton
                        onButtonClick={() => goToCheckout()}
                        title="Se kurv"
                        minWidthDesktop="150px"
                        mobileMargin="2rem auto!important"
                        mobileWidth="100%"
                    />
                </DialogActionsElm>
            </DialogComponent>
        </motion.div>
    );
}

export default withRouter(SuccessBookingDialog);
