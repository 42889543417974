import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import PersonalDataDialog from '../dialogs/personal-data-dialog/PersonalDataDialog';
const OuterContainer = styled.div`
    position: relative;
    margin-bottom: 2rem;
`;
const UnderlinedText = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;
const TermsOfPurchaseContainer = styled.div`
    height: 25rem;

    overflow: scroll;
    overflow-x: hidden;

    & :last-child {
        padding-bottom: 3rem;
    }
`;

const GradientOverlay = styled.div`
    height: 6rem;
    width: 100%;
    background-image: linear-gradient(rgba(21, 19, 19, 0), rgba(21, 19, 19, 1));
    position: absolute;
    bottom: 0;
`;
//
const Text = styled(Typography)`
    padding: 0.3rem 0rem;
    width: 75%;
    font-size: 1rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 90%;
        font-size: 0.8rem;
    }
`;
const TextTitle = styled(Typography)`
    font-size: 1.2rem;
    padding: 0.8rem 0rem 0.1rem 0rem;
    color: ${(props) => props.theme.palette.colors.orange};
    width: 80%;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 90%;
        font-size: 1rem;
    }
`;

const SubTitle = styled(Typography)`
    font-size: 1.2rem;
    padding: 0.3rem 0rem 0.1rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        font-size: 1rem;
    }
`;
const LinkText = styled.a`
    text-decoration: underline;
`;
const OrderedList = styled.ol`
    @media (max-width: 350px) {
        padding: 13px;
    }
`;

const BoldText = styled(Typography)`
    font-weight: bold;
`;

const UnorderedList = styled.ul`
    @media (max-width: 350px) {
        padding: 13px;
    }
`;
const ListItem = styled.li`
    width: 70%;
    font-size: 1rem;
    padding: 0.5rem 0rem;
    margin-bottom: 0rem !important;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        font-size: 0.8rem;
    }
    @media (max-width: 350px) {
        width: 100%;
    }
`;

const UnderlineOnly = styled.span`
    text-decoration: underline;
`;

function TermsOfPurchase({ isDialog }) {
    const [isPersonalDataDialogOpen, setIsPersonalDataDialogOpen] = useState(
        false
    );
    const showPersonalDataRules = () => {
        setIsPersonalDataDialogOpen(true);
    };
    return (
        <OuterContainer>
            {isDialog ? (
                <>
                    <TermsOfPurchaseContainer>
                        <SubTitle gutterBottom>
                            Dette websted{' '}
                            <LinkText
                                target="_blank"
                                rel="noopener noreferrer"
                                href="bulowsvej10.dk"
                            >
                                {' '}
                                bulowsvej10.dk
                            </LinkText>{' '}
                            ejes og drives af:
                        </SubTitle>
                        <Text gutterBottom>
                            Den Ny Musikskole ApS <br /> Bülowsvej 10
                            <br /> 1870 Frederiksberg
                            <br /> CVR.nr.: 33382898
                            <br /> Telefon: 30 14 01 23 <br />
                            <LinkText href="mailto:kontakt@bulowsvej10.dk">
                                kontakt@bulowsvej10.dk
                            </LinkText>
                        </Text>
                        <TextTitle variant="body2" gutterBottom>
                            Medlemskab og lokaleleje
                        </TextTitle>

                        <Text gutterBottom>
                            På{' '}
                            <LinkText
                                target="_blank"
                                rel="noopener noreferrer"
                                href="bulowsvej10.dk"
                            >
                                bulowsvej10.dk
                            </LinkText>{' '}
                            kan du oprette et medlemskab, som giver dig adgang
                            til alle ledige lokaler, der er angivet på
                            lokaleoversigten, 24/7 i hele medlemsperioden.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Du kan også reservere lokaler i en selvvalgt
                            tidsperiode. Lokalereservation kan kombineres med
                            medlemskab, men det er ikke et krav medmindre du
                            ønsker at være andre end dig selv i lokalet.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Lokaleleje kombineret med medlemskab giver 50% rabat
                            på lokalelejen.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Lokalerne koster 5 kr. per m2 i timen. Den mindste
                            tidsblok lokalet kan lejes er 15 min. Lejeren kan
                            først tage lokalet i brug fra lejeperiodens start og
                            skal have forladt lokalet helt ved lejeperiodens
                            udløb (back to back).
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Betaling, opsigelse og misligholdelse
                        </TextTitle>

                        <Text variant="subtitle1" gutterBottom>
                            Medlemskab
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Medlemskabet betales månedsvis forud den første dag
                            i måneden, og løber indtil det opsiges. Medlemskabet
                            kan altid opsiges til udgangen af igangværende
                            måned.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Opsigelse skal ske via{' '}
                            <LinkText
                                href="/min-side"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Min side.
                            </LinkText>{' '}
                            (tryk på punktet ”Medlemskab” i menuen og derefter
                            knappen ”Opsig medlemskab”). Opsigelsen skal være
                            foretaget senest 24 timer før en ny medlemsperiode
                            påbegyndes. Medlemskabet bevares naturligvis resten
                            af måneden uanset hvornår der opsiges.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Ved første tilmelding beregner systemet en relativ
                            betaling fra tilmeldingsdatoen til sidste dag i den
                            pågældende måned. Dette beløb trækkes med det samme
                            der trykkes på betal knappen. Derefter trækkes der
                            kun betaling den første.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            NB! I opsummeringen før betalingen vises kun
                            betalingen for den kommende måneds medlemskab,
                            selvom medlemskabet automatisk fornyes indtil det
                            opsiges.
                        </Text>

                        <Text variant="subtitle1" gutterBottom>
                            Lokaleleje
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Lokaleleje betales månedsvis bagud den første dag i
                            den følgende måned.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Der reserveres den fulde pris for den samlede
                            lokaleleje på dit kort. Og der betales kun for den
                            forbrugte lokaleleje i den forrige måned.
                            <br />I opsummeringen for lokalelejen vises både det
                            fulde beløb og rabatterne for reservationer måned
                            for måned.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            OBS! Rabatterne aktiveres kun, hvis du oprettede
                            medlemskab eller allerede var medlem da du oprettede
                            reservationen, og medlemskabet opretholdes i hele
                            perioden til og med den sidste registrerede
                            lokaleleje.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Opsigelse af medlemskab vil medføre bortfald af
                            rabatterne. Oprettelse af medlemskab efter en
                            reservation vil ikke give mulighed for rabat.
                        </Text>

                        <Text variant="subtitle1" gutterBottom>
                            Annulleringsregler
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Du kan altid annullere en lokalereservation, det vil
                            dog koste dig mellem 0 og 50 % af lokalelejen, alt
                            efter hvornår du annullerer og om du er medlem eller
                            ej.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Hvis du annullerer{' '}
                            <UnderlineOnly>mere</UnderlineOnly> end én uge før
                            lejetidspunktet, opkræves medlemmer ingen leje, ikke
                            medlemmer opkræves 25% af lejen.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Hvis du annullerer{' '}
                            <UnderlineOnly>mindre</UnderlineOnly> end én uge før
                            lejetidspunktet, opkræves medlemmer 25% af lejen,
                            ikke medlemmer opkræves 50% af lejen.
                        </Text>

                        <Text variant="subtitle1" gutterBottom>
                            Generelt
                        </Text>

                        <Text variant="body2" gutterBottom>
                            Vi accepterer betaling fra Dankort (via{' '}
                            <LinkText
                                href="https://payments.nets.eu/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                NETS
                            </LinkText>{' '}
                            ), Visa og MasterCard (via{' '}
                            <LinkText
                                href="https://www.clearhaus.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Clearhouse
                            </LinkText>
                            ) . Vi opkræver ikke kreditkortgebyrer. Alle priser
                            er angivet i DKK.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Rent teknisk oprettes både medlemskab og
                            lokalereservationer som et såkaldt abonnement hos
                            vores betalingsudbyder QuickPay.{' '}
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Efter at købet er gennemført, får du kvittering for
                            købet, tilsendt på mail. Der kan gå op til 24 timer.
                            Hvis du ikke har modtaget en e-mail fra os efter 24
                            timer, skal du tjekke dit spamfilter eller kontakte
                            os på e-mail: kontakt@bulowsvej10.dk.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Du kan til hver en tid få et samlet overblik over
                            alle dine lokalereservationer inde på{' '}
                            <LinkText
                                href="/min-side"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Min side.
                            </LinkText>
                        </Text>

                        <Text variant="subtitle1" gutterBottom>
                            Øvrigt
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Du skal være fyldt 18 år for at handle hos os. Er du
                            under 18 år, skal du have samtykke fra en forælder
                            eller værge.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Et køb kan altid annulleres af en forælder eller
                            værge, hvis det skulle vise sig, at den er blevet
                            indgået af et barn eller en person under 18 år.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Købsaftalen indgås på dansk. Den er juridisk
                            bindende, når du har modtaget ordrebekræftelsen.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Medlemskab og lokaleleje er personligt og må ikke
                            overdrages eller udlånes til andre – hverken helt
                            eller delvist.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Medlemmet/lejeren skal{' '}
                            <UnderlineOnly>altid</UnderlineOnly> selv være
                            fysisk til stede, når udlejningslokaler og
                            fællesarealer tages i brug.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Et brud på dette,anses som misligholdelse af
                            aftalen, og kan medføre øjeblikkelig bortvisning af
                            medlemmet/lejeren, uden økonomisk kompensation for
                            allerede betalt medlemskab/leje. Det samme gælder
                            ved udøvelse af hærværk på lokaler, inventar eller
                            bygningen i øvrigt.
                        </Text>

                        {/* <TextTitle variant="body2" gutterBottom>
                            Parkering
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Biler kan parkeres i gården mod betaling via
                            parkeringsapp (Easypark, OK, ParkPark).
                            Områdenummeret er 118.
                            <br />
                            Der er automatisk kameraovervågning, som registrerer
                            nummerplade ved ind- og udkørsel. Regler og vilkår
                            fremgår af skiltningen på pladsen.
                            <br />
                            Udlejeren har intet ansvar for eventuelle afgifter
                            ved manglende betaling for parkeringen.
                        </Text> */}

                        {/* <Text variant="body2" gutterBottom>
                            Medlemmer og lejere forpligter sig til at parkere
                            cykler foran huset ud mod Bülowsvej, samt at sikre
                            at eventuelle elever, deltagere, kunder, klienter og
                            andre gæster gør det samme.
                        </Text> */}

                        <TextTitle variant="body2" gutterBottom>
                            Adgang
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Ved indgåelse af medlemsaftale og/eller lejeaftale
                            tildeles der adgang til huset via brugerens
                            mobiltelefon.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Det er ikke udlejerens ansvar, hvis
                            medlemmet/lejeren ikke kan få adgang pga. manglende
                            mobiltelefon.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Adgang til huset sker via bagdøren.
                            <br />
                            Døren står permanent låst og kan kun åbnes via ”lås
                            op” knappen, når du er logget ind på hjemmesiden og
                            er fysisk tæt på døren.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Døren har dørpumpe og lukker af sig selv. Det er
                            ikke tilladt at holde døren åben med nogen
                            genstande. Døren må kun åbnes og holdes fysisk åben
                            af personer.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            ’Lås op’ -knappen virker kun når du har et gyldigt
                            medlemskab eller en lejeaftale.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            For lejere, der ikke er medlemmer, er der adgang til
                            bygningen og fællesarealerne 15 minutter før en
                            påbegyndt lejeperiode.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Skofri bygning
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Det meste af huset er skofrit område. Skoene sættes
                            i skoreolerne i lokalerne omkring indgangsdøren.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Alternativt tages der et særligt overtræk ud over
                            skoene. Hvis du har gæster med, er det dit ansvar,
                            at de også tager skoene af eller overtræk på.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Lokalernes anvendelse
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Lokalerne kan f.eks. anvendes til undervisning,
                            akustisk øvning, sang, kor, møder, massage, terapi,
                            kontor, fester, fødselsdage, foredrag, yoga, Tai Chi
                            m.m.
                            <br />
                            Spørg os, hvis du er i tvivl om, hvorvidt lokalerne
                            kan benyttes til din aktivitet.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Brugeren er ansvarlig for, at de benyttede lokaler
                            omgås forsvarligt. Brugeren er erstatningsansvarlig
                            for enhver skade – herunder også hændelige skader –
                            som påføres de benyttede lokaler, inventaret eller
                            ejendommen i øvrigt.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Ændringer i de benyttede lokaler må kun foretages
                            med ejers skriftlige godkendelse. Har brugeren efter
                            aftale foretaget ændringer af de benyttede lokaler,
                            er brugeren ved medlemskabets eller lejens ophør
                            forpligtet til at reetablere lokalerne, med mindre
                            ejer skriftligt frafalder dette krav.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Lokalerne efterlades generelt i samme tilstand og
                            opstilling, som ved brugerens ankomst og
                            ibrugtagning: Møbler og andet inventar stilles på
                            deres plads, klaverlåg lukkes ned, vinduer lukkes og
                            affald tages med ud af rummet.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Forbrugsregnskab
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Den Lille Musikskole sørger for levering af el, vand
                            og varme.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Husorden og brug af udenoms- og fællesarealer
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Som bruger af huset Bülowsvej 10, skal følgende
                            husorden overholdes. Det er 100% medlemmer og
                            lejeres ansvar at deres gæster, elever, klienter,
                            kunder overholder husreglerne.
                        </Text>

                        <BoldText variant="body2">Værdier og vision</BoldText>

                        <Text variant="body2" gutterBottom>
                            Visionen for Bülowsvej 10 er at være en langsom,
                            analog og klar ramme og vært for alle som har lyst
                            til og finder det meningsfuldt, at være her.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Vi ønsker at være værter for mange forskellige
                            mennesker og aktiviteter, men er ikke nødvendigvis
                            det rigtige sted for alle mennesker og aktiviteter.
                            Vi stiller nogle krav til vores brugere og gæs
                        </Text>
                        <OrderedList>
                            <ListItem>
                                Alle, der færdes i huset, skal tage deres sko af
                                i garderoben eller bruge overtræk eller
                                hjemmesko.
                            </ListItem>
                            <ListItem>
                                Alle udendørs og indendørs fællesarealer er
                                skærmfri områder. Undtagelsen er det særlige
                                skærmrum, hvor der kun er adgang for voksne over
                                18 år. Dette rum benyttes også til lytning af
                                podcast, lydbøger og lignende, samt læsning i
                                elektroniske bøger og brug af mobiltelefon (som
                                gerne må være kort og diskret). Vi henstiller
                                til at mobilen sættes på lydløs, når du træder
                                ind på matriklen. Og slet ikke bruger nogen
                                enheder til surfing og spil med mere. På denne
                                måde er du som gæst med til at opretholde
                                værternes ønsker for vores sted.{' '}
                            </ListItem>
                            <ListItem>
                                Ingen gæster, elever, klienter, kunder må
                                opholde sig i udlejningslokaler uden medlemmet
                                eller lejeren er til stede.
                            </ListItem>
                            <ListItem>
                                Ingen gæster, elever, klienter, kunder må
                                opholde sig i andre udlejningslokaler end det
                                lejede.
                            </ListItem>
                            <ListItem>
                                Al undervisningsmateriale, instrumenter,
                                rekvisitter og andet udstyr der befinder sig i
                                udlejningslokalerne, som ikke er nævnt i næste
                                afsnit, må kun benyttes efter direkte aftale med
                                Den Lille Musikskole.
                            </ListItem>
                            <ListItem>
                                De farvede taburetter må kun benyttes af børn
                            </ListItem>
                            <ListItem>
                                Støjende aktiviteter skal altid udøves for
                                lukkede vinduer og døre
                            </ListItem>
                            <ListItem>
                                Der skal luftes ud inden lokalet forlades
                            </ListItem>
                            <ListItem>
                                Klaverlåg lukkes ned og alle møbler, samt
                                inventar stilles pænt på plads.
                            </ListItem>
                            <ListItem>
                                Affald bæres ned i køkkenet til vores
                                affaldssortering.
                            </ListItem>
                            <ListItem>Hele matriklen holdes røgfri.</ListItem>
                            <ListItem>
                                Udendørs ophold og aktiviteter må ikke være til
                                gene for naboerne.
                            </ListItem>
                            <ListItem>
                                Instrumenter, inventar m.m. må ikke fjernes fra
                                huset uden forudgående aftale med Den Lille
                                Musikskole.
                            </ListItem>
                        </OrderedList>

                        <Text variant="body2">
                            *Vi opfordrer til, men håndhæver ikke, at børn under
                            18 år heller ikke bruger skærme i
                            udlejningslokalerne.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Inventar
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Den Lille Musikskole, som er fast bruger af
                            lokalerne hos Bülowsvej 10, har en del instrumenter,
                            rekvisitter og undervisningsmateriale liggende, som
                            ikke er til brug af andre end musikskolen.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Følgende inventar er til fri afbenyttelse:
                        </Text>
                        <UnorderedList>
                            <ListItem>
                                Klaver/flygel (låget lukkes efter brug)
                            </ListItem>
                            <ListItem>Guitarer</ListItem>
                            <ListItem>Stereoanlæg</ListItem>
                            <ListItem>Metronomer</ListItem>
                            <ListItem>Sand-ure</ListItem>
                            <ListItem>
                                Faldunderlagsmåtter (pakkes sammen igen efter
                                brug)
                            </ListItem>
                            <ListItem>
                                Meditationspuder (lægges på plads efter brug)
                            </ListItem>
                            <ListItem>
                                Massagebriks (tørres af og foldes sammen efter
                                brug)
                            </ListItem>
                            <ListItem>
                                Tæpper (foldes og lægges på plads efter brug)
                            </ListItem>
                            <ListItem>
                                Borde og stole (sættes på plads efter brug)
                            </ListItem>
                            <ListItem>
                                Farvede taburetter (NB! Må kun benyttes af børn)
                            </ListItem>
                            <ListItem>
                                Køkken og service (beskidt service sættes i
                                opvaskemaskinen. NB! Hvis opvaskemaskinen
                                indeholder rent service, sættes dette på plads
                                først)
                            </ListItem>
                        </UnorderedList>

                        <Text variant="body2" gutterBottom>
                            NB! Brug af andre instrumenter, inventar og
                            rekvisitter end nævnt ovenfor, kræver særskilt
                            forudgående skriftlig aftale med Den Lille
                            Musikskole.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Kopimaskine
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Det er muligt at benytte vores printer/kopimaskine
                            mod betaling.
                            <br />
                            Sort/hvid: 25 øre per kopi
                            <br />
                            Farve 50: øre per kopi
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Send en mail med antal kopier og type til:
                            kontakt@bulowsvej10.dk
                            <br />
                            Vi har tillid til, at vores brugere er ansvarlige og
                            ærlige.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Vi sender en faktura for kopier:
                        </Text>
                        <ul>
                            <li>Når antallet af kopier runder 1000 stk.</li>{' '}
                            <li>Ved ophør af medlemskab</li>
                            <li>Ved ophør af lejeperiode </li>
                        </ul>

                        <TextTitle variant="body2" gutterBottom>
                            Opslagstavlen
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Opslagstavlen er en gratis og valgfri ekstra service
                            for alle brugerne af bulowsvej10.dk. Det kræver kun
                            et login enten et medlemskab, eller en fremtidig
                            lokaleleje eller intention om enten et fremtidigt
                            medlemskab og/eller lokaleleje.
                        </Text>

                        <Text variant="body2">
                            På opslagstavlen kan du oprette et opslag, hvor du
                            præsenterer dig selv og hvad du tilbyder. Det kan
                            være undervisning, behandling, terapi med mere.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Sådan gør du:
                        </Text>

                        <ul>
                            <li>
                                Giv dit opslag en kort og præcis titel (eks.
                                Guitarundervisning, body-SDS, samtaleterapi
                                etc.)
                            </li>
                            <li>
                                Beskriv din undervisning / dit tilbud så præcist
                                og beskrivende som muligt. Der er plads til 700
                                tegn.
                            </li>
                            <li>
                                Tilføj kontaktoplysninger og eventuelt links til
                                sociale medier. Du vælger selv, hvordan du vil
                                kontaktes, din mail og telefonnummer er dog
                                skjult for søgemaskiner, så det kræver ekstra
                                handling fra besøgende, at få det synligt.
                            </li>
                            <li>
                                Upload op til 5 billeder af dig selv, dit
                                instrument, sessions etc.
                            </li>
                            <li>
                                Vælg en eller flere kategorier for dit opslag.
                            </li>
                        </ul>

                        <Text variant="body2" gutterBottom>
                            Medmindre du er medlem eller har en fremtidig
                            reservation, er dit opslag kun aktivt i 30 dage fra
                            dit sidste login, eller indtil du deaktiverer det.
                            <br />
                            <br />
                            3 dage før dit opslag udløber, vil du modtage en
                            e-mail med en påmindelse og et genaktiveringslink.
                            <br />
                            <br />
                            Hvis ikke du trykker på linket inden for 3 dage,
                            bliver opslaget automatisk deaktiveret. Du kan til
                            hver en tid genaktivere og redigere dit opslag.
                        </Text>

                        <Text variant="body2" gutterBottom>
                            NB! Den Lille Musikskole har intet ansvar for den
                            undervisning, som brugerne af opslagstavlen
                            tilbyder.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Klageadgang
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Hvis du som forbruger vil klage over dit køb, skal
                            du kontakte os på e-mail:{' '}
                            <LinkText href="mailto:kontakt@bulowsvej10.dk">
                                kontakt@bulowsvej10.dk
                            </LinkText>
                            . Hvis det ikke lykkes os at finde en løsning, kan
                            du sende en klage til:
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Center for Klageløsning
                            <br />
                            Nævnenes Hus
                            <br />
                            Toldboden 2<br />
                            8800 Viborg
                            <br />
                            www.naevneneshus.dk
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Ved indgivelse af en klage, skal du oplyse denne
                            e-mailadresse:{' '}
                            <LinkText href="mailto:kontakt@bulowsvej10.dk">
                                kontakt@bulowsvej10.dk
                            </LinkText>
                            .
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Lov og værneting
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Køb på{' '}
                            <LinkText
                                target="_blank"
                                rel="noopener noreferrer"
                                href="bulowsvej10.dk"
                            >
                                {' '}
                                bulowsvej10.dk
                            </LinkText>{' '}
                            er omfattet af dansk ret, og værneting er
                            forbrugerens hjemting. Personer under 18 år kan ikke
                            handle på{' '}
                            <LinkText
                                target="_blank"
                                rel="noopener noreferrer"
                                href="bulowsvej10.dk"
                            >
                                {' '}
                                bulowsvej10.dk
                            </LinkText>
                            , uden samtykke fra en værge.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Ansvarsfraskrivelse
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Den Ny Musikskole ApS/bulowsvej10.dk kan til enhver
                            tid ændre indholdet af denne side. Den Ny Musikskole
                            ApS/bulowsvej10.dk kan ikke drages til ansvar for
                            eventuelle skader opstået som resultat af brug af
                            siden eller for den sags skyld manglende adgang til
                            brug af siden.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Persondatapolitik
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Vi har brug for følgende oplysninger, når du
                            opretter en profil og/eller handler hos os: Navn,
                            adresse, e-mailadresse og telefonnummer.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Vi registrerer de personoplysninger, som er
                            nødvendige for, at du kan oprette et medlemskab
                            og/eller reservere lokaler. Personoplysningerne
                            registreres hos Den Ny Musikskole/bulowsvej10.dk og
                            opbevares i fem år, hvorefter oplysningerne slettes.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Vi behandler dine personoplysninger fortroligt, og
                            du kan altid logge ind og se dine oplysninger eller
                            lukke din konto, så alle data anonymiseres, i det
                            omfang lovgivningen tillader det.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Vi er jævnfør regnskabsloven forpligtet til at gemme
                            regnskabsinformationer om betalinger i fem år.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Vi samarbejder med de virksomheder, der er
                            nødvendige for at drifte betalingsmodul og
                            hjemmeside. Virksomhederne behandler udelukkende
                            oplysninger på vores vegne og må ikke bruge dem til
                            egne formål.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Vi samarbejder kun med databehandlere i EU eller i
                            lande, der kan give dine oplysninger en
                            tilstrækkelig beskyttelse.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Den dataansvarlige på{' '}
                            <LinkText
                                target="_blank"
                                rel="noopener noreferrer"
                                href="bulowsvej10.dk"
                            >
                                {' '}
                                bulowsvej10.dk
                            </LinkText>{' '}
                            er Den Ny Musikskole ApS.
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Du har ret til at få oplyst, hvilke oplysninger vi
                            har registreret om dig. Hvis du mener, at
                            oplysningerne er unøjagtige, har du ret til at få
                            dem rettet. I nogle tilfælde har vi pligt til at
                            slette dine persondata, hvis du beder om det. Det
                            kan f.eks. være, hvis dine data ikke længere er
                            nødvendige i forhold til det formål, som vi skulle
                            bruge dem til. Du kan også kontakte os, hvis du
                            mener, at dine persondata bliver behandlet i strid
                            med lovgivningen. Du kan skrive til os på:{' '}
                            <LinkText href="mailto:kontakt@bulowsvej10.dk">
                                kontakt@bulowsvej10.dk
                            </LinkText>
                            .
                        </Text>
                        {/* come back */}
                        <Text variant="body2" gutterBottom>
                            For yderligere oplysninger om vores håndtering af
                            persondata, se da under vores{' '}
                            <UnderlinedText onClick={showPersonalDataRules}>
                                persondatapolitik
                            </UnderlinedText>
                            .
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Ansvar og risici
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Den Ny Musikskole ApS bærer ikke risici eller
                            forsikrer mod tyveri af brugers personlige
                            genstande. Medlemmer og lejere bør derfor have
                            instrumentforsikring og forsikring imod personligt
                            tyveri.
                        </Text>

                        <TextTitle variant="body2" gutterBottom>
                            Force Majeure
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Såfremt Den Ny Musikskole ApS i en periode er
                            nødsaget til at lukke adgang til huset, som følge af
                            force majeure og force majeure lignende
                            begivenheder, gives ikke refunderinger, erstatning,
                            kompensation, forholdsmæssigt afslag i prisen eller
                            lignende, som følge af sådanne begivenheder. Som
                            force majeure og force majeure lignende begivenheder
                            anses eksempelvis naturkatastrofer, brand, strejker,
                            pandemier o.l.
                        </Text>

                        <TextTitle>Kontakt</TextTitle>
                        <Text variant="body2" gutterBottom>
                            Har du spørgsmål, eller er du i tvivl om noget i
                            forbindelse med din oprettelse af medlemskab eller
                            reservation af lokaler, så kan du kontakte os på
                            telefon 30 14 01 23 (mandag og onsdag kl. 10-12).
                        </Text>
                        <Text variant="body2" gutterBottom>
                            Du kan også skrive til os på{' '}
                            <LinkText href="mailto:kontakt@bulowsvej10.dk">
                                kontakt@bulowsvej10.dk
                            </LinkText>
                            , så svarer vi inden for 1-3 hverdage.
                        </Text>
                        <small>
                            Handels- og medlemsbetingelser er senest opdateret
                            den 30. juli 2024.
                        </small>
                    </TermsOfPurchaseContainer>
                    <GradientOverlay />
                </>
            ) : (
                <>
                    <SubTitle gutterBottom>
                        Dette websted{' '}
                        <LinkText
                            target="_blank"
                            rel="noopener noreferrer"
                            href="bulowsvej10.dk"
                        >
                            {' '}
                            bulowsvej10.dk
                        </LinkText>{' '}
                        ejes og drives af:
                    </SubTitle>
                    <Text gutterBottom>
                        Den Ny Musikskole ApS <br /> Bülowsvej 10
                        <br /> 1870 Frederiksberg
                        <br /> CVR.nr.: 33382898
                        <br /> Telefon: 30 14 01 23 <br />
                        <LinkText href="mailto:kontakt@bulowsvej10.dk">
                            kontakt@bulowsvej10.dk
                        </LinkText>
                    </Text>
                    <TextTitle variant="body2" gutterBottom>
                        Medlemskab og lokaleleje
                    </TextTitle>

                    <Text gutterBottom>
                        På{' '}
                        <LinkText
                            target="_blank"
                            rel="noopener noreferrer"
                            href="bulowsvej10.dk"
                        >
                            bulowsvej10.dk
                        </LinkText>{' '}
                        kan du oprette et medlemskab, som giver dig adgang til
                        alle ledige lokaler, der er angivet på lokaleoversigten,
                        24/7 i hele medlemsperioden.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Du kan også reservere lokaler i en selvvalgt
                        tidsperiode. Lokalereservation kan kombineres med
                        medlemskab, men det er ikke et krav medmindre du ønsker
                        at være andre end dig selv i lokalet.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Lokaleleje kombineret med medlemskab giver 50% rabat på
                        lokalelejen.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Lokalerne koster 5 kr. per m2 i timen. Den mindste
                        tidsblok lokalet kan lejes er 15 min. Lejeren kan først
                        tage lokalet i brug fra lejeperiodens start og skal have
                        forladt lokalet helt ved lejeperiodens udløb (back to
                        back).
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Betaling, opsigelse og misligholdelse
                    </TextTitle>

                    <Text variant="subtitle1" gutterBottom>
                        Medlemskab
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Medlemskabet betales månedsvis forud den første dag i
                        måneden, og løber indtil det opsiges. Medlemskabet kan
                        altid opsiges til udgangen af igangværende måned.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Opsigelse skal ske via{' '}
                        <LinkText
                            href="/min-side"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Min side.
                        </LinkText>{' '}
                        (tryk på punktet ”Medlemskab” i menuen og derefter
                        knappen ”Opsig medlemskab”). Opsigelsen skal være
                        foretaget senest 24 timer før en ny medlemsperiode
                        påbegyndes. Medlemskabet bevares naturligvis resten af
                        måneden uanset hvornår der opsiges.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Ved første tilmelding beregner systemet en relativ
                        betaling fra tilmeldingsdatoen til sidste dag i den
                        pågældende måned. Dette beløb trækkes med det samme der
                        trykkes på betal knappen. Derefter trækkes der kun
                        betaling den første.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        NB! I opsummeringen før betalingen vises kun betalingen
                        for den kommende måneds medlemskab, selvom medlemskabet
                        automatisk fornyes indtil det opsiges.
                    </Text>

                    <Text variant="subtitle1" gutterBottom>
                        Lokaleleje
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Lokaleleje betales månedsvis bagud den første dag i den
                        følgende måned.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Der reserveres den fulde pris for den samlede lokaleleje
                        på dit kort. Og der betales kun for den forbrugte
                        lokaleleje i den forrige måned.
                        <br />I opsummeringen for lokalelejen vises både det
                        fulde beløb og rabatterne for reservationer måned for
                        måned.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        OBS! Rabatterne aktiveres kun, hvis du oprettede
                        medlemskab eller allerede var medlem da du oprettede
                        reservationen, og medlemskabet opretholdes i hele
                        perioden til og med den sidste registrerede lokaleleje.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Opsigelse af medlemskab vil medføre bortfald af
                        rabatterne. Oprettelse af medlemskab efter en
                        reservation vil ikke give mulighed for rabat.
                    </Text>

                    <Text variant="subtitle1" gutterBottom>
                        Annulleringsregler
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Du kan altid annullere en lokalereservation, det vil dog
                        koste dig mellem 0 og 50 % af lokalelejen, alt efter
                        hvornår du annullerer og om du er medlem eller ej.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Hvis du annullerer <UnderlineOnly>mere</UnderlineOnly>{' '}
                        end én uge før lejetidspunktet, opkræves medlemmer ingen
                        leje, ikke medlemmer opkræves 25% af lejen.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Hvis du annullerer <UnderlineOnly>mindre</UnderlineOnly>{' '}
                        end én uge før lejetidspunktet, opkræves medlemmer 25%
                        af lejen, ikke medlemmer opkræves 50% af lejen.
                    </Text>

                    <Text variant="subtitle1" gutterBottom>
                        Generelt
                    </Text>

                    <Text variant="body2" gutterBottom>
                        Vi accepterer betaling fra Dankort (via{' '}
                        <LinkText
                            href="https://payments.nets.eu/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            NETS
                        </LinkText>{' '}
                        ), Visa og MasterCard (via{' '}
                        <LinkText
                            href="https://www.clearhaus.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Clearhouse
                        </LinkText>
                        ) . Vi opkræver ikke kreditkortgebyrer. Alle priser er
                        angivet i DKK.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Rent teknisk oprettes både medlemskab og
                        lokalereservationer som et såkaldt abonnement hos vores
                        betalingsudbyder QuickPay.{' '}
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Efter at købet er gennemført, får du kvittering for
                        købet, tilsendt på mail. Der kan gå op til 24 timer.
                        Hvis du ikke har modtaget en e-mail fra os efter 24
                        timer, skal du tjekke dit spamfilter eller kontakte os
                        på e-mail: kontakt@bulowsvej10.dk.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Du kan til hver en tid få et samlet overblik over alle
                        dine lokalereservationer inde på{' '}
                        <LinkText
                            href="/min-side"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Min side.
                        </LinkText>
                    </Text>

                    <Text variant="subtitle1" gutterBottom>
                        Øvrigt
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Du skal være fyldt 18 år for at handle hos os. Er du
                        under 18 år, skal du have samtykke fra en forælder eller
                        værge.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Et køb kan altid annulleres af en forælder eller værge,
                        hvis det skulle vise sig, at den er blevet indgået af et
                        barn eller en person under 18 år.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Købsaftalen indgås på dansk. Den er juridisk bindende,
                        når du har modtaget ordrebekræftelsen.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Medlemskab og lokaleleje er personligt og må ikke
                        overdrages eller udlånes til andre – hverken helt eller
                        delvist.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Medlemmet/lejeren skal{' '}
                        <UnderlineOnly>altid</UnderlineOnly> selv være fysisk
                        til stede, når udlejningslokaler og fællesarealer tages
                        i brug.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Et brud på dette,anses som misligholdelse af aftalen, og
                        kan medføre øjeblikkelig bortvisning af
                        medlemmet/lejeren, uden økonomisk kompensation for
                        allerede betalt medlemskab/leje. Det samme gælder ved
                        udøvelse af hærværk på lokaler, inventar eller bygningen
                        i øvrigt.
                    </Text>

                    {/* 
                    <TextTitle variant="body2" gutterBottom>
                        Parkering
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Biler kan parkeres i gården mod betaling via
                        parkeringsapp (Easypark, OK, ParkPark). Områdenummeret
                        er 118.
                        <br />
                        Der er automatisk kameraovervågning, som registrerer
                        nummerplade ved ind- og udkørsel. Regler og vilkår
                        fremgår af skiltningen på pladsen.
                        <br />
                        Udlejeren har intet ansvar for eventuelle afgifter ved
                        manglende betaling for parkeringen.
                    </Text> */}

                    {/* <Text variant="body2" gutterBottom>
                        Medlemmer og lejere forpligter sig til at parkere cykler
                        foran huset ud mod Bülowsvej, samt at sikre at
                        eventuelle elever, deltagere, kunder, klienter og andre
                        gæster gør det samme.
                    </Text> */}

                    <TextTitle variant="body2" gutterBottom>
                        Adgang
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Ved indgåelse af medlemsaftale og/eller lejeaftale
                        tildeles der adgang til huset via brugerens
                        mobiltelefon.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Det er ikke udlejerens ansvar, hvis medlemmet/lejeren
                        ikke kan få adgang pga. manglende mobiltelefon.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Adgang til huset sker via bagdøren.
                        <br />
                        Døren står permanent låst og kan kun åbnes via ”lås op”
                        knappen, når du er logget ind på hjemmesiden og er
                        fysisk tæt på døren.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Døren har dørpumpe og lukker af sig selv. Det er ikke
                        tilladt at holde døren åben med nogen genstande. Døren
                        må kun åbnes og holdes fysisk åben af personer.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        ’Lås op’ -knappen virker kun når du har et gyldigt
                        medlemskab eller en lejeaftale.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        For lejere, der ikke er medlemmer, er der adgang til
                        bygningen og fællesarealerne 15 minutter før en
                        påbegyndt lejeperiode.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Skofri bygning
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Det meste af huset er skofrit område. Skoene sættes i
                        skoreolerne i lokalerne omkring indgangsdøren.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Alternativt tages der et særligt overtræk ud over
                        skoene. Hvis du har gæster med, er det dit ansvar, at de
                        også tager skoene af eller overtræk på.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Lokalernes anvendelse
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Lokalerne kan f.eks. anvendes til undervisning, akustisk
                        øvning, sang, kor, møder, massage, terapi, kontor,
                        fester, fødselsdage, foredrag, yoga, Tai Chi m.m.
                        <br />
                        Spørg os, hvis du er i tvivl om, hvorvidt lokalerne kan
                        benyttes til din aktivitet.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Brugeren er ansvarlig for, at de benyttede lokaler omgås
                        forsvarligt. Brugeren er erstatningsansvarlig for enhver
                        skade – herunder også hændelige skader – som påføres de
                        benyttede lokaler, inventaret eller ejendommen i øvrigt.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Ændringer i de benyttede lokaler må kun foretages med
                        ejers skriftlige godkendelse. Har brugeren efter aftale
                        foretaget ændringer af de benyttede lokaler, er brugeren
                        ved medlemskabets eller lejens ophør forpligtet til at
                        reetablere lokalerne, med mindre ejer skriftligt
                        frafalder dette krav.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Lokalerne efterlades generelt i samme tilstand og
                        opstilling, som ved brugerens ankomst og ibrugtagning:
                        Møbler og andet inventar stilles på deres plads,
                        klaverlåg lukkes ned, vinduer lukkes og affald tages med
                        ud af rummet.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Forbrugsregnskab
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Den Lille Musikskole sørger for levering af el, vand og
                        varme.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Husorden og brug af udenoms- og fællesarealer
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Som bruger af huset Bülowsvej 10, skal følgende husorden
                        overholdes. Det er 100% medlemmer og lejeres ansvar at
                        deres gæster, elever, klienter, kunder overholder
                        husreglerne.
                    </Text>

                    <BoldText variant="body1">Værdier og vision</BoldText>
                    <Text variant="body2" gutterBottom>
                        Visionen for Bülowsvej 10 er at være en langsom, analog
                        og klar ramme og vært for alle som har lyst til og
                        finder det meningsfuldt, at være her.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Vi ønsker at være værter for mange forskellige mennesker
                        og aktiviteter, men er ikke nødvendigvis det rigtige
                        sted for alle mennesker og aktiviteter. Vi stiller nogle
                        krav til vores brugere og gæs
                    </Text>
                    <OrderedList>
                        <ListItem>
                            Alle, der færdes i huset, skal tage deres sko af i
                            garderoben eller bruge overtræk eller hjemmesko.
                        </ListItem>
                        <ListItem>
                            Alle udendørs og indendørs fællesarealer er skærmfri
                            områder. Undtagelsen er det særlige skærmrum, hvor
                            der kun er adgang for voksne over 18 år. Dette rum
                            benyttes også til lytning af podcast, lydbøger og
                            lignende, samt læsning i elektroniske bøger og brug
                            af mobiltelefon (som gerne må være kort og diskret).
                            Vi henstiller til at mobilen sættes på lydløs, når
                            du træder ind på matriklen. Og slet ikke bruger
                            nogen enheder til surfing og spil med mere. På denne
                            måde er du som gæst med til at opretholde værternes
                            ønsker for vores sted.{' '}
                        </ListItem>
                        <ListItem>
                            Ingen gæster, elever, klienter, kunder må opholde
                            sig i udlejningslokaler uden medlemmet eller lejeren
                            er til stede.
                        </ListItem>
                        <ListItem>
                            Ingen gæster, elever, klienter, kunder må opholde
                            sig i andre udlejningslokaler end det lejede.
                        </ListItem>
                        <ListItem>
                            Al undervisningsmateriale, instrumenter, rekvisitter
                            og andet udstyr der befinder sig i
                            udlejningslokalerne, som ikke er nævnt i næste
                            afsnit, må kun benyttes efter direkte aftale med Den
                            Lille Musikskole.
                        </ListItem>
                        <ListItem>
                            De farvede taburetter må kun benyttes af børn
                        </ListItem>
                        <ListItem>
                            Støjende aktiviteter skal altid udøves for lukkede
                            vinduer og døre
                        </ListItem>
                        <ListItem>
                            Der skal luftes ud inden lokalet forlades
                        </ListItem>
                        <ListItem>
                            Klaverlåg lukkes ned og alle møbler, samt inventar
                            stilles pænt på plads.
                        </ListItem>
                        <ListItem>
                            Affald bæres ned i køkkenet til vores
                            affaldssortering.
                        </ListItem>
                        <ListItem>Hele matriklen holdes røgfri.</ListItem>
                        <ListItem>
                            Udendørs ophold og aktiviteter må ikke være til gene
                            for naboerne.
                        </ListItem>
                        <ListItem>
                            Instrumenter, inventar m.m. må ikke fjernes fra
                            huset uden forudgående aftale med Den Lille
                            Musikskole.
                        </ListItem>
                    </OrderedList>

                    <Text variant="body2">
                        *Vi opfordrer til, men håndhæver ikke, at børn under 18
                        år heller ikke bruger skærme i udlejningslokalerne.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Inventar
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Den Lille Musikskole, som er fast bruger af lokalerne
                        hos Bülowsvej 10, har en del instrumenter, rekvisitter
                        og undervisningsmateriale liggende, som ikke er til brug
                        af andre end musikskolen.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Følgende inventar er til fri afbenyttelse:
                    </Text>
                    <UnorderedList>
                        <ListItem>
                            Klaver/flygel (låget lukkes efter brug)
                        </ListItem>
                        <ListItem>Guitarer</ListItem>
                        <ListItem>Stereoanlæg</ListItem>
                        <ListItem>Metronomer</ListItem>
                        <ListItem>Sand-ure</ListItem>
                        <ListItem>
                            Faldunderlagsmåtter (pakkes sammen igen efter brug)
                        </ListItem>
                        <ListItem>
                            Meditationspuder (lægges på plads efter brug)
                        </ListItem>
                        <ListItem>
                            Massagebriks (tørres af og foldes sammen efter brug)
                        </ListItem>
                        <ListItem>
                            Tæpper (foldes og lægges på plads efter brug)
                        </ListItem>
                        <ListItem>
                            Borde og stole (sættes på plads efter brug)
                        </ListItem>
                        <ListItem>
                            Farvede taburetter (NB! Må kun benyttes af børn)
                        </ListItem>
                        <ListItem>
                            Køkken og service (beskidt service sættes i
                            opvaskemaskinen. NB! Hvis opvaskemaskinen indeholder
                            rent service, sættes dette på plads først)
                        </ListItem>
                    </UnorderedList>

                    <Text variant="body2" gutterBottom>
                        NB! Brug af andre instrumenter, inventar og rekvisitter
                        end nævnt ovenfor, kræver særskilt forudgående skriftlig
                        aftale med Den Lille Musikskole.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Kopimaskine
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Det er muligt at benytte vores printer/kopimaskine mod
                        betaling.
                        <br />
                        Sort/hvid: 25 øre per kopi
                        <br />
                        Farve 50: øre per kopi
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Send en mail med antal kopier og type til:
                        kontakt@bulowsvej10.dk
                        <br />
                        Vi har tillid til, at vores brugere er ansvarlige og
                        ærlige.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Vi sender en faktura for kopier:
                    </Text>
                    <ul>
                        <li>Når antallet af kopier runder 1000 stk.</li>{' '}
                        <li>Ved ophør af medlemskab</li>
                        <li>Ved ophør af lejeperiode </li>
                    </ul>

                    <TextTitle variant="body2" gutterBottom>
                        Opslagstavlen
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Opslagstavlen er en gratis og valgfri ekstra service for
                        alle brugerne af bulowsvej10.dk. Det kræver kun et login
                        enten et medlemskab, eller en fremtidig lokaleleje eller
                        intention om enten et fremtidigt medlemskab og/eller
                        lokaleleje.
                    </Text>

                    <Text variant="body2">
                        På opslagstavlen kan du oprette et opslag, hvor du
                        præsenterer dig selv og hvad du tilbyder. Det kan være
                        undervisning, behandling, terapi med mere.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Sådan gør du:
                    </Text>

                    <ul>
                        <li>
                            Giv dit opslag en kort og præcis titel (eks.
                            Guitarundervisning, body-SDS, samtaleterapi etc.)
                        </li>
                        <li>
                            Beskriv din undervisning / dit tilbud så præcist og
                            beskrivende som muligt. Der er plads til 700 tegn.
                        </li>
                        <li>
                            Tilføj kontaktoplysninger og eventuelt links til
                            sociale medier. Du vælger selv, hvordan du vil
                            kontaktes, din mail og telefonnummer er dog skjult
                            for søgemaskiner, så det kræver ekstra handling fra
                            besøgende, at få det synligt.
                        </li>
                        <li>
                            Upload op til 5 billeder af dig selv, dit
                            instrument, sessions etc.
                        </li>
                        <li>Vælg en eller flere kategorier for dit opslag.</li>
                        <li>
                            Dit opslag er aktivt i 30 dage fra dit sidste login,
                            eller indtil du deaktiverer det. 3 dage før dit
                            opslag udløber, vil du modtage en e-mail med en
                            påmindelse og et genaktiveringslink. Hvis ikke du
                            trykker på linket inden for 3 dage, bliver opslaget
                            automatisk deaktiveret. Du kan til hver en tid
                            genaktivere og redigere dit opslag.
                        </li>
                    </ul>

                    <Text variant="body2" gutterBottom>
                        Medmindre du er medlem eller har en fremtidig
                        reservation, er dit opslag kun aktivt i 30 dage fra dit
                        sidste login, eller indtil du deaktiverer det.
                        <br />
                        <br />
                        3 dage før dit opslag udløber, vil du modtage en e-mail
                        med en påmindelse og et genaktiveringslink.
                        <br />
                        <br />
                        Hvis ikke du trykker på linket inden for 3 dage, bliver
                        opslaget automatisk deaktiveret. Du kan til hver en tid
                        genaktivere og redigere dit opslag.
                    </Text>

                    <Text variant="body2" gutterBottom>
                        NB! Den Lille Musikskole har intet ansvar for den
                        undervisning, som brugerne af opslagstavlen tilbyder.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Klageadgang
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Hvis du som forbruger vil klage over dit køb, skal du
                        kontakte os på e-mail:{' '}
                        <LinkText href="mailto:kontakt@bulowsvej10.dk">
                            kontakt@bulowsvej10.dk
                        </LinkText>
                        . Hvis det ikke lykkes os at finde en løsning, kan du
                        sende en klage til:
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Center for Klageløsning
                        <br />
                        Nævnenes Hus
                        <br />
                        Toldboden 2<br />
                        8800 Viborg
                        <br />
                        www.naevneneshus.dk
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Ved indgivelse af en klage, skal du oplyse denne
                        e-mailadresse:{' '}
                        <LinkText href="mailto:kontakt@bulowsvej10.dk">
                            kontakt@bulowsvej10.dk
                        </LinkText>
                        .
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Lov og værneting
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Køb på{' '}
                        <LinkText
                            target="_blank"
                            rel="noopener noreferrer"
                            href="bulowsvej10.dk"
                        >
                            {' '}
                            bulowsvej10.dk
                        </LinkText>{' '}
                        er omfattet af dansk ret, og værneting er forbrugerens
                        hjemting. Personer under 18 år kan ikke handle på{' '}
                        <LinkText
                            target="_blank"
                            rel="noopener noreferrer"
                            href="bulowsvej10.dk"
                        >
                            {' '}
                            bulowsvej10.dk
                        </LinkText>
                        , uden samtykke fra en værge.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Ansvarsfraskrivelse
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Den Ny Musikskole ApS/bulowsvej10.dk kan til enhver tid
                        ændre indholdet af denne side. Den Ny Musikskole
                        ApS/bulowsvej10.dk kan ikke drages til ansvar for
                        eventuelle skader opstået som resultat af brug af siden
                        eller for den sags skyld manglende adgang til brug af
                        siden.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Persondatapolitik
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Vi har brug for følgende oplysninger, når du opretter en
                        profil og/eller handler hos os: Navn, adresse,
                        e-mailadresse og telefonnummer.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Vi registrerer de personoplysninger, som er nødvendige
                        for, at du kan oprette et medlemskab og/eller reservere
                        lokaler. Personoplysningerne registreres hos Den Ny
                        Musikskole/bulowsvej10.dk og opbevares i fem år,
                        hvorefter oplysningerne slettes.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Vi behandler dine personoplysninger fortroligt, og du
                        kan altid logge ind og se dine oplysninger eller lukke
                        din konto, så alle data anonymiseres, i det omfang
                        lovgivningen tillader det.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Vi er jævnfør regnskabsloven forpligtet til at gemme
                        regnskabsinformationer om betalinger i fem år.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Vi samarbejder med de virksomheder, der er nødvendige
                        for at drifte betalingsmodul og hjemmeside.
                        Virksomhederne behandler udelukkende oplysninger på
                        vores vegne og må ikke bruge dem til egne formål.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Vi samarbejder kun med databehandlere i EU eller i
                        lande, der kan give dine oplysninger en tilstrækkelig
                        beskyttelse.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Den dataansvarlige på{' '}
                        <LinkText
                            target="_blank"
                            rel="noopener noreferrer"
                            href="bulowsvej10.dk"
                        >
                            {' '}
                            bulowsvej10.dk
                        </LinkText>{' '}
                        er Den Ny Musikskole ApS.
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Du har ret til at få oplyst, hvilke oplysninger vi har
                        registreret om dig. Hvis du mener, at oplysningerne er
                        unøjagtige, har du ret til at få dem rettet. I nogle
                        tilfælde har vi pligt til at slette dine persondata,
                        hvis du beder om det. Det kan f.eks. være, hvis dine
                        data ikke længere er nødvendige i forhold til det
                        formål, som vi skulle bruge dem til. Du kan også
                        kontakte os, hvis du mener, at dine persondata bliver
                        behandlet i strid med lovgivningen. Du kan skrive til os
                        på:{' '}
                        <LinkText href="mailto:kontakt@bulowsvej10.dk">
                            kontakt@bulowsvej10.dk
                        </LinkText>
                        .
                    </Text>
                    {/* come back */}
                    <Text variant="body2" gutterBottom>
                        For yderligere oplysninger om vores håndtering af
                        persondata, se da under vores{' '}
                        <UnderlinedText onClick={showPersonalDataRules}>
                            persondatapolitik
                        </UnderlinedText>
                        .
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Ansvar og risici
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Den Ny Musikskole ApS bærer ikke risici eller forsikrer
                        mod tyveri af brugers personlige genstande. Medlemmer og
                        lejere bør derfor have instrumentforsikring og
                        forsikring imod personligt tyveri.
                    </Text>

                    <TextTitle variant="body2" gutterBottom>
                        Force Majeure
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Såfremt Den Ny Musikskole ApS i en periode er nødsaget
                        til at lukke adgang til huset, som følge af force
                        majeure og force majeure lignende begivenheder, gives
                        ikke refunderinger, erstatning, kompensation,
                        forholdsmæssigt afslag i prisen eller lignende, som
                        følge af sådanne begivenheder. Som force majeure og
                        force majeure lignende begivenheder anses eksempelvis
                        naturkatastrofer, brand, strejker, pandemier o.l.
                    </Text>

                    <TextTitle>Kontakt</TextTitle>
                    <Text variant="body2" gutterBottom>
                        Har du spørgsmål, eller er du i tvivl om noget i
                        forbindelse med din oprettelse af medlemskab eller
                        reservation af lokaler, så kan du kontakte os på telefon
                        30 14 01 23 (mandag og onsdag kl. 10-12).
                    </Text>
                    <Text variant="body2" gutterBottom>
                        Du kan også skrive til os på{' '}
                        <LinkText href="mailto:kontakt@bulowsvej10.dk">
                            kontakt@bulowsvej10.dk
                        </LinkText>
                        , så svarer vi inden for 1-3 hverdage.
                    </Text>
                    <small>
                        Handels- og medlemsbetingelser er senest opdateret den
                        30. juli 2024
                    </small>
                </>
            )}
            {isPersonalDataDialogOpen && (
                <PersonalDataDialog
                    close={() => setIsPersonalDataDialogOpen(false)}
                />
            )}
        </OuterContainer>
    );
}

export default TermsOfPurchase;
