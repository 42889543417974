import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import styled from 'styled-components';
import { overridings } from '../../themes/DarkTheme';
import { Box, Typography } from '@material-ui/core';
import {
    getLastDayOfSubscription,
    getLocationNameFromRoomId,
    isInLastMonthOfSubscription,
    isSubscriber
} from '../../context/getData';
import {
    calculateSingleEventDiscountPrice,
    getRightPriceCellsForEventsOnly
} from './ProductTableFunctions';
import SumRowDiscountedPrice from './SumRowDiscountedprice';
import SumRowNormalPrice from './SumRowNormalPrice';
import EventItemCheckout from '../event-item/EventItemCheckout';

const IconContainer = styled.div`
    margin-right: 28px;
    cursor: pointer;
    display: inline;
    color: ${(props) => props.actiontype === 'delete' && 'red'};
    & .MuiSvgIcon-root {
        width: 1.5rem;
        height: 1.5rem;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin-right: 0;
    }
`;

// const CustomTableContainer = styled(TableContainer)`
//     & .sum-row {
//         margin-bottom: 1.5rem;
//     }
// `;

const ExpanderTableCell = styled(TableCell)`
    width: 190px;
    .expander-cell-text {
        text-decoration: underline;
    }

    .expander-cell-text,
    .icon-tabler-chevron-down {
        cursor: pointer;
    }

    .icon-tabler-chevron-down {
        position: relative;
        top: 7px;
    }
`;

// const ChevronUpContainer = styled.span`
//     & .icon-tabler {
//         transform: rotate(180deg);
//     }

//     position: relative;
//     /* top: 5px; */
//     /* margin-left: 6px; */
// `;

const ProductTableContainer = styled.div`
    margin-bottom: 2rem;
`;

const MobileTopRow = styled(Box)`
    display: flex;
    background: ${(props) => overridings.palette.primary.light};
`;

const TableMobile = styled(Table)`
    & .no-border-bottom-cell {
        /* background: green; */
        border-bottom: 1px solid transparent;
    }
`;

function ProductTable({
    orderType,
    membershipFirstMonthPrice,
    handleSubscriptionDeleteClick,
    draftOwnEvents,
    handleDeleteClick
}) {
    const [rows, setRows] = useState([]);
    const [eventsByMonth, setEventsByMonth] = useState([]);
    const [sumPrice, setSumPrice] = useState(0);
    const [showAllEventRows, setShowAllEventRows] = useState(false);
    const [showDiscountedPrices, setShowDiscountedPrices] = useState(false);
    const [toggleVisible, setToggleVisible] = useState(false);

    const createRows = (productName, price) => {
        return { productName, price };
    };

    const setTableDataForSubscription = () => {
        let newRows;
        const lastDateOfMonth = moment().endOf('month');
        const generalMembershipRow = createRows(
            'Medlemskab (fornyes automtisk hver måned)',
            '400 kr./mdr.'
        );

        // Show the price of the first month
        const membershipRow = createRows(
            `Medlemskab fra ${moment().format('DD.MM.YYYY')} til ${moment(
                lastDateOfMonth
            ).format('DD.MM.YYYY')}`,
            `${membershipFirstMonthPrice.toString().replaceAll('.', ',')} kr.`
        );
        newRows = [membershipRow, generalMembershipRow];
        console.log('show membership rows', membershipRow);
        console.log('show newRows', newRows);
        setSumPrice(membershipFirstMonthPrice);
        setRows(newRows);
    };

    const setTableDataForEvents = (haveDiscountedEvent) => {
        if (draftOwnEvents && draftOwnEvents.length > 0) {
            // Need to separate the events by month
            // Find the different months where the events are
            const months = [];

            for (let i = 0; i < draftOwnEvents.length; i++) {
                months.push(moment(draftOwnEvents[i].startdate).format('MMMM'));
            }
            const monthsWithoutDuplicates = [...new Set(months)];

            const eventsOrganisedByMonth = monthsWithoutDuplicates.map(
                (month) => ({
                    month,
                    events: draftOwnEvents.filter((event) => {
                        const eventMonth = moment(event.startdate).format(
                            'MMMM'
                        );
                        return eventMonth === month;
                    })
                })
            );

            // Calculate sum price per month
            eventsOrganisedByMonth.forEach((month) => {
                const normalPricePerMonth = month.events.reduce(
                    (accumulator, event) => accumulator + event.normalPrice,
                    0
                );
                month.normalPricePerMonth = normalPricePerMonth / 100;

                // Add duedate
                const firstEventInTheMonth = month.events[0];
                month.dueDate = moment(firstEventInTheMonth.startdate)
                    .add(1, 'months')
                    .startOf('month')
                    .format('DD.MM.YYYY');
            });

            // If user is subscriber, then they only have to pay 50%
            if (isSubscriber()) {
                eventsOrganisedByMonth.forEach((month) => {
                    month.discountedPricePerMonth =
                        month.normalPricePerMonth / 2;
                });
            } else if (
                orderType === 'events' &&
                isInLastMonthOfSubscription()
            ) {
                // The current month should be discounted, after that should be full price
                // Add 1 day, because for some reason it shows 1 hour before dueDate (perhaps due to winter-sumer time stuff?)
                const dueDateMonth = moment(getLastDayOfSubscription())
                    .subtract(1, 'days')
                    .format('MMMM');
                if (eventsOrganisedByMonth[0].month === dueDateMonth) {
                    eventsOrganisedByMonth[0].discountedPricePerMonth =
                        eventsOrganisedByMonth[0].normalPricePerMonth / 2;
                }
            } else if (orderType === 'combo') {
                eventsOrganisedByMonth.forEach((month) => {
                    // We need to display both the original and discounted price
                    month.discountedPricePerMonth =
                        month.normalPricePerMonth / 2;
                });
            }

            if (haveDiscountedEvent) {
                setShowDiscountedPrices(true);
            }

            // If there are more than 3 events, then we need to enable the toggle to hide or show the events
            if (draftOwnEvents && draftOwnEvents.length > 3) {
                setToggleVisible(true);
            }
            setEventsByMonth(eventsOrganisedByMonth);
        }
    };

    const calculatePriceToPayNow = () => {
        if (orderType === 'subscription') {
            return membershipFirstMonthPrice;
        } else if (orderType === 'events') {
        }
    };

    // componentDidMount
    useEffect(() => {
        // If user is in last month of subscription, check if we need to show discount prices
        let haveDiscountedEvent;
        if (isInLastMonthOfSubscription()) {
            const subscriptionDueDate = getLastDayOfSubscription();
            console.log('last day', subscriptionDueDate);

            // Check if there are any events in the array, that are within the last day of subscription
            if (draftOwnEvents && draftOwnEvents.length > 0) {
                console.log('show draftownevents', draftOwnEvents);
                haveDiscountedEvent = draftOwnEvents.some((event) => {
                    console.log('show even', event.startdate);
                    console.log(
                        'isbefore',
                        moment(
                            event.startdate + ' ' + event.starttime
                        ).isSameOrBefore(moment(subscriptionDueDate))
                    );
                    return moment(
                        event.startdate + ' ' + event.starttime
                    ).isSameOrBefore(moment(subscriptionDueDate));
                });
                if (haveDiscountedEvent) {
                    setShowDiscountedPrices(true);
                }
            }
        }

        if (isSubscriber()) {
            setShowDiscountedPrices(true);
        }

        if (orderType === 'subscription') {
            setTableDataForSubscription();
        } else if (orderType === 'events') {
            setTableDataForEvents(haveDiscountedEvent);
        } else if (orderType === 'combo') {
            setTableDataForSubscription();
            setTableDataForEvents();
            setShowDiscountedPrices(true);
        }

        calculatePriceToPayNow();
        // eslint-disable-next-line
    }, [orderType, membershipFirstMonthPrice, draftOwnEvents]);

    const getRightText = () => {
        if (orderType === 'subscription') {
            return (
                <Box mb="1.5rem">
                    <Typography variant="subtitle1">
                        Du er nu ved at oprette et medlemskab. <br />
                        Medlemskabet fornyes automatisk den første i hver måned,
                        indtil du opsiger det.
                    </Typography>
                </Box>
            );
        }
    };

    const calculateScroll = (month) => {
        if (document.body.offsetWidth > 960) {
            //Desktop
            if (month.events.length > 3) {
                return 'scroll';
            } else {
                return 'hidden';
            }
            // Mobile
        } else {
            if (month.events.length === 1) {
                return 'auto';
            } else {
                return 'scroll';
            }
        }
    };

    return (
        <ProductTableContainer>
            {getRightText()}
            {/* Subscription only */}
            {(orderType === 'events' || orderType === 'combo') && (
                <Box mb="2rem">
                    {/* In mobile version we need to show a different table */}
                    {document.body.offsetWidth > 960 ? (
                        <>
                            <TableContainer
                                component={Paper}
                                sx={{
                                    background: 'inherit',
                                    overflow: 'hidden'
                                }}
                            >
                                <Table
                                    sx={{ minWidth: 286 }}
                                    aria-label="simple table"
                                >
                                    <TableHead>
                                        <TableRow
                                            sx={{
                                                backgroundColor:
                                                    overridings.palette.primary
                                                        .light
                                            }}
                                        >
                                            <TableCell
                                                sx={{
                                                    color:
                                                        overridings.palette
                                                            .primary
                                                            .contrastText,
                                                    '@media (max-width: 600px)': {
                                                        fontSize: '10px'
                                                    }
                                                }}
                                            >
                                                <b>
                                                    Reservations- og
                                                    betalingsoversigt
                                                </b>
                                            </TableCell>

                                            {toggleVisible && (
                                                <>
                                                    {showAllEventRows ? (
                                                        <ExpanderTableCell
                                                            sx={{
                                                                color:
                                                                    overridings
                                                                        .palette
                                                                        .primary
                                                                        .contrastText
                                                            }}
                                                            align="right"
                                                            onClick={() =>
                                                                setShowAllEventRows(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            {/* <span className="expander-cell-text">
                                                                Skjul
                                                            </span> */}
                                                            {/* <ChevronUpContainer>
                                                                <ChevronDownIcon />
                                                            </ChevronUpContainer> */}
                                                        </ExpanderTableCell>
                                                    ) : (
                                                        <ExpanderTableCell
                                                            sx={{
                                                                color:
                                                                    overridings
                                                                        .palette
                                                                        .primary
                                                                        .contrastText
                                                            }}
                                                            align="right"
                                                            onClick={() =>
                                                                setShowAllEventRows(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            {/* <span className="expander-cell-text">
                                                                Vis mere
                                                            </span> */}

                                                            {/* <ChevronDownIcon /> */}
                                                        </ExpanderTableCell>
                                                    )}
                                                </>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </TableContainer>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow
                                            sx={{
                                                backgroundColor:
                                                    overridings.palette.primary
                                                        .light
                                            }}
                                        >
                                            <TableCell
                                                sx={{
                                                    color:
                                                        overridings.palette
                                                            .primary
                                                            .contrastText,
                                                    minWidth: '95px',
                                                    width: '11%',
                                                    '@media (max-width: 600px)': {
                                                        fontSize: '10px',
                                                        minWidth: 'unset'
                                                    }
                                                }}
                                                align="left"
                                            >
                                                Lokale
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    color:
                                                        overridings.palette
                                                            .primary
                                                            .contrastText,
                                                    minWidth: '250px',
                                                    width: '25%',
                                                    '@media (max-width: 600px)': {
                                                        fontSize: '10px',
                                                        minWidth: 'unset'
                                                    }
                                                }}
                                                align="center"
                                            >
                                                Dato og tid
                                            </TableCell>
                                            {orderType === 'events' &&
                                                !showDiscountedPrices && (
                                                    <TableCell
                                                        sx={{
                                                            color:
                                                                overridings
                                                                    .palette
                                                                    .primary
                                                                    .contrastText,
                                                            minWidth: '115px',
                                                            width: '13%',
                                                            '@media (max-width: 600px)': {
                                                                fontSize: '10px'
                                                            }
                                                        }}
                                                        align="center"
                                                    >
                                                        Pris
                                                    </TableCell>
                                                )}
                                            {(orderType === 'combo' ||
                                                showDiscountedPrices) && (
                                                <>
                                                    <TableCell
                                                        sx={{
                                                            color:
                                                                overridings
                                                                    .palette
                                                                    .primary
                                                                    .contrastText,
                                                            minWidth: '115px',
                                                            width: '13%',
                                                            '@media (max-width: 600px)': {
                                                                fontSize:
                                                                    '10px',
                                                                minWidth:
                                                                    'unset',
                                                                display: 'none'
                                                            }
                                                        }}
                                                        align="center"
                                                    >
                                                        Normalpris
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            color:
                                                                overridings
                                                                    .palette
                                                                    .primary
                                                                    .contrastText,
                                                            minWidth: '115px',
                                                            width: '13%',
                                                            '@media (max-width: 600px)': {
                                                                fontSize:
                                                                    '10px',
                                                                minWidth:
                                                                    'unset',
                                                                display: 'none'
                                                            }
                                                        }}
                                                        align="center"
                                                    >
                                                        Rabat
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            color:
                                                                overridings
                                                                    .palette
                                                                    .primary
                                                                    .contrastText,
                                                            minWidth: '115px',
                                                            width: '13%',
                                                            '@media (max-width: 600px)': {
                                                                fontSize:
                                                                    '10px',
                                                                minWidth:
                                                                    'unset'
                                                            }
                                                        }}
                                                        align="center"
                                                    >
                                                        {document.body
                                                            .offsetWidth > 600
                                                            ? ' Medlemspris'
                                                            : 'Pris'}
                                                    </TableCell>
                                                </>
                                            )}

                                            <TableCell
                                                sx={{
                                                    color:
                                                        overridings.palette
                                                            .primary
                                                            .contrastText,
                                                    '@media (max-width: 600px)': {
                                                        fontSize: '10px'
                                                    }
                                                }}
                                                align="right"
                                            >
                                                Fjern fra kurv
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                                {/* If there are more than 3 events, then we are not displaying them all, unless they choose to see it */}{' '}
                                {eventsByMonth.map((month) => (
                                    <Box key={month.month}>
                                        <TableContainer
                                            sx={{
                                                maxHeight: '200px',
                                                overflowY: calculateScroll(
                                                    month
                                                )
                                            }}
                                        >
                                            <Table>
                                                <TableBody>
                                                    {/* Always show at least 3 rows */}
                                                    {month.events.map(
                                                        (event, index) => (
                                                            <TableRow
                                                                key={event.id}
                                                                sx={{
                                                                    backgroundColor:
                                                                        overridings
                                                                            .palette
                                                                            .secondary
                                                                            .light
                                                                }}
                                                            >
                                                                {/* Location */}
                                                                <TableCell
                                                                    sx={{
                                                                        color:
                                                                            overridings
                                                                                .palette
                                                                                .primary
                                                                                .contrastText,
                                                                        minWidth:
                                                                            '95px',
                                                                        width:
                                                                            '11%',
                                                                        '@media (max-width: 600px)': {
                                                                            fontSize:
                                                                                '10px',
                                                                            minWidth:
                                                                                'unset'
                                                                        }
                                                                    }}
                                                                    align="left"
                                                                >
                                                                    Lokale{' '}
                                                                    {getLocationNameFromRoomId(
                                                                        event.roomId
                                                                    )}
                                                                </TableCell>
                                                                {/* Date */}
                                                                <TableCell
                                                                    sx={{
                                                                        color:
                                                                            overridings
                                                                                .palette
                                                                                .primary
                                                                                .contrastText,
                                                                        minWidth:
                                                                            '250px',
                                                                        width:
                                                                            '25%',
                                                                        '@media (max-width: 600px)': {
                                                                            fontSize:
                                                                                '10px',
                                                                            minWidth:
                                                                                'unset'
                                                                        }
                                                                    }}
                                                                    align="center"
                                                                >
                                                                    {moment(
                                                                        event.startdate
                                                                    ).format(
                                                                        'DD.MM.YYYY'
                                                                    )}{' '}
                                                                    kl.{' '}
                                                                    {moment(
                                                                        event.startdate +
                                                                            ' ' +
                                                                            event.starttime
                                                                    ).format(
                                                                        'HH:mm'
                                                                    )}
                                                                    -
                                                                    {moment(
                                                                        event.startdate +
                                                                            ' ' +
                                                                            event.endtime
                                                                    ).format(
                                                                        'HH.mm'
                                                                    )}
                                                                </TableCell>
                                                                {/* Display prices */}
                                                                {orderType ===
                                                                    'events' && (
                                                                    <>
                                                                        {/* If user is in last month subscription and events are before last date, 
                                                                we should show the discounted price
                                                                */}{' '}
                                                                        {getRightPriceCellsForEventsOnly(
                                                                            event,
                                                                            overridings
                                                                        )}{' '}
                                                                    </>
                                                                )}

                                                                {orderType ===
                                                                    'combo' && (
                                                                    <>
                                                                        <TableCell
                                                                            sx={{
                                                                                color:
                                                                                    overridings
                                                                                        .palette
                                                                                        .primary
                                                                                        .contrastText,
                                                                                minWidth:
                                                                                    '115px',
                                                                                width:
                                                                                    '13%',
                                                                                textDecoration:
                                                                                    'line-through',
                                                                                '@media (max-width: 600px)': {
                                                                                    fontSize:
                                                                                        '10px',
                                                                                    minWidth:
                                                                                        'unset',
                                                                                    display:
                                                                                        'none'
                                                                                }
                                                                            }}
                                                                            align="center"
                                                                        >
                                                                            {/* {calculateSingleEventDiscountPrice(
                                                                            event.normalPrice,
                                                                            event.startdate,
                                                                            orderType
                                                                        )} */}
                                                                            {(
                                                                                event.normalPrice /
                                                                                100
                                                                            )
                                                                                .toString()
                                                                                .replaceAll(
                                                                                    '.',
                                                                                    ','
                                                                                )}{' '}
                                                                            kr.
                                                                        </TableCell>
                                                                        <TableCell
                                                                            sx={{
                                                                                color:
                                                                                    overridings
                                                                                        .palette
                                                                                        .primary
                                                                                        .contrastText,
                                                                                minWidth:
                                                                                    '115px',
                                                                                width:
                                                                                    '13%',
                                                                                '@media (max-width: 600px)': {
                                                                                    fontSize:
                                                                                        '10px',
                                                                                    minWidth:
                                                                                        'unset',
                                                                                    display:
                                                                                        'none'
                                                                                }
                                                                            }}
                                                                            align="center"
                                                                        >
                                                                            50%
                                                                        </TableCell>
                                                                        <TableCell
                                                                            sx={{
                                                                                color:
                                                                                    overridings
                                                                                        .palette
                                                                                        .primary
                                                                                        .contrastText,
                                                                                minWidth:
                                                                                    '115px',
                                                                                width:
                                                                                    '13%',
                                                                                '@media (max-width: 600px)': {
                                                                                    fontSize:
                                                                                        '10px',
                                                                                    minWidth:
                                                                                        'unset'
                                                                                }
                                                                            }}
                                                                            align="center"
                                                                        >
                                                                            {calculateSingleEventDiscountPrice(
                                                                                event.normalPrice,
                                                                                event.startdate,
                                                                                orderType
                                                                            )}{' '}
                                                                            kr.
                                                                        </TableCell>
                                                                    </>
                                                                )}

                                                                <TableCell
                                                                    sx={{
                                                                        color:
                                                                            overridings
                                                                                .palette
                                                                                .primary
                                                                                .contrastText,
                                                                        '@media (max-width: 600px)': {
                                                                            fontSize:
                                                                                '10px'
                                                                        }
                                                                    }}
                                                                    align="right"
                                                                >
                                                                    <IconContainer
                                                                        actiontype="delete"
                                                                        onClick={() => {
                                                                            handleDeleteClick(
                                                                                event.id,
                                                                                event.repeatSeqId
                                                                            );
                                                                        }}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconContainer>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {orderType === 'events' && (
                                            <>
                                                {month.discountedPricePerMonth ? (
                                                    <SumRowDiscountedPrice
                                                        sumNormalPricePerMonth={
                                                            month.normalPricePerMonth
                                                        }
                                                        sumDiscountedPricePerMonth={
                                                            month.discountedPricePerMonth
                                                        }
                                                        monthArr={month}
                                                        month={month.month}
                                                        dueDate={month.dueDate}
                                                        showDiscountedPrices={
                                                            showDiscountedPrices
                                                        }
                                                    />
                                                ) : (
                                                    <SumRowNormalPrice
                                                        sumNormalPricePerMonth={
                                                            month.normalPricePerMonth
                                                        }
                                                        monthArr={month}
                                                        month={month.month}
                                                        dueDate={month.dueDate}
                                                        showDiscountedPrices={
                                                            showDiscountedPrices
                                                        }
                                                    />
                                                )}
                                            </>
                                        )}
                                        {orderType === 'combo' && (
                                            <SumRowDiscountedPrice
                                                sumNormalPricePerMonth={
                                                    month.normalPricePerMonth
                                                }
                                                sumDiscountedPricePerMonth={
                                                    month.discountedPricePerMonth
                                                }
                                                monthArr={month}
                                                month={month.month}
                                                dueDate={month.dueDate}
                                                showDiscountedPrices={
                                                    showDiscountedPrices
                                                }
                                            />
                                        )}
                                    </Box>
                                ))}
                            </TableContainer>
                        </>
                    ) : (
                        <>
                            <MobileTopRow>
                                <Table>
                                    <TableRow>
                                        <TableCell>
                                            Reservations- og betalingsoversigt
                                        </TableCell>
                                        {draftOwnEvents.length > 3 ? (
                                            <>
                                                <TableCell
                                                // onClick={() =>
                                                //     setShowAllEventRows(
                                                //         !showAllEventRows
                                                //     )
                                                // }
                                                >
                                                    {/* {showAllEventRows ? (
                                                        <ChevronUpContainer>
                                                            <ChevronDownIcon />
                                                        </ChevronUpContainer>
                                                    ) : (
                                                        <ChevronDownIcon />
                                                    )} */}
                                                </TableCell>
                                            </>
                                        ) : (
                                            <TableCell></TableCell>
                                        )}
                                    </TableRow>
                                </Table>
                            </MobileTopRow>
                            {eventsByMonth.map((month) => (
                                <Box key={month.name}>
                                    <TableContainer
                                        sx={{
                                            maxHeight: '200px',
                                            overflowY: calculateScroll(month)
                                        }}
                                    >
                                        <TableMobile>
                                            {month.events.map(
                                                (event, index) => (
                                                    <EventItemCheckout
                                                        key={event.id}
                                                        event={event}
                                                        handleDeleteClick={
                                                            handleDeleteClick
                                                        }
                                                        canBeDeleted={true}
                                                        orderType={orderType}
                                                    />
                                                )
                                            )}
                                        </TableMobile>
                                    </TableContainer>
                                    <TableContainer
                                        component={Paper}
                                        sx={{
                                            background: 'inherit',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        {month.discountedPricePerMonth ? (
                                            <SumRowDiscountedPrice
                                                sumNormalPricePerMonth={
                                                    month.normalPricePerMonth
                                                }
                                                sumDiscountedPricePerMonth={
                                                    month.discountedPricePerMonth
                                                }
                                                monthArr={month}
                                                month={month.month}
                                                dueDate={month.dueDate}
                                                showDiscountedPrices={
                                                    showDiscountedPrices
                                                }
                                                isMobile={true}
                                            />
                                        ) : (
                                            <SumRowNormalPrice
                                                isMobile={true}
                                                sumNormalPricePerMonth={
                                                    month.normalPricePerMonth
                                                }
                                                monthArr={month}
                                                month={month.month}
                                                dueDate={month.dueDate}
                                                showDiscountedPrices={
                                                    showDiscountedPrices
                                                }
                                            ></SumRowNormalPrice>
                                        )}
                                    </TableContainer>
                                </Box>
                            ))}
                        </>
                    )}
                </Box>
            )}

            {(orderType === 'subscription' || orderType === 'combo') && (
                <Box mb="2rem">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 286 }} aria-label="simple table">
                            <TableBody>
                                <TableRow
                                    sx={{
                                        backgroundColor:
                                            overridings.palette.primary.light
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            color:
                                                overridings.palette.primary
                                                    .contrastText,
                                            '@media (max-width: 600px)': {
                                                fontSize: '10px'
                                            }
                                        }}
                                    >
                                        <b>Produkt</b>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            color:
                                                overridings.palette.primary
                                                    .contrastText,
                                            '@media (max-width: 600px)': {
                                                fontSize: '10px'
                                            }
                                        }}
                                        align="center"
                                    >
                                        Pris
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            color:
                                                overridings.palette.primary
                                                    .contrastText,
                                            '@media (max-width: 600px)': {
                                                fontSize: '10px'
                                            }
                                        }}
                                        align="right"
                                    >
                                        Fjern fra kurv
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            <TableBody>
                                {rows.length > 0 &&
                                    rows.map((row) => (
                                        <TableRow
                                            key={row.productName}
                                            sx={{
                                                backgroundColor:
                                                    overridings.palette
                                                        .secondary.light,

                                                '&:last-child td, &:last-child th': {
                                                    border: 0
                                                }
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                sx={{
                                                    color:
                                                        overridings.palette
                                                            .primary
                                                            .contrastText,
                                                    '@media (max-width: 600px)': {
                                                        fontSize: '10px'
                                                    }
                                                }}
                                            >
                                                {row.productName}
                                            </TableCell>
                                            <TableCell
                                                align="center"
                                                sx={{
                                                    color:
                                                        overridings.palette
                                                            .primary
                                                            .contrastText,
                                                    '@media (max-width: 600px)': {
                                                        fontSize: '10px'
                                                    }
                                                }}
                                            >
                                                {row.price}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{
                                                    color:
                                                        overridings.palette
                                                            .primary
                                                            .contrastText,
                                                    '@media (max-width: 600px)': {
                                                        fontSize: '10px'
                                                    }
                                                }}
                                            >
                                                {row.productName !==
                                                    'Medlemskab (fornyes automtisk hver måned)' && (
                                                    <IconContainer
                                                        actiontype="delete"
                                                        onClick={() => {
                                                            handleSubscriptionDeleteClick();
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconContainer>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0
                                        },
                                        backgroundColor:
                                            overridings.palette.primary.light
                                    }}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                            color:
                                                overridings.palette.primary
                                                    .contrastText,
                                            '@media (max-width: 600px)': {
                                                fontSize: '10px'
                                            }
                                        }}
                                    >
                                        <b>I alt til betaling nu:</b>
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        sx={{
                                            color:
                                                overridings.palette.primary
                                                    .contrastText,
                                            '@media (max-width: 600px)': {
                                                fontSize: '10px'
                                            }
                                        }}
                                    >
                                        {sumPrice
                                            .toString()
                                            .replaceAll('.', ',')}{' '}
                                        kr.
                                    </TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            )}
        </ProductTableContainer>
    );
}

export default ProductTable;
