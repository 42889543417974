import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Context } from '../../context/Context';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { overridings } from '../../themes/DarkTheme';
import RegularButton from '../buttons/regular-button/RegularButton';

// const ChangePrivacyContainer = styled.div`
//   position: fixed;
//   overflow: hidden;
//   bottom: 20px;
//   left: 20px;
//   cursor: pointer;
// `;

const ButtonContainer = styled(Box)`
    margin: 1rem 1rem 0 1rem;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

const DrawerContainer = styled(Drawer)`
    @media (max-width: 350px) {
        & .MuiSlider-markLabelActive {
            font-size: 0.7rem;
        }
        & .MuiSlider-markLabel {
            font-size: 0.7rem;
        }
        & .MuiButton-root {
            min-width: 50px;
        }
        .Privacy-header-49 {
            padding-bottom: 5px;
        }
    }

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        & .MuiDrawer-paper {
            padding-bottom: 1rem;
        }
    }
`;
const Text = styled(Typography)`
    @media (max-width: 350px) {
        font-size: 0.7rem;
    }
`;

const TextBold = styled(Typography)`
    /* font-size: 0.9rem; */
    font-family: 'Benton Sans Medium';
`;
const PrivacyButton = styled(Button)`
    box-shadow: inset 0 0 5px grey;
    margin: 0rem 1rem 1rem 1rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin: 1rem 1rem 0 1rem;
    }

    @media (max-width: 350px) {
        font-size: 0.7rem;
    }
`;

const ExplainerContainer = styled(Box)`
    display: flex;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
        margin-bottom: 0.5rem;
    }
`;

const domain = document.location.hostname; // For example: dev.denlillemusikskole.dk, test.denlillemusikskole.dk

const cookiePrivacyLevel = domain + ':privacy-level';

const styles = (theme) => ({
    container: {
        padding: 10,
        width: '100%',
        // minHeight: 480,

        [`@media (max-width:${overridings.breakpoints.values.md})`]: {
            overflowY: 'scroll'
        }
    },
    paper: {
        // height: "60%",
        // minHeight: "600px",
        marginLeft: '2%',
        width: '96%',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10
        // media query
        // ['@media (max-width:300px)']: {
        //   minHeight: 635,
        // },
    },
    header: {
        fontSize: '1rem',
        marginTop: '0',
        marginBottom: '10px'
    },
    slider: {
        color: '#999999',
        marginLeft: '10%',
        width: '80%'
    },
    info: {
        border: '0px',
        padding: '10px 15px',
        margin: '0px',
        marginTop: '10px',
        overflow: 'auto',
        minHeight: '120px',
        width: '95%'
    },
    list: {
        margin: '0px',
        overflowX: 'hidden',
        overflowY: 'scroll',
        height: '150px',
        width: '100%'
    },
    cookie: {
        border: '1px solid #DDDDDD',
        borderRadius: '5px',
        marginRight: '10px',
        marginBottom: '10px'
    },
    cookieHeader: {
        display: 'flex',
        backgroundColor: '#999999',
        padding: '5px'
    },
    cookieHeaderTitle: {
        flex: 1
    },
    cookieBody: {
        padding: '5px'
    },
    storage: {
        border: '1px solid #DDDDDD',
        borderRadius: '5px',
        marginRight: '10px',
        marginBottom: '10px'
    },
    storageHeader: {
        display: 'flex',
        backgroundColor: '#999999',
        padding: '5px'
    },
    storageHeaderTitle: {
        flex: 1
    },
    storageBody: {
        padding: '5px'
    },
    checkbox: {},
    leftButton: {
        float: 'left'
    },
    rightButton: {
        float: 'right',
        border: '1px solid #002460',
        borderRadius: '20px',
        paddingTop: 10,
        fontWeight: 'bold',
        backgroundColor: '#DCE775'
    }
});

const values = {
    cookieList: [
        {
            name: cookiePrivacyLevel,
            level: 2,
            manager: 'Den Ny Musikskole ApS',
            purpose: 'Gemmer dit valg af cookie accept',
            domains: domain,
            expires: ''
        },
        {
            name: 'email',
            level: 2,
            manager: 'Den Ny Musikskole ApS',
            purpose: 'Din e-mail bruges til login',
            domains: domain,
            expires: ''
        },
        {
            name: 'access-token',
            level: 1,
            manager: 'Den Ny Musikskole ApS',
            purpose: 'Session token bruges til adgangsrettigheder',
            domains: domain,
            expires: ''
        }
    ],
    storageList: [
        {
            name: domain + ':state-history',
            manager: 'Den Ny Musikskole ApS',
            purpose: 'App tilstand og seneste lokation gemmes',
            domains: domain,
            expires: ''
        }
    ]
};

// const levels = [
//   {
//     value: 1,
//     label: "Nødvendig",
//   },
//   {
//     value: 2,
//     label: "Præference",
//   },
//   {
//     value: 3,
//     label: "Statistik",
//   },
//   {
//     value: 4,
//     label: "Marketing",
//   },
// ];

class Privacy extends Component {
    static contextType = Context;
    state = {
        open: this.context.state.isOpenPrivacy,
        list: false,
        level: 4,
        dialog: false,
        dialogCancelButton: true,
        dialogTitle: '',
        dialogMessage: '',
        dialogCancel: undefined,
        dialogConfirm: undefined
    };

    componentWillUnmount() {}

    componentDidMount() {
        // if (this.props.showCookies) {
        //   this.toggleDrawer(true)
        // }
        // if (this.props.open) {
        //   this.setState({ open: true });
        // }
        // let context = this.context;
        let level = 0;
        try {
            level = parseInt(this.getCookie(cookiePrivacyLevel));
            console.log('level', level);
        } catch {}
        if (this.context.state.isOpenPrivacy) {
            this.show();
        }

        if (level > 0) {
            // if(this.props.open){
            //   this.show();
            // }
            this.setState({ level });
        } else {
            // TODO: Fix error in strict mode
            this.show();
        }
    }

    // Standard alert dialog
    showMessageDialog = (title, message, confirmCallback, cancelCallback) => {
        this.setState({
            dialog: true,
            dialogTitle: title,
            dialogMessage: message,
            dialogCancel: cancelCallback,
            dialogConfirm: confirmCallback
        });
    };

    handleCloseMessageDialog = (event) => {
        this.setState({
            dialog: false,
            dialogCancel: null,
            dialogConfirm: null
        });
    };

    handleCancelMessageDialog = (event) => {
        this.setState({ dialog: false });
        if (this.state.dialogCancel) this.state.dialogCancel();
    };

    handleConfirmMessageDialog = (event) => {
        this.setState({ dialog: false });
        if (this.state.dialogConfirm) {
            this.state.dialogConfirm();
        }
    };

    show = () => {
        this.setState({ open: true });
        this.context.saveIsOpenPrivacy(true);
    };

    hide = () => {
        this.setState({ open: false });
        this.context.saveIsOpenPrivacy(false);
    };

    getCookie = (name) => {
        const { cookies } = this.props;
        return cookies.get(name);
    };

    setCookie = (name, value) => {
        const { cookies } = this.props;
        // Make cookie available to any path /
        cookies.set(name, value, { path: '/' });
    };

    removeCookie = (name) => {
        const { cookies } = this.props;
        // const { cookies } = window.sweetspot.app.props;
        cookies.remove(name, { path: '/' });
        this.forceUpdate();
    };

    removeStorage = (name) => {
        if (localStorage) {
            localStorage.removeItem(name);
            this.forceUpdate();
        }
    };

    removeChecked = () => {
        let self = this;
        Object.keys(self.state).forEach((key) => {
            if (key.startsWith('storage-')) {
                if (self.state[key]) {
                    self.removeStorage(key.substring(8));
                    self.setState({ key: false });
                }
            }
            if (key.startsWith('cookie-')) {
                if (self.state[key]) {
                    self.removeCookie(key.substring(7));
                    self.setState({ key: false });
                }
            }
        });
        this.forceUpdate();
    };

    handleRemoveChecked = (event) => {
        // let self = this;

        let text = [];
        text.push(
            <div key={text.length}>
                Slet alle lokale browserdata og cookies?
            </div>
        );

        // They wanted us to remove the checkboxes and list, so this is outcommented
        // Object.keys(self.state).forEach((key) => {
        //   console.log(key);
        //   if (key.startsWith("storage-")) {
        //     text.push(<div key={text.length}>Item {key.substring(8)}</div>);
        //   }
        //   if (key.startsWith("cookie-")) {
        //     text.push(<div key={text.length}>Cookie {key.substring(7)}</div>);
        //   }
        // });

        const removesAllCookiesAndStorage = () => {
            // Instead of removing the chosen cookies and local storage, we empty it
            localStorage.clear();
            this.removeCookie(`${domain}:privacy-level`);
        };

        this.showMessageDialog(
            'Slet browserdata',
            text,
            removesAllCookiesAndStorage
        );
    };

    handleCheckboxChange = (name) => (event) => {
        this.setState({ ...this.state, [name]: event.target.checked });
    };

    toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        this.setState({ ...this.state, open });
        this.context.saveIsOpenPrivacy(!this.context.state.isOpenPrivacy);
    };

    showCookiePrivacy = (event) => {
        this.setState({ list: true });
    };

    showDataProtection = (event) => {
        this.setState({ list: false });
    };

    handleAccept = (event) => {
        this.setCookie(cookiePrivacyLevel, this.state.level);
        // this.context.saveIsOpenPrivacy(false);
        this.hide();
    };

    setCookieLevel = (value) => {
        if (value === 'necessary') {
            this.setState({ level: 1 });
        } else if (value === 'preference') {
            this.setState({ level: 2 });
        } else if (value === 'statistic') {
            this.setState({ level: 3 });
        } else if (value === 'marketing') {
            this.setState({ level: 4 });
        }
    };

    getButtonBg = (buttonLevel) => {
        if (buttonLevel === this.state.level) {
            return overridings.palette.primary.gray;
        } else {
            return overridings.palette.colors.green;
        }
    };

    render() {
        console.log('rendered privacy');
        // let context = this.context;

        const { classes, cookies } = this.props;

        // Get all cookie name and value object
        const cookie = cookies.getAll();

        // const valuetext = (value) => {
        //   return value;
        // };

        let localStorageKeys = [];
        if (localStorage) localStorageKeys = Object.keys(localStorage);

        // Combine values.storageList with any local storage keys
        let domain = window.location.href;
        let storageList = JSON.parse(JSON.stringify(values.storageList));
        let storageListKeys = storageList.map((item) => item.name);
        localStorageKeys.forEach((key) => {
            if (!storageListKeys.includes(key)) {
                storageList.push({
                    name: key,
                    domains: domain
                });
            }
        });

        // const renderLocalStorage = (name, index) => {
        //   let value = localStorage.getItem(name);
        //   let size = 0;
        //   if (value) size = Math.floor(value.length / 1024);
        //   let info = storageList[index];
        //   if (info) {
        //     return (
        //       <div key={"storage" + index} className={classes.storage}>
        //         <div className={classes.storageHeader}>
        //           <div className={classes.storageHeaderTitle}>{name}</div>
        //           <Checkbox
        //             icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        //             checkedIcon={<CheckBoxIcon fontSize="small" />}
        //             className={classes.checkbox}
        //             checked={
        //               this.state["storage-" + name] !== undefined
        //                 ? this.state["storage-" + name]
        //                 : false
        //             }
        //             onChange={this.handleCheckboxChange("storage-" + name)}
        //             value={name}
        //           />
        //         </div>
        //         <div className={classes.storageBody}>
        //           <div style={{ display: info.manager ? "block" : "none" }}>
        //             Ansvarlig: {info.manager}
        //           </div>
        //           <div style={{ display: info.purpose ? "block" : "none" }}>
        //             Formål: {info.purpose}
        //           </div>
        //           <div style={{ display: info.domains ? "block" : "none" }}>
        //             Domæne: {info.domains}
        //           </div>
        //           <div style={{ display: info.expires ? "block" : "none" }}>
        //             Udløber: {info.expires}
        //           </div>
        //           <div>Størrelse: {size} Kb</div>
        //         </div>
        //       </div>
        //     );
        //   }
        //   return (
        //     <div key={"storage" + index}>
        //       <div className={classes.storageHeader}>{name}</div>
        //       <div>Størrelse: {size} Kb</div>
        //     </div>
        //   );
        // };

        // Combine values.cookieList with all cookies found
        let cookieList = JSON.parse(JSON.stringify(values.cookieList));
        let cookieListKeys = cookieList.map((item) => item.name);
        Object.keys(cookie).forEach((key) => {
            if (!cookieListKeys.includes(key)) {
                cookieList.push({
                    name: key,
                    domains: domain
                });
            }
        });

        //console.log('Cookie',cookie)
        //console.log('Cookie list',cookieList)
        //console.log('Function cookies',  Object.keys(cookie).filter( x => (cookieList[x] && cookieList[x].level === 2) ))

        // const renderCookie = (name, index) => {
        //   let value = cookie[name];
        //   let info = cookieList[index];
        //   if (info) {
        //     return (
        //       <div key={"cookie" + index} className={classes.cookie}>
        //         <div className={classes.cookieHeader}>
        //           <div className={classes.cookieHeaderTitle}>{name}</div>
        //           <Checkbox
        //             icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        //             checkedIcon={<CheckBoxIcon fontSize="small" />}
        //             className={classes.checkbox}
        //             checked={
        //               this.state["cookie-" + name] !== undefined
        //                 ? this.state["cookie-" + name]
        //                 : false
        //             }
        //             onChange={this.handleCheckboxChange("cookie-" + name)}
        //             value={name}
        //           />
        //         </div>
        //         <div className={classes.cookieBody}>
        //           <div style={{ display: info.manager ? "block" : "none" }}>
        //             Ansvarlig: {info.manager}
        //           </div>
        //           <div style={{ display: info.purpose ? "block" : "none" }}>
        //             Formål: {info.purpose}
        //           </div>
        //           <div style={{ display: info.domains ? "block" : "none" }}>
        //             Domæne: {info.domains}
        //           </div>
        //           <div style={{ display: info.expires ? "block" : "none" }}>
        //             Udløber: {info.expires}
        //           </div>
        //           <div>Værdi: {value}</div>
        //         </div>
        //       </div>
        //     );
        //   }
        //   return (
        //     <div key={"cookie" + index}>
        //       <div className={classes.cookieHeader}>{name}</div>
        //       <div className={classes.cookieBody}>
        //         <div>Værdi: {value}</div>
        //       </div>
        //     </div>
        //   );
        // };

        return (
            <React.Fragment>
                <DrawerContainer
                    variant="persistent"
                    classes={{ paper: classes.paper }}
                    id="privacy"
                    anchor="bottom"
                    open={this.state.open}
                    onClose={this.toggleDrawer(false)}
                >
                    <div className={classes.container}>
                        <div className={classes.info}>
                            <h3 className={classes.header}>
                                Cookieindstillinger
                            </h3>
                            <TextBold variant="body2">
                                Her kan du vælge, hvilke cookies vi må gemme
                            </TextBold>
                            <Text variant="body2" id="gdpr" gutterBottom>
                                Vi behandler dine private data fortroligt, og du
                                kan altid logge ind og se dine oplysninger eller
                                lukke din konto, så alle data anonymiseres i det
                                omfang lovgivningen tillader det. Vi er jævnfør
                                regnskabsloven forpligtet til at gemme
                                regnskabsinformationer om betalinger i 5 år.
                            </Text>
                        </div>
                        {/* <div
              className={classes.list}
              style={{ display: this.state.list ? "none" : "block" }}
            >
              <h3 className={classes.header}>Data gemt i din browser</h3>
              <div>
                {storageList.map((item, index) =>
                  renderLocalStorage(item.name, index)
                )}
              </div>
            </div> */}
                        {/* <div
              className={classes.list}
              style={{ display: this.state.list ? "block" : "none" }}
            >
              <h3 className={classes.header}>Cookies (ePrivacy)</h3>
              {cookieList
                .filter((item) => item.level === 1)
                .map((item, index) => renderCookie(item.name, index))}
              <h3>Funktion cookies</h3>
              {cookieList
                .filter((item) => item.level === 2)
                .map((item, index) => renderCookie(item.name, index))}
              <h3>Statistik cookies</h3>
              {cookieList
                .filter((item) => item.level === 3)
                .map((item, index) => renderCookie(item.name, index))}
              <h3>Marketing cookies</h3>
              {cookieList
                .filter((item) => item.level === 4)
                .map((item, index) => renderCookie(item.name, index))}
            </div> */}
                        <div style={{ marginTop: '10px', paddingLeft: '15px' }}>
                            <h3 className={classes.header}>
                                Du kan vælge mellem 4 cookieindstillinger:
                            </h3>
                            <ExplainerContainer>
                                <Box mr="0.5rem">
                                    <TextBold variant="body2">
                                        Nødvendige:{' '}
                                    </TextBold>
                                </Box>

                                <Text variant="body2">
                                    Cookies, som skal bruges for at hjemmesiden
                                    kan fungere
                                </Text>
                            </ExplainerContainer>
                            <ExplainerContainer>
                                <Box mr="0.5rem">
                                    <TextBold variant="body2">
                                        Præference:{' '}
                                    </TextBold>
                                </Box>

                                <Text variant="body2">
                                    Cookies, som husker nogle af dine valg, fx
                                    dit foretrukne sprog eller i hvilken del af
                                    landet, du befinder dig.
                                </Text>
                            </ExplainerContainer>
                            <ExplainerContainer>
                                <Box mr="0.5rem">
                                    <TextBold variant="body2">
                                        Statistik:{' '}
                                    </TextBold>
                                </Box>

                                <Text variant="body2">
                                    Cookies, som kan bruges i vores statistik.
                                    Det er fx registrering af klik på links
                                    eller hvor lang tid, der bruges på en side.
                                    Statistiske cookies hjælper os med at gøre
                                    hjemmesiden mere brugervenlig.
                                </Text>
                            </ExplainerContainer>
                            <ExplainerContainer>
                                <Box mr="0.5rem">
                                    <TextBold variant="body2">
                                        Marketing:{' '}
                                    </TextBold>
                                </Box>

                                <Text variant="body2">
                                    Cookies, som kan bruges til at give dig
                                    relevante oplysninger, tilbud og annoncer,
                                    som er tilpasset den enkelte bruger.
                                </Text>
                            </ExplainerContainer>
                        </div>
                        <ButtonContainer>
                            <RegularButton
                                title="Nødvendig"
                                onButtonClick={() =>
                                    this.setCookieLevel('necessary')
                                }
                                background={this.getButtonBg(1)}
                                mobileWidth="13rem"
                                margin="1rem auto"
                                minWidth="13rem"
                            />
                            <RegularButton
                                title="Præference"
                                onButtonClick={() =>
                                    this.setCookieLevel('preference')
                                }
                                background={this.getButtonBg(2)}
                                mobileWidth="13rem"
                                margin="1rem auto"
                                minWidth="13rem"
                            />
                            <RegularButton
                                title="Statistik"
                                onButtonClick={() =>
                                    this.setCookieLevel('statistic')
                                }
                                background={this.getButtonBg(3)}
                                mobileWidth="13rem"
                                margin="1rem auto"
                                minWidth="13rem"
                            />
                            <RegularButton
                                title="Marketing"
                                onButtonClick={() =>
                                    this.setCookieLevel('marketing')
                                }
                                background={this.getButtonBg(4)}
                                mobileWidth="13rem"
                                margin="1rem auto"
                                minWidth="13rem"
                            />
                        </ButtonContainer>
                        {/* Michael wants buttons instead of slider */}
                        {/* <div>
              <Slider
                onChange={(event, value) => this.setState({ level: value })}
                value={this.state.level}
                step={1}
                min={1}
                max={4}
                getAriaValueText={valuetext}
                aria-labelledby="cookie-consent-slider"
                valueLabelDisplay="off"
                marks={levels}
                className={classes.slider}
              />
            </div> */}
                        <div>
                            {/* <PrivacyButton
                className={classes.leftButton}
                onClick={this.showDataProtection}
                color="default"
              >
                Data
              </PrivacyButton>
              <PrivacyButton
                className={classes.leftButton}
                onClick={this.showCookiePrivacy}
                color="default"
              >
                Cookies
              </PrivacyButton> */}

                            <PrivacyButton
                                className={classes.leftButton}
                                onClick={this.handleRemoveChecked}
                                color="default"
                            >
                                Slet
                            </PrivacyButton>
                            <PrivacyButton
                                className={classes.rightButton}
                                onClick={this.handleAccept}
                                color="primary"
                            >
                                Tillad
                            </PrivacyButton>
                        </div>
                    </div>
                    <Dialog
                        open={this.state.dialog}
                        onClose={this.handleCloseMessageDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {this.state.dialogTitle}
                        </DialogTitle>
                        <DialogContent>
                            <div>{this.state.dialogMessage}</div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.handleCancelMessageDialog}
                                style={{
                                    display: this.state.dialogCancelButton
                                        ? 'block'
                                        : '  none'
                                }}
                            >
                                Annuller
                            </Button>
                            <Button
                                onClick={this.handleConfirmMessageDialog}
                                autoFocus
                            >
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </DrawerContainer>
            </React.Fragment>
        );
    }
}

Privacy.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withCookies(withStyles(styles)(Privacy));
