import React from 'react';
import styled from 'styled-components';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import TermsOfPurchase from '../../terms-of-purchase/TermsOfPurchase';

const CustomDialogContent = styled(DialogContent)`
    overflow: hidden;
    @media (max-width: 350px) {
        padding: 8px 10px;
    }
`;

const DialogComponent = styled(Dialog)`
    position: relative;
    & .MuiPaper-root {
        padding: 1rem !important;
        @media (max-width: 350px) {
            padding: 10px 0px !important;
        }
    }
    & .MuiTypography-h6 {
        @media (max-width: 350px) {
            font-size: 1.1rem;
        }
    }
    .MuiDialogTitle-root {
        @media (max-width: 350px) {
            padding: 8px 10px;
        }
    }
`;

function TermsDialog({ close }) {
    return (
        <DialogComponent
            onClose={close}
            open={true}
            aria-labelledby="terms-and-conditions"
            aria-describedby="terms-and-conditions"
            disableRestoreFocus
        >
            <DialogTitle id="terms-and-conditions">
                Handels- og medlemsbetingelser
            </DialogTitle>
            <CustomDialogContent>
                <DialogContentText id="terms-and-conditions"></DialogContentText>
                <TermsOfPurchase isDialog={true} />
            </CustomDialogContent>
            <DialogActions>
                <Button onClick={() => close()}>Ok</Button>
            </DialogActions>
        </DialogComponent>
    );
}

export default TermsDialog;
