import React, {
    // useEffect,
    useState,
    useContext
} from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { overridings } from '../../themes/DarkTheme';
import {
    getAndReturnData,
    postTokenAuthorization
} from '../../context/helperFunctions';
import { getControlPoints, getToken } from '../../context/getData';
import RegularButton from '../buttons/regular-button/RegularButton';
import { Context } from '../../context/Context';
import Loader from '../loader/Loader';

const OpenDoorButtonContainer = styled(Box)`
    width: ${(props) => (props.customwidth ? props.customwidth : '18%')};
    min-width: 10rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 50%;
        max-width: 10rem;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
`;

const PaperContainer = styled(Paper)`
    background-color: transparent;
    box-shadow: none;
`;
const OpenDoorMessage = styled(MenuList)`
    z-index: 1;
    .MuiPaper-root {
        background-color: transparent;
    }
    background-color: transparent;
`;
const MenuItemMessage = styled(MenuItem)`
    background-color: white;
    color: black;
    width: 100%;
    white-space: normal;
    border-radius: 7px;
    &:hover {
        background-color: white;
    }
`;

const CustomPopper = styled(Popper)`
    z-index: 1 !important;
`;

export default function OpenDoor({ customWidth }) {
    const [open, setOpen] = useState(false);
    const anchorRef = React.useRef(null);
    const [openDoorMessege, setOpenDoorMessege] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const context = useContext(Context);
    let controlPoints = getControlPoints(context);
    const handleCloseMenu = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const handleError = (err) => {
        console.log('An error occured', err);
    };

    const postToOpenDoor = async () => {
        const jwtToken = await getToken();
        // Check if user is an employee (has @denlillemusikskole email)
        // If it is, we don't need to know the location coordinates
        // if (getUserEmail().endsWith("@denlillemusikskole.dk")) {

        // Update: for now we disable the checking of geo location
        const result = await postTokenAuthorization(
            '/api/v1/uni-lock/open-door',
            {},
            jwtToken,
            handleError
        );

        if (result) {
            if (result.status === 200) {
                setOpen(true);
                setOpenDoorMessege('Døren er åben i 3 sekunder.');
            } else if (result.status === 400) {
                setOpen(true);
                setOpenDoorMessege(
                    'Du skal være medlem eller have en reservation for at komme ind.'
                );
            } else if (result.status === 422) {
                setOpen(true);
                setOpenDoorMessege(
                    'Fejl: Du skal max være 20 meter fra huset.'
                );
            } else if (result.status === 500) {
                setOpen(true);
                setOpenDoorMessege('Ukendt fejl.');
            } else {
                setOpen(true);
                setOpenDoorMessege('Ukendt fejl.');
            }
            setIsLoading(false);
        } else {
            setTimeout(() => {
                setOpen(true);
                setOpenDoorMessege('Ukendt fejl.');
                handleError('Post OpenDoor response error result undefiend');
                setIsLoading(false);
            }, 2000);
        }
        // } else {
        // Disabled location check from here
        // let options = {
        //   enableHighAccuracy: true,
        //   timeout: 5000,
        //   maximumAge: 0,
        // };

        // async function success(position) {
        //   // let crd = position.coords;
        //   const result = await postTokenAuthorization(
        //     "/api/v1/uni-lock/open-door",
        //     // { latitudeUser: crd.latitude, longitudeUser: crd.longitude },
        //     {},
        //     jwtToken,
        //     handleError
        //   );

        //   if (result) {
        //     if (result.status === 200) {
        //       setOpen(true);
        //       setOpenDoorMessege("Døren er åben i 3 sekunder.");
        //     } else if (result.status === 400) {
        //       setOpen(true);
        //       setOpenDoorMessege(
        //         "Du skal have abonnement eller reserveringer for at komme ind."
        //       );
        //     } else if (result.status === 422) {
        //       setOpen(true);
        //       setOpenDoorMessege("Fejl: Du skal max være 20 meter fra huset.");
        //     } else {
        //       setOpen(true);
        //       setOpenDoorMessege(
        //         "Du skal have et abonnement eller en booking for at komme ind."
        //       );
        //     }
        //   } else {
        //     setOpen(true);
        //     // Here to add contact admin and add phone number
        //     setOpenDoorMessege("Ukendt fejl.");
        //     handleError("Post OpenDoor response error result undefiend");
        //   }
        //   setIsLoading(false);
        // }

        // function error(err) {
        //   console.warn(`ERROR(${err.code}): ${err.message}`);
        //   setOpen(true);
        //   setOpenDoorMessege(
        //     `Fejl: Check at din browser har lov til at bruge GPS og at du er maks. 20 meter fra huset.`
        //   );
        //   setIsLoading(false);
        // }
        // navigator.geolocation.getCurrentPosition(success, error, options);
        // Disabled location check until here

        // }
    };

    const handleOpenDoor = async (event) => {
        setIsLoading(true);
        event.persist();
        let doorId;
        if (open) {
            setOpen(false);
            setIsLoading(false);
        } else {
            if (controlPoints !== undefined) {
                if (Object.keys(controlPoints).length !== 0) {
                    doorId = controlPoints.controlPoints.controlPoints[0].guid;
                }
            } else {
                // Send get request to Unilock to get doors list
                const response = await getAndReturnData(
                    'api/v1/uni-lock/access',
                    handleError
                );
                if (response) {
                    if (response.controlpoint !== undefined) {
                        context.saveControlPoints({
                            controlPoints: response.controlpoint
                        });
                        controlPoints = response.controlpoint;
                        doorId = controlPoints[0].guid;
                    }
                }
                // else {
                //     setOpen(true);
                //     setOpenDoorMessege('Ukendt fejl.');
                //     handleError('getdoors error');
                // }
            }
            await postToOpenDoor(doorId);
        }
    };
    return (
        <>
            <OpenDoorButtonContainer ref={anchorRef} customwidth={customWidth}>
                <RegularButton
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onButtonClick={handleOpenDoor}
                    title="Lås op"
                    background={overridings.palette.colors.green}
                    mobileWidth={'13rem'}
                    isOpenDoorButton={true}
                />
            </OpenDoorButtonContainer>
            <CustomPopper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom'
                        }}
                    >
                        <PaperContainer className="paper-container">
                            <ClickAwayListener onClickAway={handleCloseMenu}>
                                <OpenDoorMessage
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    <MenuItemMessage>
                                        {openDoorMessege}
                                    </MenuItemMessage>
                                </OpenDoorMessage>
                            </ClickAwayListener>
                        </PaperContainer>
                    </Grow>
                )}
            </CustomPopper>

            {isLoading && (
                <Box ml="auto" mr="auto" width="fit-content" mt="1rem">
                    <Loader />
                </Box>
            )}
        </>
    );
}
