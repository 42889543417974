import React from 'react';
import styled from 'styled-components';
import { getStatus } from './ProgressBarFunctions';
import { Box } from '@material-ui/core';
import ProgressStage from './ProgressStage';

import StageLine from './StageLine';
import { useEffect } from 'react';
import { useState } from 'react';

const ProgressStageContainer = styled(Box)`
    margin-bottom: 4rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin-bottom: 1.5rem;
    }
`;

// Stages of enrollment signup
const stagesInitial = [
    { stageNumber: 1, stageTitle: 'Kalender', status: 'initial' },
    { stageNumber: 2, stageTitle: 'Oplysninger', status: 'initial' },
    { stageNumber: 3, stageTitle: 'Opsummering', status: 'initial' }, // If you change the title, change condition in StageTitle component as well accordingly!
    { stageNumber: 4, stageTitle: 'Betaling', status: 'initial' },
    { stageNumber: 5, stageTitle: 'Kvittering', status: 'initial' }
];

const authStagesInitial = [
    { stageNumber: 1, stageTitle: 'Kalender', status: 'initial' },
    { stageNumber: 2, stageTitle: 'Opsummering', status: 'initial' },
    { stageNumber: 3, stageTitle: 'Betaling', status: 'initial' },
    { stageNumber: 4, stageTitle: 'Kvittering', status: 'initial' }
];

function ProgressBar({ currentStage, type }) {
    const getStagesData = (type) => {
        if (type === 'auth') {
            return authStagesInitial;
        } else {
            return stagesInitial;
        }
    };

    const [trimmedStages, setTrimmedStages] = useState([]);
    const [firstStage, setFirstStage] = useState([]);

    useEffect(() => {
        const stagesData = getStagesData(type);

        const stages = getStatus(stagesData, currentStage);

        //Separating first and rest of the stages - to use horizontal line between stages
        const stagesTrimmed = stages.filter((stage) => stage.stageNumber > 1);
        setTrimmedStages(stagesTrimmed);

        const stageFirst = stages.filter((stage) => stage.stageNumber === 1);
        setFirstStage(stageFirst);
    }, [type, currentStage]);

    return (
        <ProgressStageContainer display="flex" justifyContent="space-between">
            {firstStage.map((stage) => (
                <ProgressStage
                    key={stage.stageNumber}
                    stageNumber={stage.stageNumber}
                    stageTitle={stage.stageTitle}
                    status={stage.status}
                    currentStage={currentStage}
                    type={type}
                />
            ))}
            {trimmedStages.map((stage) => (
                <React.Fragment key={stage.stageNumber}>
                    <StageLine status={stage.status} />
                    <ProgressStage
                        stageNumber={stage.stageNumber}
                        stageTitle={stage.stageTitle}
                        status={stage.status}
                        currentStage={currentStage}
                        type={type}
                    />
                </React.Fragment>
            ))}
        </ProgressStageContainer>
    );
}

export default ProgressBar;
