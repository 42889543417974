import React from 'react';
import { motion } from 'framer-motion';
import ContentContainer from '../../components/containers/ContentContainer';
import PersonalDataRules from '../../components/personal-data-rules/PersonalDataRules';
import RegularHeadline from '../../components/headlines/RegularHeadline';
import HeadersContainer from '../../components/headers/headers-container/HeadersContainer';

function PersonalDataPage(props) {
    return (
        <div>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.7 }}
            >
                <HeadersContainer />

                <ContentContainer>
                    <RegularHeadline
                        title="Persondatapolitikken"
                        smallfontsize="0.9rem"
                    />
                    <PersonalDataRules />
                </ContentContainer>
            </motion.div>
        </div>
    );
}

export default PersonalDataPage;
