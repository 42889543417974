import React from 'react';
import styled from 'styled-components';
import { prevWeek, prevDay } from '../CalendarFunctions';

const ArrowContainer = styled.div`
    font-size: 1.8rem;
    position: absolute;
    left: 1rem;
    cursor: pointer;
    top: ${(props) => (props.top ? props.top : '15rem')};
`;

function LeftArrow({ setDateObject, dateObject, arrowType, top }) {
    return (
        <ArrowContainer
            top={top}
            onClick={() =>
                arrowType === 'rooms'
                    ? setDateObject(prevDay(dateObject))
                    : setDateObject(prevWeek(dateObject))
            }
        >
            &#60;
        </ArrowContainer>
    );
}

export default LeftArrow;
