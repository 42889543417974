import React, { useContext } from 'react';

import { withRouter } from 'react-router-dom';
import { Context } from '../../../context/Context';
import { isSubscriber } from '../../../context/getData';

function ClickableLink(props) {
    const context = useContext(Context);

    const onMembershipClick = () => {
        if (isSubscriber()) {
            // If the user is already subscriber, just take them to mypage.
            props.history.push('/min-side');
        } else {
            // If the user is not subscriber, we should send them to the checkout page, and add subscription in the local storage
            context.saveOrder({ subscription: true });
            props.history.push({
                pathname: '/opsummering',
                state: {
                    from: { path: '/faq', title: 'FAQ' },
                    isOnlySignup: true
                }
            });
        }
    };

    return (
        <>
            {props.wantsMembership ? (
                <span onClick={() => onMembershipClick()}>{props.text}</span>
            ) : (
                <span
                    onClick={() =>
                        props.history.push({
                            pathname: props.pathname,
                            state: {
                                from: {
                                    path: props.fromPath,
                                    title: props.fromTitle
                                },
                                isOnlySignup: true
                            }
                        })
                    }
                >
                    {props.text}
                </span>
            )}
        </>
    );
}

export default withRouter(ClickableLink);
