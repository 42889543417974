import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { Context } from '../../../context/Context';

import RegistrationContentContainer from '../../../components/containers/registration/RegistrationContentContainer';
import RegularHeadline from '../../../components/headlines/RegularHeadline';

import RegistrationButton from '../../../components/buttons/registration-button/RegistrationButton';

import ErrorDialog from '../../../components/dialogs/error-dialog/ErrorDialog';
import DeleteDialog from '../../../components/dialogs/delete-dialog/DeleteDialog';
import {
    deleteAndReturnResp,
    deleteAndReturnRespWithAuth,
    getAndReturnData
} from '../../../context/helperFunctions';
import RegularButton from '../../../components/buttons/Button';
import ProgressBar from '../../../components/progress-bar/ProgressBar';

import ResendCodeDialog from '../../../components/dialogs/resend-code-dialog/ResendCodeDialog';
import { motion } from 'framer-motion';

import {
    getIsNewUser,
    getOrder,
    getUserId,
    returnAuthenticated,
    getOwnEvents,
    returnTotalNormalPrice,
    returnRepeatSeqId,
    getLocationNameFromRoomId,
    getCurrentStage,
    returnOrderType,
    getSubscriptionOrderFromStorage,
    getToken,
    getLastRoomId,
    hasPrevPageDestination,
    getPrevPageDestination,
    getPrevPageUrl
} from '../../../context/getData';
import ReturnLinkReusable from '../../../components/links/ReturnLinkReusable';
import { subscriptionPricePerMonth } from '../../../util';
import DeleteOrderDialog from '../../../components/dialogs/delete-order-dialog/DeleteOrderDialog';
import SuccessDialog from '../../../components/dialogs/success-dialog/SuccessDialog';
import moment from 'moment';
import HeadersContainer from '../../../components/headers/headers-container/HeadersContainer';
import { overridings } from '../../../themes/DarkTheme';

import RuleExplanationDialog from '../../../components/dialogs/rule-explanation-dialog/RuleExplanationDialog';
import NewSubscriptionOnlyText from '../../../components/checkout-texts/NewSubscriptionOnlyText';
import NewEventsAndSubscriptionText from '../../../components/checkout-texts/NewEventsAndSubscriptionText';

import NewEventsOnlyText from '../../../components/checkout-texts/NewEventsOnlyText';
import ProductTable from '../../../components/product-table/ProductTable';

const DescriptionText = styled(Typography)``;

const ReturnLinkContainer = styled.span`
    cursor: pointer;
`;

const ButtonContainer = styled(Box)`
    width: 12rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 100%;
    }
`;

const FlexContainer = styled(Box)`
    display: flex;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

function Checkout(props) {
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // Prices
    const [priceToAuthorise, setPriceToAuthorise] = useState(0);
    const [monthlyPriceSubscription, setMonthlyPriceSubscription] = useState(0);
    const [totalPriceEvents, setTotalPriceEvents] = useState(0);
    const [draftOwnEvents, setDraftOwnEvents] = useState([]);
    const [newSubscriber, setNewSubscriber] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [selectedEventIdToDelete, setSelectedEventIdToDelete] = useState(0);
    const [multipleEvents, setMultipleEvents] = useState(false);

    const [resendCodeDialog, setResendCodeDialog] = useState(false);
    const [deleteOrderDialog, setDeleteOrderDialog] = useState(false);
    const [successDialog, setSuccessDialog] = useState(false);
    const [successDialogMessage, setSuccessDialogMessage] = useState('');
    const [priceToPayNow, setPriceToPayNow] = useState(0);

    // There are 4 different order types:
    // - none (nothing is added to cart)
    // - subscription
    // - event
    // - combo (subscription + event)

    const [orderType, setOrderType] = useState('none');
    const [listOfRooms, setListOfRooms] = useState([]);

    const [showQuestionMarkDialog, setShowQuestionMarkDialog] = useState(false);

    const contentRef = useRef();

    const context = useContext(Context);

    const deleteAllDraftEvents = async () => {
        const token = getToken();
        const response = await deleteAndReturnRespWithAuth(
            `/api/v1/users/${getUserId()}/delete-all-draft-events`,
            token,
            handleError
        );
        if (response && response.status) {
            if (response.status === 200) {
                setDeleteOrderDialog(false);
                setSuccessDialog(true);
                setSuccessDialogMessage('Din bestilling er annulleret.');
            } else {
                setError(true);
                setErrorMessage('Noget gik galt. Prøv igen.');
            }
        }
    };

    const createEventListItem = (draftOwnEvents) => {
        const listOfRooms = draftOwnEvents.map((event) =>
            getLocationNameFromRoomId(event.roomId)
        );

        const uniqueListOfRooms = [...new Set(listOfRooms)];
        setListOfRooms(uniqueListOfRooms);
    };

    const confirmDeleteOrder = () => {
        handleSubscriptionDeleteClick();
        deleteAllDraftEvents();
    };

    const navigateToCalendar = () => {
        // Get last room id from context/storage
        const lastRoomId = getLastRoomId();
        if (lastRoomId) {
            props.history.push(
                `/booking-kalender/${getLocationNameFromRoomId(lastRoomId)}`
            );
        } else {
            props.history.push(`/booking-kalender/${1}`);
        }
    };

    // User is able to delete its own event by confirming the deletion in the DeleteDialog
    const confirmRemovingEvent = async (eventId, repeatSeqId) => {
        if (repeatSeqId) {
            // Check if there are multiple events to delete
            // If there are, we need to ask the user,  if they want to delete all related events
            const data = await getAndReturnData(
                `/api/v1/events/${repeatSeqId}`,
                handleError
            );
            if (data && data.events && data.events.length > 1) {
                setSelectedEventIdToDelete(eventId);
                setMultipleEvents(true);
                setDeleteDialog(true);
            } else {
                setSelectedEventIdToDelete(eventId);
                setDeleteDialog(true);
            }
        } else {
            setSelectedEventIdToDelete(eventId);
            setDeleteDialog(true);
        }
    };

    const onSubmit = async () => {
        // If there are no orders to buy, show error.
        if (!newSubscriber && draftOwnEvents.length < 1) {
            setErrorMessage('Du har ikke bestilt noget.');
            return setError(true);
        }

        // Remove isNewUser from localstorage (dialog about the creation of their profile won't be shown again.)
        if (getIsNewUser()) {
            context.saveNewUser(false);
        }

        let orderInfoToSave;
        // Save the order info for the next page
        if (orderType === 'subscription') {
            orderInfoToSave = {
                orderTotal: priceToAuthorise * 100,
                orderType: 'subscription',
                orderName: 'Abonnement',
                products: [
                    {
                        productName: 'Subscription',
                        quantity: 1,
                        total: priceToAuthorise * 100
                    }
                ]
            };
        } else if (orderType === 'events') {
            const arrayOfEventsIds = draftOwnEvents.map((event) => event.id);

            // Events paid in one piece
            orderInfoToSave = {
                orderTotal: priceToAuthorise * 100,
                orderType: 'payment',
                orderName: 'Udlejning',
                products: [
                    {
                        productName: 'Events',
                        quantity: 1,
                        total: priceToAuthorise * 100,
                        arrayOfEventsIds
                    }
                ]
            };
        } else if (orderType === 'combo') {
            const arrayOfEventsIds = draftOwnEvents.map((event) => event.id);
            const firstPayment = calculateMembershipFirstMonthPrice();
            // Combination of events + subscriptions
            orderInfoToSave = {
                orderTotal: priceToAuthorise * 100,
                orderType: 'subscription',
                orderName: 'Udlejning og abonnement',
                products: [
                    {
                        productName: 'Subscription',
                        quantity: 1,
                        total: monthlyPriceSubscription * 100
                    },
                    {
                        productName: 'Events',
                        quantity: 1,
                        total: totalPriceEvents * 100,
                        arrayOfEventsIds
                    }
                ]
            };
        }
        // Save productBody in local storage
        context.saveOrderInfo(orderInfoToSave);

        props.history.push('/handelsbetingelser');
    };

    const handleDeleteClick = (eventId, repeatSeqId) => {
        confirmRemovingEvent(eventId, repeatSeqId);
    };

    const removeBookedEvent = async (eventId, repeatSeqId) => {
        const getUrl = () => {
            if (repeatSeqId !== undefined) {
                return `/api/v1/users/${getUserId()}/events/draft/${eventId}?repeatSeqId=${repeatSeqId}`;
            } else {
                return `/api/v1/users/${getUserId()}/events/draft/${eventId}`;
            }
        };
        const resp = await deleteAndReturnResp(getUrl(), handleError);

        if (resp && resp.status === 200) {
            setPrices();
            // Rerender events from db
            const updatedEventsFromDb = await getOwnEvents();
            const onlyDraftEvents = updatedEventsFromDb.filter(
                (event) => event.state === 'draft'
            );
            setDraftOwnEvents(onlyDraftEvents);
            setDeleteDialog(false);
            setSuccessDialog(true);
            setSuccessDialogMessage('Din reservation er annulleret.');
        } else {
            handleError(
                'Noget gik galt. Kunne ikke fjerne reservationen.  Prøv igen.'
            );
            setError(true);
            setErrorMessage('Serverfejl. Kunne ikke fjerne reservationen.');
        }
    };

    const handleSubscriptionDeleteClick = () => {
        const orderFromStorage = getOrder();
        orderFromStorage.subscription = false;
        context.saveOrder({ orderFromStorage });
        setNewSubscriber(false);
        setPrices();
    };

    // eslint-disable-next-line
    const addReservation = () => {
        props.history.push('/booking-kalender');
    };

    const addSubscription = () => {
        // Save subscription in local storage, as order
        context.saveOrder({ subscription: true });
        setNewSubscriber(true);
        setPrices();
    };

    const hasSubscriptionInStorage = () => {
        const subscriptionFromStorage = getSubscriptionOrderFromStorage();
        if (subscriptionFromStorage) {
            return true;
        }
    };

    const handleError = () => {
        console.log('error');
    };

    const setPrices = async () => {
        const subscriptionMonthlyPrice = subscriptionPricePerMonth;
        // Set prices according to order type
        const ownEventsFromDb = await getOwnEvents();
        let onlyDraftEvents = [];
        if (ownEventsFromDb && ownEventsFromDb.length > 0) {
            onlyDraftEvents = ownEventsFromDb.filter(
                (event) => event.state === 'draft'
            );
        }

        // Needed for mobile view of events
        createEventListItem(ownEventsFromDb);

        const updatedOrderType = await returnOrderType(
            hasSubscriptionInStorage(),
            onlyDraftEvents
        );
        setOrderType(updatedOrderType);

        if (updatedOrderType === 'events') {
            onlyDraftEvents.sort(
                (a, b) => new Date(a.startdate) - new Date(b.startdate)
            );
            setDraftOwnEvents(onlyDraftEvents);

            const arrayOfEventsIds = onlyDraftEvents.map((event) => event.id);
            const totalNormalPrice = await returnTotalNormalPrice(
                arrayOfEventsIds
            );
            if (onlyDraftEvents && onlyDraftEvents.length > 0) {
                setTotalPriceEvents(totalNormalPrice / 100);
                setPriceToAuthorise(totalNormalPrice / 100);
            }
        } else if (updatedOrderType === 'subscription') {
            const firstPayment = calculateMembershipFirstMonthPrice();
            // const authorizeTotalAmount = Math.round(
            //     subscriptionMonthlyPrice * 12 + firstPayment
            // ); // We remove this, because now we are not authorizing 12*400, but only 400
            const authorizeTotalAmount = subscriptionMonthlyPrice;
            setPriceToAuthorise(authorizeTotalAmount);
            setMonthlyPriceSubscription(subscriptionMonthlyPrice);
        } else if (updatedOrderType === 'combo') {
            if (onlyDraftEvents && onlyDraftEvents.length > 0) {
                setDraftOwnEvents(onlyDraftEvents);
                const arrayOfEventsIds = onlyDraftEvents.map(
                    (event) => event.id
                );
                const totalNormalPrice = await returnTotalNormalPrice(
                    arrayOfEventsIds
                );

                setTotalPriceEvents(totalNormalPrice / 100);
                // const firstPayment = calculateMembershipFirstMonthPrice();
                setPriceToAuthorise(
                    subscriptionMonthlyPrice +
                        (await returnTotalNormalPrice(arrayOfEventsIds)) / 100
                );
                setMonthlyPriceSubscription(subscriptionPricePerMonth);
            }
        } else {
            setPriceToAuthorise(0);
            setMonthlyPriceSubscription(0);
        }
    };

    const calculateMembershipFirstMonthPrice = () => {
        // Hardcoded subscription price here
        const subscriptionPricePerMonth = 400;
        const today = moment();
        const totalDayCurrentMonth = today.daysInMonth();
        const remainingDayOfMonth = totalDayCurrentMonth - today.date() + 1; // include today
        const firstPayment = Math.round(
            (remainingDayOfMonth * subscriptionPricePerMonth) /
                totalDayCurrentMonth
        );

        return firstPayment;
    };

    // ComponentDidMount
    useEffect(() => {
        // User must be logged in
        if (!returnAuthenticated()) {
            return props.history.push('/');
        }

        // Get subscription from local storage
        if (hasSubscriptionInStorage()) {
            setNewSubscriber(true);
        }

        // Display dialog is this is a new user (profile was just created in the previous page), which shows that profile is created, and verification codes are sent
        if (getIsNewUser()) {
            setResendCodeDialog(true);
            context.saveNewUser();
        }

        setPrices();

        setPriceToPayNow(calculateMembershipFirstMonthPrice());

        // eslint-disable-next-line
    }, [props]);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <HeadersContainer />

            <span ref={contentRef}></span>
            <RegistrationContentContainer
                marginTop="2.5rem"
                mobilePaddingTop="1rem"
            >
                {returnAuthenticated() ? (
                    <ProgressBar
                        currentStage={'Opsummering'}
                        type={getCurrentStage()}
                    />
                ) : (
                    <ProgressBar currentStage={'Opsummering'} />
                )}
                {hasPrevPageDestination(props) ? (
                    <ReturnLinkReusable
                        destination={getPrevPageDestination(props)}
                        url={getPrevPageUrl(props)}
                    />
                ) : (
                    <ReturnLinkContainer onClick={() => navigateToCalendar()}>
                        <ReturnLinkReusable destination="Kalender" />
                    </ReturnLinkContainer>
                )}
                <RegularHeadline
                    title="Din kurv"
                    component="h1"
                    marginbottom="1.7rem"
                />
                <RegularHeadline
                    title="Opsummering"
                    component="h6"
                    variant="h6"
                    marginbottom="2rem"
                />
                <Box mb="2rem">
                    {/* -----> Only subscription is being purchased <------- */}
                    {orderType === 'subscription' && (
                        <Box>
                            <ProductTable
                                orderType={orderType}
                                membershipFirstMonthPrice={calculateMembershipFirstMonthPrice()}
                                handleSubscriptionDeleteClick={
                                    handleSubscriptionDeleteClick
                                }
                            />
                        </Box>
                    )}
                    {/* Only events have been added */}
                    {orderType === 'events' && (
                        <>
                            <Box mb="1rem">
                                <Typography variant="subtitle1">
                                    Du er nu ved at foretage følgende
                                    reservationer:
                                </Typography>
                            </Box>
                            <ProductTable
                                orderType={orderType}
                                draftOwnEvents={draftOwnEvents}
                                handleDeleteClick={handleDeleteClick}
                                listOfRooms={listOfRooms}
                            />
                        </>
                    )}
                    {orderType === 'combo' && (
                        <Box>
                            <Box mb="1rem">
                                <Typography variant="subtitle1">
                                    Du er nu ved at oprette et medlemskab, og
                                    foretage følgende reservationer:
                                </Typography>
                            </Box>
                            <ProductTable
                                orderType={orderType}
                                draftOwnEvents={draftOwnEvents}
                                handleDeleteClick={handleDeleteClick}
                                membershipFirstMonthPrice={calculateMembershipFirstMonthPrice()}
                                handleSubscriptionDeleteClick={
                                    handleSubscriptionDeleteClick
                                }
                                listOfRooms={listOfRooms}
                            />
                        </Box>
                    )}
                    {orderType === 'none' && (
                        <>
                            <Box>
                                <DescriptionText variant="body2" mb="1rem">
                                    Din kurv er tom.
                                </DescriptionText>
                                <ButtonContainer mt="1.5rem">
                                    <RegularButton
                                        title="Tilføj reservation"
                                        onButtonClick={() => addReservation()}
                                        mobileMaxWidth="10rem"
                                    />
                                </ButtonContainer>
                            </Box>
                        </>
                    )}

                    {/* Only show price to pay now, if it's a subscription or combo order */}
                    {orderType === 'combo' && (
                        <Typography variant="subtitle1">
                            Til betaling nu: {priceToPayNow} kr.
                        </Typography>
                    )}
                </Box>
                {(orderType === 'combo' || orderType === 'events') && (
                    <>
                        <FlexContainer>
                            <ButtonContainer mr="2rem">
                                <RegistrationButton
                                    title="Rediger bestilling"
                                    onButtonClick={() => {
                                        navigateToCalendar();
                                    }}
                                    width="13rem"
                                    minWidth="15rem"
                                    mobileMargin="1.5rem 0"
                                    color={overridings.palette.primary.light}
                                    border={overridings.palette.primary.light}
                                />
                            </ButtonContainer>
                            <ButtonContainer>
                                <RegistrationButton
                                    title="Gå til betaling"
                                    width="13rem"
                                    minWidth="15rem"
                                    minWidthDesktop="fit-content"
                                    onButtonClick={onSubmit}
                                    mobileMargin="1.5rem 0"
                                />
                            </ButtonContainer>
                        </FlexContainer>
                    </>
                )}
                {orderType === 'subscription' && (
                    <ButtonContainer>
                        <RegistrationButton
                            title="Gå til betaling"
                            width="13rem"
                            minWidth="15rem"
                            minWidthDesktop="fit-content"
                            onButtonClick={onSubmit}
                            mobileMargin="1.5rem 0"
                        />
                    </ButtonContainer>
                )}

                {/* -----> Text when only subscription is ordered <------- */}
                {orderType === 'subscription' && (
                    <NewSubscriptionOnlyText
                        setShowQuestionMarkDialog={setShowQuestionMarkDialog}
                    />
                )}

                {/* -----> Text when only events are ordered <------- */}
                {orderType === 'events' && (
                    <NewEventsOnlyText
                        addSubscription={addSubscription}
                        newSubscriber={newSubscriber}
                        setShowQuestionMarkDialog={setShowQuestionMarkDialog}
                    />
                )}

                {orderType === 'combo' && <NewEventsAndSubscriptionText />}

                {/* Hidden form is needed for QuickPay payment */}
                {error && (
                    <ErrorDialog
                        dialogTitle=""
                        errorMessage={errorMessage}
                        close={() => {
                            setErrorMessage('');
                            setError(false);
                        }}
                    />
                )}
            </RegistrationContentContainer>

            {resendCodeDialog && (
                <ResendCodeDialog
                    close={() => {
                        context.saveOnlySignup(false);
                        setResendCodeDialog(false);
                    }}
                />
            )}

            {deleteDialog && (
                <DeleteDialog
                    close={() => {
                        setDeleteDialog(false);
                        setSelectedEventIdToDelete(null);
                        setMultipleEvents(false);
                    }}
                    isDraftEvent={true}
                    eventId={selectedEventIdToDelete}
                    repeatSeqId={returnRepeatSeqId(
                        draftOwnEvents,
                        selectedEventIdToDelete
                    )}
                    removeBookedEvent={removeBookedEvent}
                    multipleEvents={multipleEvents}
                    ownEvents={draftOwnEvents}
                />
            )}

            {deleteOrderDialog && (
                <DeleteOrderDialog
                    close={() => setDeleteOrderDialog(false)}
                    confirmDeleteOrder={confirmDeleteOrder}
                />
            )}

            {successDialog && (
                <SuccessDialog
                    title="Succes"
                    successMessage={successDialogMessage}
                    close={() => setSuccessDialog(false)}
                />
            )}

            {showQuestionMarkDialog && (
                <RuleExplanationDialog
                    close={() => setShowQuestionMarkDialog(false)}
                />
            )}

            <form
                id="quickpayform"
                action="https://payment.quickpay.net"
                method="POST"
            ></form>
        </motion.div>
    );
}

export default withRouter(Checkout);
