import React, { Component } from 'react';
import styled from 'styled-components';
import { getEventsOfTheDay, sortEvents, getAllEvents } from '../EventFunctions';
import moment from 'moment';

import Event from '../event/Event';
import AvailableTimeSlot from '../available-time-slot/AvailableTimeSlot';
import { Box, Typography } from '@material-ui/core';

const DayContainer = styled.div`
    display: flex;
    margin-top: 1rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    @media (min-width: 960px) {
        flex-basis: 100%;
        display: block;
    }
`;

// const Backdrop = styled.div`
//   background: pink;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vw;
//   z-index: 1;
// `;

const HeadlineContainer = styled.div`
    width: auto; // here we should use flex grow instead of setting the width??
    margin-bottom: 0.5rem;
    /* margin-right: 1rem; */

    @media (min-width: 960px) {
        width: auto;
        margin-right: 0;
    }
    @media (max-width: 960px) {
        background-color: ${(props) => props.theme.palette.colors.green};
    }
`;

const TodayHeadline = styled.div`
    border-radius: 15px;
    margin: 0.5rem 0 0.5rem 0;
    padding: 0rem 0.5rem 0rem 0.5rem;
    width: 2.7rem;
    @media (min-width: 960px) {
        width: 100%;
        background-color: ${(props) => props.theme.palette.primary.light};
        display: flex;
        justify-content: space-between;
        padding-top: 0.3rem;
    }
`;

const DayHeadline = styled.div`
    border-radius: 15px;
    width: 2.7rem;
    margin: 0.5rem 0 0.5rem 0;
    padding: 0rem 0.5rem 0rem 0.5rem;
    @media (min-width: 960px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 0.3rem;
    }
`;

const DayDisplay = styled(Typography)`
    text-transform: uppercase;
    // font-family: "Benton Sans Med";
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
    @media (min-width: 960px) {
        font-weight: normal;
    }
`;

const DateDisplay = styled(Typography)`
    font-family: 'Benton Sans Medium';
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
    @media (min-width: 960px) {
        font-weight: normal;
    }
`;

const TodayDateDisplay = styled(Typography)`
    background-color: ${(props) => props.theme.palette.primary.contrastText};
    color: ${(props) => props.theme.palette.primary.main};
    width: fit-content;
    padding: 3px 5px 0 5px;
    border-radius: 16px;
`;

const DayContentContainer = styled.div`
  // background-color: ${(props) => props.theme.palette.secondary.light};
  width: 100%;
  overflow: ${(props) => (props.isexpanded ? 'inherit' : 'scroll')};
  -ms-overflow-style: none; /* Overflow hiding in IE and Edge */
  scrollbar-width: none; /*Overflow hiding in Firefox */
  &::-webkit-scrollbar {
    display: none; /* Overflow hiding in Chrome, Safari, Opera */
  }
  @media (min-width: 960px) {
    background-color: ${(props) => props.theme.palette.secondary.main};
    min-height: 100%;
    border: 1px solid ${(props) => props.theme.palette.secondary.contrastText};
    width: auto;
  }
`;

const TodayHeadlineDesktop = styled(Box)`
    display: none;
    @media (min-width: 960px) {
        display: contents;
    }
`;

const TodayHeadlineMobile = styled(Box)`
    display: block;
    @media (min-width: 960px) {
        display: none;
    }
`;

const DayHeadlineMobile = styled(Box)`
    display: inline-block;
    @media (min-width: 960px) {
        display: none;
    }
`;

const DayHeadlineDesktop = styled(Box)`
    display: none;
    @media (min-width: 960px) {
        display: contents;
    }
`;

class Day extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allEventsOfTheDay: [],
            isExpanded: false,
            displayBackdrop: false
            // isToggled: false
        };
    }

    setIsExpanded = (value) => {
        this.setState({ isExpanded: value });
        if (value) {
            this.setState({ displayBackdrop: true });
        } else {
            this.setState({ displayBackdrop: false });
        }
    };

    setDayColor = (dayName) => {
        switch (dayName) {
            case 'mandag':
                return `purple`;
            case 'tirsdag':
                return `green`;
            case 'onsdag':
                return `yellow`;
            case 'torsdag':
                return `blue`;
            case 'fredag':
                return `orange`;
            case 'lørdag':
                return `red`;
            case 'søndag':
                return `aqua`;
            default:
                return `white`;
        }
    };
    getAllEventsOfTheDay = () => {
        // Get events of the day, and merge and sort them.
        const allEventsOfTheDay = sortEvents([
            ...getEventsOfTheDay(this.props.events, this.props.date)
        ]);

        this.setState({
            allEventsOfTheDay: getAllEvents(
                allEventsOfTheDay,
                this.props.date,
                this.props.dayName
            )
        });
    };

    isFromToday = (event) => {
        // If it is a day in the past, then we should not display available time slot.
        const today = new Date();
        const todayFormatted = moment(today).format('YYYY-MM-DD');
        const todayMorning = todayFormatted + ' 00:01';
        if (
            moment(todayMorning).isBefore(
                moment(event.date + ' ' + event.startTime)
            )
        ) {
            return true;
        } else {
            return false;
        }
    };

    // Updates component on props (data) change
    componentDidUpdate(nextProps) {
        const { date, events } = this.props;

        if (nextProps.date !== date || nextProps.events !== events) {
            this.getAllEventsOfTheDay();
        }
    }

    componentDidMount() {
        this.getAllEventsOfTheDay();
    }

    render() {
        const {
            isToday,
            displayDate,
            dayName,
            dayNameShort,
            // addNewOwnEvent,
            roomId,
            fetchAndFilterEventsInCalendar
        } = this.props;
        return (
            <DayContainer>
                {isToday ? (
                    <HeadlineContainer>
                        <TodayHeadline>
                            <TodayHeadlineMobile>
                                <DayDisplay variant="subtitle1">
                                    {dayNameShort}
                                </DayDisplay>
                                <TodayDateDisplay variant="body1">
                                    {displayDate}
                                </TodayDateDisplay>
                            </TodayHeadlineMobile>

                            <TodayHeadlineDesktop>
                                <DayDisplay variant="subtitle1">
                                    {dayName}
                                </DayDisplay>
                                <DateDisplay variant="subtitle1">
                                    {displayDate}
                                </DateDisplay>
                            </TodayHeadlineDesktop>
                        </TodayHeadline>
                    </HeadlineContainer>
                ) : (
                    <HeadlineContainer>
                        <DayHeadline>
                            <DayHeadlineMobile>
                                <DayDisplay variant="subtitle1">
                                    {dayNameShort}
                                </DayDisplay>
                                <DateDisplay variant="subtitle1">
                                    {displayDate}
                                </DateDisplay>
                            </DayHeadlineMobile>

                            <DayHeadlineDesktop>
                                <DayDisplay variant="subtitle1">
                                    {dayName}
                                </DayDisplay>
                                <DateDisplay variant="subtitle1">
                                    {displayDate}
                                </DateDisplay>
                            </DayHeadlineDesktop>
                        </DayHeadline>
                    </HeadlineContainer>
                )}
                <DayContentContainer
                    className="day"
                    isexpanded={this.state.isExpanded}
                >
                    {this.state.allEventsOfTheDay.map((event) => {
                        if (
                            this.props.selectedEventIdToEdit &&
                            event.event_id ===
                                this.props.selectedEventIdToEdit &&
                            this.isFromToday(event)
                        ) {
                            return (
                                // Check if selectedEventIdToEdit exists in the list
                                // Event needs to be modified

                                <AvailableTimeSlot
                                    key={event.endTime}
                                    date={event.date}
                                    startTime={event.startTime}
                                    endTime={event.endTime}
                                    roomId={event.roomId}
                                    fetchAndFilterEventsInCalendar={
                                        fetchAndFilterEventsInCalendar
                                    }
                                    saveEditedEvent={this.props.saveEditedEvent}
                                    edit={true}
                                    title={event.title}
                                    isPrivate={event.isPrivate}
                                    existingEvent={true}
                                    selectedEventIdToEdit={
                                        this.props.selectedEventIdToEdit
                                    }
                                    removeEdit={this.props.removeEdit}
                                    handleError={this.props.handleError}
                                    // Check if the previous and next event is an actual event or an available time slot
                                    allEventsOfTheDay={
                                        this.state.allEventsOfTheDay
                                    }
                                    setIsExpanded={this.setIsExpanded}
                                    setJustBookedEvents={
                                        this.props.setJustBookedEvents
                                    }
                                    setConflictingEvents={
                                        this.props.setConflictingEvents
                                    }
                                    setConflictPostRequestBody={
                                        this.props.setConflictPostRequestBody
                                    }
                                    repeatSeqId={event.repeatSeqId}
                                    ownEvents={this.props.ownEvents}
                                    updateOwnEvents={this.props.updateOwnEvents}
                                    isExpanded={this.state.isExpanded}
                                    shouldBeVisible={event.shouldBeVisible}
                                />
                            );
                        } else if (
                            event.isAvailable &&
                            this.isFromToday(event)
                        ) {
                            return (
                                <AvailableTimeSlot
                                    key={event.endTime}
                                    date={event.date}
                                    startTime={event.startTime}
                                    endTime={event.endTime}
                                    roomId={roomId}
                                    fetchAndFilterEventsInCalendar={
                                        fetchAndFilterEventsInCalendar
                                    }
                                    handleError={this.props.handleError}
                                    setIsExpanded={this.setIsExpanded}
                                    setJustBookedEvents={
                                        this.props.setJustBookedEvents
                                    }
                                    setConflictingEvents={
                                        this.props.setConflictingEvents
                                    }
                                    setConflictPostRequestBody={
                                        this.props.setConflictPostRequestBody
                                    }
                                    ownEvents={this.props.ownEvents}
                                    updateOwnEvents={this.props.updateOwnEvents}
                                    isExpanded={this.state.isExpanded}
                                    shouldBeVisible={event.shouldBeVisible}
                                    removeEdit={this.props.removeEdit}
                                    // isToggled={this.state.isToggled}
                                />
                            );
                        } else {
                            if (event.title !== 'Ledig') {
                                return (
                                    <Event
                                        key={event.event_id}
                                        eventId={event.event_id}
                                        title={event.title}
                                        isPrivate={event.isPrivate}
                                        startTime={event.startTime}
                                        endTime={event.endTime}
                                        type={event.type}
                                        holdId={event.hold_id}
                                        state={event.state}
                                        date={event.date}
                                        repeatSeqId={event.repeatSeqId}
                                        ownEvents={this.props.ownEvents}
                                        editBookedEvent={
                                            this.props.editBookedEvent
                                        }
                                        confirmRemovingEvent={
                                            this.props.confirmRemovingEvent
                                        }
                                        setIsExpanded={this.setIsExpanded}
                                        dayColor={this.setDayColor(
                                            this.props.dayName
                                        )}
                                        shouldBeVisible={event.shouldBeVisible}
                                        isOwnEvent={event.isOwnEvent}
                                        removeEdit={this.props.removeEdit}
                                        userId={event.userId}
                                        // isToggled={this.state.isToggled}
                                        isBookingCalendar={
                                            this.props.isBookingCalendar
                                        }
                                    />
                                );
                            } else {
                                return null;
                            }
                        }
                    })}
                </DayContentContainer>
            </DayContainer>
        );
    }
}

export default Day;
