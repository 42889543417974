import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const OuterContainer = styled.div`
    position: relative;
    margin-bottom: 2rem;
`;

const TermsOfPurchaseContainer = styled.div`
    height: 25rem;
    padding-bottom: 4rem;
    overflow: scroll;
    overflow-x: hidden;

    & :last-child {
        margin-bottom: 5rem;
    }
`;

const GradientOverlay = styled.div`
    height: 6rem;
    width: 100%;
    background-image: linear-gradient(rgba(21, 19, 19, 0), rgba(21, 19, 19, 1));
    position: absolute;
    bottom: 0;
`;

// const Underlined = styled(Typography)`
//   width: 60%;
//   text-decoration: underline;
//   @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
//     width: 90%;
//   }
// `;
const TextTitle = styled(Typography)`
    font-size: 1.2rem;
    padding: 0.8rem 0rem 0.1rem 0rem;
    color: ${(props) => props.theme.palette.colors.orange};
    width: 80%;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 90%;
    }
    @media (max-width: 350px) {
        font-size: 1rem;
    }
`;
const Text = styled(Typography)`
    padding: 0.3rem 0rem;
    width: 75%;
    font-size: 1rem;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 90%;
    }
    @media (max-width: 350px) {
        font-size: 0.9rem;
    }
`;

const OrderedList = styled.ol`
    @media (max-width: 350px) {
        padding: 13px;
    }
`;

const ListItem = styled.li`
    width: 70%;
    font-size: 1rem;
    padding: 0.5rem 0rem;
    margin-bottom: 0rem !important;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        font-size: 0.8rem;
    }
    @media (max-width: 350px) {
        width: 100%;
    }
`;

function HouseRules({ isDialog }) {
    return (
        <OuterContainer>
            {}
            {isDialog ? (
                <>
                    <TermsOfPurchaseContainer>
                        <TextTitle variant="body2" gutterBottom>
                            Husorden og brug af udendørs- og fællesarealer af
                            Bülowsvej 10
                        </TextTitle>
                        <Text variant="body2" gutterBottom>
                            Som bruger af huset Bülowsvej 10, skal følgende
                            husorden overholdes. Det er 100% medlemmer og
                            lejeres ansvar at deres gæster, elever, klienter,
                            kunder overholder husreglerne.
                        </Text>
                        <OrderedList>
                            <ListItem>
                                Alle, der færdes i huset, skal tage deres sko af
                                i garderoben eller bruge overtræk eller
                                hjemmesko.
                            </ListItem>
                            <ListItem>
                                Ingen gæster, elever, klienter, kunder må
                                opholde sig i udlejningslokaler uden medlemmet
                                eller lejeren er til stede.
                            </ListItem>
                            <ListItem>
                                Ingen gæster, elever, klienter, kunder må
                                opholde sig i andre udlejningslokaler end det
                                lejede.
                            </ListItem>
                            <ListItem>
                                Al undervisningsmateriale, instrumenter,
                                rekvisitter og andet udstyr der befinder sig i
                                udlejningslokalerne, som ikke er nævnt i næste
                                afsnit, må kun benyttes efter direkte aftale med
                                Den Lille Musikskole.
                            </ListItem>
                            <ListItem>
                                De farvede taburetter må kun benyttes af børn
                            </ListItem>
                            <ListItem>
                                Støjende aktiviteter skal altid udøves for
                                lukkede vinduer og døre
                            </ListItem>
                            <ListItem>
                                Der skal luftes ud inden lokalet forlades
                            </ListItem>
                            <ListItem>
                                Klaverlåg lukkes ned og alle møbler, samt
                                inventar stilles pænt på plads.
                            </ListItem>
                            <ListItem>
                                Affald bæres ned i køkkenet til vores
                                affaldssortering.
                            </ListItem>
                            <ListItem>Hele matriklen holdes røgfri.</ListItem>
                            <ListItem>
                                Udendørs ophold og aktiviteter må ikke være til
                                gene for naboerne.
                            </ListItem>
                            <ListItem>
                                Instrumenter, inventar m.m. må ikke fjernes fra
                                huset uden forudgående aftale med Den Lille
                                Musikskole.
                            </ListItem>
                        </OrderedList>

                        <Text variant="small" gutterBottom>
                            Husorden er opdateret den 24. juli 2024
                        </Text>
                    </TermsOfPurchaseContainer>{' '}
                    <GradientOverlay />
                </>
            ) : (
                <>
                    <TextTitle variant="body2" gutterBottom>
                        Husorden og brug af udendørs- og fællesarealer af
                        Bülowsvej 10
                    </TextTitle>
                    <Text variant="body2" gutterBottom>
                        Som bruger af huset Bülowsvej 10, skal følgende husorden
                        overholdes. Det er 100% medlemmer og lejeres ansvar at
                        deres gæster, elever, klienter, kunder overholder
                        husreglerne.
                    </Text>
                    <OrderedList>
                        <ListItem>
                            Alle, der færdes i huset, skal tage deres sko af i
                            garderoben eller bruge overtræk eller hjemmesko.
                        </ListItem>
                        <ListItem>
                            Ingen gæster, elever, klienter, kunder må opholde
                            sig i udlejningslokaler uden medlemmet eller lejeren
                            er til stede.
                        </ListItem>
                        <ListItem>
                            Ingen gæster, elever, klienter, kunder må opholde
                            sig i andre udlejningslokaler end det lejede.
                        </ListItem>
                        <ListItem>
                            Al undervisningsmateriale, instrumenter, rekvisitter
                            og andet udstyr der befinder sig i
                            udlejningslokalerne, som ikke er nævnt i næste
                            afsnit, må kun benyttes efter direkte aftale med Den
                            Lille Musikskole.
                        </ListItem>
                        <ListItem>
                            De farvede taburetter må kun benyttes af børn
                        </ListItem>
                        <ListItem>
                            Støjende aktiviteter skal altid udøves for lukkede
                            vinduer og døre
                        </ListItem>
                        <ListItem>
                            Der skal luftes ud inden lokalet forlades
                        </ListItem>
                        <ListItem>
                            Klaverlåg lukkes ned og alle møbler, samt inventar
                            stilles pænt på plads.
                        </ListItem>
                        <ListItem>
                            Affald bæres ned i køkkenet til vores
                            affaldssortering.
                        </ListItem>
                        <ListItem>Hele matriklen holdes røgfri.</ListItem>
                        <ListItem>
                            Udendørs ophold og aktiviteter må ikke være til gene
                            for naboerne.
                        </ListItem>
                        <ListItem>
                            Instrumenter, inventar m.m. må ikke fjernes fra
                            huset uden forudgående aftale med Den Lille
                            Musikskole.
                        </ListItem>
                    </OrderedList>

                    <Text variant="small" gutterBottom>
                        Husorden er opdateret den 24. juli 2024
                    </Text>
                </>
            )}
        </OuterContainer>
    );
}

export default HouseRules;
