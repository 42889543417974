import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import HeadersContainer from '../../components/headers/headers-container/HeadersContainer';
import ReturnLinkReusable from '../../components/links/ReturnLinkReusable';
import ContentContainer from '../../components/containers/ContentContainer';
import ActivityCardFull from './ActivityCardFull';
import {
    getPrevPageDestination,
    getPrevPageUrl,
    getUserId
} from '../../context/getData';
import PencilIcon from '../../components/icons/pencil-icon/PencilIcon';

const ActivityContainer = styled(Box)`
    background-color: ${(props) => props.theme.palette.secondary.main};
    padding: 44px 37px 100px;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        padding: 0px 20px 30px;
    }
`;

const BoxMobile = styled(Box)`
    display: none;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

const BoxDesktop = styled(Box)`
    display: block;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: none;
    }
`;

const EditLink = styled(Box)`
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
    margin-top: 2rem;
`;

function ActivityPage(props) {
    const [previousUrl, setPreviousUrl] = useState('/opslagstavle');
    const [previousDestination, setPreviousDestination] = useState(
        'Opslagstavle'
    );
    const [showEditLink, setShowEditLink] = useState(false);

    // componentDidMount
    useEffect(() => {
        if (!props.match.params.id) {
            props.history.push('/opslagstavle');
        }

        const prevDestination = getPrevPageDestination(props);
        const prevUrl = getPrevPageUrl(props);

        if (prevDestination) {
            setPreviousDestination(prevDestination);
        }
        if (prevUrl) {
            setPreviousUrl(prevUrl);
        }

        // If this is the user's own page, they should see a link where they can be taken to edit
        if (getUserId().toString() === props.match.params.id) {
            setShowEditLink(true);
        }

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <HeadersContainer />

            <ContentContainer customMarginTop="5px">
                <BoxMobile>
                    <ReturnLinkReusable
                        destination={previousDestination}
                        url={previousUrl}
                    />
                </BoxMobile>
                <ActivityContainer className="activity-container">
                    <BoxDesktop>
                        <ReturnLinkReusable
                            destination={previousDestination}
                            url={previousUrl}
                        />
                    </BoxDesktop>

                    <ActivityCardFull />
                    {showEditLink && (
                        <EditLink
                            display="flex"
                            onClick={() =>
                                props.history.push(
                                    '/min-side/rediger-min-opslagstavle'
                                )
                            }
                        >
                            <PencilIcon />
                            <Typography>Rediger min opslagstavle</Typography>
                        </EditLink>
                    )}
                </ActivityContainer>
            </ContentContainer>
        </>
    );
}

export default withRouter(ActivityPage);
