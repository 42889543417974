import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import { postAndReturnData } from '../../context/helperFunctions';
import { Context } from '../../context/Context';
import { getCurrentUserInfo } from '../../context/getData';
import Loader from '../../components/loader/Loader';
import ErrorDialog from '../../components/dialogs/error-dialog/ErrorDialog';
import SuccessDialog from '../../components/dialogs/success-dialog/SuccessDialog';
import { Box } from '@material-ui/core';
import DialogsBackground from './../../components/dialogs/DialogsBackground';

function SignupEmailVerification(props) {
    const errorMessage = 'Din email er endnu ikke verificeret.';
    const secondPartErrorMessage =
        'Hvis du har trykket flere gange på gensend email, er det kun linket i den sidste mail der virker.';
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const { uniqueIdEmail } = props.match.params;
    const [isLoading, setIsLoading] = useState(false);
    const [isPhoneVerified, setIsPhoneVerified] = useState(false);
    const context = useContext(Context);

    const handleError = (message) => {
        return message;
    };

    const verifyEmail = async (requestBody) => {
        const response = await postAndReturnData(
            '/api/v1/users/verify-email',
            requestBody,
            handleError
        );
        if (!response) {
            setError(true);
        } else {
            if (response.emailVerifiedAt) {
                const userData = getCurrentUserInfo();
                if (userData && userData.userFirstname) {
                    userData.emailVerifiedAt = response.emailVerifiedAt;
                    context.saveUser({
                        userFirstname: userData.userFirstname,
                        userEmail: userData.userEmail,
                        userId: userData.userId,
                        emailVerifiedAt: userData.emailVerifiedAt,
                        phoneVerifiedAt: userData.phoneVerifiedAt,
                        subscriber: userData.subscriber,
                        token: userData.token,
                        tokenExpires: userData.tokenExpires,
                        lastDayOfSubscription: userData.lastDayOfSubscription,
                        subscriptionDueDate: userData.subscriptionDueDate,
                        isProfilePublic: userData.isProfilePublic,
                        role: userData.role,
                        lastAcceptedTerms: userData.lastAcceptedTerms
                    });
                }

                if (response.phoneVerifiedAt) {
                    setIsPhoneVerified(true);
                }
                setSuccess(true);
            } else {
                setError(true);
            }
        }
        setIsLoading(false);
    };

    // ComponentDidMount
    useEffect(() => {
        setIsLoading(true);

        const requestBody = {
            uniqueIdEmail: uniqueIdEmail
        };

        verifyEmail(requestBody);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {isLoading ? (
                <>
                    <DialogsBackground />
                    <Box ml="auto" mr="auto" width="fit-content">
                        <Loader />
                    </Box>
                </>
            ) : (
                <>
                    {error && (
                        <>
                            <DialogsBackground />
                            <ErrorDialog
                                dialogTitle=""
                                close={() => {
                                    setError(false);
                                    props.history.push('/min-side');
                                }}
                                errorMessage={errorMessage}
                                secondPartErrorMessage={secondPartErrorMessage}
                            />
                        </>
                    )}
                    {success && (
                        <>
                            <DialogsBackground />
                            <SuccessDialog
                                close={() => {
                                    setSuccess(false);
                                    props.history.push('/min-side');
                                }}
                                successMessage={
                                    isPhoneVerified
                                        ? 'Din konto er nu verificeret og klar til brug.'
                                        : 'Din email er verificeret. Husk at verificere dit telefonnummer. Tjek din sms.'
                                }
                            />
                        </>
                    )}
                </>
            )}
            {/* </ContentContainer> */}
        </>
    );
}
export default withRouter(withCookies(SignupEmailVerification));
