import React from 'react';
import TransactionsDesktop from './TransactionsDesktop';
import TransactionsMobile from './TransactionsMobile';

function TransactionsPage(props) {
    return (
        <div>
            {document.body.offsetWidth < 960 ? (
                <TransactionsMobile />
            ) : (
                <TransactionsDesktop />
            )}
        </div>
    );
}

export default TransactionsPage;
