import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getLocalStorageJson } from '../../util';
import { withCookies } from 'react-cookie';
import { getCurrentUserInfo } from '../../context/getData';

// Example of role based authentication <AuthenticatedRoute path="/admin/*" role="admin">

function AuthenticatedRoute(props) {
    const { role, children, ...rest } = props;
    const user = getCurrentUserInfo();
    // if (user !== undefined)
    //  console.log('Authenticated route', props.path, 'user', user, 'user id', 'must be',role);

    /* const locationContext = useContext(Context); */

    const attemptedUrl = window.location.pathname;
    // Check if system update in progress
    let key = document.location.hostname + ':system-update';
    let systemUpdate = false;
    if (typeof Storage !== 'undefined' && localStorage.getItem(key) === 'true')
        systemUpdate = true;
    // Admin paths should we accessible during system updates
    if (props.path && props.path.startsWith('/admin/')) systemUpdate = false;

    const checkAuth = () => {
        const key = document.location.hostname + ':state-history';
        const dataFromLocalStorage = getLocalStorageJson(key, {});
        // Check local storage and cookies for user
        // If user is not authenticated, redirect to Login page
        if (
            dataFromLocalStorage.currentUser &&
            dataFromLocalStorage.currentUser.userFirstname !== ''
        ) {
            return true;
        } else {
            return false;
        }
    };
    const storageKey = document.location.hostname + ':state-history';
    const dataFromLocalStorage = getLocalStorageJson(storageKey, {});
    const systemUsers = [
        'admin@denlillemusikskole.dk',
        'test@denlillemusikskole.dk',
        'lk@denlillemusikskole.dk',
        'rj@denlillemusikskole.dk',
        'nlh@denlillemusikskole.dk',
        'admin@leonardo.dk',
        'lk@leonardo.dk'
    ];
    let userEmail;
    if (
        dataFromLocalStorage &&
        dataFromLocalStorage.currentUser &&
        dataFromLocalStorage.currentUser.userEmail
    ) {
        userEmail = dataFromLocalStorage.currentUser.userEmail;
    }

    //Might need this part later when testing IOS.
    // const saveCurrentLocation = useCallback(() => {
    //   const props = { ...rest };
    //   // Check this out. I made the condition because when checking courses from my page, it gets props with a delay, so not working
    //   if (props.location) {
    //     locationContext.saveLocation(props.location.pathname);
    //   }
    //   // Come back: check the dependencies
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // useEffect(() => {
    //   return function cleanup() {
    //     if (document.body.offsetWidth < 960) {
    //       saveCurrentLocation();
    //     }
    //   };
    // }, [saveCurrentLocation]);
    // Check if system update is in progress and with are not logged in with an admin user email
    let goHome = false;
    if (systemUpdate === true && !systemUsers.includes(userEmail))
        goHome = true;
    // Check user role if specified in route

    if (
        !goHome &&
        role !== undefined &&
        (user === undefined || user.role !== role)
    ) {
        goHome = true;
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                checkAuth() ? ( // if authenticated
                    goHome ? (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: {
                                    from: attemptedUrl
                                }
                            }}
                        />
                    ) : (
                        children
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: attemptedUrl }
                        }}
                    />
                )
            }
        />
    );
}

export default withCookies(AuthenticatedRoute);
