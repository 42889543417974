import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import PencilIcon from '../../../assets/icons/pencil.png';
import BinIcon from '../../../assets/icons/bin.png';
import { overridings } from '../../../themes/DarkTheme';
import moment from 'moment';
import ActivityDialog from '../../dialogs/activity-dialog/ActivityDialog';
import ActivityPopup from '../../../containers/activities/ActivityPopup';

const EventContainer = styled.div`
    border-left: 3px solid ${(props) => handleColor(props.color)};
    background-color: ${(props) => props.theme.palette.primary.main};
    position: ${(props) => (props.showactivitypopup ? 'static' : 'relative')};
    min-height: 3rem;
    display: flex;
    padding: 0 0.5rem 0.5rem 0.5rem;
    margin: 0.5rem 0 0.5rem 0.3rem;
    @media (max-width: 960px) {
        padding-top: 0.5rem;
        margin: 0.5rem 0;
    }
`;

const EventLink = styled.div`
    font-size: 0.8rem;
    word-break: break-word;
    text-decoration: underline;
    cursor: pointer;
`;

const ActivityPopupContainer = styled(Box)``;

const handleColor = (props) => {
    switch (props) {
        case 'purple':
            return `${overridings.palette.colors.purple}`;
        case 'green':
            return `${overridings.palette.colors.green}`;
        case 'yellow':
            return `${overridings.palette.colors.yellow}`;
        case 'blue':
            return `${overridings.palette.colors.blue}`;
        case 'orange':
            return `${overridings.palette.colors.orange}`;
        case 'red':
            return `red`;
        case 'aqua':
            return `aqua`;
        case '#a71f48':
            return '#a71f48';
        default:
            return `${overridings.palette.secondary.contrastText}`;
    }
};

const EventContent = styled.div`
    margin-top: 0.2rem;
    color: ${(props) => props.eventcolor && props.eventcolor};
`;

const EventTitle = styled(Typography)`
    font-size: 0.8rem;
    word-break: break-word;
`;

const IconImg = styled.img`
    cursor: pointer;
    width: 0.6rem;
    height: 0.7rem;
    margin-right: 0.2rem;

    @media (max-width: 1270px) {
        display: none;
    }
`;

const IconText = styled(Typography)`
    text-decoration: underline;
    cursor: pointer;
    position: relative;
    margin-right: ${(props) => (props.iconname ? '0' : '0.5rem')};
    color: ${(props) => props.iconname && 'red'};
    font-size: 0.8rem;
`;

function Event(props) {
    const [isEditable, setIsEditable] = useState(false);
    const [isActivityOpen, setIsActivityOpen] = useState(false);
    const [showActivityPopup, setShowActivityPopup] = useState(false);
    const [showPopupOnLeft, setShowPopupOnLeft] = useState(false);
    const [elementTop, setElementTop] = useState(0);
    const [elementLeft, setElementLeft] = useState(0);

    const handleEditClick = (eventId) => {
        props.editBookedEvent(eventId);
        // This is needed in order to be able to display the calendar picker correctly
        props.setIsExpanded(true);
    };

    const handleDeleteClick = (eventId, repeatSeqId) => {
        props.confirmRemovingEvent(eventId, repeatSeqId);
    };

    const onEventClick = () => {
        console.log('event clicked');
        if (document.body.offsetWidth < 960) {
            setIsActivityOpen(true);
        }
    };

    const handleMouseEnter = (e) => {
        const day = moment(props.date).day();

        if (!props.isBookingCalendar && document.body.offsetWidth >= 960) {
            if (
                !props.isPrivate &&
                e.target.className.includes('event-container')
            ) {
                // If the event date is on a Friday or Saturday or Sunday, we should display the popup on the left side.
                if (day === 5 || day === 6 || day === 0) {
                    setElementLeft(e.target.offsetLeft);
                    setShowPopupOnLeft(true);
                }

                setElementTop(e.target.offsetTop);
                setShowActivityPopup(true);
            }
        }
    };

    const handleMouseLeave = () => {
        if (!props.isBookingCalendar && document.body.offsetWidth >= 960) {
            if (showActivityPopup) {
                setShowActivityPopup(false);
            }
        }
    };

    // ComponentDidMount
    useEffect(() => {
        // Check if any event is the user's own event, because if it is, they should be able to edit or delete it
        if (props.ownEvents) {
            for (let i = 0; i < props.ownEvents.length; i++) {
                if (props.eventId === props.ownEvents[i].id) {
                    // Event should only be able to be edited max 2 hours before the starttime ??
                    if (
                        moment(props.date + ' ' + props.startTime).isAfter(
                            moment(new Date())
                        ) &&
                        props.state === 'draft'
                    ) {
                        // There is an own editable event
                        setIsEditable(true);
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [props.ownEvents]);
    return (
        <>
            {(props.shouldBeVisible || props.isOwnEvent) && (
                <EventContainer
                    className="event-container"
                    showactivitypopup={showActivityPopup}
                    color={props.dayColor}
                    onMouseEnter={(e) => handleMouseEnter(e)}
                    onMouseLeave={() => handleMouseLeave()}
                >
                    {/* <HorizontalLine color={props.dayColor}></HorizontalLine> */}
                    <EventContent
                        eventcolor={overridings.palette.secondary.contrastText}
                    >
                        <EventTitle variant="body2">
                            {props.startTime.substr(0, 2) +
                                '.' +
                                props.startTime.substr(3, 2)}{' '}
                            -{' '}
                            {props.endTime.substr(0, 2) +
                                '.' +
                                props.endTime.substr(3, 2)}
                        </EventTitle>
                        <EventTitle variant="body2"> {props.title}</EventTitle>
                        {!props.isPrivate &&
                            !isEditable &&
                            document.body.offsetWidth < 960 && (
                                <EventLink onClick={() => onEventClick()}>
                                    Se mere
                                </EventLink>
                            )}
                        {isEditable && (
                            <Box display="flex">
                                <IconText
                                    variant="body2"
                                    onClick={() =>
                                        handleEditClick(props.eventId)
                                    }
                                >
                                    <IconImg src={PencilIcon} alt="edit" />
                                    Rediger
                                </IconText>
                                <IconText
                                    variant="body2"
                                    iconname="bin"
                                    onClick={() =>
                                        handleDeleteClick(
                                            props.eventId,
                                            props.repeatSeqId
                                        )
                                    }
                                >
                                    <IconImg
                                        iconname="bin"
                                        src={BinIcon}
                                        alt="bin"
                                    />
                                    Annuller
                                </IconText>
                            </Box>
                        )}
                    </EventContent>
                    {isActivityOpen && (
                        <ActivityDialog
                            close={() => setIsActivityOpen(false)}
                            activityInfo={props}
                        />
                    )}
                    {showActivityPopup && (
                        <ActivityPopupContainer>
                            <ActivityPopup
                                activityInfo={props}
                                showPopupOnLeft={showPopupOnLeft}
                                elementTop={elementTop}
                                elementLeft={elementLeft}
                            />
                        </ActivityPopupContainer>
                    )}
                </EventContainer>
            )}
        </>
    );
}

export default Event;
