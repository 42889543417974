import React, { useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const DialogComponent = styled(Dialog)`
  & .MuiPaper-root {
    padding: 1rem !important;
  }
`;

function SuccessDialog({ title, successMessage, close, setScrollView }) {
  // ComponentDidMount
  useEffect(() => {
    if (setScrollView) {
      setScrollView();
    }
    // Come back: check if its okay not to include setScrollView in dependencies
    // eslint-disable-next-line
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.7 }}
    >
      <DialogComponent
        onClose={close}
        open={true}
        aria-labelledby="success"
        aria-describedby="success-description"
        disableRestoreFocus
      >
        <DialogTitle id="success">{title}</DialogTitle>
        {successMessage !== "" && (
          <DialogContent>
            <DialogContentText id="success-description">
              {successMessage}
            </DialogContentText>
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={() => close()}>Ok</Button>
        </DialogActions>
      </DialogComponent>
    </motion.div>
  );
}

export default SuccessDialog;
