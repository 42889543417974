import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText
} from '@material-ui/core';

const DialogComponent = styled(Dialog)`
    & .MuiPaper-root {
        padding: 1rem !important;
    }
`;

function ContinueToPayDialog({ close, onSubmit, backToCalendar }) {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <DialogComponent
                onClose={close}
                open={true}
                aria-labelledby="success"
                aria-describedby="success-description"
                disableRestoreFocus
            >
                {/* English text */}
                {/* <DialogTitle id="success">Vigtigt</DialogTitle> */}

                <DialogContent>
                    <DialogContentText id="success-description">
                        Det er ikke muligt at redigere i dine reservationer
                        efter betaling.
                        <br />
                        Har du ændringer til din booking, eller vil du tilføje
                        tider, så tryk “Tilbage til kalender”. Hvis du klikker
                        'Fortsæt' uden at fuldføre betalingen, vil din
                        reservation stå i systemet i 24 timer før det slettes.
                        Tiderne vil derfor stå som optaget indtil systemet
                        frigiver dem.
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    {/* <Button onClick={() => close()}></Button> */}
                    <Button onClick={() => backToCalendar()}>Tilbage</Button>
                    <Button onClick={() => onSubmit()}>Fortsæt</Button>
                </DialogActions>
            </DialogComponent>
        </motion.div>
    );
}

export default ContinueToPayDialog;
