import {
    capitalize,
    postAndReturnResponseWithAuth
} from '../../../context/helperFunctions';
import {
    postAndReturnResp,
    postAndReturnData
} from '../../../context/helperFunctions';

export const validateName = (
    value,
    label,
    setFirstname,
    setSurname,
    firstname,
    surname
) => {
    const regex = /^[A-Za-z æøåÆØÅ.,-]+$/;
    const capitalizedValue = capitalize(value);
    // What should be the max length of a name?
    if (regex.test(capitalizedValue) && capitalizedValue.length < 500) {
        switch (label) {
            case 'Fornavn':
                setFirstname({ ...firstname, value: capitalizedValue });
                break;
            case 'Efternavn':
                setSurname({ ...surname, value: capitalizedValue });
                break;
            default:
                break;
        }
    } else {
        switch (label) {
            case 'Fornavn':
                setFirstname({
                    ...firstname,
                    error: true,
                    errorMessage: `${label} mangler.`,
                    value: capitalizedValue
                });
                break;
            case 'Efternavn':
                setSurname({
                    ...surname,
                    error: true,
                    errorMessage: `${label} mangler.`,
                    value: capitalizedValue
                });
                break;
            default:
                break;
        }
    }
};

export const validateEmail = (value, setEmailObj, emailObj) => {
    // var regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(value)) {
        setEmailObj({ ...emailObj, value });
    } else {
        setEmailObj({
            ...emailObj,
            error: true,
            errorMessage: `Din mailadresse mangler eller er ukorrekt.`,
            value
        });
    }
};

export const validateActivityOrWebpage = (
    value,
    label,
    setActivityNameObj,
    setWebpageLinkObj,
    setFacebookObj,
    setInstagramObj,
    setLinkedinObj,
    setYoutubeObj,
    // setOrganizationObj,
    activityNameObj,
    webpageLinkObj,
    facebookObj,
    instagramObj,
    linkedinObj,
    youtubeObj
    // organizationObj
) => {
    const regex = /^[A-Za-z0-9 æøåüÆØÅÜ=.,-/:()!?'";]+$/;
    const capitalizedValue = capitalize(value);
    if (label === 'Navn på din aktivitet' || label === 'Hjemmeside') {
        if (
            regex.test(capitalizedValue) &&
            capitalizedValue.length < 64 &&
            capitalizedValue.length > 0
        ) {
            if (label === 'Navn på din aktivitet') {
                setActivityNameObj({
                    ...activityNameObj,
                    value: capitalizedValue
                });
            } else if (label === 'Hjemmeside') {
                setWebpageLinkObj({ ...webpageLinkObj, value: value });
            }
            // else if (label === 'Organisation') {
            //     setOrganizationObj({ ...organizationObj, value: capitalizedValue });
            // }
        } else if (value === '' || value === undefined) {
            if (label === 'Navn på din aktivitet') {
                // setActivityNameObj({
                //     ...activityNameObj,
                //     error: true,
                //     errorMessage: `${label} er ugyldigt eller længere end 64 karakter.`,
                //     value: capitalizedValue
                // });
                setActivityNameObj({
                    ...activityNameObj,
                    value: capitalizedValue
                });
            } else if (label === 'Hjemmeside') {
                setWebpageLinkObj({ ...webpageLinkObj, value: value });
            }
            // else if (label === 'Organisation') {
            //     setOrganizationObj({ ...organizationObj, value: capitalizedValue });
            // }
        } else {
            if (label === 'Navn på din aktivitet') {
                setActivityNameObj({
                    ...activityNameObj,
                    error: true,
                    errorMessage: `${label} er ugyldigt eller længere end 64 karakter.`,
                    value: capitalizedValue
                });
            } else if (label === 'Hjemmeside') {
                setWebpageLinkObj({
                    ...webpageLinkObj,
                    error: true,
                    errorMessage: `${label} er ugyldigt eller længere end 64 karakter.`,
                    value: value
                });
            }
            // else if (label === 'Organisation') {
            //     setOrganizationObj({
            //         ...organizationObj,
            //         error: true,
            //         errorMessage: `${label} er ugyldigt eller længere end 64 karakter.`,
            //         value: value
            //     });
            // }
        }
    } else {
        if ((regex.test(value) && value.length < 255) || value.length < 1) {
            if (label === 'Facebook') {
                setFacebookObj({ ...facebookObj, value });
            } else if (label === 'Instagram') {
                setInstagramObj({ ...instagramObj, value });
            } else if (label === 'LinkedIn') {
                setLinkedinObj({ ...linkedinObj, value });
            } else if (label === 'Youtube') {
                setYoutubeObj({ ...youtubeObj, value });
            }
        } else {
            if (label === 'Facebook') {
                setFacebookObj({
                    ...facebookObj,
                    error: true,
                    errorMessage: `${label} er ugyldigt eller længere end 255 karakter.`,
                    value
                });
            } else if (label === 'Instagram') {
                setInstagramObj({
                    ...instagramObj,
                    error: true,
                    errorMessage: `${label} er ugyldigt eller længere end 255 karakter.`,
                    value
                });
            } else if (label === 'LinkedIn') {
                setLinkedinObj({
                    ...linkedinObj,
                    error: true,
                    errorMessage: `${label} er ugyldigt eller længere end 255 karakter.`,
                    value
                });
            } else if (label === 'Youtube') {
                setYoutubeObj({
                    ...youtubeObj,
                    error: true,
                    errorMessage: `${label} er ugyldigt eller længere end 255 karakter.`,
                    value
                });
            }
        }
    }
};

export const validateActivityDescription = (
    value,
    label,
    setActivityDescriptionObj,
    activityDescriptionObj
) => {
    if (value && value !== '' && value.length > 0) {
        //  const regex = /^[A-Za-z0-9 æøåüÆØÅÜ.,-/:()!?'";_\n\r]+$/
        if (value.length < 1000) {
            return true;
        } else {
            return false;
        }
    }
    // if (value && value !== '' && value.length > 0) {
    //     // const regex = /^[A-Za-z0-9 æøåüÆØÅÜ.,-/:()!?'";_\n\r]+$/
    //     if (value.length < 1000) {
    //         setActivityDescriptionObj({ ...activityDescriptionObj, value });
    //     } else {
    //         setActivityDescriptionObj({
    //             ...activityDescriptionObj,
    //             error: true,
    //             errorMessage: `${label} længere end 700 karakter.`
    //         });
    //     }
    // } else {
    //     setActivityDescriptionObj({ ...activityDescriptionObj, value: '' });
    // }
};

export const validateStringAddress = (
    value,
    label,
    setStreetObj,
    setHousenumberObj,
    setCityObj,
    streetObj,
    housenumberObj,
    cityObj
) => {
    const regex = /^[A-Za-z0-9 æøåüÆØÅÜ.,-]+$/;
    const regexCity = /^[A-Za-z æøåüÆØÅÜ.,-]+$/;
    const capitalizedValue = capitalize(value);
    if (label === 'Husnummer/etage/side' || label === 'Gade') {
        if (regex.test(capitalizedValue) && capitalizedValue.length < 500) {
            switch (label) {
                case 'Gade':
                    setStreetObj({ ...streetObj, value: capitalizedValue });
                    break;
                case 'Husnummer/etage/side':
                    setHousenumberObj({
                        ...housenumberObj,
                        value: capitalizedValue
                    });
                    break;

                default:
                    break;
            }
        } else {
            switch (label) {
                case 'Gade':
                    setStreetObj({
                        ...streetObj,
                        error: true,
                        errorMessage: `Dit gadenavn mangler eller er ukorrekt.`,
                        value: capitalizedValue
                    });
                    break;
                case 'Husnummer/etage/side':
                    setHousenumberObj({
                        ...housenumberObj,
                        error: true,
                        errorMessage: `Dit husnummer mangler eller er ukorrekt.`,
                        value: capitalizedValue
                    });
                    break;

                default:
                    break;
            }
        }
    } else {
        if (regexCity.test(capitalizedValue) && capitalizedValue.length < 500) {
            setCityObj({ ...cityObj, value: capitalizedValue });
        } else {
            setCityObj({
                ...cityObj,
                error: true,
                errorMessage: `Din by mangler eller er ukorrekt.`,
                value: capitalizedValue
            });
        }
    }
};

export const validatePostcode = (value, setPostcodeObj, postcodeObj) => {
    const regex = /^[0-9]+$/;
    if (regex.test(value) && value.length === 4) {
        setPostcodeObj({ ...postcodeObj, value });
    } else {
        setPostcodeObj({
            ...postcodeObj,
            error: true,
            errorMessage: `Dit postnummer mangler eller er ukorrekt (4 cifre).`,
            value
        });
    }
};

export const validatePhone = (
    value,
    label,
    setPhoneObj,
    setActivityPhoneObj,
    phoneObj,
    activityPhoneObj
) => {
    //The  old validation, that allowed minimum 8 characters (no upper limit) and +
    // We still allow this for activity phone number
    // const regexActivityPhone = /^[0-9+ ]{8,}$/;
    // New validation is changed, so only numbers are allowed and exactly 8 characters
    // We use this for the regular phone number, where we send activation code
    // const regexRegularPhone = /^[0-9 ]{8}$/;
    let regex;
    if (label === 'Mobil') {
        regex = /^[0-9 ]{8}$/;
    } else if (label === 'Kontaktnummer') {
        regex = /^[0-9+ ]{8,}$/;
    }

    if (regex.test(value) && value) {
        if (label === 'Mobil') {
            setPhoneObj({ ...phoneObj, value });
        } else if (label === 'Kontaktnummer') {
            setActivityPhoneObj({ ...activityPhoneObj, value });
        }
    } else if (
        (value === '' || value === undefined) &&
        label === 'Kontaktnummer'
    ) {
        setActivityPhoneObj({ ...activityPhoneObj, value });
    } else {
        if (label === 'Mobil') {
            setPhoneObj({
                ...phoneObj,
                error: true,
                errorMessage: `Dit mobilnummer mangler (8 cifre, kun tal).`,
                value
            });
        } else if (label === 'Kontaktnummer') {
            setActivityPhoneObj({
                ...activityPhoneObj,
                error: true,
                errorMessage: `Dit nummer mangler (8 cifre).`,
                value
            });
        }
    }
};

export const validatePassword = (value, setPasswordObj, passwordObj) => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/;
    if (value.length < 6) {
        setPasswordObj({
            ...passwordObj,
            error: true,
            errorMessage:
                'Adgangskoden skal være på mindst 6 tegn og indeholde mindst et tal og både store og små bogstaver.',
            value
        });
    } else if (value.length > 250) {
        setPasswordObj({
            ...passwordObj,
            error: true,
            errorMessage: 'Adgangskode kan være max 250 bogstaver.',
            value
        });
    } else if (!regex.test(value)) {
        setPasswordObj({
            ...passwordObj,
            error: true,
            errorMessage:
                'Adgangskode skal være på mindst 6 tegn og indeholde mindst et tal og både store og små bogstaver.',
            value
        });
    } else {
        setPasswordObj({ ...passwordObj, value });
    }
};

// Validate password without passing password object
export const validatePasswordValue = (value) => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/;
    if (value.length < 6 || value.length > 250 || !regex.test(value)) {
        return false;
    } else {
        return true;
    }
};

export const validatePasswordMatch = (
    password,
    repeatPassword,
    setRepeatPasswordObj,
    repeatPasswordObj
) => {
    if (password !== repeatPassword) {
        setRepeatPasswordObj({
            ...repeatPasswordObj,
            error: true,
            errorMessage: 'Adgangskode matcher ikke.',
            value: repeatPassword
        });
    } else {
        setRepeatPasswordObj({ ...repeatPasswordObj, value: repeatPassword });
    }
};
// Validate match password without passing password object and repeat password object
export const validatePasswordValueMatch = (password, repeatPassword) => {
    if (password !== repeatPassword) {
        return false;
    } else {
        return true;
    }
};
// Returns true, if any state has an object
export const hasErrors = (states) => {
    return states.find((state) => state.error);
};

export const returnStateObj = (statesArr, label) => {
    return statesArr.find((state) => state.label === label);
};

export const returnSetStateObj = (setStatesArr, label) => {
    const setStateObj = setStatesArr.find(
        (setState) => setState.label === label
    );
    if (setStateObj) {
        return setStateObj.function;
    }
};

export const throwError = (label, stateObj, setStateObj) => {
    switch (label) {
        case 'firstname':
            setStateObj({
                ...stateObj,
                errorMessage: 'Fornavn skal udfyldes.',
                error: true
            });
            break;

        case 'surname':
            setStateObj({
                ...stateObj,
                errorMessage: 'Efternavn skal udfyldes.',
                error: true
            });
            break;

        case 'email':
            setStateObj({
                ...stateObj,
                errorMessage: 'Din mailadresse mangler eller er ukorrekt.',
                error: true
            });
            break;

        case 'phone':
            setStateObj({
                ...stateObj,
                errorMessage: 'Dit mobilnummer mangler (8 cifre).',
                error: true
            });
            break;

        case 'password':
            setStateObj({
                ...stateObj,
                errorMessage:
                    'Adgangskode skal være på mindst 8 tegn og indeholde mindst et tal og både store og små bogstaver.',
                error: true
            });
            break;
        case 'repeatPassword':
            setStateObj({
                ...stateObj,
                errorMessage:
                    'Adgangskode skal være på mindst 8 tegn og indeholde mindst et tal og både store og små bogstaver.',
                error: true
            });
            break;

        case 'street':
            setStateObj({
                ...stateObj,
                errorMessage: 'Dit gadenavn mangler eller er ukorrekt.',
                error: true
            });
            break;
        case 'housenumber':
            setStateObj({
                ...stateObj,
                errorMessage: 'Dit husnummer mangler eller er ukorrekt.',
                error: true
            });
            break;

        case 'postcode':
            setStateObj({
                ...stateObj,
                errorMessage: 'Postnummer mangler eller er ukorrekt (4 cifre).',
                error: true
            });
            break;
        case 'city':
            setStateObj({
                ...stateObj,
                errorMessage: 'Din by mangler eller er ukorrekt.',
                error: true
            });
            break;
        default:
            break;
    }
};

const registerUser = async (
    requestObj,
    context,
    props,
    setError,
    setErrorMessage,
    setIsLoading,
    isOnlySignup
) => {
    const response = await postAndReturnResp(
        '/api/v1/users/signup',
        requestObj
    );

    if (response && response.status === 200) {
        // Log user in
        const data = await postAndReturnData(
            '/api/v1/users/login',
            { email: requestObj.email, password: requestObj.password },
            setError
        );
        console.log('show data', data);
        if (
            data &&
            data.user &&
            data.user.id &&
            data.user.firstname &&
            data.user.email &&
            data.userToken
        ) {
            context.saveUser({
                userFirstname: data.user.firstname,
                userEmail: data.user.email,
                userId: data.user.id,
                emailVerifiedAt: data.user.emailVerifiedAt,
                phoneVerifiedAt: data.user.phoneVerifiedAt,
                subscriber: data.user.subscriber,
                token: data.userToken,
                tokenExpires: data.expires,
                lastDayOfSubscription: data.user.lastDayOfSubscription,
                subscriptionDueDate: data.user.subscriptionDueDate,
                inLastMonthOfSubscription: data.user.inLastMonthOfSubscription,
                isProfilePublic: data.user.isProfilePublic,
                role: data.user.role,
                lastAcceptedTerms: data.user.lastAcceptedTerms
            });

            // Send out verify email and sms
            postAndReturnResponseWithAuth(
                `/api/v1/users/${data.user.id}/send-verification-email?isWelcomeEmail=true`,
                {},
                data.userToken,
                setError
            );
            postAndReturnResponseWithAuth(
                `/api/v1/users/${data.user.id}/send-verification-sms`,
                {},
                data.userToken,
                setError
            );

            // Save in local storage, that this is a new user, so the modal about having to verify email and phone must be shown to them

            console.log('should save new user');
            context.saveNewUser(true);
            context.showFullProgressBar(true);
            //Empty context registration information
            context.saveRegistrationData({});

            // If it is an only signup, user should be directed to My page
            if (isOnlySignup) {
                context.saveOnlySignup(true);
                props.history.push({
                    pathname: '/min-side'
                });
            } else {
                props.history.push('/opsummering');
            }
        } else {
            setError(true);
            setErrorMessage('Noget gik galt. Prøv igen.');
        }
    } else if (response && response.status === 500) {
        const responseObj = await response.json();

        if (responseObj && responseObj.message) {
            // Check error message
            if (responseObj.message === 'email must be unique') {
                setError(true);
                // Come back! We should get a custom error modal here to prompt people to log in?
                setErrorMessage(
                    'Denne email er allerede registreret. Log ind.'
                );
            } else {
                setError(true);
                setErrorMessage('Noget gik galt. Prøv igen.');
            }
        } else {
            setError(true);
            setErrorMessage('Noget gik galt. Prøv igen.');
        }
    } else {
        setError(true);
        setErrorMessage('Noget gik galt. Prøv igen.');
    }
    setIsLoading(false);
};

export const setScrollView = (ref) => {
    ref.current.scrollIntoView();
};

export const returnValue = (statesArr, label) => {
    const state = statesArr.find((state) => state.label === label);
    if (state && state.value) {
        return state.value;
    }
};

export const findUnfilledInputOrRegister = async (
    statesArr,
    setStatesArr,
    context,
    props,
    isAuthenticated,
    parentNameRef,
    emailRef,
    phoneRef,
    passwordRef,
    addressRef,
    setError,
    setErrorMessage,
    setIsLoading,
    isOnlySignup,
    acceptedPublicProfile,
    userCategories
) => {
    if (
        statesArr.some(
            (state) =>
                // state.label !== 'organization' &&
                // (state.label !== 'activityName' ||
                //     state.label !== 'webpageLink' ||
                //     state.label !== 'activityDescription' ||
                //     state.label !== 'activityPhone') &&
                // state.label !== 'facebook' ||
                // state.label !== 'instagram' ||
                // state.label !== 'linkedin' ||
                // state.label !== 'youtube')
                (state.label === 'firstname' ||
                    state.label === 'surname' ||
                    state.label === 'street' ||
                    state.label === 'city' ||
                    state.label === 'housenumber' ||
                    state.label === 'postcode' ||
                    state.label === 'phone' ||
                    state.label === 'email' ||
                    state.label === 'password') &&
                state.value === ''
        )
    ) {
        const notFilledState = statesArr.filter(
            (state) => state.value === ''
            //  &&
            // state.label !== facebook &&
            // state.label !== 'instagram' &&
            // state.label !== 'linkedin' &&
            // state.label !== 'youtube'
        );

        // Throw error in these States
        notFilledState.forEach((state) =>
            throwError(
                state.label,
                returnStateObj(statesArr, state.label),
                returnSetStateObj(setStatesArr, state.label)
            )
        );

        // Set scroll view to the first element that has error
        const firstErrorLabel = notFilledState[0].label;
        if (firstErrorLabel === 'firstname' || firstErrorLabel === 'surname') {
            setScrollView(parentNameRef);
        } else if (firstErrorLabel === 'email') {
            setScrollView(emailRef);
        } else if (firstErrorLabel === 'phone') {
            setScrollView(phoneRef);
        } else if (
            firstErrorLabel === 'street' ||
            firstErrorLabel === 'housenumber' ||
            firstErrorLabel === 'postcode' ||
            firstErrorLabel === 'city'
        ) {
            setScrollView(addressRef);
        } else if (
            firstErrorLabel === 'password' ||
            firstErrorLabel === 'repeatPassword'
        ) {
            setScrollView(passwordRef);
        }
        setIsLoading(false);
    } else {
        const regData = {};
        regData.information = {
            firstname: returnValue(statesArr, 'firstname'),
            surname: returnValue(statesArr, 'surname'),
            email: returnValue(statesArr, 'email'),
            phone: returnValue(statesArr, 'phone'),
            street: returnValue(statesArr, 'street'),
            housenumber: returnValue(statesArr, 'housenumber'),
            city: returnValue(statesArr, 'city'),
            postcode: returnValue(statesArr, 'postcode'),
            password: returnValue(statesArr, 'password'),
            newsLetter: false, // Since we don't have newsletters now, it is just set to false
            activityName: returnValue(statesArr, 'activityName'),
            activityDescription: returnValue(statesArr, 'activityDescription'),
            webpageLink: returnValue(statesArr, 'webpageLink'),
            isProfilePublic: acceptedPublicProfile,
            activityPhone: returnValue(statesArr, 'activityPhone'),
            facebook: returnValue(statesArr, 'facebook'),
            instagram: returnValue(statesArr, 'instagram'),
            linkedin: returnValue(statesArr, 'linkedin'),
            youtube: returnValue(statesArr, 'youtube'),
            // organization: returnValue(statesArr, 'organization'),
            categories: userCategories
        };

        // Save information to storage, so if the request fails, user don't need to retype everything
        const regDataWithoutPassword = {
            firstname: returnValue(statesArr, 'firstname'),
            surname: returnValue(statesArr, 'surname'),
            email: returnValue(statesArr, 'email'),
            phone: returnValue(statesArr, 'phone'),
            street: returnValue(statesArr, 'street'),
            housenumber: returnValue(statesArr, 'housenumber'),
            city: returnValue(statesArr, 'city'),
            postcode: returnValue(statesArr, 'postcode'),
            activityName: returnValue(statesArr, 'activityName'),
            activityDescription: returnValue(statesArr, 'activityDescription'),
            webpageLink: returnValue(statesArr, 'webpageLink'),
            isProfilePublic: acceptedPublicProfile,
            activityPhone: returnValue(statesArr, 'activityPhone'),
            facebook: returnValue(statesArr, 'facebook'),
            instagram: returnValue(statesArr, 'instagram'),
            linkedin: returnValue(statesArr, 'linkedin'),
            youtube: returnValue(statesArr, 'youtube'),
            // organization: returnValue(statesArr, 'organization'),
            categories: userCategories
        };
        // Save information to local storage
        context.saveRegistrationData(regDataWithoutPassword);

        // Call /users/signup endpoint. Create a user
        registerUser(
            regData.information,
            context,
            props,
            setError,
            setErrorMessage,
            setIsLoading,
            isOnlySignup
        );
    }
};

// Check if there is user information saved in storage
export const storageHasInformation = (regInformation) => {
    if (
        regInformation.firstname &&
        regInformation.surname &&
        regInformation.street &&
        regInformation.housenumber &&
        regInformation.postcode &&
        regInformation.city &&
        regInformation.email &&
        regInformation.phone
    ) {
        return true;
    } else {
        return false;
    }
};
