import { Box, Typography } from '@material-ui/core';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import ContentContainer from '../../components/containers/ContentContainer';
import EventItem from '../../components/event-item/EventItem';
import {
    getLocationNameFromRoomId,
    getUserId,
    returnRepeatSeqId
} from '../../context/getData';
import HeadersContainer from '../../components/headers/headers-container/HeadersContainer';
import {
    deleteAndReturnResp,
    getAndReturnData,
    getAndSetEvents
} from '../../context/helperFunctions';
import DeleteDialog from '../../components/dialogs/delete-dialog/DeleteDialog';
import SuccessDialog from '../../components/dialogs/success-dialog/SuccessDialog';
import moment from 'moment';

function MyEventsAfterMembershipExpiry(props) {
    const [ownEvents, setOwnEvents] = useState([]);
    const [ownEventsAfterExpiry, setOwnEventsAfterExpiry] = useState([]);
    const [listOfRooms, setListOfRooms] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [selectedEventIdToDelete, setSelectedEventIdToDelete] = useState('');
    const [multipleEvents, setMultipleEvents] = useState(false);
    const [successfulDeleteDialog, setSuccessfulDeleteDialog] = useState(false);

    const handleDeleteClick = (eventId, repeatSeqId) => {
        confirmRemovingEvent(eventId, repeatSeqId);
    };

    const handleError = (err) => {
        console.log('Error:', err);
    };

    // A dialog is displayed to make the user confirm the deletion
    // If they press yes, the removeBookedEvent will be run
    const confirmRemovingEvent = async (eventId, repeatSeqId) => {
        if (repeatSeqId) {
            // Check if there are multiple events to delete
            // If there are, we need to ask the user,  if they want to delete all related events
            const data = await getAndReturnData(
                `/api/v1/events/${repeatSeqId}`,
                handleError
            );
            if (data && data.events && data.events.length > 1) {
                setDeleteDialog(true);
                setSelectedEventIdToDelete(eventId);
                setMultipleEvents(true);
            } else {
                setDeleteDialog(true);
                setSelectedEventIdToDelete(eventId);
            }
        } else {
            setDeleteDialog(true);
            setSelectedEventIdToDelete(eventId);
        }
    };

    // User is able to delete its own event by confirming the deletion in the DeleteDialog
    const removeBookedEvent = async (eventId, repeatSeqId) => {
        const getUrl = () => {
            if (repeatSeqId !== undefined) {
                return `/api/v1/users/${getUserId()}/events/draft/${eventId}?repeatSeqId=${repeatSeqId}`;
            } else {
                return `/api/v1/users/${getUserId()}/events/draft/${eventId}`;
            }
        };
        const resp = await deleteAndReturnResp(getUrl(), handleError);
        if (resp && resp.status === 200) {
            getAndSetEvents(setOwnEvents);
            setSuccessfulDeleteDialog(true);
        } else {
            handleError(
                'Noget gik galt. Kunne ikke fjerne reservationen.  Prøv igen.'
            );
        }
    };

    const getEventsAfterExpiry = () => {
        const lastDateOfThisMonth = moment()
            .endOf('month')
            .format('YYYY-MM-DD');
        const affectedEvents = ownEvents.filter((event) =>
            moment(event.startdate).isAfter(moment(lastDateOfThisMonth))
        );
        setOwnEventsAfterExpiry(affectedEvents);
        return affectedEvents;
    };

    const createEventListItem = useCallback(() => {
        const eventsAfterExpiry = getEventsAfterExpiry();
        const listOfRooms = eventsAfterExpiry.map((event) =>
            getLocationNameFromRoomId(event.roomId)
        );

        const uniqueListOfRooms = [...new Set(listOfRooms)];
        setListOfRooms(uniqueListOfRooms);
        // eslint-disable-next-line
    }, [ownEvents]);

    useEffect(() => {
        createEventListItem();
    }, [ownEvents, createEventListItem]);

    // componentDidMount
    useEffect(() => {
        getAndSetEvents(setOwnEvents);
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.7 }}
            >
                <HeadersContainer />

                <ContentContainer>
                    {/* <Box mb="2rem">
                        <ReturnLinkReusable
                            destination="Min side"
                            url="/min-side"
                        />
                    </Box>
                    <RegularHeadline
                        title="Mine reserveringer"
                        marginbottom="1rem"
                    /> */}

                    <Box mb="2rem">
                        {ownEventsAfterExpiry.length > 0 ? (
                            <>
                                <Typography variant="subtitle1">
                                    Prisen på følgende reservation bliver
                                    påvirket, hvis du vælger at opsige dit
                                    medlemskab nu:
                                </Typography>
                                <Box mt="1rem">
                                    {listOfRooms.map((room) => (
                                        <Box key={room}>
                                            <Typography
                                                variant="body2"
                                                style={{ marginBottom: '1rem' }}
                                            >
                                                Dine reserverede tidspunkter i
                                                lokale {room}
                                            </Typography>
                                            <Box mb="1rem">
                                                {ownEventsAfterExpiry.map(
                                                    (event) => {
                                                        if (
                                                            getLocationNameFromRoomId(
                                                                event.roomId
                                                            ) === room
                                                        ) {
                                                            return (
                                                                // User is not able to edit the events directly from Checkout.jsx
                                                                // They need to click on the button, which would take them back to the calendar instead, where they can edit
                                                                <EventItem
                                                                    key={
                                                                        event.id
                                                                    }
                                                                    event={
                                                                        event
                                                                    }
                                                                    canBeDeleted={
                                                                        true
                                                                    }
                                                                    handleDeleteClick={() =>
                                                                        handleDeleteClick(
                                                                            event.id,
                                                                            event.repeatSeqId
                                                                        )
                                                                    }
                                                                />
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    }
                                                )}
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </>
                        ) : (
                            <Typography>
                                There are no events, which are going to be
                                affected if you cancel the membership now.
                            </Typography>
                        )}
                    </Box>
                </ContentContainer>
                {deleteDialog && (
                    <DeleteDialog
                        close={() => {
                            setDeleteDialog(false);
                            setSelectedEventIdToDelete(null);
                            setMultipleEvents(false);
                        }}
                        eventId={selectedEventIdToDelete}
                        repeatSeqId={returnRepeatSeqId(
                            ownEventsAfterExpiry,
                            selectedEventIdToDelete
                        )}
                        // removeBookedEvent={() => window.location.reload()}
                        removeBookedEvent={removeBookedEvent}
                        multipleEvents={multipleEvents}
                    />
                )}

                {successfulDeleteDialog && (
                    <SuccessDialog
                        successMessage="Din reservation er annulleret."
                        close={() => setSuccessfulDeleteDialog(false)}
                    />
                )}
            </motion.div>
        </div>
    );
}

export default MyEventsAfterMembershipExpiry;
