import React from "react";
import StageNumberActive from "./stage-numbers/StageNumberActive";
import StageNumberCompleted from "./stage-numbers/StageNumberCompleted";
import StageNumberInComplete from "./stage-numbers/StageNumberIncomplete";
import StageTitle from "./StageTitle";

function ProgressStage({ stageNumber, stageTitle, status, type }) {
  //   /* Get stage component according to status */
  const getRightStageComponent = () => {
    if (status === "active") {
      return <StageNumberActive stageNumber={stageNumber} />;
    } else if (status === "completed") {
      return <StageNumberCompleted stageNumber={stageNumber} />;
    } else {
      return <StageNumberInComplete stageNumber={stageNumber} />;
    }
  };

  return (
    <div>
      {getRightStageComponent()}
      <StageTitle stageTitle={stageTitle} status={status} type={type} />
    </div>
  );
}

export default ProgressStage;
