import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import DesktopCalendar from '../../components/calendar/DesktopCalendar';
import MobileCalendar from '../../components/calendar/MobileCalendar';
import { getLocalStorageJson } from '../../util';

import { motion } from 'framer-motion';
import { Box } from '@material-ui/core';
import RoomsDesktopCalendar from '../../components/calendar/RoomsDesktopCalendar';
import { Context } from '../../context/Context';
import { withContext } from '../../components/containers/ContextContainer';
import HeadersContainer from '../../components/headers/headers-container/HeadersContainer';

let localStorageKey = document.location.hostname + ':state-history';

function CalendarPage(props) {
    let json = getLocalStorageJson(localStorageKey);
    const context = useContext(Context);
    const [checked, setChecked] = useState(
        json !== undefined
            ? json.calendarType !== 'rooms'
                ? false
                : true
            : false
    );

    const handleChange = () => {
        console.log('changing calendar change');
        checked
            ? context.saveCalendarType('normal')
            : context.saveCalendarType('rooms');

        setChecked(!checked);
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}
        >
            <HeadersContainer />

            {/* {document.body.offsetWidth < 960 ? (
                <></>
            ) : (
                <Box mt="4rem">
                    <SwitchButtonCalendar
                        handleChange={handleChange}
                        checked={checked}
                    />
                </Box>
            )} */}

            {document.body.offsetWidth < 960 ? (
                <Box width="90%" ml="auto" mr="auto">
                    <MobileCalendar />
                </Box>
            ) : (
                <Box mt="4rem">
                    {checked ? (
                        <RoomsDesktopCalendar
                            handleCalendarChange={handleChange}
                            checked={checked}
                        />
                    ) : (
                        <DesktopCalendar
                            handleCalendarChange={handleChange}
                            checked={checked}
                        />
                    )}
                </Box>
            )}
        </motion.div>
    );
}

export default withContext(withRouter(CalendarPage));
