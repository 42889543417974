import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { overridings } from '../../themes/DarkTheme';

function SumRowDiscountedPrice({
    sumDiscountedPricePerMonth,
    sumNormalPricePerMonth,
    dueDate,
    isMobile,
    month
}) {
    console.log('show month', month);
    return (
        <>
            {isMobile ? (
                <Table>
                    <TableRow
                        sx={{
                            backgroundColor: overridings.palette.primary.light
                        }}
                    >
                        <TableCell
                            sx={{
                                color: overridings.palette.primary.contrastText
                            }}
                        >
                            I alt til betaling den {dueDate}
                        </TableCell>
                        <TableCell
                            sx={{
                                color: overridings.palette.primary.contrastText
                            }}
                        >
                            {sumDiscountedPricePerMonth
                                .toString()
                                .replaceAll('.', ',')}{' '}
                            kr.
                        </TableCell>
                    </TableRow>
                </Table>
            ) : (
                <Table
                    sx={{
                        overflow: 'unset'
                    }}
                >
                    <TableBody>
                        <TableRow
                            sx={{
                                backgroundColor:
                                    overridings.palette.primary.light
                            }}
                        >
                            <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                    color:
                                        overridings.palette.primary
                                            .contrastText,
                                    minWidth: '95px',
                                    width: '11%',
                                    '@media (max-width: 600px)': {
                                        fontSize: '10px',
                                        minWidth: 'unset'
                                    }
                                }}
                            >
                                <b>I alt</b>
                            </TableCell>

                            <TableCell
                                sx={{
                                    minWidth: '250px',
                                    width: '25%',
                                    '@media (max-width: 600px)': {
                                        minWidth: 'unset',
                                        width: '0px'
                                    }
                                }}
                            ></TableCell>

                            <TableCell
                                sx={{
                                    color:
                                        overridings.palette.primary
                                            .contrastText,
                                    textDecoration: 'line-through',
                                    minWidth: '115px',
                                    width: '13%',
                                    '@media (max-width: 600px)': {
                                        display: 'none'
                                    }
                                }}
                                align="center"
                            >
                                <b>
                                    {' '}
                                    {sumNormalPricePerMonth
                                        .toString()
                                        .replaceAll('.', ',')}
                                </b>{' '}
                                kr.
                            </TableCell>
                            <TableCell
                                sx={{
                                    minWidth: '115px',
                                    width: '13%',
                                    '@media (max-width: 600px)': {
                                        fontSize: '10px',
                                        minWidth: 'unset'
                                    }
                                }}
                            ></TableCell>

                            <TableCell
                                sx={{
                                    color:
                                        overridings.palette.primary
                                            .contrastText,
                                    minWidth: '115px',
                                    width: '13%',
                                    '@media (max-width: 600px)': {
                                        fontSize: '10px',
                                        minWidth: 'unset'
                                    }
                                }}
                                align="center"
                            >
                                <b>
                                    {sumDiscountedPricePerMonth
                                        .toString()
                                        .replaceAll('.', ',')}{' '}
                                    kr.
                                </b>
                            </TableCell>

                            <TableCell
                                sx={{
                                    color:
                                        overridings.palette.primary
                                            .contrastText,
                                    '@media (max-width: 600px)': {
                                        fontSize: '10px',
                                        minWidth: 'unset'
                                    }
                                }}
                                align="right"
                            >
                                <b>Opkræves den {dueDate}</b>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )}
        </>
    );
}

export default SumRowDiscountedPrice;
