import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import 'moment/locale/da';

import LeftArrow from './arrow/LeftArrow';
import RightArrow from './arrow/RightArrow';
import Room from './Room.jsx';

import '../../index.css';

// Helper functions
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link, withRouter } from 'react-router-dom';
import { Context } from '../../context/Context';
import { withContext } from '../containers/ContextContainer';
import { sortEvents } from './EventFunctions';

import {
    getEventIdFromStorage,
    getProperRoomId,
    getUserId,
    getUserEmail,
    returnAuthenticated,
    getOwnEvents,
    returnRepeatSeqId,
    getDateFromQuery,
    hasPrevPageDestination,
    getPrevPageDestination,
    getPrevPageUrl
} from '../../context/getData';
import {
    deleteAndReturnResp,
    getAndReturnData,
    postAndReturnResp
} from '../../context/helperFunctions';
import ErrorDialog from '../dialogs/error-dialog/ErrorDialog';
import DeleteDialog from '../dialogs/delete-dialog/DeleteDialog';
import ReturnLinkReusable from '../links/ReturnLinkReusable';
import SuccessBookingDialog from '../dialogs/success-booking-dialog/SuccessBookingDialog';
import BookingSummary from './booking-summary/BookingSummary';
import ConflictingEventsDialog from '../dialogs/conflicting-events-dialog/ConflictingEventsDialog';
// import ProgressBar from "../progress-bar/ProgressBar";
// import RegistrationContentContainer from "../containers/registration/RegistrationContentContainer";
// import CheckboxComponent from "../../components/checkbox/CheckboxComponent";
import DatePickerComponent from '../date-picker/DatePickerStartdate';
import SwitchButtonCalendar from '../switch-button/SwitchButtonCalendar';

const DatePickerContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    width: 20rem;
`;
const PickerTitle = styled(Typography)`
    font-size: 1.2rem;
    margin-bottom: 5px;
    color: ${(props) =>
        props.haserror === 'true'
            ? props.theme.palette.error.main
            : props.theme.palette.primary.contrastText};
`;

const CalendarContainer = styled.div`
    margin-bottom: 5rem;
    width: 100%;
    @media (min-width: 960px) {
        display: flex;
        flex-basis: 100%;
        flex-wrap: nowrap;
        align-items: stretch;
        /* height: auto; */
        height: auto;
        min-height: 50vh;
        justify-content: center;
    }
`;

const CalendarHeadlineContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const MiddleColumn = styled.div`
    /* position: relative; */
    @media (min-width: 960px) {
        width: 85%;
        margin: 0 auto;
    }
`;

const BacklinkContainer = styled(Box)`
    cursor: pointer;
`;

const LeftSideColumn = styled.div`
    position: absolute;
    width: 5%;
    left: 1rem;
`;

const RightSideColumn = styled.div`
    position: absolute;
    width: 5%;
    right: 1rem;
`;

const MainContainer = styled.div`
  /* display:${(props) => (props.checked ? 'block' : 'none')} ; */
  display: block;
  @media (min-width: 960px) {
    width: 100%;
    margin: 0rem auto;
    position: relative;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;

// const DayDate = styled(Typography)`
//     margin: 1rem 0rem;
// `;
const CalendarTitle = styled(Typography)`
    margin-top: 0.5rem;
    font-size: 1.2rem;
    text-align: left;
    @media (min-width: 960px) {
        font-size: 1.5rem;
    }
`;

const UnderlinedText = styled.span`
    text-decoration: underline;
`;

class RoomsDesktopCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateObject: moment(),
            rooms: ['1', '2', '3', '4', '5', '6', '7', '8'],
            roomsNames: [
                'Lokale1',
                'Lokale 2',
                'Lokale 3',
                'Lokale 4',
                'Lokale 5',
                'Lokale 6',
                'Lokale 7',
                'Lokale 8'
            ],
            weekNumber: '',
            dayDate: '',
            today: '',
            chosenRoom: 1,
            events: [],
            monthName: '',
            isLoading: false,
            roomId: 1,
            isBookingCalendar: false,

            // Events that the user created
            ownDraftEvents: [],

            //Error dialog
            error: false,
            errorMessage: '',

            // Success dialog
            showSuccessBooking: false,
            // Events, which has been created after submiting a request -  These are displayed in the success dialog
            justBookedEvents: [],

            //Conflict booking dialog
            showConflictBooking: false,
            // Events, which has been created after submiting a request -  These are displayed in the conflict events dialog
            conflictingEvents: [],
            conflictPostRequestBody: {},

            //Dialog for confirming the deletion of booking
            deleteDialog: false,
            deleteDialogMessage: '',
            selectedEventIdToDelete: null,
            multipleEvents: false,

            // Editing a selected event
            selectedEventIdToEdit: null,
            acceptedRooms: [
                'Lokale 1',
                'Lokale 2',
                'Lokale 3',
                'Lokale 4',
                'Lokale 5',
                'Lokale 6',
                'Lokale 7',
                'Lokale 8',
                'Alle'
            ],
            acceptedRoom1: true,
            acceptedRoom2: true,
            acceptedRoom3: true,
            acceptedRoom4: true,
            acceptedRoom5: true,
            acceptedRoom6: true,
            acceptedRoom7: true,
            acceptedRoom8: true,
            acceptedRoomAll: true,

            trigger: false
        };
    }

    static contextType = Context;
    colors = ['blue', 'purple', 'green', 'orange', 'yellow'];

    // Set date object, dates when arrows are clicked
    setDateObject = (dateObject) => {
        this.setState({
            dateObject: dateObject,
            startDate: dateObject,
            endDate: dateObject
        });
        // Change date in url query param too
        let path;
        if (window.location.href.includes('booking-kalender')) {
            path = 'booking-kalender';
        } else {
            path = 'aktivitets-kalender';
        }
        this.props.history.push(
            `/${path}/1?date=${moment(dateObject).format('YYYY-MM-DD')}`
        );
    };

    setJustBookedEvents = (events) => {
        this.setState({ justBookedEvents: events, showSuccessBooking: true });
    };

    editBookedEvent = async (eventId) => {
        this.setState({ selectedEventIdToEdit: eventId });
    };

    // A dialog is displayed to make the user confirm the deletion
    // If they press yes, the removeBookedEvent will be run
    confirmRemovingEvent = async (eventId, repeatSeqId) => {
        if (repeatSeqId) {
            // Check if there are multiple events to delete
            // If there are, we need to ask the user,  if they want to delete all related events
            const data = await getAndReturnData(
                `/api/v1/events/${repeatSeqId}`,
                this.handleError
            );
            if (data && data.events && data.events.length > 1) {
                this.setState({
                    deleteDialog: true,
                    selectedEventIdToDelete: eventId,
                    multipleEvents: true
                });
            } else {
                this.setState({
                    deleteDialog: true,
                    selectedEventIdToDelete: eventId
                });
            }
        } else {
            this.setState({
                deleteDialog: true,
                selectedEventIdToDelete: eventId
            });
        }
        this.updateOwnEvents();
    };

    // User is able to delete its own event by confirming the deletion in the DeleteDialog
    removeBookedEvent = async (eventId, repeatSeqId) => {
        const getUrl = () => {
            if (repeatSeqId !== undefined) {
                return `/api/v1/users/${getUserId()}/events/draft/${eventId}?repeatSeqId=${repeatSeqId}`;
            } else {
                return `/api/v1/users/${getUserId()}/events/draft/${eventId}`;
            }
        };
        const resp = await deleteAndReturnResp(getUrl(), this.handleError);
        if (resp && resp.status === 200) {
            this.updateOwnEvents();
        } else {
            this.handleError(
                'Noget gik galt. Kunne ikke fjerne reservationen.  Prøv igen.'
            );
        }

        // Update events by getting events data from backend, and update own events.
        // this.fetchAndFilterEvents(this.state.isBookingCalendar);
        this.updateOwnEvents(this, this.handleError);
    };

    setConflictingEvents = (events) => {
        this.setState({ conflictingEvents: events, showConflictBooking: true });
    };
    removeEdit = () => {
        this.setState({ selectedEventIdToEdit: null });
        if (getEventIdFromStorage()) {
            this.context.saveEventIdToEdit(0);
        }
    };

    updateOwnEvents = async () => {
        if (returnAuthenticated()) {
            const ownEvents = await getOwnEvents(this.handleError);
            if (ownEvents) {
                const onlyDrafts = ownEvents.filter(
                    (event) => event.state === 'draft'
                );
                if (onlyDrafts) {
                    this.setState({ ownDraftEvents: sortEvents(onlyDrafts) });
                }
            }
        }
    };

    // Come back: handle error
    handleError = (message) => {
        if (message && message !== '') {
            this.setState({
                error: true,
                errorMessage: message
            });
        } else {
            this.setState({
                error: true,
                errorMessage: 'Noget gik galt. Prøv at opdatere siden.'
            });
        }
    };

    getDayName = (date) => {
        return moment(date).format('dddd');
    };

    handleCheckboxChangeCategories = (room) => {
        this.setState({ trigger: !this.state.trigger });
        if (room === 'Alle') {
            if (!this.state.acceptedRoomAll) {
                this.setState({
                    acceptedRooms: [
                        'Lokale 1',
                        'Lokale 2',
                        'Lokale 3',
                        'Lokale 4',
                        'Lokale 5',
                        'Lokale 6',
                        'Lokale 7',
                        'Lokale 8',
                        'Alle'
                    ]
                });
                this.setState({
                    rooms: ['1', '2', '3', '4', '5', '6', '7', '8']
                });
                this.context.saveSelectedRooms([
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8'
                ]);
                this.setState({ acceptedRoomAll: true });
                this.setState({ acceptedRoom1: true });
                this.setState({ acceptedRoom2: true });
                this.setState({ acceptedRoom3: true });
                this.setState({ acceptedRoom4: true });
                this.setState({ acceptedRoom5: true });
                this.setState({ acceptedRoom6: true });
                this.setState({ acceptedRoom7: true });
                this.setState({ acceptedRoom8: true });
            }
            this.setState({ acceptedRoomAll: !this.state.acceptedRoomAll });
            let newRooms = this.state.acceptedRooms.filter((r) => r !== 'Alle');
            this.setState({ acceptedRooms: newRooms });
        } else {
            let roomNumber = room.slice(-1);
            if (this.state.acceptedRoomAll) {
                // let newRooms = this.state.acceptedRooms.filter((r) => r !== 'Alle');
                // this.setState({ acceptedRooms: newRooms })
                this.setState({ acceptedRoomAll: !this.state.acceptedRoomAll });
            }
            if (this.state.acceptedRooms.includes(room)) {
                let newRooms = this.state.acceptedRooms.filter(
                    (r) => r !== room && r !== 'Alle'
                );
                let newRoomsNum = this.state.rooms.filter(
                    (rNum) => rNum !== roomNumber
                );
                this.setState({ rooms: newRoomsNum });
                this.context.saveSelectedRooms(newRoomsNum);
                this.setState({ acceptedRooms: newRooms });
                // setUserCategories(newCategories);
                // onSubmit(null, null, newRooms);
            } else {
                this.setState({
                    acceptedRooms: [...this.state.acceptedRooms, room]
                });
                this.setState({ rooms: [...this.state.rooms, roomNumber] });
                this.context.saveSelectedRooms([
                    ...this.state.rooms,
                    roomNumber
                ]);

                // setUserCategories([...userCategories, category]);
                // onSubmit(null, null, [...userCategories, category]);
            }
        }

        switch (room) {
            case 'Lokale 1':
                return this.setState({
                    acceptedRoom1: !this.state.acceptedRoom1
                });
            case 'Lokale 2':
                return this.setState({
                    acceptedRoom2: !this.state.acceptedRoom2
                });
            case 'Lokale 3':
                return this.setState({
                    acceptedRoom3: !this.state.acceptedRoom3
                });
            case 'Lokale 4':
                return this.setState({
                    acceptedRoom4: !this.state.acceptedRoom4
                });
            case 'Lokale 5':
                return this.setState({
                    acceptedRoom5: !this.state.acceptedRoom5
                });
            case 'Lokale 6':
                return this.setState({
                    acceptedRoom6: !this.state.acceptedRoom6
                });
            case 'Lokale 7':
                return this.setState({
                    acceptedRoom7: !this.state.acceptedRoom7
                });
            case 'Lokale 8':
                return this.setState({
                    acceptedRoom8: !this.state.acceptedRoom8
                });
            case 'Alle':
                return this.setState({
                    acceptedRoomAll: !this.state.acceptedRoomAll
                });

            default:
            // setError(true);
            // setErrorMessage("Category error");
        }
    };

    // Sending the request body, which is needed to create a recurring booking, if there is conflicts,
    // and the user wants to create the events anyways.
    setConflictPostRequestBody = (requestBody) => {
        this.setState({ conflictPostRequestBody: requestBody });
    };

    componentDidMount() {
        //Find out if it is activity calendar or booking calendar, because we display events accordingly
        let path;
        if (window.location.href.includes('booking-kalender')) {
            this.setState({ isBookingCalendar: true });
            path = 'booking-kalender';
        } else {
            this.setState({ isBookingCalendar: false });
            path = 'aktivitets-kalender';
        }

        // Get dateobject from query Url. That shows, what week we should show
        const dateFromQuery = getDateFromQuery(this);
        let dateObject;
        if (dateFromQuery) {
            dateObject = dateFromQuery;
        } else {
            dateObject = this.state.dateObject;
        }
        this.setState({
            dateObject: moment(dateObject).format('YYYY-MM-DD')
        });

        // Build calendar, and get events, available times
        this.setState(
            {
                dayDate: moment(dateObject)
            },
            () => {
                // Filter data to default room, use fetched data here
                // this.fetchAndFilterEvents(isBookingCalendar);
            }
        );

        this.updateOwnEvents();

        // Check if there is any event id to edit in local storage
        if (getEventIdFromStorage() && getEventIdFromStorage() !== 0) {
            this.setState({ selectedEventIdToEdit: getEventIdFromStorage() });
        }
        this.props.history.push(
            `/${path}/1?date=${moment(dateObject).format('YYYY-MM-DD')}`
        );
    }

    bookNonConflictingEvents = async () => {
        // See setConflictingPostRequestBody to modify the requestBody
        const response = await postAndReturnResp(
            '/api/v1/events/non-conflicting-events/booking', // Here the new endpoint should be implemented
            this.state.conflictPostRequestBody,
            this.handleError
        );

        if (!response || response.status !== 200) {
            return this.handleError();
        }

        if (response && response.status === 200) {
            const data = await response.json();
            if (
                // This part is dependent on the endpoint, how the data is being returned
                data &&
                data.createdEvents &&
                data.createdEvents.length > 0 &&
                data.createdEvents[0].id
            ) {
                // This is needed to refresh the events in the calendar.
                // this.fetchAndFilterEvents(this.state.isBookingCalendar);
                // This is needed to be able to display in the coming dialog of what events has been created
                this.setJustBookedEvents(data.createdEvents);
            }
        }
    };

    render() {
        return (
            <MainContainer checked={this.props.checked}>
                <Box display="flex">
                    <LeftSideColumn>
                        <LeftArrow
                            top="25rem"
                            arrowType={'rooms'}
                            setDateObject={this.setDateObject}
                            dateObject={this.state.dateObject}
                        />
                    </LeftSideColumn>
                    <MiddleColumn>
                        <CalendarHeadlineContainer>
                            <Box>
                                {hasPrevPageDestination(this.props) ? (
                                    <ReturnLinkReusable
                                        destination={getPrevPageDestination(
                                            this.props
                                        )}
                                        url={getPrevPageUrl(this.props)}
                                        marginBottom="2rem"
                                    />
                                ) : (
                                    <BacklinkContainer
                                        width="fit-content"
                                        onClick={() => {
                                            this.props.history.goBack();
                                            this.props.history.goBack();
                                        }}
                                    >
                                        <ReturnLinkReusable marginBottom="2rem" />
                                    </BacklinkContainer>
                                )}

                                <CalendarTitle
                                    variant="h4"
                                    gutterBottom
                                    m="2"
                                    mb="1.5rem"
                                    component="h1"
                                    className="calendar-title"
                                >
                                    {this.state.isBookingCalendar
                                        ? 'Lokalevisning'
                                        : 'Aktiviteter på Bülowsvej 10'}
                                </CalendarTitle>
                                {this.state.isBookingCalendar ? (
                                    <></>
                                ) : (
                                    //     <Typography variant="body2">
                                    //         Når du vil reservere et lokale, kan du
                                    //         vælge mellem to visninger:
                                    //         <br />
                                    //         Ugevisning: En ugeoversigt for et lokale
                                    //         ad gangen – skift lokale i rullemenuen.
                                    //         <br />
                                    //         Lokalevisning: En dagsoversigt over alle
                                    //         lokaler – skift dag med piletast eller
                                    //         minikalenderen. Du kan se ledige tider
                                    //         og dine reservationer her. Du kan se
                                    //         alle aktiviteter i
                                    //         <Link to="/aktivitets-kalender">
                                    //             {' '}
                                    //             <UnderlinedText>
                                    //                 aktivitets kalenderen
                                    //             </UnderlinedText>
                                    //         </Link>
                                    //         .
                                    //     </Typography>
                                    <Typography variant="body2">
                                        Dette er hele husets aktivitetsoversigt.{' '}
                                        <br />
                                        Medlemmer kan bruge oversigten til at se
                                        hvilke lokaler, der er ledige. <br />
                                        Hold musen over en aktivitet for at læse
                                        mere om arrangøren. <br />
                                        Ønsker du at foretage en reservation af
                                        et lokale? Klik
                                        <Link to="/booking-kalender">
                                            {' '}
                                            <UnderlinedText>her</UnderlinedText>
                                        </Link>
                                        .
                                    </Typography>
                                )}
                            </Box>
                        </CalendarHeadlineContainer>

                        {/* <DayDate>
                            {`${moment(this.state.dateObject)
                                .locale('da')
                                .format('dddd')
                                .slice(0, 1)
                                .toUpperCase()}${moment(this.state.dateObject)
                                .locale('da')
                                .format('dddd')
                                .slice(1)}  ${moment(
                                this.state.dateObject
                            ).format('DD.MM.YYYY')}`}
                        </DayDate> */}
                        <Box display="flex">
                            <Box mb="2rem">
                                <DatePickerContainer>
                                    <PickerTitle>Skift dag:</PickerTitle>
                                    <DatePickerComponent
                                        startdate={
                                            new Date(this.state.dateObject)
                                        }
                                        setStartdate={this.setDateObject}
                                    />
                                </DatePickerContainer>
                            </Box>
                            {document.body.offsetWidth < 960 ? (
                                <></>
                            ) : (
                                <SwitchButtonCalendar
                                    marginTop="3rem"
                                    handleChange={
                                        this.props.handleCalendarChange
                                    }
                                    checked={this.props.checked}
                                />
                            )}
                        </Box>

                        <CalendarContainer>
                            {this.state.rooms.map((room, key) => {
                                this.color = this.colors[
                                    Math.floor(
                                        Math.random() * this.colors.length
                                    )
                                ];
                                while (
                                    this.color === this.oldColor ||
                                    this.color === 'yellow'
                                ) {
                                    this.color = this.colors[
                                        Math.floor(
                                            Math.random() * this.colors.length
                                        )
                                    ];
                                }

                                this.oldColor = this.color;

                                return (
                                    <Room
                                        isBookingCalendar={
                                            this.state.isBookingCalendar
                                        }
                                        key={key}
                                        roomNumber={room}
                                        roomName={this.state.acceptedRooms[key]}
                                        dayName={this.getDayName(
                                            moment(this.state.dateObject)
                                        )}
                                        date={this.state.dateObject}
                                        handleError={this.handleError}
                                        selectedEventIdToEdit={
                                            this.state.selectedEventIdToEdit
                                        }
                                        editBookedEvent={this.editBookedEvent}
                                        removeEdit={this.removeEdit}
                                        roomsCount={this.state.rooms.length}
                                        roomId={getProperRoomId(room)}
                                        setJustBookedEvents={
                                            this.setJustBookedEvents
                                        }
                                        updateOwnEvents={this.updateOwnEvents}
                                        ownEvents={this.state.ownDraftEvents}
                                        confirmRemovingEvent={
                                            this.confirmRemovingEvent
                                        }
                                        setConflictingEvents={
                                            this.setConflictingEvents
                                        }
                                        setConflictPostRequestBody={
                                            this.setConflictPostRequestBody
                                        }
                                        trigger={this.state.trigger}
                                        // date={this.state.dateObject}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        rooms={this.state.rooms}
                                    />
                                );
                            })}
                            {/* </DaysContainer> */}
                        </CalendarContainer>
                        {returnAuthenticated() &&
                            this.state.isBookingCalendar && (
                                <>
                                    {getUserEmail() !==
                                        'admin@denlillemusikskole.dk' && (
                                        <BookingSummary
                                            ownEvents={
                                                this.state.ownDraftEvents
                                            }
                                            confirmRemovingEvent={
                                                this.confirmRemovingEvent
                                            }
                                            isBookingCalendar={
                                                this.state.isBookingCalendar
                                            }
                                            editBookedEvent={
                                                this.editBookedEvent
                                            }
                                            chosenRoom={this.state.chosenRoom}
                                            getSelectedRoom={
                                                this.getSelectedRoom
                                            }
                                            roomId={this.state.roomId}
                                            calendarType={'rooms'}
                                        />
                                    )}
                                </>
                            )}
                    </MiddleColumn>
                    <RightSideColumn>
                        <RightArrow
                            top="25rem"
                            arrowType={'rooms'}
                            setDateObject={this.setDateObject}
                            dateObject={this.state.dateObject}
                        />
                    </RightSideColumn>
                </Box>

                {this.state.isLoading && (
                    <Box display="flex" justifyContent="center" pt="2rem">
                        <CircularProgress color="primary.contrastText" />
                    </Box>
                )}
                {this.state.error && (
                    <ErrorDialog
                        dialogTitle=""
                        close={() =>
                            this.setState({ error: false, errorMessage: '' })
                        }
                        errorMessage={this.state.errorMessage}
                    />
                )}

                {this.state.showSuccessBooking && (
                    <SuccessBookingDialog
                        events={this.state.justBookedEvents}
                        close={() => {
                            if (this.state.conflictingEvents.length > 0) {
                                window.location.reload(); // come back later and remove this!!
                            } else {
                                this.setState({
                                    showSuccessBooking: false,
                                    showConflictBooking: false,
                                    justBookedEvents: [],
                                    conflictPostRequestBody: {},
                                    conflictingEvents: []
                                });
                            }

                            // window.location.reload(); // come back later and remove this!!
                        }}
                    />
                )}

                {this.state.showConflictBooking && (
                    <ConflictingEventsDialog
                        conflictingEvents={this.state.conflictingEvents}
                        bookNonConflictingEvents={this.bookNonConflictingEvents}
                        close={() => {
                            this.setState({
                                showConflictBooking: false,
                                conflictingEvents: []
                            });
                        }}
                    />
                )}

                {this.state.deleteDialog && (
                    <DeleteDialog
                        close={() =>
                            this.setState({
                                deleteDialog: false,
                                selectedEventIdToDelete: null,
                                multipleEvents: false
                            })
                        }
                        eventId={this.state.selectedEventIdToDelete}
                        repeatSeqId={returnRepeatSeqId(
                            this.state.ownDraftEvents,
                            this.state.selectedEventIdToDelete
                        )}
                        removeBookedEvent={this.removeBookedEvent}
                        multipleEvents={this.state.multipleEvents}
                        isDraftEvent={true}
                    />
                )}
            </MainContainer>
        );
    }
}

export default withContext(withRouter(RoomsDesktopCalendar));
