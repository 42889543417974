import { getAndReturnResponse } from '../../context/helperFunctions';

export const getProfileImages = async (profileImagesIds, userId) => {
    let profileImages;
    if (profileImagesIds === undefined) {
        profileImages = '/images/avatar.png';
    } else {
        const res = await getFilesUrls(profileImagesIds, userId);
        profileImages = res;
    }
    return profileImages;
};

export const getFilesUrls = async (profileImagesIds, userId) => {
    let res;
    return Promise.all(
        profileImagesIds.map(async (imageId) => {
            res = await getAndReturnResponse(
                `/api/v1/users/${userId}/${imageId}/files`
            );
            return res.url;
        })
    );
};

export const profileImagesFunc = async (
    imagesUrl,
    userId,
    setUserProfileImages,
    setIsLoading
) => {
    const profileImgs = await getProfileImages(imagesUrl, userId);
    if (profileImgs.length > 0) setUserProfileImages(profileImgs);
};
